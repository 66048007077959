import CommonService from "../../../service/common_service";
import { put } from "redux-saga/effects";
import { showPopup } from "../../popup/actions/popup_actions";
import { GET_QRCODES, GET_QRCODES_VALORE_FAIL } from "../../qrCode/actions/qrCode_actions"

import axios from "axios"
import CommonServicePdf from "../../../service/common_service_pdf";

export const GET_RACCOLTA = "getRaccolta"
export const GET_RACCOLTA_SUCCESS = "get_raccolta_success"
export const GET_RACCOLTA_FAIL = "get_raccolta_fail"


export function getRaccolta() {
  return {
      type: GET_RACCOLTA
  }
}

export function* getRaccoltaSaga() {
  try {

      const request = {
          method: "GET",
          url: "/api/v1/raccolta/cerca",
      }

      const response = yield CommonService.execute(request)
      yield put({
          type: GET_RACCOLTA_SUCCESS,
          payload: response.data
      })

  } catch (error) {
      yield put({
          type: GET_RACCOLTA_FAIL,
          payload: error
      })
      yield put(
        showPopup(
            "error",
            "OPERAZIONE FALLITA",
            error.response.data.data.error
        )
    )
  }
}

export const INSERT_RACCOLTA = "insertRaccolta"
export const INSERT_RACCOLTA_SUCCESS = "insertRaccolta_success"
export const INSERT_RACCOLTA_FAILED = "insertRaccolta_failed"

export function insertRaccolta(mastello, data, quantita) {
    return {
        type: INSERT_RACCOLTA,
        payload: {
            mastello: mastello,
            data: data,
            quantita: quantita
        }
    }
}

export function* insertRaccoltaSaga(action) {
    try {
        const request = {
            url: "/api/v1/raccolta/insert", // crea un raccolta e lo assegna all'utenza
            method: "POST",
            data: {
                quantita: action.payload.quantita,
                data: action.payload.data,
                mastello: action.payload.mastello,
                
                
            }
        }

        const response = yield CommonService.execute(request)
        yield put({
            type: GET_RACCOLTA
        })
        yield put(
            showPopup(
                "notify",
                "OPERAZIONE RIUSCITA",
                response.data.message
            )
        )
        yield put({
            type: GET_QRCODES
        })
        yield put ({
            type: GET_QRCODES_VALORE_FAIL
        })

    } catch (error) {
        yield put({
            type: INSERT_RACCOLTA_FAILED,
            payload: error.response.data.data.error
        })
        yield put(
            showPopup(
                "error",
                "OPERAZIONE FALLITA",
                error.response.data.message
            )
        )
    }
}

export const UPDATE_RACCOLTA = "updateRaccolta"
export const UPDATE_RACCOLTA_SUCCESS = "updateRaccolta_success"
export const UPDATE_RACCOLTA_FAILED = "updateRaccolta_failed"

export function updateRaccolta(raccoltaId, utenza, tipologiaSpazzatura, valoreQrCode, statoQr) {
    return {
        type: UPDATE_RACCOLTA,
        payload: {
            raccoltaId : raccoltaId,
            utenza: utenza,
            tipologiaSpazzatura: tipologiaSpazzatura,
            valoreQrCode: valoreQrCode,
            statoQr: statoQr
        }
    }
}

export function* updateRaccoltaSaga(action) {
    try {
        const request = {
            url: "/api/v1/raccolta/update",
            method: "PUT",
            data: {
                id: action.payload.raccoltaId,
                utenzaId: action.payload.utenza,
                tipologiaSpazzaturaId: action.payload.tipologiaSpazzatura,
                valoreQR: action.payload.valoreQrCode,
                statoQr: action.payload.statoQr
            }
        }

        const response = yield CommonService.execute(request)
        yield put({
            type: GET_RACCOLTA
        })
        yield put({
            type: GET_QRCODES
        })
        yield put(
            showPopup(
                "notify",
                "OPERAZIONE RIUSCITA",
                response.data.message
            )
        )

    } catch (error) {
        yield put({
            type: UPDATE_RACCOLTA_FAILED,
            payload: error.response.data.data.error
        })
        yield put(
            showPopup(
                "error",
                "OPERAZIONE FALLITA",
                error.response.data.message
            )
        )
    }
}

export const DELETE_RACCOLTA = "deleteRaccolta"
export const DELETE_RACCOLTA_SUCCESS = "delete_raccolta_success"
export const DELETE_RACCOLTA_FAILED = "delete_raccolta_fail"

export function deleteRaccolta(raccoltaId) {
    return {
        type: DELETE_RACCOLTA,
        payload: raccoltaId
    }
}

export function* deleteRaccoltaSaga(action) {
    try {
        const url = "/api/v1/raccolta/storna-qrcode/" + action.payload // Elimina un Raccolta e riporta a false lo stato del QrCode associato
        const request = {
            method: "DELETE",
            url: url,
        }

        const response = yield CommonService.execute(request)
        yield put({
            type: GET_RACCOLTA
        })
        yield put({
            type: GET_QRCODES
        })
        yield put(
            showPopup(
                "notify",
                "OPERAZIONE RIUSCITA",
                response.data.message
            )
        )  

    } catch (error) {
        yield put({
            type: DELETE_RACCOLTA_FAILED,
            payload: error.response.data.data.error
        })
        yield put(
            showPopup(
                "error",
                "OPERAZIONE FALLITA",
                error.response.data.data.error
            )
        )
    }
}

export const STAMPA_RACCOLTA = "stampaRaccolta"
export const STAMPA_RACCOLTA_SUCCESS = "stampa_raccolta_success"
export const STAMPA_RACCOLTA_FAILED = "stampa_raccolta_fail"

export function stampaRaccolta(id_utenza,comune, 
                                comune_indirizzo, 
                                data, 
                                num_raccolta, 
                                tipo_raccolta, 
                                nome_cognome, 
                                cod_fisc,
                                indirizzo, 
                                comune_utenza) {
    return {
        type: STAMPA_RACCOLTA,
        payload: {
             id_utenza: id_utenza,
             comune: comune, 
             comune_indirizzo: comune_indirizzo, 
             data: data, 
             num_raccolta: num_raccolta, 
             tipo_raccolta: tipo_raccolta, 
             nome_cognome: nome_cognome, 
             cod_fisc: cod_fisc,
             indirizzo: indirizzo, 
             comune_utenza: comune_utenza

        }
    }
}

export function* stampaRaccoltaSaga(action) {
    try {
        //const url = "/api/v1/raccolta/export/pdf/" + action.payload // Elimina un Raccolta e riporta a false lo stato del QrCode associato generatePdfFile
        const url = "/api/v1/raccolta/generatePdfFile"// + action.payload // Elimina un Raccolta e riporta a false lo stato del QrCode associato generatePdfFile
        const request = {
            method: "POST",
            url: url,
            data: {
                id_utenza: action.payload.id_utenza,
                comune: action.payload.comune, 
                comune_indirizzo: action.payload.comune_indirizzo, 
                data: action.payload.data, 
                num_raccolta: action.payload.num_raccolta, 
                tipo_raccolta: action.payload.tipo_raccolta, 
                nome_cognome: action.payload.nome_cognome, 
                cod_fisc: action.payload.cod_fisc,
                indirizzo: action.payload.indirizzo, 
                comune_utenza: action.payload.comune_utenza,
            }
        }

        const response_pdf = yield CommonServicePdf.execute(request)

        const file = new Blob([response_pdf.data], { type: 'application/pdf' })
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, '_blank');


        // axios.get(`http://localhost:8080/api/v1/raccolta/generatePdfFile`, {
        //     request,
        //     responseType: 'blob'
        //   }).then((response) => {
        //     window.open(URL.createObjectURL(response.data));
        //   })
        
        // yield put({
        //   type: GET_RACCOLTA,
        //   payload: undefined
        // })
        // yield put({
        //     type: GET_QRCODES
        // })
        yield put(
            showPopup(
                "notify",
                "OPERAZIONE RIUSCITA - Stampa ricevuta generata ed inviata",
                response_pdf.data.message
            )
        )  

        // let blobData = new Blob([file.body], {
        //     type: 'application/pdf'
        // });


        // console.warn(urlFile);
        // this.uploadService.getFile(urlFile).subscribe((responseMessage) => {
    
        //   const extensionFile = urlFile.slice((Math.max(0, urlFile.lastIndexOf(".")) || Infinity) + 1);
        //   console.warn(extensionFile);
        //   let typeFile = 'application/pdf';
        //   if(extensionFile === 'jpg' ){ typeFile = 'image/jpeg'}
        //   if(extensionFile === 'png' ){ typeFile = 'image/png'}
        //   const file = new Blob([responseMessage], { type: typeFile })
        //   const fileURL = URL.createObjectURL(file);
        //   window.open(fileURL, '_blank');
        // });

        


        // let fileData = new File([response.data], "name", {type: 'application/pdf'});
        // let fileURL = window.URL.createObjectURL(fileData);
        // window.open(fileURL,"_blank")

    } catch (error) {
        yield put({
            type: STAMPA_RACCOLTA_FAILED,
            payload: error.response.data.data.error
        })
        yield put(
            showPopup(
                "error",
                "OPERAZIONE FALLITA",
                error.response.data.data.error
            )
        )
    }
}


