import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  ModalFooter,
  Input,
  Select,
  Row,
  Col
} from "design-react-kit";
import { hideOperatoreModal, showOperatoriModal } from "../actions/operatoriModal_actions"
import { getUserInfo } from "../../dashboard/dashboard_Action";
import _ from "lodash";

const OperatoriModal = (props) => {

  const dispatch = useDispatch();

  const userInfo = useSelector(
    (state) => state.userInfo.userInfo
  );

  

  const [cognome, setCognome] = useState();
  const [nome, setNome] = useState();
  const [telefono, setTelefono] = useState();
  const [email, setMail] = useState();

  const [username, setUsername] = useState();
  const [password, setPassword] = useState();

  const [loginOp, setLoginOP] = useState();
  const [ente, setEnte] = useState();
  const [comune, setComune] = useState();

  

  const operatoriToEdit = useSelector((state) => state.operatoreModal);
  const [collaborazioniOptions, setCollaborazioniOptions] = useState();

  const type = useSelector((state) => state.operatoreModal.type);
  const header = useSelector((state) => state.operatoreModal.header);
  const show = useSelector((state) => state.operatoreModal.show);
  const f = useSelector((state) => state.operatoreModal.function);

  const [listaErrori, setListaErrori] = useState([]);
  let listaErroriTmp = [];
  function isValidEmailAddress(address) {
    if(address == null || address === '') return false;
    return !! address.match(/.+@.+/);
  }
  function erroriForm(listaErroriInfo){
    return listaErroriInfo.map(function (errore) {
      return(
        <p className="text-mess-alert">{errore}</p>
      )})  
  }
  
  const [errors, setErrors] = useState({});
  function checkDisabled() {
    return !_.isEmpty(errors);
  }


  const login = {
    "username": username,
    "password": password,
    "tipologiaLogin": "OPERATORE"
  }

  const confirm = () => {

    const login = {
      "username": username,
      "password": password,
      "tipologiaLogin": "OPERATORE"
    }

    listaErroriTmp = []
    let telefonoIsNum = /^\d+$/.test(telefono);
    if(type === "insert"){
      if(username != null || username != ''){
        let codiceFiscale = /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/.test(username);
        let partitaIva = /^[0-9]{11}$/.test(username);
        if(!codiceFiscale && !partitaIva) listaErroriTmp.push("- Username deve essere una Partita Iva o Codice Fiscale")
      }
    }
    
    let emailValid = isValidEmailAddress(email);

    if(!telefonoIsNum) listaErroriTmp.push("- Telefono deve essere un numero valido")
    if(!emailValid) listaErroriTmp.push("- Email non è nel formato corretto")
    if(telefonoIsNum && telefono.length < 6) listaErroriTmp.push("- Telefono deve essere almeno di 6 caratteri")
    if(cognome == null || cognome === '') listaErroriTmp.push("- Cognome è un campo obbligatorio")
    if(cognome != null && cognome.length < 4) listaErroriTmp.push("- Cognome deve essere almeno di 2 caratteri")
    if(nome == null || nome === '') listaErroriTmp.push("- Nome è un campo obbligatorio")
    if(nome != null && nome.length < 4) listaErroriTmp.push("- Nome deve essere almeno di 2 caratteri")
    if(type === "insert"){
      if(username == null || username === '') listaErroriTmp.push("- Username è un campo obbligatorio")
      if(username != null && (username.length != 11 && username.length != 16 )) listaErroriTmp.push("- Username deve essere di 11 (PIva) o 16 (CodFisc) caratteri")
      if(password == null || password === '') listaErroriTmp.push("- Password è un campo obbligatorio")
      if(password != null && password.length < 8) listaErroriTmp.push("- Password deve essere almeno di 8 caratteri")
    }
    if(ente == null || ente === '') listaErroriTmp.push("- Ente è un campo obbligatorio")

    if(listaErroriTmp.length == 0){
      switch (type) {
        case "insert":
            dispatch(f(cognome, nome, telefono, email, login, ente, comune));
          break;
        case "edit":
          let enteId = ente.value
          if(ente.value === undefined || ente.value === null){
            enteId = ente.id
          }
            dispatch(f(operatoriToEdit.obj.id, cognome, nome, telefono, email, operatoriToEdit.obj.login, enteId, comune.id));
          close();
          break;
        default:
          close();
      }
      setListaErrori([])
      close();
    }else{
      setListaErrori(listaErroriTmp)
    }
  };

  const close = () => dispatch(hideOperatoreModal());

  console.log(operatoriToEdit.obj)

  useEffect(() => {
    if (userInfo === undefined) {
      dispatch(getUserInfo());
    }
  }, [userInfo]);

  useEffect(() => {
    if (type !== "edit" && operatoriToEdit.obj === undefined) {
      setCognome(undefined);
      setNome(undefined);
      setTelefono(undefined);
      setMail(undefined);
      setLoginOP(undefined);
      setEnte(undefined);
      if( userInfo!=null && userInfo.tipologiaUtente === "COMUNE"){
        setComune(userInfo.id);
      }
      if(userInfo!=null && userInfo.tipologiaUtente === "ENTE"){
        setComune(userInfo.comuneResidenzaId);
      }

    } else {
      setCognome(operatoriToEdit.obj.cognome);
      setNome(operatoriToEdit.obj.nome);
      setTelefono(operatoriToEdit.obj.telefono);
      setMail(operatoriToEdit.obj.email);
      setLoginOP(operatoriToEdit.obj.loginOp);
      setEnte(operatoriToEdit.obj.ente);
      setComune(operatoriToEdit.obj.comune);
    }
  }, [type, operatoriToEdit]);

  useEffect(() => {
    if (props.enti === undefined) {
      setCollaborazioniOptions([]);
    } else {
      const tmp = [];
      _.forEach(props.enti, (c) => {
        tmp.push({ value: c.ente.id, label: c.ente.denominazione });
      });
      setCollaborazioniOptions(tmp);
    }
  }, [props.enti]);

  const defaultCollaborazioneValue = () => {

    console.log(operatoriToEdit)
    switch (type) {
      case "insert":
        break;
      case "edit":
        return {
          label: operatoriToEdit.obj.ente.denominazione,
          value: operatoriToEdit.id,
        };
      default:
        return {
          label: undefined,
          value: undefined,
        };
    }
  };

  
  

  
  return (
    <div>
      <Modal isOpen={show} labelledBy="operatoreModal">
        <ModalHeader id="operatoreModal">{header}</ModalHeader>
        <ModalBody>
          <FormGroup>
            <div className="bootstrap-select-wrapper">
              {/* <label htmlFor="selectDescrizione">Descrizione</label> */}
              <div className="form-group">
                <Select
                  classNamePrefix="react-select form-group"
                  id="ente"
                  onChange={(selectedOption) => {
                    setEnte(selectedOption);
                  }}
                  options={collaborazioniOptions}
                  defaultValue={defaultCollaborazioneValue()}
                  placeholder="Seleziona ente"
                  aria-label="Seleziona ente"
                />
              </div>
              <Input
                id="cognome"
                onChange={(e) => {
                  setCognome(e.target.value);
                }}
                value={cognome}
                type="text"
                placeholder="Inserisci Cognome"
                aria-label="Inserisci Cognome"
              />
              <Input
                id="nome"
                onChange={(e) => {
                  setNome(e.target.value);
                }}
                value={nome}
                type="text"
                placeholder="Inserisci Nome"
                aria-label="Inserisci Nome"
              />
              <Input
                id="telefono"
                onChange={(e) => {
                  setTelefono(e.target.value);
                }}
                value={telefono}
                type="text"
                placeholder="Inserisci Telefono"
                aria-label="Inserisci Telefono"
              />
              <Input
                id="Email"
                onChange={(e) => {
                  setMail(e.target.value);
                }}
                value={email}
                type="text"
                placeholder="Inserisci Email"
                aria-label="Inserisci Email"
              />
              {type == "insert" &&
              <>
              <Input
                id="Username"
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
                value={username}
                type="text"
                placeholder="Inserisci Codice Fiscale"
                aria-label="Inserisci Codice Fiscale"
              />
              <Input
                id="Password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                value={password}
                type="text"
                placeholder="Inserisci Password"
                aria-label="Inserisci Password"
              />
              </>
              }
              {type == "edit" &&
              <>
              <Input
                id="Username"
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
                value={username}
                type="text"
                placeholder="Non Modificabile"
                aria-label="Non Modificabile"
                disabled
              />
              <Input
                id="Password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                value={password}
                type="text"
                placeholder="Non Modificabile"
                aria-label="Non Modificabile"
                disabled
              />
              </>
              }
              
            </div>
          </FormGroup>
          {listaErrori.length>0 &&
            <Row>
                <Col>
                  {erroriForm(listaErrori)}
                </Col>
            </Row>
          }
        </ModalBody>
        <ModalFooter>
          <span>
            <Button outline color='primary' onClick={() => close()}>ANNULLA</Button>
          </span>
          <span>
            <Button outline color='primary' onClick={() => confirm()} disabled={checkDisabled()}>CONFERMA</Button>
          </span>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default OperatoriModal;
