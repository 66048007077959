import Utenze from "../../common/components/utenze/components/Utenze"
import Collaborazioni from "../../common/components/collaborazioni/components/Collaborazioni"
import Calendario from "../../common/components/calendario/components/Calendario"
import Mastello from "../../common/components/mastello/components/Mastello"
import QrCode from "../../common/components/qrCode/components/QrCode"
import RichiestaIngombranti from "../../common/components/richiestaIngombranti/components/RichiestaIngombranti"
import TipologiaSpazzatura from "../../common/components/tipologiaSpazzatura/components/TipologiaSpazzatura"
import Utente from "../../common/components/utente/components/Utente"
import DashboardComune from "../../common/components/dashboard/comune"
import Operatori from "../../common/components/operatori/components/Operatori"
import ProfiloComune from "../../common/components/profilo"
import Dispositivo from "../../common/components/dispositivi/components/Dispositivo"
import Pesata from "../../common/components/pesata/components/Pesata"
import Enti from "../../common/components/enti/components/Enti"
import Raccolta from "../../common/components/raccolta/components/Raccolta"
import Scontistica from "../../common/components/scontistica/components/Scontistica"
import DashboardAdmin from "../../common/components/dashboard/admin"
import Comune from "../../common/components/comune/components/Comune"


const adminRoutes = [
    {
        path: "/dashboard",
        name: "Home",
        //icon: "",
        component: DashboardAdmin,
        layout: "/admin",
        //icon: "faHome"
    },
    {
        path: "/profilo",
        name: "Profilo",
        //icon: "",
        component: ProfiloComune,
        layout: "/admin",
        //icon: "faHome"
    },
    {
        path: "/comune",
        name: "Comune",
        //icon: "",
        component: Comune,
        layout: "/admin",
        //icon: "faHome"
    },
    {
        path: "/utenze",
        name: "Utenze",
        //icon: "",
        component: Utenze,
        layout: "/admin",
        //icon: "faHome"
    },
    {
        path: "/utenti",
        name: "Utenti",
        //icon: "",
        component: Utente,
        layout: "/admin",
        //icon: "faUser"
    },
    {
        path: "/gestione-pesata",
        name: "Gestione Pesata",
        //icon: "",
        component: Raccolta,
        layout: "/admin",
        //icon: "faHome"
    },
    {
        path: "/gestione-tariffe-scontistica",
        name: "Gestione Tariffe",
        //icon: "",
        component: Scontistica,
        layout: "/admin",
        //icon: "faHome"
    },
    {
        path: "/enti",
        name: "Enti",
        //icon: "",
        component: Enti,
        layout: "/admin",
        //icon: "faCalendar"
    },
    {
        path: "/collaborazioni",
        name: "Collaborazioni",
        //icon: "",
        component: Collaborazioni,
        layout: "/admin",
        //icon: "faCalendar"
    },
    {
        path: "/calendari",
        name: "Calendari",
        //icon: "",
        component: Calendario,
        layout: "/admin",
        //icon: "faCalendar"
    },
    {
        path: "/mastelli",
        name: "Mastelli",
        //icon: "",
        component: Mastello,
        layout: "/admin",
        //icon: "faCalendar"
    },
    {
        path: "/qrCodes",
        name: "QrCodes",
        //icon: "",
        component: QrCode,
        layout: "/admin",
        //icon: "faCalendar"
    },
    {
        path: "/richieste-ingombranti",
        name: "Richieste Ingombranti",
        //icon: "",
        component: RichiestaIngombranti,
        layout: "/admin",
        //icon: "faCalendar"
    },
    {
        path: "/tipologia-spazzatura",
        name: "Tipologie Spazzatura",
        //icon: "",
        component: TipologiaSpazzatura,
        layout: "/admin",
        //icon: "faCalendar"
    },
    {
        path: "/operatori",
        name: "Operatori",
        //icon: "",
        component: Operatori,
        layout: "/admin",
        //icon: "faCalendar"
    },
    {
        path: "/dispositivi",
        name: "Dispositivi",
        //icon: "",
        component: Dispositivo,
        layout: "/admin",
        //icon: "faCalendar"
    }
]

export default adminRoutes