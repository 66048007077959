import { 
    GET_UTENZE, GET_UTENZE_SUCCES, GET_UTENZE_FAIL,
    INSERT_UTENZA, INSERT_UTENZA_SUCCESS, INSERT_UTENZA_FAILED,
    UPDATE_UTENZA, UPDATE_UTENZA_SUCCESS, UPDATE_UTENZA_FAILED,
    DELETE_UTENZA, DELETE_UTENZA_SUCCESS, DELETE_UTENZA_FAILED
} from "../actions/utenze_actions";
import _ from "lodash"

export function utenzeReducer(state = { loading: false, error: undefined, utenze: undefined }, action) {
    if (_.isEmpty(action) || _.isEmpty(action.type)) return state
    const newState = state
    switch (action.type) {
        case GET_UTENZE:
            newState.loading = true
            newState.error = undefined
            newState.utenze = undefined
            break;
        case GET_UTENZE_SUCCES:
            newState.loading = false
            newState.error = undefined
            newState.utenze = action.payload
            break;
        case GET_UTENZE_FAIL:
            newState.loading = false
            newState.error = action.payload
            newState.utenze = undefined
            break;
        case INSERT_UTENZA:
            break;
        case INSERT_UTENZA_SUCCESS:
            break;
        case INSERT_UTENZA_FAILED:
            break;
        case UPDATE_UTENZA:
            break;
        case UPDATE_UTENZA_SUCCESS:
            break;
        case UPDATE_UTENZA_FAILED:
            break;
        case DELETE_UTENZA:
            break;
        case DELETE_UTENZA_SUCCESS:
            break;
        case DELETE_UTENZA_FAILED:
            break;
        default:
            break;
    }
    return Object.assign({}, state, newState)
}