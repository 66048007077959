import CommonService from "../../../service/common_service";
import { put } from "redux-saga/effects"
import { showPopup } from "../../popup/actions/popup_actions";
import { GET_CALENDARI } from "../../calendario/actions/calendario_actions";

export const GET_COLLABORAZIONI = "getCollaborazioni"
export const GET_COLLABORAZIONI_SUCCESS = "get_collaborazioni_success"
export const GET_COLLABORAZIONI_FAIL = "get_collaborazioni_fail"

export function getCollaborazioni() {
    return {
        type: GET_COLLABORAZIONI
    }
}

export function* getCollaborazioniSaga() {
    try {

        const request = {
            method: "GET",
            url: "/api/v1/collaborazione/cerca",
        }

        const response = yield CommonService.execute(request)
        yield put({
            type: GET_COLLABORAZIONI_SUCCESS,
            payload: response.data
        })

    } catch (error) {
        yield put({
            type: GET_COLLABORAZIONI_FAIL,
            payload: error.response.data.data.error
        })
        yield put(
            showPopup(
                "error",
                "OPERAZIONE FALLITA",
                error.response.data.data.error
            )
        )
    }

}

export const DELETE_COLLABORAZIONE = "deleteCollaborazione"
export const DELETE_COLLABORAZIONE_SUCCESS = "delete_collaborazione_success"
export const DELETE_COLLABORAZIONE_FAILED = "delete_collaborazione_fail"

export function deleteCollaborazione(collaborazioneId) {
    return {
        type: DELETE_COLLABORAZIONE,
        payload: collaborazioneId
    }
}

export function* deleteCollaborazioneSaga(action) {
    try {
        const url = "/api/v1/collaborazione/delete/" + action.payload
        const request = {
            method: "DELETE",
            url: url,
        }

        const response = yield CommonService.execute(request)
        yield put({
            type: GET_COLLABORAZIONI
        })
        yield put(
            showPopup(
                "notify",
                "OPERAZIONE RIUSCITA",
                response.data.message
            )
        ) 
        yield put({
            type: GET_CALENDARI
        })

    } catch (error) {
        yield put({
            type: DELETE_COLLABORAZIONE_FAILED,
            payload: error.response.data.data.error
        })
        yield put(
            showPopup(
                "error",
                "OPERAZIONE FALLITA",
                error.response.data.data.error
            )
        )
    }
}

export const INSERT_COLLABORAZIONE = "insertCollaborazione"
export const INSERT_COLLABORAZIONE_SUCCESS = "insertCollaborazione_success"
export const INSERT_COLLABORAZIONE_FAILED = "insertCollaborazione_failed"

export function insertCollaborazione(ente, comune) {
    return {
        type: INSERT_COLLABORAZIONE,
        payload: {
            ente: ente,
            comune: comune // SUPERUSER -> deve inserire anche l'id del comune per creare una nuova collaborazione
        }
    }
}

export function* insertCollaborazioneSaga(action) {
    try {
        const request = {
            url: "/api/v1/collaborazione/insert",
            method: "POST",
            data: {
                enteId: action.payload.ente,
                comuneId: action.payload.comune
            }
        }

        const response = yield CommonService.execute(request)
        yield put({
            type: GET_COLLABORAZIONI
        })
        yield put(
            showPopup(
                "notify",
                "OPERAZIONE RIUSCITA",
                response.data.message
            )
        )

    } catch (error) {
        yield put({
            type: INSERT_COLLABORAZIONE_FAILED,
            payload: error.response.data.data.error
        })
        yield put(
            showPopup(
                "error",
                "OPERAZIONE FALLITA",
                error.response.data.data.error
            )
        )
    }
}