
import CommonService from "../../service/common_service";
import { put } from "redux-saga/effects";
import { showPopup } from "../popup/actions/popup_actions";

export const UPDATE_PWD = "updatePwd"
export const UPDATE_PWD_SUCCESS = "update_pwd_success"
export const UPDATE_PWD_FAILED = "update_pwd_fail"

export function updatePwd(userId, username, pwdPre, pwdNew, pwdconfirm, tipologiaLogin ) {
    return {
        type: UPDATE_PWD,
        payload: {
            userId: userId,
            username: username,
            password: pwdNew,
            oldPassword: pwdPre,
            tipologiaLogin: tipologiaLogin,
        }
    }
}

export function* updatePwdSaga(action) {
    try {
        const request = {
            url: "/api/v1/update-password",
            method: "PUT",
            data: {
                id: action.payload.userId,
                username: action.payload.username,
                password: action.payload.password,
                oldPassword: action.payload.oldPassword,
                tipologiaLogin: action.payload.tipologiaLogin,
            }
        }

        const response = yield CommonService.execute(request)
        yield put({
            type: UPDATE_PWD_SUCCESS,
            payload: response.data.data
        })
        yield put(
            showPopup(
                "notify",
                "OPERAZIONE RIUSCITA",
                response.data.message
            )
        )

    } catch (error) {
        yield put({
            type: UPDATE_PWD_FAILED,
            payload: error
        })
        yield put(
            showPopup(
                "error",
                "OPERAZIONE FALLITA",
                error.response.data.message
            )
        )
    }
}
