import CommonService from "../../../service/common_service"
import { put } from "redux-saga/effects"
import { showPopup } from "../../popup/actions/popup_actions";

export const LOGOUT_REQUEST = "logout_request"
export const LOGOUT_SUCCESS = "logout_success"
export const LOGOUT_FAILED = "logout_failed"

export function logout() {
    return {
        type: LOGOUT_REQUEST
    }
}

export function* logoutSaga() {
    try {

        const request = {
            url: "/api/v1/signout",
            method: "POST",
        }

        const response = yield CommonService.execute(request)
        yield put({
            type: LOGOUT_SUCCESS,
            payload: response.data.message
        })
        // yield put(
        //     showPopup(
        //         "notify",
        //         "OPERAZIONE RIUSCITA",
        //         response.data.message
        //     )
        // )

    } catch (error) {
        yield put({
            type: LOGOUT_FAILED,
            payload: error.response.data.data.error
        })
        yield put(
            showPopup(
                "error",
                "OPERAZIONE FALLITA",
                error.response.data.message
            )
        )
    }

}

