import CommonService from "../../../service/common_service";
import { put } from "redux-saga/effects";
import { showPopup } from "../../popup/actions/popup_actions";
import { GET_QRCODES } from "../../qrCode/actions/qrCode_actions"

import axios from "axios"
import CommonServicePdf from "../../../service/common_service_pdf";

export const GET_PESATE = "getPesate"
export const GET_PESATE_SUCCESS = "get_pesate_success"
export const GET_PESATE_FAIL = "get_pesate_fail"


export function getPesate() {
  return {
      type: GET_PESATE
  }
}

export function* getPesateSaga() {
  try {

      const request = {
          method: "GET",
          url: "/api/v1/pesata/cerca",
      }

      const response = yield CommonService.execute(request)
      yield put({
          type: GET_PESATE_SUCCESS,
          payload: response.data
      })

  } catch (error) {
      yield put({
          type: GET_PESATE_FAIL,
          payload: error
      })
      yield put(
        showPopup(
            "error",
            "OPERAZIONE FALLITA",
            error.response.data.data.error
        )
    )
  }
}

export const INSERT_PESATA = "insertPesata"
export const INSERT_PESATA_SUCCESS = "insertPesata_success"
export const INSERT_PESATA_FAILED = "insertPesata_failed"

export function insertPesata(utenza, tipologiaSpazzatura, valoreQrCode) {
    return {
        type: INSERT_PESATA,
        payload: {
            utenza: utenza,
            tipologiaSpazzatura: tipologiaSpazzatura,
            valoreQrCode: valoreQrCode
        }
    }
}

export function* insertPesataSaga(action) {
    try {
        const request = {
            url: "/api/v1/pesata/pesata-to-utenza", // crea un pesata e lo assegna all'utenza
            method: "POST",
            data: {
                utenzaId: action.payload.utenza,
                tipologiaSpazzaturaId: action.payload.tipologiaSpazzatura,
                valoreQR: action.payload.valoreQrCode
            }
        }

        const response = yield CommonService.execute(request)
        yield put({
            type: GET_PESATE
        })
        yield put(
            showPopup(
                "notify",
                "OPERAZIONE RIUSCITA",
                response.data.message
            )
        )
        yield put({
            type: GET_QRCODES
        })

    } catch (error) {
        yield put({
            type: INSERT_PESATA_FAILED,
            payload: error.response.data.data.error
        })
        yield put(
            showPopup(
                "error",
                "OPERAZIONE FALLITA",
                error.response.data.message
            )
        )
    }
}

export const UPDATE_PESATA = "updatePesata"
export const UPDATE_PESATA_SUCCESS = "updatePesata_success"
export const UPDATE_PESATA_FAILED = "updatePesata_failed"

export function updatePesata(pesataId, utenza, tipologiaSpazzatura, valoreQrCode, statoQr) {
    return {
        type: UPDATE_PESATA,
        payload: {
            pesataId : pesataId,
            utenza: utenza,
            tipologiaSpazzatura: tipologiaSpazzatura,
            valoreQrCode: valoreQrCode,
            statoQr: statoQr
        }
    }
}

export function* updatePesataSaga(action) {
    try {
        const request = {
            url: "/api/v1/pesata/update",
            method: "PUT",
            data: {
                id: action.payload.pesataId,
                utenzaId: action.payload.utenza,
                tipologiaSpazzaturaId: action.payload.tipologiaSpazzatura,
                valoreQR: action.payload.valoreQrCode,
                statoQr: action.payload.statoQr
            }
        }

        const response = yield CommonService.execute(request)
        yield put({
            type: GET_PESATE
        })
        yield put({
            type: GET_QRCODES
        })
        yield put(
            showPopup(
                "notify",
                "OPERAZIONE RIUSCITA",
                response.data.message
            )
        )

    } catch (error) {
        yield put({
            type: UPDATE_PESATA_FAILED,
            payload: error.response.data.data.error
        })
        yield put(
            showPopup(
                "error",
                "OPERAZIONE FALLITA",
                error.response.data.message
            )
        )
    }
}

export const DELETE_PESATA = "deletePesata"
export const DELETE_PESATA_SUCCESS = "delete_pesata_success"
export const DELETE_PESATA_FAILED = "delete_pesata_fail"

export function deletePesata(pesataId) {
    return {
        type: DELETE_PESATA,
        payload: pesataId
    }
}

export function* deletePesataSaga(action) {
    try {
        const url = "/api/v1/pesata/storna-qrcode/" + action.payload // Elimina un Pesata e riporta a false lo stato del QrCode associato
        const request = {
            method: "DELETE",
            url: url,
        }

        const response = yield CommonService.execute(request)
        yield put({
            type: GET_PESATE
        })
        yield put({
            type: GET_QRCODES
        })
        yield put(
            showPopup(
                "notify",
                "OPERAZIONE RIUSCITA",
                response.data.message
            )
        )  

    } catch (error) {
        yield put({
            type: DELETE_PESATA_FAILED,
            payload: error.response.data.data.error
        })
        yield put(
            showPopup(
                "error",
                "OPERAZIONE FALLITA",
                error.response.data.data.error
            )
        )
    }
}

export const STAMPA_PESATA = "stampaPesata"
export const STAMPA_PESATA_SUCCESS = "stampa_pesata_success"
export const STAMPA_PESATA_FAILED = "stampa_pesata_fail"

export function stampaPesata(id_utenza,comune, 
                                comune_indirizzo, 
                                data, 
                                num_pesata, 
                                tipo_pesata, 
                                nome_cognome, 
                                cod_fisc,
                                indirizzo, 
                                comune_utenza) {
    return {
        type: STAMPA_PESATA,
        payload: {
             id_utenza: id_utenza,
             comune: comune, 
             comune_indirizzo: comune_indirizzo, 
             data: data, 
             num_pesata: num_pesata, 
             tipo_pesata: tipo_pesata, 
             nome_cognome: nome_cognome, 
             cod_fisc: cod_fisc,
             indirizzo: indirizzo, 
             comune_utenza: comune_utenza

        }
    }
}

export function* stampaPesataSaga(action) {
    try {
        //const url = "/api/v1/pesata/export/pdf/" + action.payload // Elimina un Pesata e riporta a false lo stato del QrCode associato generatePdfFile
        const url = "/api/v1/pesata/generatePdfFile"// + action.payload // Elimina un Pesata e riporta a false lo stato del QrCode associato generatePdfFile
        const request = {
            method: "POST",
            url: url,
            data: {
                id_utenza: action.payload.id_utenza,
                comune: action.payload.comune, 
                comune_indirizzo: action.payload.comune_indirizzo, 
                data: action.payload.data, 
                num_pesata: action.payload.num_pesata, 
                tipo_pesata: action.payload.tipo_pesata, 
                nome_cognome: action.payload.nome_cognome, 
                cod_fisc: action.payload.cod_fisc,
                indirizzo: action.payload.indirizzo, 
                comune_utenza: action.payload.comune_utenza,
            }
        }

        const response_pdf = yield CommonServicePdf.execute(request)

        const file = new Blob([response_pdf.data], { type: 'application/pdf' })
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, '_blank');


        // axios.get(`http://localhost:8080/api/v1/pesata/generatePdfFile`, {
        //     request,
        //     responseType: 'blob'
        //   }).then((response) => {
        //     window.open(URL.createObjectURL(response.data));
        //   })
        
        // yield put({
        //   type: GET_PESATE,
        //   payload: undefined
        // })
        // yield put({
        //     type: GET_QRCODES
        // })
        yield put(
            showPopup(
                "notify",
                "OPERAZIONE RIUSCITA - Stampa ricevuta generata ed inviata",
                response_pdf.data.message
            )
        )  

        // let blobData = new Blob([file.body], {
        //     type: 'application/pdf'
        // });


        // console.warn(urlFile);
        // this.uploadService.getFile(urlFile).subscribe((responseMessage) => {
    
        //   const extensionFile = urlFile.slice((Math.max(0, urlFile.lastIndexOf(".")) || Infinity) + 1);
        //   console.warn(extensionFile);
        //   let typeFile = 'application/pdf';
        //   if(extensionFile === 'jpg' ){ typeFile = 'image/jpeg'}
        //   if(extensionFile === 'png' ){ typeFile = 'image/png'}
        //   const file = new Blob([responseMessage], { type: typeFile })
        //   const fileURL = URL.createObjectURL(file);
        //   window.open(fileURL, '_blank');
        // });

        


        // let fileData = new File([response.data], "name", {type: 'application/pdf'});
        // let fileURL = window.URL.createObjectURL(fileData);
        // window.open(fileURL,"_blank")

    } catch (error) {
        yield put({
            type: STAMPA_PESATA_FAILED,
            payload: error.response.data.data.error
        })
        yield put(
            showPopup(
                "error",
                "OPERAZIONE FALLITA",
                error.response.data.data.error
            )
        )
    }
}


