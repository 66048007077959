import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  ModalFooter,
  Input,
  Select,
  Col,
  Row,
  Collapse,
  Card,
  CardBody
} from "design-react-kit";
import { hideUtenzaModal } from "../actions/utenzaModal_actions";
import { getUtenteByUsernameClean } from "../../utente/actions/utente_actions";
import _ from "lodash";

const UtenzaModal = (props) => {
  const dispatch = useDispatch();

  const [intestatario, setIntestatario] = useState();
  const [tipoImmobile, setTipoImmobile] = useState();
  const [indirizzo, setIndirizzo] = useState();

  const [intestatarioOptions, setIntestatarioOptions] = useState();
  const [tipoImmobileOptions, setTipoImmobileOptions] = useState();

  const utenzaToEdit = useSelector((state) => state.utenzaModal.obj);

  const intestatarioToUtenza = useSelector((state) => state.utenzaModal.obj);

  const savedUser = useSelector((state) => state.utenzaModal.obj);

  const [modificaUtenza, setmodificaUtenza] = useState(false); 

  const type = useSelector((state) => state.utenzaModal.type);
  const header = useSelector((state) => state.utenzaModal.header);
  const body = useSelector((state) => state.utenzaModal.body);
  const buttonMessage = useSelector((state) => state.utenzaModal.buttonMessage);
  const show = useSelector((state) => state.utenzaModal.show);
  const f = useSelector((state) => state.utenzaModal.function);

  const [collapse, setCollapse] = useState(false);

  let listaErroriTmp = [];
  const [listaErrori, setListaErrori] = useState([]);

  function close() {
    dispatch(getUtenteByUsernameClean());
    dispatch(hideUtenzaModal());
    setCollapse(false);
  }

  function confirm() {
    listaErroriTmp = []
    if(tipoImmobile == null || tipoImmobile.value === '') listaErroriTmp.push("- Tipologia Immobile è un campo obbligatorio")
    if(indirizzo == null || indirizzo === '') listaErroriTmp.push("- Indirizzo è un campo obbligatorio")
    if(listaErroriTmp.length == 0){
    switch (type) {
      case "insert":
        dispatch(f(intestatario.value, tipoImmobile.value, indirizzo));
        break;
      case "associate":
        dispatch(f(intestatarioToUtenza.id, tipoImmobile.value, indirizzo));
        break;
      case "forcedAssociation":
        dispatch(f(savedUser.id, tipoImmobile.value, indirizzo));
        break;
      case "edit":
        dispatch(
          f(utenzaToEdit.id, intestatario.value, tipoImmobile.value, indirizzo)
        );
        break;
      default:
        close();
    }
    setCollapse(false);
    close();
    }else{
      setListaErrori(listaErroriTmp)
    }
  }

  useEffect(() => {
    if (props.intestatarioOptions === undefined) {
      setIntestatarioOptions([]);
    } else {
      console.log(props.intestatarioOptions);
      const tmp = [];
      _.forEach(props.intestatarioOptions, (i) => {
        tmp.push({
          value: i.id,
          label: i.userName + " - " + i.cognome + " " + i.nome,
        });
      });
      setIntestatarioOptions(tmp);
    }
  }, [props.intestatarioOptions]);

  useEffect(() => {
    if (props.tipoImmobileOptions === undefined) {
      setTipoImmobileOptions([]);
    } else {
      const tmp = [];
      _.forEach(props.tipoImmobileOptions, (ti) => {
        tmp.push({ value: ti.id, label: ti.descrizione });
      });
      setTipoImmobileOptions(tmp);
    }
  }, [props.tipoImmobileOptions]);

  useEffect(() => {
    if (type !== "edit") {
      setIntestatario(undefined);
      setTipoImmobile(undefined);
      setIndirizzo(undefined);
    } else {
      setmodificaUtenza(true)
      setIntestatario({
        value: utenzaToEdit.intestatario.id,
        label: utenzaToEdit.proprietario,
      });
      setTipoImmobile({
        label: utenzaToEdit.tipoImmobile.descrizione,
        value: utenzaToEdit.tipoImmobile.id,
      });
      setIndirizzo(utenzaToEdit.indirizzo);
    }
  }, [type, utenzaToEdit]);

  const defaultIntestatarioValue = () => {
    switch (type) {
      case "insert":
        break;
      case "edit":
        return {
          label: utenzaToEdit.proprietario,
          value: utenzaToEdit.intestatario.id,
        };
      case "associate":
        return {
          value: intestatarioToUtenza.id,
          label: intestatarioToUtenza.proprietario,
        };
      case "forcedAssociation":
        return {
          value: savedUser.id,
          label: savedUser.proprietario,
        };
      default:
        return {
          label: undefined,
          value: undefined,
        };
    }
  };

  const defaultTipoImmobileValue = () => {
    switch (type) {
      case "insert":
        break;
      case "edit":
        return {
          label: utenzaToEdit.tipoImmobile.descrizione,
          value: utenzaToEdit.tipoImmobile.id,
        };
      case "associate":
        break;
      case "forcedAssociation":
        break;
      default:
        return {
          label: undefined,
          value: undefined,
        };
    }
  };

  const checkAriaExpanded = () => {
    return (
      <>
        {body ? (
          <>
            <ModalBody>
              <p className='mb-0'>{body}</p>
            </ModalBody>
            <ModalFooter>
              <span>
                <Button
                  outline
                  color="primary"
                  onClick={() => close()}
                  disabled={type === "forcedAssociation"}
                >
                  ANNULLA
                </Button>
              </span>
              <span>
                <Button
                  outline
                  color="primary"
                  onClick={() => setCollapse(!collapse)}
                  aria-expanded={collapse}
                >
                  {buttonMessage}
                </Button>
              </span>
            </ModalFooter>
          </>
        ) : (
          <>
            {utenzaForm()}
          </>
        )}
      </>
    );
  };

  function erroriForm(listaErroriInfo){
    return listaErroriInfo.map(function (errore) {
      return(
        <p className="text-mess-alert">{errore}</p>
      )})  
  }

  const utenzaForm = () => {
    return (
      <>
        <ModalBody>
          <FormGroup>
            <div className="bootstrap-select-wrapper">
              <label htmlFor="selectIntestatario">Intestatario</label>
              <Select
                id="intestatario"
                onChange={(selectedOption) => {
                  setIntestatario(selectedOption);
                }}
                options={intestatarioOptions}
                defaultValue={defaultIntestatarioValue()}
                placeholder="Seleziona intestatario"
                aria-label="Seleziona intestatario"
                isDisabled={type === "associate" || type === "forcedAssociation" || modificaUtenza ? true : false}
              />
            </div>
          </FormGroup>
          <FormGroup>
            <div className="bootstrap-select-wrapper">
              <label htmlFor="selectTipoImmobile">Tipologia Immobile</label>
              <Select
                id="tipoImmobile"
                onChange={(selectedOption) => {
                  setTipoImmobile(selectedOption);
                }}
                options={tipoImmobileOptions}
                defaultValue={defaultTipoImmobileValue()}
                placeholder="Seleziona tipologia immobile"
                aria-label="Seleziona tipologia immobile"
              />
            </div>
          </FormGroup>
          <FormGroup>
            <div className="bootstrap-select-wrapper">
              <label htmlFor="inputIndirizzo">Indirizzo</label>
              <Input
                id="indirizzo"
                onChange={(e) => {
                  setIndirizzo(e.target.value);
                }}
                value={indirizzo}
                type="text"
                placeholder="Inserisci indirizzo"
                aria-label="Inserisci indirizzo"
              />
            </div>
          </FormGroup>
          {listaErrori.length>0 &&
            <Row>
                <Col>
                  {erroriForm(listaErrori)}
                </Col>
            </Row>
          }
        </ModalBody>
        <ModalFooter>
          <span>
            <Button
              outline
              color="primary"
              onClick={() => close()}
              // disabled={type === "forcedAssociation"}
            >
              ANNULLA
            </Button>
          </span>
          <span>
            <Button outline color="primary" onClick={() => confirm()}>
              CONFERMA
            </Button>
          </span>
        </ModalFooter>
      </>
    );
  };

  const utenzaModal = () => {
    return (
      <div>
        <Modal isOpen={show} labelledBy="utenteModal">
          <ModalHeader id="utenteModal">{header}</ModalHeader>
            {checkAriaExpanded()}
          <Collapse isOpen={collapse}>
            <Card>
              <CardBody>{utenzaForm()}</CardBody>
            </Card>
          </Collapse>
        </Modal>
      </div>
    );
  };

  return utenzaModal();
};

export default UtenzaModal;
