import {
    GET_USER_INFO, GET_USER_INFO_FAIL, GET_USER_INFO_SUCCESS, UPDATE_USER, UPDATE_USER_SUCCESS, UPDATE_USER_FAILED
  } from "../../components/dashboard/dashboard_Action"
  import _ from "lodash"
  
  export function userReducer(state = { loading: false, error: undefined, userInfo: undefined }, action) {
  
    if (_.isEmpty(action) || _.isEmpty(action.type)) return state
  
    const newState = state
  
    switch (action.type) {
  
      case GET_USER_INFO:
        newState.loading = true
        newState.error = undefined
        newState.userInfo = undefined
        break;
      case GET_USER_INFO_SUCCESS:
        newState.loading = false
        newState.error = undefined
        newState.userInfo = action.payload
        break;
      case GET_USER_INFO_FAIL:
        newState.loading = false
        newState.error = action.payload
        newState.userInfo = undefined
        break;
      case UPDATE_USER:
        break;
      case UPDATE_USER_SUCCESS:
        break;
      case UPDATE_USER_FAILED:
        break;
      default:
        break;
    }
  
    return Object.assign({}, state, newState)
  }
  