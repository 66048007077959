import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Spinner, Row,
  Col,
  Icon,
  UncontrolledTooltip, } from "design-react-kit";
import CustomTable from "../../table/component/CustomTable";
import _ from "lodash";
import { showPopup } from "../../popup/actions/popup_actions";
import EntiModal from "./EntiModal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faRefresh, faTrashAlt,faIdBadge } from "@fortawesome/free-solid-svg-icons";
import { deleteEnte, getEnte, insertEnte, updateEnte} from "../actions/enti_actions";
import { showEnteModal } from "../actions/entiModal_actions";
import { getCollaborazioni } from "../../collaborazioni/actions/collaborazioni_actions";


const Enti = () => {

  const dispatch = useDispatch();

  const delEnte = (arg) => {
    dispatch(deleteEnte(arg));
  };

  const showActionPopUp = (type, header, message, f, arg) => {
    dispatch(showPopup(type, header, message, f, arg));
  };

  const showActionModal = (type, header, f, obj) => {
    dispatch(showEnteModal(type, header, f, obj));
  };

  const enti = useSelector((state) => state.enticomune);
  const loading = useSelector((state) => state.enticomune.loading);
  const [dati, setDati] = useState([]);

  const collaborazioni = useSelector((state) => state.collaborazioni.collaborazioni)

  const [tipoEnteLista, setTipoEnteLista] = useState();

  useEffect(() => {
    if(collaborazioni === undefined) {
      dispatch(getCollaborazioni())
    }
  },[collaborazioni])

  useEffect(() => {
    if (enti === undefined) {
      dispatch(getCollaborazioni());
    } else {
      //console.log(collaborazioni)
      setTipoEnteLista([
        {value: "Privato", label: "Privato"},
        {value: "Comunale", label: "Comunale"},
        {value: "Cooperativa", label: "Cooperativa"},
      ])


      const tmp = [];
      _.forEach(collaborazioni, (ts) => {
        ts.enteriferimento = ts.ente.denominazione;
        ts.tipoEnte = ts.ente.tipoEnte;
        ts.telefono = ts.ente.telefono;
        ts.email = ts.ente.email;
        ts.identificativo = ts.ente.login.username
        ts.actions = (
          <section>
            <span className="rounded-icon iconbottom"
              onClick={() => {
                showActionModal("edit", "Modifica Ente", updateEnte, ts);
              }}
            >
            <FontAwesomeIcon icon={faEdit} />
            </span>
            <span className="rounded-icon iconbottomcancel"
             onClick={() =>
              showActionPopUp(
                "confirm",
                "ATTENZIONE",
                `Ente con ${ts.ente.denominazione} verra' cancellato. Procedere?`,
                delEnte,
                ts.ente.id
              )
            }
            >
            <FontAwesomeIcon icon={faTrashAlt} />
            </span>
          </section>
        );
        tmp.push(ts);
      });
      setDati(tmp);
    }
  }, [enti, dispatch]);

  const headers = [
    {
      label: "Ente",
      field: "enteriferimento"
    },
    {
      label: "Identificativo",
      field: "identificativo"
    },
    {
      label: "Tipo Ente",
      field: "tipoEnte",
    },
    {
      label: "Telefono",
      field: "telefono",
    },
    {
      label: "email",
      field: "email",
    },
    {
      label: "Azioni",
      field: "actions",
    },
  ];

  const refreshList = () => {
    dispatch(getCollaborazioni());
  };

  return (
    <React.Fragment>
      <EntiModal enti={collaborazioni} tipoEnteLista={tipoEnteLista}/>
      {loading ? (
        <div className="customTableContainer">
          <div className="container_spinner"><Spinner active  className="spinnerwebe"/></div>
        </div>
      ) : (
        <div className="customTableContainer">
          <Row className="justify-content-md-center">
            <Col lg="9" xl="9" md="9" sm="9" xs="12">
              <h1 className="">Enti <FontAwesomeIcon icon={faRefresh} className="padding-lr-icon linkeffect" onClick={()=> refreshList()}/></h1>
            </Col>
            <Col lg="3" xl="3" md="3" sm="3" xs="12" className="text-right">
              <Button
                color="primary"
                onClick={() =>
                  showActionModal(
                    "insert",
                    "Inserimento nuovo Ente Raccolta",
                    insertEnte
                  )
                }
                icon
                id="addNewEnte"
                style={{ marginBottom: "8px" }}
              >
                <FontAwesomeIcon icon={faIdBadge} className="padding-lr-icon"/> {' '}Aggiungi
              </Button>
              <UncontrolledTooltip placement="top" target="addNewEnte">
                Aggiungi un nuovo Ente
              </UncontrolledTooltip>
            </Col>
          </Row>
          <CustomTable headers={headers} data={dati} />
        </div>
      )}
    </React.Fragment>
  );
};

export default Enti;
