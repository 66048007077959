import {
  GET_COLLABORAZIONI, GET_COLLABORAZIONI_SUCCESS, GET_COLLABORAZIONI_FAIL,
  DELETE_COLLABORAZIONE, DELETE_COLLABORAZIONE_SUCCESS, DELETE_COLLABORAZIONE_FAILED,
  INSERT_COLLABORAZIONE, INSERT_COLLABORAZIONE_SUCCESS, INSERT_COLLABORAZIONE_FAILED
} from "../actions/collaborazioni_actions"
import _ from "lodash"

export function collaborazioniReducer(state = { loading: false, collaborazioni: undefined }, action) {

  if (_.isEmpty(action) || _.isEmpty(action.type)) return state 

  const newState = state

  switch (action.type) {

    case GET_COLLABORAZIONI:
      newState.loading = true
      newState.collaborazioni = undefined
      break;
    case GET_COLLABORAZIONI_SUCCESS:
      newState.loading = false
      newState.collaborazioni = action.payload
      break;
    case GET_COLLABORAZIONI_FAIL:
      newState.loading = false
      newState.collaborazioni = undefined
      break;
    case DELETE_COLLABORAZIONE:
      break;
    case DELETE_COLLABORAZIONE_SUCCESS:
      break;
    case DELETE_COLLABORAZIONE_FAILED:
      break;
    case INSERT_COLLABORAZIONE:
      break;
    case INSERT_COLLABORAZIONE_SUCCESS:
      break;
    case INSERT_COLLABORAZIONE_FAILED:
      break;
    default:
      break;
  }
  return Object.assign({}, state, newState)
}