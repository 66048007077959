import CommonService from "../../../service/common_service";
import { put } from "redux-saga/effects"
import { showPopup } from "../../popup/actions/popup_actions";

export const GET_CALENDARI = "getCalendari"
export const GET_CALENDARI_SUCCESS = "get_calendari_success"
export const GET_CALENDARI_FAIL = "get_calendari_fail"

export function getCalendari() {
    return {
        type: GET_CALENDARI
    }
}

export function* getCalendariSaga() {
    try {

        const request = {
            method: "GET",
            url: "/api/v1/calendario/cerca",
        }

        const response = yield CommonService.execute(request)
        yield put({
            type: GET_CALENDARI_SUCCESS,
            payload: response.data
        })

    } catch (error) {
        yield put({
            type: GET_CALENDARI_FAIL,
            payload: error
        })
        yield put(
            showPopup(
                "error",
                "OPERAZIONE FALLITA",
                error.response.data.data.error
            )
        )
    }
}

export const GET_CALENDARI_BY_COLLABORAZIONE = "getCalendariByCollaborazione"
export const GET_CALENDARI_BY_COLLABORAZIONE_SUCCESS = "getCalendariByCollaborazione_success"
export const GET_CALENDARI_BY_COLLABORAZIONE_FAIL = "getCalendariByCollaborazione_fail"

export function getCalendariByCollaborazione(collaborazioneId, f) {
    return {
        type: GET_CALENDARI_BY_COLLABORAZIONE,
        payload: {
            collaborazioneId,
            function: f
        }
    }
}

export function* getCalendariByCollaborazioneSaga(action) {
    try {
        const url = "/api/v1/calendario/cerca/collaborazione-id/" + action.payload.collaborazioneId
        const request = {
            method: "GET",
            url: url
        }

        const response = yield CommonService.execute(request)
        yield put({
            type: GET_CALENDARI_BY_COLLABORAZIONE_SUCCESS,
            payload: response.data
        })
        yield put(
            showPopup(
                "confirm",
                "ATTENZIONE",
                `La collaborazione ${action.payload.collaborazioneId} è attualmente legata a calendari attivi. 
                Procedere con l'eliminazione?`,
                action.payload.function,
                action.payload.collaborazioneId
            )
        )


    } catch (error) {
        yield put({
            type: GET_CALENDARI_BY_COLLABORAZIONE_FAIL
        })
        yield put(
            showPopup(
                "confirm",
                "ATTENZIONE",
                `La collaborazione ${action.payload.collaborazioneId} verra' eliminata. Procedere?`,
                action.payload.function,
                action.payload.collaborazioneId
            )
        )
    }
}

export const INSERT_CALENDARIO = "insertCalendario"
export const INSERT_CALENDARIO_SUCCESS = "iinsertCalendario_success"
export const INSERT_CALENDARIO_FAILED = "insertCalendario_failed"

export function insertCalendario(collaborazione, zona, lunedi, martedi, mercoledi, giovedi, venerdi, sabato, domenica) {
    return {
        type: INSERT_CALENDARIO,
        payload: {
            collaborazione: collaborazione,
            zona: zona,
            lunedi: lunedi,
            martedi: martedi,
            mercoledi: mercoledi,
            giovedi: giovedi,
            venerdi: venerdi,
            sabato: sabato,
            domenica: domenica
        }
    }
}

export function* insertCalendarioSaga(action) {
    try {
        const request = {
            url: "/api/v1/calendario/insert",
            method: "POST",
            data: {
                collaborazione: action.payload.collaborazione,
                zona: action.payload.zona,
                lunedi: action.payload.lunedi,
                martedi: action.payload.martedi,
                mercoledi: action.payload.mercoledi,
                giovedi: action.payload.giovedi,
                venerdi: action.payload.venerdi,
                sabato: action.payload.sabato,
                domenica: action.payload.domenica
            }
        }

        const response = yield CommonService.execute(request)
        yield put({
            type: GET_CALENDARI
        })
        yield put(
            showPopup(
                "notify",
                "OPERAZIONE RIUSCITA",
                response.data.message
            )
        )

    } catch (error) {
        yield put({
            type: INSERT_CALENDARIO_FAILED,
            payload: error.response.data.data.error
        })
        yield put(
            showPopup(
                "error",
                "OPERAZIONE FALLITA",
                error.response.data.data.error
            )
        )
    }
}

export const UPDATE_CALENDARIO = "updateCalendario"
export const UPDATE_CALENDARIO_SUCCESS = "updateCalendario_success"
export const UPDATE_CALENDARIO_FAILED = "updateCalendario_failed"

export function updateCalendario(calendarioId, collaborazione, zona, lunedi, martedi, mercoledi, giovedi, venerdi, sabato, domenica) {
    return {
        type: UPDATE_CALENDARIO,
        payload: {
            calendarioId: calendarioId,
            collaborazione: collaborazione,
            zona: zona,
            lunedi: lunedi,
            martedi: martedi,
            mercoledi: mercoledi,
            giovedi: giovedi,
            venerdi: venerdi,
            sabato: sabato,
            domenica: domenica
        }
    }
}

export function* updateCalendarioSaga(action) {
    try {
        const request = {
            url: "/api/v1/calendario/update",
            method: "PUT",
            data: {
                id: action.payload.calendarioId,
                collaborazione: action.payload.collaborazione,
                zona: action.payload.zona,
                lunedi: action.payload.lunedi,
                martedi: action.payload.martedi,
                mercoledi: action.payload.mercoledi,
                giovedi: action.payload.giovedi,
                venerdi: action.payload.venerdi,
                sabato: action.payload.sabato,
                domenica: action.payload.domenica
            }
        }

        const response = yield CommonService.execute(request)
        yield put({
            type: GET_CALENDARI
        })
        yield put(
            showPopup(
                "notify",
                "OPERAZIONE RIUSCITA",
                response.data.message
            )
        )

    } catch (error) {
        yield put({
            type: UPDATE_CALENDARIO_FAILED,
            payload: error.response.data.data.error
        })
        yield put(
            showPopup(
                "error",
                "OPERAZIONE FALLITA",
                error.response.data.message
            )
        )
    }

}

export const DELETE_CALENDARIO = "deleteCalendario"
export const DELETE_CALENDARIO_SUCCESS = "delete_calendario_success"
export const DELETE_CALENDARIO_FAILED = "delete_calendario_fail"

export function deleteCalendario(utenzaId) {
    return {
        type: DELETE_CALENDARIO,
        payload: utenzaId
    }
}

export function* deleteCalendarioSaga(action) {
    try {
        const url = "/api/v1/calendario/delete/" + action.payload
        const request = {
            method: "DELETE",
            url: url,
        }

        const response = yield CommonService.execute(request)
        yield put({
            type: GET_CALENDARI
        })
        yield put(
            showPopup(
                "notify",
                "OPERAZIONE RIUSCITA",
                response.data.message
            )
        )

    } catch (error) {
        yield put({
            type: DELETE_CALENDARIO_FAILED,
            payload: error.response.data.data.error
        })
        yield put(
            showPopup(
                "error",
                "OPERAZIONE FALLITA",
                error.response.data.data.error
            )
        )
    }
}



