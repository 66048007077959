import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Spinner,
  Row,
  Col,
  Icon,
  UncontrolledTooltip,
} from "design-react-kit";
import {
  getMastelli,
  insertMastello,
  updateMastello,
  deleteMastello,
  stampaMastello
} from "../actions/mastello_actions";
import CustomTable from "../../table/component/CustomTable";
import _ from "lodash";
import { showPopup } from "../../popup/actions/popup_actions";
import { showMastelloModal } from "../actions/mastelloModal_actions";
import MastelloModal from "./MastelloModal";
import { getUtenze } from "../../utenze/actions/utenze_actions";
import { getTipologieSpazzatura } from "../../tipologiaSpazzatura/actions/tipologiaSpazzatura_actions";
import { getQrCodes } from "../../qrCode/actions/qrCode_actions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRefresh, faEdit, faArchive, faTrashAlt, faFilePdf } from '@fortawesome/free-solid-svg-icons'
import { getUserInfo } from "../../dashboard/dashboard_Action";

const Mastello = () => {

  const dispatch = useDispatch();

  const userInfo = useSelector(
    (state) => state.userInfo.userInfo
  );


  const mastelli = useSelector((state) => state.mastello.mastelli);
  const loading = useSelector((state) => state.mastello.loading);
  const [dati, setDati] = useState([]);

  const utenze = useSelector((state) => state.utenze.utenze);
  const tipologieSpazzatura = useSelector((state) => state.tipologiaSpazzatura.tipologieSpazzatura);
  const qrCodes = useSelector((state) => state.qrCode.qrCodes);

  const delMastello = (arg) => {
    console.log("delete button")
    dispatch(deleteMastello(arg));
  };

  const stampaRicevutaMastello = (arg) => {
    console.log(arg)

    dispatch(stampaMastello(String(arg.utenza.id), arg.utenza.comune.denominazione, 
      arg.utenza.comune.indirizzo, arg.dataAssegnazione, arg.dataAssegnazioneString,  
      arg.qrCode.valore, arg.tipologiaSpazzatura.descrizione, arg.utenza.intestatario.cognome +" "+ arg.utenza.intestatario.nome,
      arg.utenza.intestatario.userName,arg.utenza.indirizzo,arg.utenza.comune.citta, arg.utenza.intestatario.email ));
  };

  const showActionPopUp = (type, header, message, f, arg) => {
    dispatch(showPopup(type, header, message, f, arg));
  };

  const showActionModal = (type, header, f, obj) => {
    dispatch(showMastelloModal(type, header, f, obj));
  };
  
  const statoQrCode = (mastello) => {
    if (mastello.qrCode.stato === true) {
      return "assegnato";
    } else {
      return "libero";
    }
  };

  useEffect(() => {
    if (mastelli === undefined) {
      dispatch(getMastelli());
      //dispatch(getUserInfo());
    } else {
      //dispatch(getUserInfo());
      const tmp = [];
      _.forEach(mastelli, (m) => {
        m.proprietario = m.utenza.intestatario.userName + " - " + m.utenza.intestatario.cognome + " " + m.utenza.intestatario.nome;
        m.comune = m.utenza.comune.denominazione;
        m.statoQrCode = statoQrCode(m);
        if(userInfo != null && (userInfo.tipologiaUtente === "COMUNE" || userInfo.tipologiaUtente === "ADMIN" || userInfo.tipologiaUtente === "ENTE" || userInfo.tipologiaUtente === "OPERATORE" )){
          m.actions = (
            <section>
              {/* <Button
                color="primary"
                onClick={() => {
                  showActionModal("edit", "Modifica Mastello", updateMastello, m);
                }}
              >
                edit
              </Button>{" "} */}
              <span className="rounded-icon iconbottom"
                onClick={() => {
                  showActionModal("edit", "Modifica Mastello", updateMastello, m);
                }}
              >
              <FontAwesomeIcon icon={faEdit} />
              </span>
              <span className="rounded-icon iconbottom"
                onClick={() => {
                  showActionPopUp(
                    "confirm",
                    "Stampa Ricevuta",
                    `Vuoi generare ricevuta del mastello ${m.utenza.id}. Procedere?`,
                    stampaRicevutaMastello,
                    m
                  )
                }}
              >
              <FontAwesomeIcon icon={faFilePdf} />
              </span>
              <span className="rounded-icon iconbottomcancel"
                onClick={() =>
                  showActionPopUp(
                    "confirm",
                    "ATTENZIONE",
                    `Il mastello ${m.id} verra' cancellato. Procedere?`,
                    delMastello,
                    m.id
                  )
                }
              >
              <FontAwesomeIcon icon={faTrashAlt} />
              </span>
              {/* <Button
                color="primary"
                onClick={() =>
                  showActionPopUp(
                    "confirm",
                    "ATTENZIONE",
                    `Il mastello ${m.id} verra' cancellato. Procedere?`,
                    delMastello,
                    m.id
                  )
                }
              >
                delete
              </Button> */}
            </section>
          );
        }else{
          m.actions =(
            <span className="rounded-icon iconbottom"></span>
          )
        }
        
        tmp.push(m);
      });
      setDati(tmp);
    }
  }, [dispatch, mastelli]);

  useEffect(() => {
    if (utenze === undefined) {
      dispatch(getUtenze());
    }
  }, [utenze]);

  useEffect(() => {
    if (userInfo === undefined) {
      dispatch(getUserInfo());
    }
  }, [userInfo]);

  useEffect(() => {
    if (tipologieSpazzatura === undefined) {
      dispatch(getTipologieSpazzatura());
    }
  }, [tipologieSpazzatura]);

  useEffect(() => {
    if(userInfo != null && (userInfo.tipologiaUtente === "COMUNE" || userInfo.tipologiaUtente === "ADMIN" || userInfo.tipologiaUtente === "ENTE" || userInfo.tipologiaUtente === "OPERATORE" )){
      if (qrCodes === undefined) {
        dispatch(getQrCodes());
      }
    }
    
  },[qrCodes]);

  const headers = [
    {
      label: "Data",
      field: "dataAssegnazioneString",
    },
    {
      label: "Intestario",
      field: "proprietario",
    },
    {
      label: "Indirizzo",
      field: "utenza.indirizzo",
    },
    {
      label: "Comune",
      field: "comune"
    },
    {
      label: "Tipologia",
      field: "tipologiaSpazzatura.descrizione",
    },
    // {
    //   label: "Valore QrCode",
    //   field: "qrCode.valore",
    // },
    {
      label: "Stato",
      field: "statoQrCode",
    },
    {
      label: "Azioni",
      field: "actions",
    },
  ];

  const refreshList = () => {
    dispatch(getMastelli());
    dispatch(getTipologieSpazzatura());
    dispatch(getQrCodes());
    dispatch(getUtenze());
  };

  return (
    <React.Fragment>
      <MastelloModal
        utenzeOptions={utenze}
        tipologieSpazzaturaOptions={tipologieSpazzatura}
        qrCodeOptions={qrCodes}
      />
      {loading ? (
        <div className="customTableContainer">
          <div className="container_spinner"><Spinner active  className="spinnerwebe"/></div>
        </div>
      ) : (
        <div className="customTableContainer">
          <Row className="justify-content-md-center">
            <Col lg="9" xl="9" md="9" sm="9" xs="12">
              <h1 className="">Mastelli <FontAwesomeIcon icon={faRefresh} className="padding-lr-icon linkeffect" onClick={()=> refreshList()}/></h1>
            </Col>
            <Col lg="3" xl="3" md="3" sm="3" xs="12" className="text-right">
             
            {
              userInfo != null && userInfo.tipologiaUtente != "UTENTE" &&
              <>
              
              <Button
                color="primary"
                onClick={() =>
                  showActionModal(
                    "insert",
                    "Inserimento nuovo Mastello",
                    insertMastello
                  )
                }
                icon
                id="addNewMastello"
                style={{ marginBottom: "8px" }}
              >
                <FontAwesomeIcon icon={faArchive} className="padding-lr-icon"/> {' '}Aggiungi
              </Button>
              <UncontrolledTooltip placement="top" target="addNewMastello">
              Aggiungi un nuovo mastello
            </UncontrolledTooltip>
            </>
            }  
              
              
            </Col>
          </Row>
          <CustomTable headers={headers} data={dati} />
        </div>
      )}
    </React.Fragment>
  );
};

export default Mastello;
