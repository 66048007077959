import { Icon} from "design-react-kit";
import React, { useState } from "react";
import { useLocation } from "react-router-dom"
import StickyBox from "react-sticky-box";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons'

import "./sidebar.css"
import CustomHeader from "../CustomHeader";

const CustomSidebar = ({ routes }) => {

    const location = useLocation();
    const activeRoute = (routeName) => {
        return location.pathname.indexOf(routeName) > -1 ? "active" : "";
    };

    const [menuCollapse, setMenuCollapse] = useState(false)
    //custom function that will change menucollapse state from false to true and true to false
    const menuIconClick = () => {
        //condition checking to change state from true to false and vice versa
        menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
    };

    let count = 1;
    return (
        <StickyBox>
            
            <div className="min-vh-100">
                <div id="sidebar-wrapper" className="min-vh-100 d-none d-sm-none d-md-none d-lg-block d-xl-block d-xxl-block">
                    <ul className="list-unstyled components">
                        {
                            routes.map((route, i)=>{
                                count = count + 1
                                return(
                                    <>
                                    {
                                        route.name != "Profilo" &&
                                        <li className="navbar-item" key={i}>
                                            <Link to={route.layout+route.path} className="nav-link">
                                                {/* <FontAwesomeIcon icon={route.icon}></FontAwesomeIcon> */}
                                                {route.name}</Link>
                                        </li>
                                    }
                                    </>
                                )
                                
                            })
                        }
                    </ul>
                </div>
                <br />

            </div>
        </StickyBox>
    )

}

export default CustomSidebar