import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  ModalFooter,
  Input,
  Select,
  Row,
  Col
} from "design-react-kit";
import { hideComuneModal, showComuneModal } from "../actions/comuneModal_actions"
import { getUserInfo } from "../../dashboard/dashboard_Action";
import _ from "lodash";

const ComuneModal = (props) => {

  const dispatch = useDispatch();

  const userInfo = useSelector(
    (state) => state.userInfo.userInfo
  );

  const [denominazione, setDenominazione] = useState();
  const [telefono, setTelefono] = useState();
  const [citta, setCitta] = useState();
  const [email, setMail] = useState();
  const [indirizzo, setIndirizzo] = useState();
  const [provincia, setProvincia] = useState();
  const [regione, setRegione] = useState();
  const [emailPec, setEmailPec] = useState();

  const [username, setUsername] = useState();
  const [password, setPassword] = useState();

  const [loginOp, setLoginOP] = useState();
  const [ente, setEnte] = useState();
  const [comune, setComune] = useState();

  const comuneToEdit = useSelector((state) => state.comuneModal);

  const type = useSelector((state) => state.comuneModal.type);
  const header = useSelector((state) => state.comuneModal.header);
  const show = useSelector((state) => state.comuneModal.show);
  const f = useSelector((state) => state.comuneModal.function);


  const login = {
    "username": username,
    "password": password,
    "tipologiaLogin": "ENTE"
  }

  const [listaErrori, setListaErrori] = useState([]);
  let listaErroriTmp = [];
  function isValidEmailAddress(address) {
    if(address == null || address === '') return false;
    return !! address.match(/.+@.+/);
  }
  function erroriForm(listaErroriInfo){
    return listaErroriInfo.map(function (errore) {
      return(
        <p className="text-mess-alert">{errore}</p>
      )})  
  }

  const confirm = () => {

    listaErroriTmp = []
    let telefonoIsNum = /^\d+$/.test(telefono);
    if(type === "insert" ){
      if(username != null || username != ''){
        let codiceFiscale = /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/.test(username);
        let partitaIva = /^[0-9]{11}$/.test(username);
        if(!codiceFiscale && !partitaIva) listaErroriTmp.push("- Inserire una Partita Iva o Codice Fiscale")
      }
    }
    
    let emailValid = isValidEmailAddress(email);
    let emailPecValid = isValidEmailAddress(emailPec)
    if(denominazione == null || denominazione === '') listaErroriTmp.push("- Denominazione è un campo obbligatorio")
    if(denominazione != null && denominazione.length < 4) listaErroriTmp.push("- Denominazione deve essere almeno di 2 caratteri")

    if(regione == null || regione === '') listaErroriTmp.push("- Regione è un campo obbligatorio")
    if(provincia == null || provincia === '') listaErroriTmp.push("- Provincia è un campo obbligatorio")
    if(provincia != null && provincia.length != 2) listaErroriTmp.push("- Privincia deve essere di 2 caratteri")
    if(indirizzo == null || indirizzo === '') listaErroriTmp.push("- Indirizzo è un campo obbligatorio")
    if(citta == null || citta === '') listaErroriTmp.push("- Città è un campo obbligatorio")


    if(!telefonoIsNum) listaErroriTmp.push("- Telefono deve essere un numero valido")
    if(!emailValid) listaErroriTmp.push("- Email non è nel formato corretto")
    if(!emailPecValid) listaErroriTmp.push("- Email Pec non è nel formato corretto")
    if(telefonoIsNum && telefono.length < 6) listaErroriTmp.push("- Telefono deve essere almeno di 6 caratteri")
    if(type === "insert" ){
      if(username == null || username === '') listaErroriTmp.push("- Codice Fiscale/Piva è un campo obbligatorio")
      if(username != null && (username.length != 11 && username.length != 16 )) listaErroriTmp.push("- Codice Fiscale/Piva deve essere di 16 o 11 caratteri")
      if(password == null || password === '') listaErroriTmp.push("- Password è un campo obbligatorio")
      if(password != null && password.length < 8) listaErroriTmp.push("- Password deve essere almeno di 8 caratteri")
    }
    
    //if(ente == null || ente === '') listaErroriTmp.push("- Ente è un campo obbligatorio")
   

    if(listaErroriTmp.length == 0){
      switch (type) {
        case "insert":
          const loginInsert = {
            "username": username,
            "password": password,
            "tipologiaLogin": "COMUNE"
          }
          dispatch(f(denominazione, indirizzo, citta, provincia, regione, telefono, email, emailPec, loginInsert));
          break;
        case "edit":
          const loginEdit = {
            "id": comuneToEdit.obj.login.id,
            "username": comuneToEdit.obj.login.username,
            "password": comuneToEdit.obj.login.password,
            "tipologiaLogin": "COMUNE"
          }
  
          dispatch(f(comuneToEdit.obj.id, denominazione, indirizzo, citta, provincia, regione, telefono, email, emailPec, loginEdit));
          close();
          break;
        default:
          close();
      }
      setListaErrori([])
      close();

    }else{
      setListaErrori(listaErroriTmp)
    }
    
  };

  const close = () => dispatch(hideComuneModal());

  useEffect(() => {
    if (userInfo === undefined) {
      dispatch(getUserInfo());
    }
  }, [userInfo]);

  useEffect(() => {
    if (type !== "edit" && comuneToEdit.obj === undefined) {
      setDenominazione(undefined);
      setIndirizzo(undefined)
      setCitta(undefined)
      setProvincia(undefined)
      setRegione(undefined)
      setEmailPec(undefined)
      setTelefono(undefined);
      setMail(undefined);
      setLoginOP(undefined);

    } else {
      setDenominazione(comuneToEdit.obj.denominazione);
      setTelefono(comuneToEdit.obj.telefono);
      setMail(comuneToEdit.obj.email);
      setLoginOP(comuneToEdit.obj.loginOp);
      setIndirizzo(comuneToEdit.obj.indirizzo)
      setCitta(comuneToEdit.obj.citta)
      setProvincia(comuneToEdit.obj.provincia)
      setRegione(comuneToEdit.obj.regione)
      setEmailPec(comuneToEdit.obj.emailPec)
    }
  }, [type, comuneToEdit]);
  
  return (
    <div>
      <Modal isOpen={show} labelledBy="operatoreModal">
        <ModalHeader id="operatoreModal">{header}</ModalHeader>
        <ModalBody>
          <FormGroup>
            <div className="bootstrap-select-wrapper">
              <Input
                id="denominazione"
                onChange={(e) => {
                  setDenominazione(e.target.value);
                }}
                value={denominazione}
                type="text"
                placeholder="Inserisci Denominazione"
                aria-label="Inserisci Denominazione"
              />
              <Input
                id="indirizzo"
                onChange={(e) => {
                  setIndirizzo(e.target.value);
                }}
                value={indirizzo}
                type="text"
                placeholder="Inserisci Indirizzo"
                aria-label="Inserisci Indirizzo"
              />
               <Input
                id="citta"
                onChange={(e) => {
                  setCitta(e.target.value);
                }}
                value={citta}
                type="text"
                placeholder="Inserisci Città"
                aria-label="Inserisci Città"
              />
              <Input
                id="provincia"
                onChange={(e) => {
                  setProvincia(e.target.value);
                }}
                value={provincia}
                type="text"
                placeholder="Inserisci sigla Provincia"
                aria-label="Inserisci sigla Provincia"
              />
              <Input
                id="regione"
                onChange={(e) => {
                  setRegione(e.target.value);
                }}
                value={regione}
                type="text"
                placeholder="Inserisci Regione"
                aria-label="Inserisci Regione"
              />
              <Input
                id="telefono"
                onChange={(e) => {
                  setTelefono(e.target.value);
                }}
                value={telefono}
                type="text"
                placeholder="Inserisci Telefono"
                aria-label="Inserisci Telefono"
              />
              <Input
                id="Email"
                onChange={(e) => {
                  setMail(e.target.value);
                }}
                value={email}
                type="text"
                placeholder="Inserisci Email"
                aria-label="Inserisci Email"
              />
              <Input
                id="Email Pec"
                onChange={(e) => {
                  setEmailPec(e.target.value);
                }}
                value={emailPec}
                type="text"
                placeholder="Inserisci Email Pec"
                aria-label="Inserisci Email Pec"
              />
              {type == "insert" &&
              <>
              <Input
                id="Username"
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
                value={username}
                type="text"
                placeholder="Inserisci Codice Fiscale/P/va"
                aria-label="Inserisci Codice Fiscale/PIva"
              />
              <Input
                id="Password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                value={password}
                type="text"
                placeholder="Inserisci Password"
                aria-label="Inserisci Password"
              />
              </>
              }
              {type == "edit" &&
              <>
              <Input
                id="Username"
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
                value={username}
                type="text"
                placeholder="Non Modificabile"
                aria-label="Non Modificabile"
                disabled
              />
              <Input
                id="Password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                value={password}
                type="text"
                placeholder="Non Modificabile"
                aria-label="Non Modificabile"
                disabled
              />
              </>
              }
              
            </div>
          </FormGroup>
          {listaErrori.length>0 &&
            <Row>
                <Col>
                  {erroriForm(listaErrori)}
                </Col>
            </Row>
          }
        </ModalBody>
        <ModalFooter>
          <span>
            <Button outline color='primary' onClick={() => close()}>ANNULLA</Button>
          </span>
          <span>
            <Button outline color='primary' onClick={() => confirm()}>CONFERMA</Button>
          </span>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ComuneModal;
