import _ from "lodash"
import { RESET_PASSWORD_REQUEST, RESET_PASSWORD_FAILED, RESET_PASSWORD_SUCCESS, RECUPERA_PASSWORD_REQUEST, RECUPERA_PASSWORD_FAILED, RECUPERA_PASSWORD_SUCCESS } from "../actions/password_actions"

export function resetPasswordReducer(state = { username: undefined, success: false, loading: false, error: undefined, jwt: undefined }, action) {
    if (_.isEmpty(action) || _.isEmpty(action.type)) return state
    const newState = state
    switch (action.type) {
       
        case RESET_PASSWORD_REQUEST:
            newState.loading = true
            newState.error = undefined
            newState.success = false
            break
        case RESET_PASSWORD_FAILED:
            newState.loading = false
            newState.error = action.payload
            newState.success = false
            break
        case RESET_PASSWORD_SUCCESS:
            newState.success = true
            newState.error = undefined
            newState.loading = false
            newState.username = action.payload.username
            break;
        default:
            break
    }
    return Object.assign({}, state, newState)
}

export function recuperaPasswordReducer(state = { username: undefined, success: false, loading: false, error: undefined, jwt: undefined }, action) {
    if (_.isEmpty(action) || _.isEmpty(action.type)) return state
    const newState = state
    switch (action.type) {
       
        case RECUPERA_PASSWORD_REQUEST:
            newState.loading = true
            newState.error = undefined
            newState.success = false
            break
        case RECUPERA_PASSWORD_FAILED:
            newState.loading = false
            newState.error = action.payload
            newState.success = false
            break
        case RECUPERA_PASSWORD_SUCCESS:
            newState.success = true
            newState.error = undefined
            newState.loading = false
            newState.show = true
            //newState.username = action.payload.username
            break;
        default:
            break
    }
    return Object.assign({}, state, newState)
}
