import Calendario from "../../calendario/components/Calendario"
import DashboardUtente from "../../dashboard/utente"
import Mastello from "../../mastello/components/Mastello"
import QrCode from "../../qrCode/components/QrCode"
import RichiestaIngombranti from "../../richiestaIngombranti/components/RichiestaIngombranti"
import Utenze from "../../utenze/components/Utenze"
import ProfiloUtente from "../../../../common/components/profilo"
import Raccolta from "../../raccolta/components/Raccolta"


const utenteRoutes = [
    {
        path: "/dashboard",
        name: "Home",
        icon: "",
        component: DashboardUtente,
        layout: "/utente",
        icon: "faHome"
    },
    {
        path: "/profilo",
        name: "Profilo",
        icon: "",
        component: ProfiloUtente,
        layout: "/utente",
        icon: "faHome"
    },
    {
        path: "/utenze",
        name: "Utenze",
        icon: "",
        component: Utenze,
        layout: "/utente",
        icon: "faHome"
    },
    {
        path: "/calendari",
        name: "Calendari",
        icon: "",
        component: Calendario,
        layout: "/utente",
        icon: "faCalendar"
    },
    {
        path: "/raccolta",
        name: "Raccolta",
        icon: "",
        component: Raccolta,
        layout: "/utente",
        icon: "faCalendar"
    },
    {
        path: "/mastelli",
        name: "Mastelli",
        icon: "",
        component: Mastello,
        layout: "/utente",
        icon: "faCalendar"
    },
    {
        path: "/richieste-ingombranti",
        name: "Richieste Ingombranti",
        icon: "",
        component: RichiestaIngombranti,
        layout: "/utente",
        icon: "faCalendar"
    },
]

export default utenteRoutes