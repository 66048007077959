import CommonService from "../../../service/common_service";
import { put } from "redux-saga/effects"
import { showPopup } from "../../popup/actions/popup_actions";
import { GET_COLLABORAZIONI } from "../../collaborazioni/actions/collaborazioni_actions";

export const GET_ENTE_COMUNE = "getEnte"
export const GET_ENTE_COMUNE_SUCCESS = "get_ente_success"
export const GET_ENTE_COMUNE_FAIL = "get_ente_fail"

export function getEnte() {
    return {
        type: GET_ENTE_COMUNE
    }
}

const headers = {
    Authorization: 'Bearer ' + localStorage.getItem("jwtToken")
}

export function* getEnteSaga() {
    try {

        const request = {
            method: "GET",
            url: "/api/v1/ente/cerca",
            headers: headers
        }

        const response = yield CommonService.execute(request)
        yield put({
            type: GET_ENTE_COMUNE_SUCCESS,
            payload: response.data
        })
        yield put({
            type: GET_COLLABORAZIONI,
        })

    } catch (error) {
        yield put({
            type: GET_ENTE_COMUNE_FAIL,
            payload: error
        })
        yield put(
            showPopup(
                "error",
                "OPERAZIONE FALLITA",
                error.response.data.data.error
            )
        )
    }
}


export const INSERT_ENTE = "insertEnte"
export const INSERT_ENTE_SUCCESS = "insertEnte_success"
export const INSERT_ENTE_FAILED = "insertEnte_failed"

export function insertEnte(denominazione, tipoEnte, telefono, email, login, comune) {
    return {
        type: INSERT_ENTE,
        payload: {
            denominazione: denominazione,
            tipoEnte: tipoEnte,
            telefono: telefono,
            email: email, // SUPERUSER -> deve inserire anche l'id del comune per creare una nuova utenza
            login: login,
            comune: comune,
        }
    }
}

export function* insertEnteSaga(action) {
    try {
        const request = {
            url: "/api/v1/ente/insert",
            method: "POST",
            data: {
                denominazione: action.payload.denominazione,
                tipoEnte: action.payload.tipoEnte,
                telefono: action.payload.telefono,
                email: action.payload.email, // SUPERUSER -> deve inserire anche l'id del comune per creare una nuova utenza
                login: action.payload.login,
                comuneResidenzaId: action.payload.comune,
            }
        }

        const response = yield CommonService.execute(request)
        yield put({
            type: GET_COLLABORAZIONI,
            payload: response.data
        })
        yield put({
            type: GET_ENTE_COMUNE
        })
        yield put(
            showPopup(
                "notify",
                "OPERAZIONE RIUSCITA",
                response.data.message
            )
        )

    } catch (error) {
        yield put({
            type: INSERT_ENTE_FAILED,
            payload: error.response.data.data.error
        })
        yield put(
            showPopup(
                "error",
                "OPERAZIONE FALLITA",
                error.response.data.message
            )
        )
    }
}

export const UPDATE_ENTE = "updateEnte"
export const UPDATE_ENTE_SUCCESS = "update_ente_success"
export const UPDATE_ENTE_FAILED = "update_ente_fail"

export function updateEnte(enteId, denominazione, tipoEnte, telefono, email, login, comune) {
    return {
        type: UPDATE_ENTE,
        payload: {
            id: enteId,
            denominazione: denominazione,
            tipoEnte: tipoEnte,
            telefono: telefono,
            email: email, // SUPERUSER -> deve inserire anche l'id del comune per creare una nuova utenza
            login: login,
            comune: comune,
        }
    }
}

export function* updateEnteSaga(action) {
    try {
        const request = {
            url: "/api/v1/ente/update",
            method: "PUT",
            data: {
                id: action.payload.id,
                denominazione: action.payload.denominazione,
                tipoEnte: action.payload.tipoEnte,
                telefono: action.payload.telefono,
                email: action.payload.email,
                login: action.payload.login,
                comuneResidenzaId: action.payload.comune,
            }
        }

        const response = yield CommonService.execute(request)
        yield put({
            type: GET_COLLABORAZIONI,
            payload: response.data
        })
        yield put({
            type: GET_ENTE_COMUNE
        })
        yield put(
            showPopup(
                "notify",
                "OPERAZIONE RIUSCITA",
                response.data.message
            )
        )

    } catch (error) {
        yield put({
            type: UPDATE_ENTE_FAILED,
            payload: error
        })
        yield put(
            showPopup(
                "error",
                "OPERAZIONE FALLITA",
                error.response.data.message
            )
        )
    }
}

export const DELETE_ENTE = "deleteEnte"
export const DELETE_ENTE_SUCCESS = "delete_ente_success"
export const DELETE_ENTE_FAILED = "delete_ente_fail"

export function deleteEnte(enteId) {
    return {
        type: DELETE_ENTE,
        payload: enteId
    }
}

export function* deleteEnteSaga(action) {
    try {
        const url = "/api/v1/ente/delete/" + action.payload
        const request = {
            method: "DELETE",
            url: url,
        }

        const response = yield CommonService.execute(request)
        yield put({
            type: GET_COLLABORAZIONI
        })
        yield put({
            type: GET_ENTE_COMUNE
        })
        yield put(
            showPopup(
                "notify",
                "OPERAZIONE RIUSCITA",
                response.data.message
            )
        )  

    } catch (error) {
        yield put({
            type: DELETE_ENTE_FAILED,
            payload: error.response.data.data.error
        })
        yield put(
            showPopup(
                "error",
                "OPERAZIONE FALLITA",
                error.response.data.data.error
            )
        )
    }
}
