export const SHOW_COMUNE_MODAL = "showComuneModal"
export const HIDE_COMUNE_MODAL = "hideComuneModal"

export function showComuneModal(type, header, f, obj){
  return {
      type: SHOW_COMUNE_MODAL,
      payload:{
        type: type,
        header: header,
        function: f,
        obj: obj
    }
  }
}

export function hideComuneModal(){
  return {
      type: HIDE_COMUNE_MODAL
  }
}