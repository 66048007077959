import { GET_RICHIESTE_INGOMBRANTI, GET_RICHIESTE_INGOMBRANTI_SUCCESS, GET_RICHIESTE_INGOMBRANTI_FAIL, INSERT_INGOMBRANTI, INSERT_INGOMBRANTI_SUCCESS, INSERT_INGOMBRANTI_FAILED, UPDATE_INGOMBRANTI,
  UPDATE_INGOMBRANTI_SUCCESS,UPDATE_INGOMBRANTI_FAILED, DELETE_INGOMBRANTI,  DELETE_INGOMBRANTI_SUCCESS,  DELETE_INGOMBRANTI_FAILED} from "../actions/richiestaIngombranti_actions"
import _ from "lodash"

export function richiestaIngombrantiReducer(state = { loading: false, error: undefined, richiesteIngombranti: undefined }, action) {

  if (_.isEmpty(action) || _.isEmpty(action.type)) return state
 
  const newState = state

  switch (action.type) {

    case GET_RICHIESTE_INGOMBRANTI:
      newState.loading = true
      newState.error = undefined
      newState.richiesteIngombranti = undefined
      break;
    case GET_RICHIESTE_INGOMBRANTI_SUCCESS:
      newState.loading = false
      newState.error = undefined
      newState.richiesteIngombranti = action.payload
      break;
    case GET_RICHIESTE_INGOMBRANTI_FAIL:
      newState.loading = false
      newState.error = action.payload
      newState.richiesteIngombranti = undefined
      break;
    case INSERT_INGOMBRANTI:
      break;
    case INSERT_INGOMBRANTI_SUCCESS:
      break;
    case INSERT_INGOMBRANTI_FAILED:
      break;
    case UPDATE_INGOMBRANTI:
      break;
    case UPDATE_INGOMBRANTI_SUCCESS:
      break;
    case UPDATE_INGOMBRANTI_FAILED:
      break;
    case DELETE_INGOMBRANTI:
      break;
    case DELETE_INGOMBRANTI_SUCCESS:
      break;
    case DELETE_INGOMBRANTI_FAILED:
      break;
    default:
      break;
  }

  return Object.assign({}, state, newState)
}
