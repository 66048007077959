import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getUserInfo, updateUser} from "../dashboard/dashboard_Action";
import {updatePwd} from "../dashboard/pwd_action.js";
import { showUserModal } from "../dashboard/dashboardModal_action"
import Mappa from "../../components/grafici/Mappa";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faContactCard, faLock } from '@fortawesome/free-solid-svg-icons'

import ProfiloModal from "./profiloModal";

import {
  Button,
  Spinner,
  Row,
  Col
} from "design-react-kit";
import _ from "lodash";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
  } from 'chart.js';
import { showPwdModal } from "../dashboard/pwdModal_action";
import PwdModal from "./pwdModal";

  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
  );


const ProfiloComune = () => {

  const dispatch = useDispatch();


  const userInfo = useSelector(
    (state) => state.userInfo.userInfo
  );
  let loading = true//useSelector((state) => state.userInfo.loading);
  let loadingInterno = false
  const [dati, setDati] = useState();

  useEffect(() => {
    if (userInfo === undefined) {
      dispatch(getUserInfo());
    } else {
      const tmp = [];
      _.forEach(userInfo, (ts) => {
        tmp.push(ts);
      });
      setDati(userInfo);
      console.log(userInfo)
      loading = false
    }
  }, [userInfo, dispatch]);

  const showActionModal = (type, header, f, obj) => {
    dispatch(showUserModal(type, header, f, obj));
  };

  const showActionPwdModal = (type, header, f, obj) => {
    loadingInterno = true;
    dispatch(showPwdModal(type, header, f, obj));
  };
 

  return (
    <React.Fragment>
      {userInfo == null ? (
        <div className="customTableContainer">
          <div className="container_spinner"><Spinner active  className="spinnerwebe"/></div>
        </div>
        ) : (
          <>
          <Row className="padding-box-15">
                <>
                <ProfiloModal userInfo={userInfo}></ProfiloModal>
                <PwdModal userInfo={userInfo}></PwdModal>
                {
                  loadingInterno && 
                  <>
                  <div className="customTableContainer loading-interno">
                    <div className="container_spinner"><Spinner active  className="spinnerwebe"/></div>
                  </div>
                  </>
                }
                 {userInfo.tipologiaUtente === "COMUNE" && (
                    <>
                      <Col xs="12" sm="3" md="3" className="map-profilo">
                        <Mappa regione={userInfo.regione}/>
                      </Col>
                      <Col xs="12" sm="9" md="9">
                        <h3 className="title-grafico">Comune: {userInfo.denominazione.toUpperCase()}</h3>
                        <h5 className="title-grafico">Regione: {userInfo.regione.toUpperCase()}</h5>
                        <h5 className="title-grafico">Ubicazione: {userInfo.indirizzo.toUpperCase()} - {userInfo.citta.toUpperCase()} ({userInfo.provincia.toUpperCase()})</h5>
                        <h5 className="title-grafico">Telefono: {userInfo.telefono}</h5>
                        <h5 className="title-grafico">Email: {userInfo.email}</h5>
                        <h5 className="title-grafico">Email Pec: {userInfo.emailPec}</h5>

                        <Row className="padding-top-row">
                          <Col xs="12" sm="4" md="4">
                          <Button color="primary" icon 
                            onClick={() => {
                              showActionModal("edit", "Modifica Informazioni", updateUser, userInfo);
                            }}
                            >
                              <span className="rounded-icon">
                                <FontAwesomeIcon icon={faContactCard} className="color-icon-brand"/>
                              </span>
                              <span>Modifica Contatti</span>
                            </Button>

                          </Col>
                          <Col xs="12" sm="4" md="4">
                          <Button color="primary" icon 
                            onClick={() => {
                              showActionPwdModal("edit", "Modifica Password", updatePwd, userInfo);
                            }}
                            >
                              <span className="rounded-icon">
                                <FontAwesomeIcon icon={faLock} className="color-icon-brand"/>
                              </span>
                              <span>Modifica Password</span>
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </>
                  )}
                  {userInfo.tipologiaUtente === "UTENTE" && 
                  (
                    <>
                     <Col xs="12" sm="3" md="3" className="map-profilo">
                        <Mappa regione={userInfo.comuneResidenzaInfo.regione}/>
                      </Col>
                      <Col xs="12" sm="9" md="9">
                        <h3 className="title-grafico">Utente: {userInfo.cognome.toUpperCase()}{" "}{userInfo.nome.toUpperCase()}</h3>
                        <h5 className="title-grafico">Regione Registrazione: {userInfo.comuneResidenzaInfo.regione.toUpperCase()}</h5>
                        <h5 className="title-grafico">Comune Registrazione: {userInfo.comuneResidenzaInfo.denominazione.toUpperCase()}</h5>
                        {/* <h5 className="title-grafico">Indirizzo: {userInfo.indirizzo.toUpperCase()} - {userInfo.citta.toUpperCase()} ({userInfo.provincia.toUpperCase()})</h5> */}
                        <h5 className="title-grafico">Telefono: {userInfo.telefono}</h5>
                        <h5 className="title-grafico">Email: {userInfo.email}</h5>
                        {/* <h5 className="title-grafico">Email Pec: {userInfo.emailPec}</h5> */}

                        <Row className="padding-top-row">
                          <Col xs="12" sm="4" md="4">
                          <Button color="primary" icon 
                            onClick={() => {
                              showActionModal("edit", "Modifica Informazioni", updateUser, userInfo);
                            }}
                            >
                              <span className="rounded-icon">
                                <FontAwesomeIcon icon={faContactCard} className="color-icon-brand"/>
                              </span>
                              <span>Modifica Contatti</span>
                            </Button>

                          </Col>
                          <Col xs="12" sm="4" md="4">
                          <Button color="primary" icon 
                            onClick={() => {
                              showActionPwdModal("edit", "Modifica Password", updatePwd, userInfo);
                            }}
                            >
                              <span className="rounded-icon">
                                <FontAwesomeIcon icon={faLock} className="color-icon-brand"/>
                              </span>
                              <span>Modifica Password</span>
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </>
                  )
                   
                  }
                  {userInfo.tipologiaUtente === "ENTE" && 
                  (
                    <>
                     <Col xs="12" sm="4" md="4" className="map-profilo">
                        <Mappa regione={userInfo.comuneResidenzaInfo.regione}/>
                      </Col>
                      <Col xs="12" sm="8" md="8">
                        <h3 className="title-grafico">Ente: {userInfo.denominazione.toUpperCase()}</h3>
                        <h5 className="title-grafico">Tipo Ente: {userInfo.tipoEnte.toUpperCase()}</h5>
                        <h5 className="title-grafico">Regione Comune Principale: {userInfo.comuneResidenzaInfo.regione.toUpperCase()}</h5>
                        {/* <h5 className="title-grafico">Indirizzo: {userInfo.indirizzo.toUpperCase()} - {userInfo.citta.toUpperCase()} ({userInfo.provincia.toUpperCase()})</h5> */}
                        <h5 className="title-grafico">Telefono: {userInfo.telefono}</h5>
                        <h5 className="title-grafico">Email: {userInfo.email}</h5>
                        {/* <h5 className="title-grafico">Email Pec: {userInfo.emailPec}</h5> */}

                        <Row className="padding-top-row">
                          <Col xs="12" sm="6" md="6">
                          <Button color="primary" icon 
                            onClick={() => {
                              showActionModal("edit", "Modifica Informazioni", updateUser, userInfo);
                            }}
                            >
                              <span className="rounded-icon">
                                <FontAwesomeIcon icon={faContactCard} className="color-icon-brand"/>
                              </span>
                              <span>Modifica Contatti</span>
                            </Button>

                          </Col>
                          <Col xs="12" sm="6" md="6">
                          <Button color="primary" icon 
                            onClick={() => {
                              showActionPwdModal("edit", "Modifica Password", updatePwd, userInfo);
                            }}
                            >
                              <span className="rounded-icon">
                                <FontAwesomeIcon icon={faLock} className="color-icon-brand"/>
                              </span>
                              <span>Modifica Password</span>
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </>
                  )
                   
                  }
                  {userInfo.tipologiaUtente === "OPERATORE" && 
                  (
                    <>
                     <Col xs="12" sm="4" md="4" className="map-profilo">
                        <Mappa regione={userInfo.comune.regione}/>
                      </Col>
                      <Col xs="12" sm="8" md="8">
                      <h3 className="title-grafico">Operatore: {userInfo.cognome.toUpperCase()} {userInfo.nome.toUpperCase()}</h3>
                      <h5 className="title-grafico">Telefono: {userInfo.telefono}</h5>
                      <h5 className="title-grafico">Email: {userInfo.email}</h5>
                      <br></br>
                      <h5 className="title-grafico">Ente: {userInfo.ente.denominazione.toUpperCase()}</h5>
                      <h5 className="title-grafico">Tipo Ente: {userInfo.ente.tipoEnte.toUpperCase()}</h5>
                      <h5 className="title-grafico">Comune Riferimento: {userInfo.comune.denominazione.toUpperCase()}</h5>


                        <Row className="padding-top-row">
                          <Col xs="12" sm="6" md="6">
                          <Button color="primary" icon 
                            onClick={() => {
                              showActionModal("edit", "Modifica Informazioni", updateUser, userInfo);
                            }}
                            >
                              <span className="rounded-icon">
                                <FontAwesomeIcon icon={faContactCard} className="color-icon-brand"/>
                              </span>
                              <span>Modifica Contatti</span>
                            </Button>

                          </Col>
                          <Col xs="12" sm="6" md="6">
                          <Button color="primary" icon 
                            onClick={() => {
                              showActionPwdModal("edit", "Modifica Password", updatePwd, userInfo);
                            }}
                            >
                              <span className="rounded-icon">
                                <FontAwesomeIcon icon={faLock} className="color-icon-brand"/>
                              </span>
                              <span>Modifica Password</span>
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </>
                  )
                   
                  }
                {/* <Col xs="12" sm="3" md="3" className="map-profilo">
                  {userInfo.tipologiaUtente === "COMUNE" ? (
                    <>
                      <Mappa regione={userInfo.regione}/>
                    </>
                  )
                  :
                  (
                    <>
                    <Mappa regione={userInfo.comuneResidenzaInfo.regione}/>
                    </>
                  )
                   
                  }
                  <Mappa regione={userInfo.regione}/>
                </Col>
                <Col xs="12" sm="9" md="9">
                  <h3 className="title-grafico">Comune: {userInfo.denominazione.toUpperCase()}</h3>
                  <h5 className="title-grafico">Regione: {userInfo.regione.toUpperCase()}</h5>
                  <h5 className="title-grafico">Ubicazione: {userInfo.indirizzo.toUpperCase()} - {userInfo.citta.toUpperCase()} ({userInfo.provincia.toUpperCase()})</h5>
                  <h5 className="title-grafico">Telefono: {userInfo.telefono}</h5>
                  <h5 className="title-grafico">Email: {userInfo.email}</h5>
                  <h5 className="title-grafico">Email Pec: {userInfo.emailPec}</h5>

                  <Row className="padding-top-row">
                    <Col xs="12" sm="4" md="4">
                    <Button color="primary" icon 
                      onClick={() => {
                        showActionModal("edit", "Modifica Informazioni", updateUser, userInfo);
                      }}
                      >
                        <span className="rounded-icon">
                          <FontAwesomeIcon icon={faContactCard} className="color-icon-brand"/>
                        </span>
                        <span>Modifica Contatti</span>
                      </Button>

                    </Col>
                    <Col xs="12" sm="4" md="4">
                    <Button color="primary" icon 
                      onClick={() => {
                        showActionPwdModal("edit", "Modifica Password", updatePwd, userInfo);
                      }}
                      >
                        <span className="rounded-icon">
                          <FontAwesomeIcon icon={faLock} className="color-icon-brand"/>
                        </span>
                        <span>Modifica Password</span>
                      </Button>
                    </Col>
                  </Row>

                </Col> */}
                </>
          </Row>
          </>
        )
      }
        
    </React.Fragment>
  );
};

export default ProfiloComune;
