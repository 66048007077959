import CommonService from "../../../service/common_service";
import { put } from "redux-saga/effects";
import { showPopup } from "../../popup/actions/popup_actions";

export const GET_QRCODES = "getQrCodes"
export const GET_QRCODES_SUCCESS = "get_qrCodes_success"
export const GET_QRCODES_FAIL = "get_qrCodes_fail"

export function getQrCodes() {
  return {
    type: GET_QRCODES
  }
}

export function* getQrCodesSaga() {
  try {
    const request = {
      method: "GET",
      url: "/api/v1/qr-code/cerca",
    }
    const response = yield CommonService.execute(request)
    yield put({
      type: GET_QRCODES_SUCCESS,
      payload: response.data
    })

  } catch (error) {
    yield put({
      type: GET_QRCODES_FAIL,
      payload: error
    })
    yield put(
      showPopup(
        "error",
        "OPERAZIONE FALLITA",
        error.response.data.data.error
      )
    )
  }
}

export const GET_QRCODES_VALORE = "getQrCodesValore"
export const GET_QRCODES_VALORE_SUCCESS = "get_qrCodesValore_success"
export const GET_QRCODES_VALORE_FAIL = "get_qrCodesValore_fail"

export function getQrCodesValore(valore) {
  return {
    type: GET_QRCODES_VALORE,
    payload:valore
  }
}

export function* getQrCodesValoreSaga(action) {
  try {
    const request = {
      method: "GET",
      url: "/api/v1/qr-code/cerca/valore/"+ action.payload,
    }
    const response = yield CommonService.execute(request)
    yield put({
      type: GET_QRCODES_VALORE_SUCCESS,
      payload: response.data
    })

  } catch (error) {
    yield put({
      type: GET_QRCODES_VALORE_FAIL,
      payload: error
    })
    yield put(
      showPopup(
        "error",
        "OPERAZIONE FALLITA",
        error.response.data.data.error
      )
    )
  }
}


export const INSERT_QRCODE = "insertQrCode"
export const INSERT_QRCODE_SUCCESS = "insertQrCode_success"
export const INSERT_QRCODE_FAILED = "insertQrCode_failed"

export function insertQrCode(valore, stato, comune) {
  return {
    type: INSERT_QRCODE,
    payload: {
      valore: valore,
      stato: stato,
      comune: comune // SUPERUSER / ENTE -> inserire anche l'id del comune per creare un nuovo qrCode
    }
  }
}

export function* insertQrCodeSaga(action) {
  try {
    const request = {
      url: "/api/v1/qr-code/insert",
      method: "POST",
      data: {
        stato: action.payload.stato,
        valore: action.payload.valore,
        comuneId: action.payload.comune,
      }
    }

    const response = yield CommonService.execute(request)
    yield put({
      type: GET_QRCODES
    })
    yield put(
      showPopup(
        "notify",
        "OPERAZIONE RIUSCITA",
        response.data.message
      )
    )

  } catch (error) {
    yield put({
      type: INSERT_QRCODE_FAILED,
      payload: error.response.data.data.error
    })
    yield put(
      showPopup(
        "error",
        "OPERAZIONE FALLITA",
        error.response.data.message
      )
    )
  }
}

export const UPDATE_QRCODE = "updateQrCode"
export const UPDATE_QRCODE_SUCCESS = "updateQrCode_success"
export const UPDATE_QRCODE_FAILED = "updateQrCode_failed"

export function updateQrCode(qrCodeId, valore, stato, comune) {
  return {
    type: UPDATE_QRCODE,
    payload: {
      qrCodeId: qrCodeId,
      valore: valore,
      stato: stato,
      comune: comune // SUPERUSER / ENTE -> inserire anche l'id del comune per creare un nuovo qrCode
    }
  }
}

export function* updateQrCodeSaga(action) {
  try {
    const request = {
      url: "/api/v1/qr-code/update",
      method: "PUT",
      data: {
        id: action.payload.qrCodeId,
        stato: action.payload.stato,
        valore: action.payload.valore,
        comuneId: action.payload.comune,
      }
    }

    const response = yield CommonService.execute(request)
    yield put({
      type: GET_QRCODES
    })
    yield put(
      showPopup(
        "notify",
        "OPERAZIONE RIUSCITA",
        response.data.message
      )
    )

  } catch (error) {
    yield put({
      type: UPDATE_QRCODE_FAILED,
      payload: error.response.data.data.error
    })
    yield put(
      showPopup(
        "error",
        "OPERAZIONE FALLITA",
        error.response.data.message
      )
    )
  }
}

export const DELETE_QRCODE = "deleteQrCode"
export const DELETE_QRCODE_SUCCESS = "delete_qrCode_success"
export const DELETE_QRCODE_FAILED = "delete_qrCode_fail"

export function deleteQrCode(qrCodeId) {
  return {
    type: DELETE_QRCODE,
    payload: qrCodeId
  }
}

export function* deleteQrCodeSaga(action) {
  try {
    const url = "/api/v1/qr-code/delete/" + action.payload
    const request = {
      method: "DELETE",
      url: url,
    }

    const response = yield CommonService.execute(request)
    yield put({
      type: GET_QRCODES
    })
    yield put(
      showPopup(
        "notify",
        "OPERAZIONE RIUSCITA",
        response.data.message
      )
    )

  } catch (error) {
    yield put({
      type: DELETE_QRCODE_FAILED,
      payload: error.response.data.data.error
    })
    yield put(
      showPopup(
        "error",
        "OPERAZIONE FALLITA",
        error.response.data.message
      )
    )
  }
}




