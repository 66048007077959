import CommonService from "../../../service/common_service";
import { put } from "redux-saga/effects";
import { showPopup } from "../../popup/actions/popup_actions";

import axios from "axios"
import CommonServicePdf from "../../../service/common_service_pdf";

export const GET_SCONTISTICA = "getScontistica"
export const GET_SCONTISTICA_SUCCESS = "get_scontistica_success"
export const GET_SCONTISTICA_FAIL = "get_scontistica_fail"


export function getScontistica() {
  return {
      type: GET_SCONTISTICA
  }
}

export function* getScontisticaSaga() {
  try {

      const request = {
          method: "GET",
          url: "/api/v1/scontistica/cerca",
      }

      const response = yield CommonService.execute(request)
      yield put({
          type: GET_SCONTISTICA_SUCCESS,
          payload: response.data
      })

  } catch (error) {
      yield put({
          type: GET_SCONTISTICA_FAIL,
          payload: error
      })
      yield put(
        showPopup(
            "error",
            "OPERAZIONE FALLITA",
            error.response.data.data.error
        )
    )
  }
}

export const INSERT_SCONTISTICA = "insertScontistica"
export const INSERT_SCONTISTICA_SUCCESS = "insertScontistica_success"
export const INSERT_SCONTISTICA_FAILED = "insertScontistica_failed"

export function insertScontistica(comune, tipologiaSpazzatura, tariffa, sconto) {
    return {
        type: INSERT_SCONTISTICA,
        payload: {
            comune: comune,
            tipologiaSpazzatura: tipologiaSpazzatura,
            tariffa: tariffa,
            sconto: sconto
        }
    }
}

export function* insertScontisticaSaga(action) {
    try {
        const request = {
            url: "/api/v1/scontistica/insert", // crea un scontistica e lo assegna all'utenza
            method: "POST",
            data: {
                comune: action.payload.comune,
                tipologiaSpazzatura: action.payload.tipologiaSpazzatura,
                tariffa: action.payload.tariffa,
                sconto: action.payload.sconto
            }
        }

        const response = yield CommonService.execute(request)
        yield put({
            type: GET_SCONTISTICA
        })
        yield put(
            showPopup(
                "notify",
                "OPERAZIONE RIUSCITA",
                response.data.message
            )
        )
    } catch (error) {
        yield put({
            type: INSERT_SCONTISTICA_FAILED,
            payload: error.response.data.data.error
        })
        yield put(
            showPopup(
                "error",
                "OPERAZIONE FALLITA",
                error.response.data.message
            )
        )
    }
}

export const UPDATE_SCONTISTICA = "updateScontistica"
export const UPDATE_SCONTISTICA_SUCCESS = "updateScontistica_success"
export const UPDATE_SCONTISTICA_FAILED = "updateScontistica_failed"

export function updateScontistica(scontisticaId, comune, tipologiaSpazzatura, tariffa, sconto) {
    return {
        type: UPDATE_SCONTISTICA,
        payload: {
            id : scontisticaId,
            comune: comune,
            tipologiaSpazzatura: tipologiaSpazzatura,
            tariffa: tariffa,
            sconto: sconto
        }
    }
}

export function* updateScontisticaSaga(action) {
    try {
        const request = {
            url: "/api/v1/scontistica/update",
            method: "PUT",
            data: {
                id: action.payload.id,
                comune: action.payload.comune,
                tipologiaSpazzatura: action.payload.tipologiaSpazzatura,
                tariffa: action.payload.tariffa,
                sconto: action.payload.sconto
            }
        }

        const response = yield CommonService.execute(request)
        yield put({
            type: GET_SCONTISTICA
        })
        yield put(
            showPopup(
                "notify",
                "OPERAZIONE RIUSCITA",
                response.data.message
            )
        )

    } catch (error) {
        yield put({
            type: UPDATE_SCONTISTICA_FAILED,
            payload: error.response.data.data.error
        })
        yield put(
            showPopup(
                "error",
                "OPERAZIONE FALLITA",
                error.response.data.message
            )
        )
    }
}

export const DELETE_SCONTISTICA = "deleteScontistica"
export const DELETE_SCONTISTICA_SUCCESS = "delete_scontistica_success"
export const DELETE_SCONTISTICA_FAILED = "delete_scontistica_fail"

export function deleteScontistica(scontisticaId) {
    return {
        type: DELETE_SCONTISTICA,
        payload: scontisticaId
    }
}

export function* deleteScontisticaSaga(action) {
    try {
        const url = "/api/v1/scontistica/delete/" + action.payload
        const request = {
            method: "DELETE",
            url: url,
        }

        const response = yield CommonService.execute(request)
        yield put({
            type: GET_SCONTISTICA
        })
        yield put(
            showPopup(
                "notify",
                "OPERAZIONE RIUSCITA",
                response.data.message
            )
        )  

    } catch (error) {
        yield put({
            type: DELETE_SCONTISTICA_FAILED,
            payload: error.response.data.data.error
        })
        yield put(
            showPopup(
                "error",
                "OPERAZIONE FALLITA",
                error.response.data.data.error
            )
        )
    }
}

export const STAMPA_SCONTISTICA = "stampaScontistica"
export const STAMPA_SCONTISTICA_SUCCESS = "stampa_scontistica_success"
export const STAMPA_SCONTISTICA_FAILED = "stampa_scontistica_fail"

export function stampaScontistica(id_utenza,comune, 
                                comune_indirizzo, 
                                data, 
                                num_scontistica, 
                                tipo_scontistica, 
                                nome_cognome, 
                                cod_fisc,
                                indirizzo, 
                                comune_utenza) {
    return {
        type: STAMPA_SCONTISTICA,
        payload: {
             id_utenza: id_utenza,
             comune: comune, 
             comune_indirizzo: comune_indirizzo, 
             data: data, 
             num_scontistica: num_scontistica, 
             tipo_scontistica: tipo_scontistica, 
             nome_cognome: nome_cognome, 
             cod_fisc: cod_fisc,
             indirizzo: indirizzo, 
             comune_utenza: comune_utenza

        }
    }
}

export function* stampaScontisticaSaga(action) {
    try {
        //const url = "/api/v1/scontistica/export/pdf/" + action.payload // Elimina un Scontistica e riporta a false lo stato del QrCode associato generatePdfFile
        const url = "/api/v1/scontistica/generatePdfFile"// + action.payload // Elimina un Scontistica e riporta a false lo stato del QrCode associato generatePdfFile
        const request = {
            method: "POST",
            url: url,
            data: {
                id_utenza: action.payload.id_utenza,
                comune: action.payload.comune, 
                comune_indirizzo: action.payload.comune_indirizzo, 
                data: action.payload.data, 
                num_scontistica: action.payload.num_scontistica, 
                tipo_scontistica: action.payload.tipo_scontistica, 
                nome_cognome: action.payload.nome_cognome, 
                cod_fisc: action.payload.cod_fisc,
                indirizzo: action.payload.indirizzo, 
                comune_utenza: action.payload.comune_utenza,
            }
        }

        const response_pdf = yield CommonServicePdf.execute(request)

        const file = new Blob([response_pdf.data], { type: 'application/pdf' })
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, '_blank');


        // axios.get(`http://localhost:8080/api/v1/scontistica/generatePdfFile`, {
        //     request,
        //     responseType: 'blob'
        //   }).then((response) => {
        //     window.open(URL.createObjectURL(response.data));
        //   })
        
        // yield put({
        //   type: GET_SCONTISTICA,
        //   payload: undefined
        // })
        // yield put({
        //     type: GET_QRCODES
        // })
        yield put(
            showPopup(
                "notify",
                "OPERAZIONE RIUSCITA - Stampa ricevuta generata ed inviata",
                response_pdf.data.message
            )
        )  

        // let blobData = new Blob([file.body], {
        //     type: 'application/pdf'
        // });


        // console.warn(urlFile);
        // this.uploadService.getFile(urlFile).subscribe((responseMessage) => {
    
        //   const extensionFile = urlFile.slice((Math.max(0, urlFile.lastIndexOf(".")) || Infinity) + 1);
        //   console.warn(extensionFile);
        //   let typeFile = 'application/pdf';
        //   if(extensionFile === 'jpg' ){ typeFile = 'image/jpeg'}
        //   if(extensionFile === 'png' ){ typeFile = 'image/png'}
        //   const file = new Blob([responseMessage], { type: typeFile })
        //   const fileURL = URL.createObjectURL(file);
        //   window.open(fileURL, '_blank');
        // });

        


        // let fileData = new File([response.data], "name", {type: 'application/pdf'});
        // let fileURL = window.URL.createObjectURL(fileData);
        // window.open(fileURL,"_blank")

    } catch (error) {
        yield put({
            type: STAMPA_SCONTISTICA_FAILED,
            payload: error.response.data.data.error
        })
        yield put(
            showPopup(
                "error",
                "OPERAZIONE FALLITA",
                error.response.data.data.error
            )
        )
    }
}


