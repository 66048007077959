export const SHOW_SEARCH_POPUP = 'showSearchPopup'
export const HIDE_SEARCH_POPUP = 'hideSearchPopup'

export function showSearchPopup(header, label, f){ 
    return {
        type: SHOW_SEARCH_POPUP,
        payload:{
            header: header,
            label: label,
            function: f
        }
    }
}

export function hideSearchPopup(){
    return {
        type: HIDE_SEARCH_POPUP 
    }
}