import CommonService from "../../../service/common_service";
import { put } from "redux-saga/effects";
import { showPopup } from "../../popup/actions/popup_actions";
import { GET_QRCODES } from "../../qrCode/actions/qrCode_actions"

import axios from "axios"
import CommonServicePdf from "../../../service/common_service_pdf";

export const GET_MASTELLI = "getMastelli"
export const GET_MASTELLI_SUCCESS = "get_mastelli_success"
export const GET_MASTELLI_FAIL = "get_mastelli_fail"


export function getMastelli() {
    return {
        type: GET_MASTELLI
    }
}

export function* getMastelliSaga() {
    try {

        const request = {
            method: "GET",
            url: "/api/v1/mastello/cerca",
        }

        const response = yield CommonService.execute(request)
        yield put({
            type: GET_MASTELLI_SUCCESS,
            payload: response.data
        })

    } catch (error) {
        yield put({
            type: GET_MASTELLI_FAIL,
            payload: error
        })
        yield put(
            showPopup(
                "error",
                "OPERAZIONE FALLITA",
                error.response.data.data.error
            )
        )
    }
}

export const INSERT_MASTELLO = "insertMastello"
export const INSERT_MASTELLO_SUCCESS = "insertMastello_success"
export const INSERT_MASTELLO_FAILED = "insertMastello_failed"

export function insertMastello(utenza, tipologiaSpazzatura, valoreQrCode) {
    return {
        type: INSERT_MASTELLO,
        payload: {
            utenza: utenza,
            tipologiaSpazzatura: tipologiaSpazzatura,
            valoreQrCode: valoreQrCode
        }
    }
}

export function* insertMastelloSaga(action) {
    try {
        const request = {
            url: "/api/v1/mastello/mastello-to-utenza", // crea un mastello e lo assegna all'utenza
            method: "POST",
            data: {
                utenzaId: action.payload.utenza,
                tipologiaSpazzaturaId: action.payload.tipologiaSpazzatura,
                valoreQR: action.payload.valoreQrCode
            }
        }

        const response = yield CommonService.execute(request)
        yield put({
            type: GET_MASTELLI
        })
        yield put(
            showPopup(
                "notify",
                "OPERAZIONE RIUSCITA",
                response.data.message
            )
        )
        yield put({
            type: GET_QRCODES
        })

    } catch (error) {
        yield put({
            type: INSERT_MASTELLO_FAILED,
            payload: error.response.data.data.error
        })
        yield put(
            showPopup(
                "error",
                "OPERAZIONE FALLITA",
                error.response.data.message
            )
        )
    }
}

export const UPDATE_MASTELLO = "updateMastello"
export const UPDATE_MASTELLO_SUCCESS = "updateMastello_success"
export const UPDATE_MASTELLO_FAILED = "updateMastello_failed"

export function updateMastello(mastelloId, utenza, tipologiaSpazzatura, valoreQrCode, statoQr) {
    return {
        type: UPDATE_MASTELLO,
        payload: {
            mastelloId: mastelloId,
            utenza: utenza,
            tipologiaSpazzatura: tipologiaSpazzatura,
            valoreQrCode: valoreQrCode,
            statoQr: statoQr
        }
    }
}

export function* updateMastelloSaga(action) {
    try {
        const request = {
            url: "/api/v1/mastello/update",
            method: "PUT",
            data: {
                id: action.payload.mastelloId,
                utenzaId: action.payload.utenza,
                tipologiaSpazzaturaId: action.payload.tipologiaSpazzatura,
                valoreQR: action.payload.valoreQrCode,
                statoQr: action.payload.statoQr
            }
        }

        const response = yield CommonService.execute(request)
        yield put({
            type: GET_MASTELLI
        })
        yield put({
            type: GET_QRCODES
        })
        yield put(
            showPopup(
                "notify",
                "OPERAZIONE RIUSCITA",
                response.data.message
            )
        )

    } catch (error) {
        yield put({
            type: UPDATE_MASTELLO_FAILED,
            payload: error.response.data.data.error
        })
        yield put(
            showPopup(
                "error",
                "OPERAZIONE FALLITA",
                error.response.data.message
            )
        )
    }
}

export const DELETE_MASTELLO = "deleteMastello"
export const DELETE_MASTELLO_SUCCESS = "delete_mastello_success"
export const DELETE_MASTELLO_FAILED = "delete_mastello_fail"

export function deleteMastello(mastelloId) {
    return {
        type: DELETE_MASTELLO,
        payload: mastelloId
    }
}

export function* deleteMastelloSaga(action) {
    try {
        const url = "/api/v1/mastello/storna-qrcode/" + action.payload // Elimina un Mastello e riporta a false lo stato del QrCode associato
        const request = {
            method: "DELETE",
            url: url,
        }

        const response = yield CommonService.execute(request)
        yield put({
            type: GET_MASTELLI
        })
        yield put({
            type: GET_QRCODES
        })
        yield put(
            showPopup(
                "notify",
                "OPERAZIONE RIUSCITA",
                response.data.message
            )
        )

    } catch (error) {
        yield put({
            type: DELETE_MASTELLO_FAILED,
            payload: error.response.data.data.error
        })
        yield put(
            showPopup(
                "error",
                "OPERAZIONE FALLITA",
                error.response.data.data.error
            )
        )
    }
}

export const STAMPA_MASTELLO = "stampaMastello"
export const STAMPA_MASTELLO_SUCCESS = "stampa_mastello_success"
export const STAMPA_MASTELLO_FAILED = "stampa_mastello_fail"

export function stampaMastello(id_utenza, comune,
    comune_indirizzo,
    data,
    dataString,
    num_mastello,
    tipo_mastello,
    nome_cognome,
    cod_fisc,
    indirizzo,
    comune_utenza,
    email) {
    return {
        type: STAMPA_MASTELLO,
        payload: {
            id_utenza: id_utenza,
            comune: comune,
            comune_indirizzo: comune_indirizzo,
            data: data,
            dataString: dataString,
            num_mastello: num_mastello,
            tipo_mastello: tipo_mastello,
            nome_cognome: nome_cognome,
            cod_fisc: cod_fisc,
            indirizzo: indirizzo,
            comune_utenza: comune_utenza,
            email: email

        }
    }
}

export function* stampaMastelloSaga(action) {

    try {
        const data = new Date(action.payload.data)
        //const url = "/api/v1/mastello/export/pdf/" + action.payload // Elimina un Mastello e riporta a false lo stato del QrCode associato generatePdfFile
        const url = "/api/v1/mastello/generatePdfFile"// + action.payload // Elimina un Mastello e riporta a false lo stato del QrCode associato generatePdfFile
        const request = {
            method: "POST",
            url: url,
            data: {
                id_utenza: action.payload.id_utenza,
                comune: action.payload.comune,
                comune_indirizzo: action.payload.comune_indirizzo,
                data: data.toLocaleDateString('it-it', { year: "numeric", month: "numeric", day: "numeric" }),
                dataString: action.payload.dataString,
                num_mastello: action.payload.num_mastello,
                tipo_mastello: action.payload.tipo_mastello,
                nome_cognome: action.payload.nome_cognome,
                cod_fisc: action.payload.cod_fisc,
                indirizzo: action.payload.indirizzo,
                comune_utenza: action.payload.comune_utenza,
                email: action.payload.email
            }
        }

        const response_pdf = yield CommonServicePdf.execute(request)

        const file = new Blob([response_pdf.data], { type: 'application/pdf' })
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, '_blank');


        // axios.get(`http://localhost:8080/api/v1/mastello/generatePdfFile`, {
        //     request,
        //     responseType: 'blob'
        //   }).then((response) => {
        //     window.open(URL.createObjectURL(response.data));
        //   })

        // yield put({
        //   type: GET_MASTELLI,
        //   payload: undefined
        // })
        // yield put({
        //     type: GET_QRCODES
        // })
        yield put(
            showPopup(
                "notify",
                "OPERAZIONE RIUSCITA - Stampa ricevuta generata ed inviata",
                response_pdf.data.message
            )
        )

        // let blobData = new Blob([file.body], {
        //     type: 'application/pdf'
        // });


        // console.warn(urlFile);
        // this.uploadService.getFile(urlFile).subscribe((responseMessage) => {

        //   const extensionFile = urlFile.slice((Math.max(0, urlFile.lastIndexOf(".")) || Infinity) + 1);
        //   console.warn(extensionFile);
        //   let typeFile = 'application/pdf';
        //   if(extensionFile === 'jpg' ){ typeFile = 'image/jpeg'}
        //   if(extensionFile === 'png' ){ typeFile = 'image/png'}
        //   const file = new Blob([responseMessage], { type: typeFile })
        //   const fileURL = URL.createObjectURL(file);
        //   window.open(fileURL, '_blank');
        // });




        // let fileData = new File([response.data], "name", {type: 'application/pdf'});
        // let fileURL = window.URL.createObjectURL(fileData);
        // window.open(fileURL,"_blank")

    } catch (error) {
        yield put({
            type: STAMPA_MASTELLO_FAILED,
            payload: error.response.data.data.error
        })
        yield put(
            showPopup(
                "error",
                "OPERAZIONE FALLITA",
                error.response.data.data.error
            )
        )
    }
}


