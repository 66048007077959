import { takeEvery, all } from "redux-saga/effects"
import { LOGIN_REQUEST, loginSaga } from "../login/actions/login_actions"
import { GET_UTENZE, getUtenzeSaga, INSERT_UTENZA, insertUtenzaSaga, UPDATE_UTENZA, updateUtenzaSaga, DELETE_UTENZA, deleteUtenzaSaga } from "../common/components/utenze/actions/utenze_actions"
import { GET_COLLABORAZIONI, DELETE_COLLABORAZIONE, INSERT_COLLABORAZIONE, getCollaborazioniSaga, deleteCollaborazioneSaga, insertCollaborazioneSaga } from "../common/components/collaborazioni/actions/collaborazioni_actions"
import { GET_CALENDARI, getCalendariSaga, GET_CALENDARI_BY_COLLABORAZIONE, getCalendariByCollaborazioneSaga, INSERT_CALENDARIO, insertCalendarioSaga, UPDATE_CALENDARIO, updateCalendarioSaga, DELETE_CALENDARIO, deleteCalendarioSaga } from "../common/components/calendario/actions/calendario_actions"
import { GET_MASTELLI, getMastelliSaga, INSERT_MASTELLO, insertMastelloSaga, UPDATE_MASTELLO, updateMastelloSaga, DELETE_MASTELLO, deleteMastelloSaga, STAMPA_MASTELLO, stampaMastelloSaga } from "../common/components/mastello/actions/mastello_actions"
import { GET_QRCODES, getQrCodesSaga, INSERT_QRCODE, insertQrCodeSaga, UPDATE_QRCODE, updateQrCodeSaga, DELETE_QRCODE, deleteQrCodeSaga, GET_QRCODES_VALORE, getQrCodesValoreSaga } from "../common/components/qrCode/actions/qrCode_actions"
import { GET_RICHIESTE_INGOMBRANTI, INSERT_INGOMBRANTI, UPDATE_INGOMBRANTI, DELETE_INGOMBRANTI, deleteIngombrantiSaga, getRichiesteIngombrantiSaga, insertIngombrantiSaga, updateIngombrantiSaga } from "../common/components/richiestaIngombranti/actions/richiestaIngombranti_actions"
import { GET_TIPO_SPAZZATURA, getTipologieSpazzaturaSaga, INSERT_TIPO_SPAZZATURA, insertTipologiaSpazzaturaSaga, UPDATE_TIPO_SPAZZATURA, updateTipologiaSpazzaturaSaga, DELETE_TIPO_SPAZZATURA, deleteTipologiaSpazzaturaSaga } from "../common/components/tipologiaSpazzatura/actions/tipologiaSpazzatura_actions"
import { GET_ALL_UTENTI, getAllUtentiSaga, GET_UTENTI_COMUNE_UTENZA, getUtentiComuneUtenzaSaga, GET_UTENTE_BY_USERNAME, getUtenteByUsernameSaga,
    INSERT_UTENTE, insertUtenteSaga, UPDATE_UTENTE, updateUtenteSaga, DELETE_UTENTE, deleteUtenteSaga } from "../common/components/utente/actions/utente_actions"
import { GET_ENTI, getEntiSaga } from "../ente/actions/ente_actions"

import { LOGOUT_REQUEST, logoutSaga } from "../common/components/logout/actions/logout_actions"
import { GET_TIPI_IMMOBILI, getTipiImmobiliSaga } from "../common/components/tipoImmobile/actions/tipoImmobile_actions"
import { GET_OPERATORE, INSERT_OPERATORE, UPDATE_OPERATORE, DELETE_OPERATORE, getOperatoreSaga, insertOperatoreSaga, updateOperatoreSaga, deleteOperatoreSaga } from "../common/components/operatori/actions/operatori_actions"
import { GET_USER_INFO, UPDATE_USER, getUserInfoSaga, updateUserSaga } from "../common/components/dashboard/dashboard_Action"
import { UPDATE_PWD, updatePwd, updatePwdSaga } from "../common/components/dashboard/pwd_action"
import { GET_DISPOSITIVI, INSERT_DISPOSITIVO, UPDATE_DISPOSITIVO, DELETE_DISPOSITIVO, deleteDispositivoSaga, getDispositiviSaga, insertDispositivoSaga, updateDispositivoSaga } from "../common/components/dispositivi/actions/dispositivo_actions"
import { DELETE_PESATA, GET_PESATE, INSERT_PESATA, UPDATE_PESATA, deletePesataSaga, getPesateSaga, insertPesataSaga, updatePesataSaga } from "../common/components/pesata/actions/pesata_actions"
import { DELETE_ENTE, GET_ENTE, GET_ENTE_COMUNE, INSERT_ENTE, UPDATE_ENTE, deleteEnteSaga, getEnteSaga, insertEnteSaga, updateEnteSaga } from "../common/components/enti/actions/enti_actions"
import { DELETE_RACCOLTA, GET_RACCOLTA, INSERT_RACCOLTA, UPDATE_RACCOLTA, deleteRaccoltaSaga, getRaccoltaSaga, insertRaccoltaSaga, updateRaccoltaSaga } from "../common/components/raccolta/actions/raccolta_actions"
import { RECUPERA_PASSWORD_REQUEST, RESET_PASSWORD_REQUEST, recuperaPasswordSaga, resetPasswordSaga } from "../password/actions/password_actions"
import { DELETE_SCONTISTICA, GET_SCONTISTICA, INSERT_SCONTISTICA, UPDATE_SCONTISTICA, deleteScontisticaSaga, getScontisticaSaga, insertScontisticaSaga, updateScontisticaSaga } from "../common/components/scontistica/actions/scontistica_actions"
import { DELETE_COMUNE, GET_COMUNE, INSERT_COMUNE, UPDATE_COMUNE, deleteComuneSaga, getComuneSaga, insertComuneSaga, updateComuneSaga } from "../common/components/comune/actions/comune_actions"

function* wbdSaga() {
    yield all([
        takeEvery(LOGIN_REQUEST, loginSaga),

        takeEvery(GET_UTENZE, getUtenzeSaga),
        takeEvery(INSERT_UTENZA, insertUtenzaSaga),
        takeEvery(UPDATE_UTENZA, updateUtenzaSaga),
        takeEvery(DELETE_UTENZA, deleteUtenzaSaga),

        takeEvery(GET_COLLABORAZIONI, getCollaborazioniSaga),
        takeEvery(DELETE_COLLABORAZIONE, deleteCollaborazioneSaga),
        takeEvery(INSERT_COLLABORAZIONE, insertCollaborazioneSaga),

        takeEvery(GET_CALENDARI, getCalendariSaga),
        takeEvery(GET_CALENDARI_BY_COLLABORAZIONE, getCalendariByCollaborazioneSaga),
        takeEvery(INSERT_CALENDARIO, insertCalendarioSaga),
        takeEvery(UPDATE_CALENDARIO, updateCalendarioSaga),
        takeEvery(DELETE_CALENDARIO, deleteCalendarioSaga),

        takeEvery(GET_MASTELLI, getMastelliSaga),
        takeEvery(INSERT_MASTELLO, insertMastelloSaga),
        takeEvery(UPDATE_MASTELLO, updateMastelloSaga),
        takeEvery(DELETE_MASTELLO, deleteMastelloSaga),
        takeEvery(STAMPA_MASTELLO, stampaMastelloSaga),

        takeEvery(GET_QRCODES, getQrCodesSaga),
        takeEvery(INSERT_QRCODE, insertQrCodeSaga),
        takeEvery(UPDATE_QRCODE, updateQrCodeSaga),
        takeEvery(DELETE_QRCODE, deleteQrCodeSaga),

        takeEvery(GET_RICHIESTE_INGOMBRANTI, getRichiesteIngombrantiSaga),
        takeEvery(INSERT_INGOMBRANTI, insertIngombrantiSaga),
        takeEvery(UPDATE_INGOMBRANTI, updateIngombrantiSaga),
        takeEvery(DELETE_INGOMBRANTI, deleteIngombrantiSaga),


        takeEvery(GET_TIPO_SPAZZATURA, getTipologieSpazzaturaSaga),
        takeEvery(INSERT_TIPO_SPAZZATURA, insertTipologiaSpazzaturaSaga),
        takeEvery(UPDATE_TIPO_SPAZZATURA, updateTipologiaSpazzaturaSaga),
        takeEvery(DELETE_TIPO_SPAZZATURA, deleteTipologiaSpazzaturaSaga),

        takeEvery(GET_ALL_UTENTI, getAllUtentiSaga),
        takeEvery(GET_UTENTI_COMUNE_UTENZA, getUtentiComuneUtenzaSaga),
        takeEvery(GET_UTENTE_BY_USERNAME, getUtenteByUsernameSaga),
        takeEvery(INSERT_UTENTE, insertUtenteSaga),
        takeEvery(UPDATE_UTENTE, updateUtenteSaga),
        takeEvery(DELETE_UTENTE, deleteUtenteSaga),

        takeEvery(GET_ENTI, getEntiSaga),
        takeEvery(LOGOUT_REQUEST, logoutSaga),
        takeEvery(GET_TIPI_IMMOBILI, getTipiImmobiliSaga),

        takeEvery(GET_OPERATORE, getOperatoreSaga),
        takeEvery(INSERT_OPERATORE, insertOperatoreSaga),
        takeEvery(UPDATE_OPERATORE, updateOperatoreSaga),
        takeEvery(DELETE_OPERATORE, deleteOperatoreSaga),
        //takeEvery(UPDATE_OP, getOperatoreSaga)
        takeEvery(GET_USER_INFO, getUserInfoSaga),
        takeEvery(UPDATE_USER, updateUserSaga),

        takeEvery(UPDATE_PWD, updatePwdSaga),

        takeEvery(GET_DISPOSITIVI, getDispositiviSaga),
        takeEvery(INSERT_DISPOSITIVO, insertDispositivoSaga),
        takeEvery(UPDATE_DISPOSITIVO, updateDispositivoSaga),
        takeEvery(DELETE_DISPOSITIVO, deleteDispositivoSaga),

        takeEvery(GET_PESATE, getPesateSaga),
        takeEvery(INSERT_PESATA, insertPesataSaga),
        takeEvery(UPDATE_PESATA, updatePesataSaga),
        takeEvery(DELETE_PESATA, deletePesataSaga),


        takeEvery(GET_ENTE_COMUNE, getEnteSaga),
        takeEvery(INSERT_ENTE, insertEnteSaga),
        takeEvery(UPDATE_ENTE, updateEnteSaga),
        takeEvery(DELETE_ENTE, deleteEnteSaga),

        takeEvery(GET_RACCOLTA, getRaccoltaSaga),
        takeEvery(INSERT_RACCOLTA, insertRaccoltaSaga),
        takeEvery(UPDATE_RACCOLTA, updateRaccoltaSaga),
        takeEvery(DELETE_RACCOLTA, deleteRaccoltaSaga),
        takeEvery(GET_QRCODES_VALORE, getQrCodesValoreSaga),


        takeEvery(RESET_PASSWORD_REQUEST, resetPasswordSaga),
        takeEvery(RECUPERA_PASSWORD_REQUEST, recuperaPasswordSaga),

        takeEvery(GET_SCONTISTICA, getScontisticaSaga),
        takeEvery(INSERT_SCONTISTICA, insertScontisticaSaga),
        takeEvery(UPDATE_SCONTISTICA, updateScontisticaSaga),
        takeEvery(DELETE_SCONTISTICA, deleteScontisticaSaga),

        takeEvery(GET_COMUNE, getComuneSaga),
        takeEvery(INSERT_COMUNE, insertComuneSaga),
        takeEvery(UPDATE_COMUNE, updateComuneSaga),
        takeEvery(DELETE_COMUNE, deleteComuneSaga)


    ])
}

export default wbdSaga