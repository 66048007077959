export const SHOW_PWD_MODAL = "showPwdModal"
export const HIDE_PWD_MODAL = "hidePwdModal"

export function showPwdModal(type, header, f, obj){
  return {
      type: SHOW_PWD_MODAL,
      payload:{
        type: type,
        header: header,
        function: f,
        obj: obj
    }
  }
}

export function hidePwdModal(){
  return {
      type: HIDE_PWD_MODAL
  }
}