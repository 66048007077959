import React, { useEffect, useState } from "react";
import CommonService from "../../../service/common_service";
import { useDispatch, useSelector } from "react-redux";

import { getUserInfo} from "../dashboard_Action";
import { getUtenze } from "../../utenze/actions/utenze_actions"


import {
  Button,
  Spinner,
  Row,
  Col
} from "design-react-kit";
import _ from "lodash";
import DonutChart from "../../grafici/DonutChart";
import { Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
  } from 'chart.js';
import Mappa from "../../grafici/Mappa";
import { getRaccolta } from "../../raccolta/actions/raccolta_actions";
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
  );


const DashboardAdmin = () => {



  const dispatch = useDispatch();


  const userInfo = useSelector(
    (state) => state.userInfo.userInfo
  );

  // const raccolta = useSelector(
  //   (state) => state.raccolta.raccolta
  // );

  let loading = true//useSelector((state) => state.userInfo.loading);
  const [dati, setDati] = useState();

  const [datiRaccoltaAnno, setDatiRaccoltaAnno] = useState([]);
  const [datiRaccoltaMese, setDatiRaccoltaMese] = useState([]);

  const [tariffaPienaMese, setTariffaPienaMese] = useState([]);
  const [tariffaScontoMese, setTariffaScontoMese] = useState([]);

  const [tariffaPienaAnno, setTariffaAnno] = useState([]);
  const [tariffaScontoAnno, setScontoAnno] = useState([]);

  const [datiRaccoltaMetalli, setDatiRaccoltaMetalli] = useState([]);
  const [datiRaccoltaCartaCartone, setDatiCartaCartone] = useState([]);
  const [datiRaccoltaElettrici, setDatiElettrici] = useState([]);
  const [datiRaccoltaIngombranti, setDatiRaccoltaIngombranti] = useState([]);
  const [datiRaccoltaSeccoIndifferenziato, setDatiRaccoltaSeccoIndifferenziato] = useState([]);
  const [datiRaccoltaSpeciali, setDatiRaccoltaSpeciali] = useState([]);
  const [datiRaccoltaUmido, setDatiRaccoltaUmido] = useState([]);
  const [datiRaccoltaVetro, setDatiRaccoltaVetro] = useState([]);
  const [datiRaccoltaPlastica, setDatiRaccoltaPlastica] = useState([]);

  const utenze = useSelector((state) => state.utenze.utenze);


  const countType = (type) => {
        const countTypes =  type.length
        return countTypes;
  }

  // useEffect(() => {
  //   if (raccolta === undefined) {
  //     //dispatch(getRaccolta());
  //   }else{
  //     console.log(raccolta)

  //     const datiRaccoltaComuneMeseCorrente = []
  //     const datiRaccoltaComuneAnnoCorrente = []

  //     let tariffaAnno = 0
  //     let scontoAnno = 0

  //     _.forEach(raccolta.raccolteTipoQtyAnno, (racc) => {

  //       const r = {
  //         "name": racc.tipoSpazzatura,
  //         "value": racc.quantita,
  //         "tariffaPiena": racc.tariffa,
  //         "tariffaScontata": racc.sconto
  //       }
  //       tariffaAnno += racc.tariffa
  //       scontoAnno += racc.sconto
  //       setTariffaAnno(tariffaAnno)
  //       setScontoAnno(scontoAnno)

  //       datiRaccoltaComuneAnnoCorrente.push(r)
  //     });
  //     let tariffaMese = 0
  //     let scontoMese = 0
  //     _.forEach(raccolta.raccolteTipoQtyMese, (racc) => {

  //       const r = {
  //         "name": racc.tipoSpazzatura,
  //         "value": racc.quantita,
  //         "tariffaPiena": racc.tariffa,
  //         "tariffaScontata": racc.sconto
  //       }
  //       tariffaMese += racc.tariffa
  //       scontoMese += racc.sconto
  //       setTariffaPienaMese(tariffaMese)
  //       setTariffaScontoMese(scontoMese)
  //       datiRaccoltaComuneMeseCorrente.push(r)
  //     });

  //     _.forEach(raccolta.raccoltaMeseAnno, (racc) => {


  //       switch(racc.tipoSpazzatura){
  //         case "ALLUMINIO E METALLI":
  //           setDatiRaccoltaMetalli(racc.raccoltaSingolaMeseQty.listaRaccolta)
  //           break;
  //         case "CARTA E CARTONE":
  //             setDatiCartaCartone(racc.raccoltaSingolaMeseQty.listaRaccolta)
  //             break;
  //         case "ELETTRICI":
  //           setDatiElettrici(racc.raccoltaSingolaMeseQty.listaRaccolta)
  //           break;
  //         case "PLASTICA":
  //           setDatiRaccoltaPlastica(racc.raccoltaSingolaMeseQty.listaRaccolta)
  //           break;
  //         case "INGOMBRANTE":
  //           setDatiRaccoltaIngombranti(racc.raccoltaSingolaMeseQty.listaRaccolta)
  //           break;
  //         case "SECCO INDIFFERENZIATO":
  //           setDatiRaccoltaSeccoIndifferenziato(racc.raccoltaSingolaMeseQty.listaRaccolta)
  //           break;
  //         case "SPECIALI":
  //           setDatiRaccoltaSpeciali(racc.raccoltaSingolaMeseQty.listaRaccolta)
  //           break;
  //         case "UMIDO O ORGANICO":
  //           setDatiRaccoltaUmido(racc.raccoltaSingolaMeseQty.listaRaccolta)
  //           break;
  //         case "VETRO":
  //           setDatiRaccoltaVetro(racc.raccoltaSingolaMeseQty.listaRaccolta)
  //           break;
  //       }

  //       const r = {
  //         "name": racc.tipoSpazzatura,
  //         "value": racc.quantita
  //       }
  //       datiRaccoltaComuneMeseCorrente.push(r)
  //     });

  //     setDatiRaccoltaMese(datiRaccoltaComuneMeseCorrente)
  //     setDatiRaccoltaAnno(datiRaccoltaComuneAnnoCorrente)

  //   }
  // }, [raccolta]);

  useEffect(() => {
    if (userInfo === undefined) {
      dispatch(getUtenze());
    } else {
      const tmp = [];
      _.forEach(userInfo, (ts) => {
        tmp.push(ts);
      });
      setDati(userInfo);
      console.log(userInfo)
      console.log(utenze)
      loading = false
    }
  }, [userInfo, dispatch]);

  //console.log(state)

    

    

      


  return (
    <React.Fragment>
      {utenze == null ? (
        <div className="customTableContainer">
          <div className="container_spinner"><Spinner active  className="spinnerwebe"/></div>
        </div>
      ) : (
        <>
        <div className="customTableContainer">
          <Row className="justify-content-md-center box-dashboard box-margin">
            <Col xs="12" sm="5" md="5">
              <Mappa regione="CALABRIA"/>
            </Col>
            <Col xs="12" sm="7" md="7">
              <Row>
                <>
                <Col xs="12" sm="12" md="12">
                  <h3 className="title-grafico">Admin WEBE DIFFERENT</h3>
                  <h4 className="title-grafico">Ubicazione: RENDE</h4>
                  {utenze != null ? (
                    <h4 className="title-grafico">Utenze Attuali: {countType(utenze)} </h4>
                  )
                  :(<h4 className="title-grafico">Utenze Attuali: in elaborazione </h4>)
                  }
                </Col>
                </>
              </Row>
            </Col>
          </Row>
          {/* <Row className="justify-content-md-center padding-top-row box-dashboard  box-margin">
                <Col xs="12" sm="6" md="6">
                    
                    {datiRaccoltaMese.length > 0 &&
                    <>
                    <h5 className="title-grafico text-center padding-title-grafico">Mensile</h5>
                    <p className="title-grafico text-center ">Tariffa Piena: € {Number(tariffaPienaMese).toFixed(2)}</p>
                    <p className="title-grafico text-center ">Tariffa Scontata: € {Number(tariffaScontoMese).toFixed(2)}</p>
                    </>
                      
                    }
                    {datiRaccoltaMese.length < 1 &&
                    <>
                    <h5 className="title-grafico text-center padding-title-grafico">Mensile</h5>
                    <p className="title-grafico text-center ">Tariffa Piena: € 0</p>
                    <p className="title-grafico text-center ">Tariffa Scontata: € 0</p>
                    </>
                      
                    }
                    
                </Col>
                <Col xs="12" sm="6" md="6">
                    {datiRaccoltaAnno.length > 0 &&
                    <>
                    <h5 className="title-grafico text-center padding-title-grafico">Annuale</h5>
                    <p className="title-grafico text-center ">Tariffa Piena: € {Number(tariffaPienaAnno).toFixed(2)}</p>
                    <p className="title-grafico text-center ">Tariffa Scontata: € {Number(tariffaScontoAnno).toFixed(2)}</p>
                    </>
                      
                    }
                    {datiRaccoltaAnno.length < 1 &&
                    <>
                    <h5 className="title-grafico text-center padding-title-grafico">Annuale</h5>
                    <p className="title-grafico text-center ">Tariffa Piena: € 0</p>
                    <p className="title-grafico text-center ">Tariffa Scontata: € 0</p>
                    </>
                      
                    }
                    
                </Col>
            </Row> */}
          {/* <Row className="justify-content-md-center box-dashboard box-margin">
                <Col xs="12" sm="6" md="6">
                    <p className="title-grafico">Raccolta Mese Corrente</p>
                    <DonutChart idgrafico="ch1" dati={dati_mese} colori={colors}></DonutChart>
                </Col>
                <Col xs="12" sm="6" md="6">
                    <p className="title-grafico">Raccolta Annuale</p>
                    <DonutChart idgrafico="ch2" dati={dati_anno} colori={colors_bis}></DonutChart>
                </Col>
            </Row> */}
            
        </div>
        </>
      )}
        
    </React.Fragment>
  );
};

export default DashboardAdmin;
