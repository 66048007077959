export const SHOW_QRCODE_MODAL = "showQrCodeModal"
export const HIDE_QRCODE_MODAL = "hide_qrCode_modal"

export function showQrCodeModal(type, header, f, obj){
  return {
      type: SHOW_QRCODE_MODAL,
      payload:{
        type: type,
        header: header,
        function: f,
        obj: obj
    }
  }
}

export function hideQrCodeModal(){
  return {
      type: HIDE_QRCODE_MODAL
  }
}
