import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Spinner,
  Row,
  Col
} from "design-react-kit";
import {
  getUtenze,
  updateUtenza,
  deleteUtenza
} from "../actions/utenze_actions";
import CustomTable from "../../table/component/CustomTable";
import _ from "lodash";
import { showUtenzaModal } from "../actions/utenzaModal_actions";
import UtenzaModal from "./UtenzaModal";
import { showPopup } from "../../popup/actions/popup_actions";
import { getUtentiComuneUtenza } from "../../utente/actions/utente_actions";
import { getTipiImmobili } from "../../tipoImmobile/actions/tipoImmobile_actions";
import { Icon } from "design-react-kit";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrashAlt, faRefresh } from '@fortawesome/free-solid-svg-icons'
import { getUserInfo } from "../../dashboard/dashboard_Action";

const Utenze = () => {

  const dispatch = useDispatch();

  const utenze = useSelector((state) => state.utenze.utenze);
  const loading = useSelector((state) => state.utenze.loading);
  const [dati, setDati] = useState([]);

  const utenti = useSelector((state) => state.utente.utentiComuneUtenza);
  const tipiImmobili = useSelector((state) => state.tipoImmobile.tipiImmobili);

  const userInfo = useSelector(
    (state) => state.userInfo.userInfo
  );

  const delUtenza = (arg) => {
    dispatch(deleteUtenza(arg));
  };

  const showActionPopUp = (type, header, message, f, arg) => {
    dispatch(showPopup(type, header, message, f, arg));
  };

  const showActionModal = (type, header, f, obj) => {
    dispatch(showUtenzaModal(type, header, f, obj));
  };

  useEffect(() => {
    if (utenze === undefined) {
      dispatch(getUtenze());
    } else {
      const tmp = [];
      _.forEach(utenze, (u) => {
        u.proprietario = u.intestatario.userName + " - " + u.intestatario.cognome + " " + u.intestatario.nome;
        u.immobileutenza = u.indirizzo + " - " + u.comune.citta + "(" + u.comune.provincia +")"
        u.actions = (
          <section>
            <span className="rounded-icon iconbottom"
              onClick={() => {
                showActionModal("edit", "Modifica Utenza", updateUtenza, u);
              }}
            >
            <FontAwesomeIcon icon={faEdit} />
            </span>
            {/* <Button
              color="primary"
              onClick={() => {
                showActionModal("edit", "Modifica Utenza", updateUtenza, u);
              }}
            >
              edit
            </Button>{" "} */}
            <span className="rounded-icon iconbottomcancel"
              onClick={() =>
                showActionPopUp(
                  "confirm",
                  "ATTENZIONE",
                  `L'utenza di ${u.proprietario} ubicata in ${u.immobileutenza} verra' cancellata. Procedere?`,
                  delUtenza,
                  u.id
                )
              }
            >
            <FontAwesomeIcon icon={faTrashAlt} />
            </span>
            {/* <Button
              color="primary"
              onClick={() =>
                showActionPopUp(
                  "confirm",
                  "ATTENZIONE",
                  `L' utenza ${u.id} verra' cancellata. Procedere?`,
                  delUtenza,
                  u.id
                )
              }
            >
              delete
            </Button> */}
          </section>
        );
        tmp.push(u);
      });
      setDati(tmp);
    }
  }, [utenze]);

  useEffect(() => {
    if (userInfo === undefined) {
      dispatch(getUserInfo());
    }
  }, [userInfo]);

  useEffect(() => {
    if(userInfo != null && userInfo.tipologiaUtente != "UTENTE"){
      if (utenti === undefined) {
        dispatch(getUtentiComuneUtenza());
      }
    }
  }, [utenti]);

  useEffect(() => {
    if (tipiImmobili === undefined) {
      dispatch(getTipiImmobili());
    }
  }, [tipiImmobili]);

  const refreshList = () => {
    dispatch(getUtenze());
    if(userInfo !=null && userInfo.tipologiaUtente != "UTENTE") dispatch(getUtentiComuneUtenza());
    dispatch(getTipiImmobili());
  };

  const headers = [
    {
      label: "Intestario",
      field: "proprietario",
    },
    {
      label: "Indirizzo",
      field: "immobileutenza",
    },
    {
      label: "Tipologia Immobile",
      field: "tipoImmobile.descrizione",
    },
    {
      label: "Azioni",
      field: "actions",
    },
  ];

  return (
    <React.Fragment>
      <UtenzaModal
        intestatarioOptions={utenti}
        tipoImmobileOptions={tipiImmobili}
      />
      {loading ? (
        <div className="customTableContainer">
          <div className="container_spinner"><Spinner active  className="spinnerwebe"/></div>
        </div>
      ) : (
        <div className="customTableContainer">
          <Row className="justify-content-md-center">
            <Col>
              <h1 className="">Utenze <FontAwesomeIcon icon={faRefresh} className="padding-lr-icon linkeffect" onClick={()=> refreshList()}/></h1>
            </Col>
          </Row>
          <CustomTable headers={headers} data={dati} />
        </div>
      )}
    </React.Fragment>
  );
};

export default Utenze;
