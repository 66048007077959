import CommonService from "../../../service/common_service";
import { put } from "redux-saga/effects"
import { showPopup } from "../../popup/actions/popup_actions";

export const GET_RICHIESTE_INGOMBRANTI = "getRichiesteIngombranti"
export const GET_RICHIESTE_INGOMBRANTI_SUCCESS = "get_richiesteIngombranti_success"
export const GET_RICHIESTE_INGOMBRANTI_FAIL = "get_richiesteIngombranti_fail"

export function getRichiesteIngombranti() {
    return {
        type: GET_RICHIESTE_INGOMBRANTI
    }
}

export function* getRichiesteIngombrantiSaga() {
    try {

        const request = {
            method: "GET",
            url: "/api/v1/richiesta-ingombranti/cerca",
        }

        const response = yield CommonService.execute(request)
        yield put({
            type: GET_RICHIESTE_INGOMBRANTI_SUCCESS,
            payload: response.data
        })

    } catch (error) {
        yield put({
            type: GET_RICHIESTE_INGOMBRANTI_FAIL,
            payload: error
        })
        yield put(
            showPopup(
              "error",
              "OPERAZIONE FALLITA",
              error.response.data.data.error
            )
          )
    }
}

export const INSERT_INGOMBRANTI = "insertIngombranti"
export const INSERT_INGOMBRANTI_SUCCESS = "insertIngombranti_success"
export const INSERT_INGOMBRANTI_FAILED = "insertIngombranti_failed"

export function insertIngombranti(utente, utenza, tipologiaSpazzatura, disponibilita, note) {
    return {
        type: INSERT_INGOMBRANTI,
        payload: {
            utenteId:utente, 
            utenzaId:utenza, 
            tipologiaSpazzatura:tipologiaSpazzatura, 
            disponibilita:disponibilita, 
            note:note, 
            statoRichiesta:"INVIATA", 
        }
    }
}

export function* insertIngombrantiSaga(action) {
    try {
        const request = {
            url: "/api/v1/richiesta-ingombranti/insert", // crea un mastello e lo assegna all'utenza
            method: "POST",
            data: {
                utenteId:action.payload.utenteId, 
                utenzaId:action.payload.utenzaId, 
                tipologiaSpazzatura:action.payload.tipologiaSpazzatura, 
                disponibilita:action.payload.disponibilita, 
                note:action.payload.note, 
                statoRichiesta:action.payload.statoRichiesta, 
            }
        }

        const response = yield CommonService.execute(request)
        yield put({
            type: GET_RICHIESTE_INGOMBRANTI
        })
        yield put(
            showPopup(
                "notify",
                "OPERAZIONE RIUSCITA",
                response.data.message
            )
        )

    } catch (error) {
        yield put({
            type: INSERT_INGOMBRANTI_FAILED,
            payload: error.response.data.data.error
        })
        yield put(
            showPopup(
                "error",
                "OPERAZIONE FALLITA",
                error.response.data.message
            )
        )
    }
}

export const UPDATE_INGOMBRANTI = "updateIngombranti"
export const UPDATE_INGOMBRANTI_SUCCESS = "updateIngombranti_success"
export const UPDATE_INGOMBRANTI_FAILED = "updateIngombranti_failed"

export function updateIngombranti(id, utente, utenza, tipologiaSpazzatura, disponibilita, note, stato) {
    return {
        type: UPDATE_INGOMBRANTI,
        payload: {
            id:id,
            utenteId:utente, 
            utenzaId:utenza, 
            tipologiaSpazzatura:tipologiaSpazzatura, 
            disponibilita:disponibilita, 
            note:note, 
            statoRichiesta:stato, 
        }
    }
}

export function* updateIngombrantiSaga(action) {
    try {
        const request = {
            url: "/api/v1/richiesta-ingombranti/update",
            method: "PUT",
            data: {
                id:action.payload.id,
                utenteId:action.payload.utenteId, 
                utenzaId:action.payload.utenzaId, 
                tipologiaSpazzatura:action.payload.tipologiaSpazzatura, 
                disponibilita:action.payload.disponibilita, 
                note:action.payload.note, 
                statoRichiesta:action.payload.statoRichiesta, 
            }
        }

        const response = yield CommonService.execute(request)
        yield put({
            type: GET_RICHIESTE_INGOMBRANTI
        })
        yield put(
            showPopup(
                "notify",
                "OPERAZIONE RIUSCITA",
                response.data.message
            )
        )

    } catch (error) {
        yield put({
            type: UPDATE_INGOMBRANTI_FAILED,
            payload: error.response.data.data.error
        })
        yield put(
            showPopup(
                "error",
                "OPERAZIONE FALLITA",
                error.response.data.message
            )
        )
    }
}

export const DELETE_INGOMBRANTI = "deleteIngombranti"
export const DELETE_INGOMBRANTI_SUCCESS = "delete_ingombranti_success"
export const DELETE_INGOMBRANTI_FAILED = "delete_ingombranti_fail"

export function deleteIngombranti(dispositivoId) {
    return {
        type: DELETE_INGOMBRANTI,
        payload: dispositivoId
    }
}

export function* deleteIngombrantiSaga(action) {
    try {
        const url = "/api/v1/richiesta-ingombranti/delete/" + action.payload // Elimina un Ingombranti e riporta a false lo stato del QrCode associato
        const request = {
            method: "DELETE",
            url: url,
        }

        const response = yield CommonService.execute(request)
        yield put({
            type: GET_RICHIESTE_INGOMBRANTI
        })
        yield put(
            showPopup(
                "notify",
                "OPERAZIONE RIUSCITA",
                response.data.message
            )
        )  

    } catch (error) {
        yield put({
            type: DELETE_INGOMBRANTI_FAILED,
            payload: error.response.data.data.error
        })
        yield put(
            showPopup(
                "error",
                "OPERAZIONE FALLITA",
                error.response.data.data.error
            )
        )
    }
}