import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Spinner, Row,
  Col,
  Icon,
  UncontrolledTooltip, } from "design-react-kit";
import CustomTable from "../../table/component/CustomTable";
import _ from "lodash";
import { showPopup } from "../../popup/actions/popup_actions";
import EntiModal from "./ComuneModal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faRecycle, faTrashAlt,faRefresh, faBuilding } from "@fortawesome/free-solid-svg-icons";
import { getCollaborazioni } from "../../collaborazioni/actions/collaborazioni_actions";
import { deleteComune, getComune, insertComune, updateComune } from "../actions/comune_actions";
import { showComuneModal } from "../actions/comuneModal_actions";
import ComuneModal from "./ComuneModal";


const Comune = () => {

  const dispatch = useDispatch();

  const delComune = (arg) => {
    dispatch(deleteComune(arg));
  };

  const showActionPopUp = (type, header, message, f, arg) => {
    dispatch(showPopup(type, header, message, f, arg));
  };

  const showActionModal = (type, header, f, obj) => {
    dispatch(showComuneModal(type, header, f, obj));
  };


  const loading = useSelector((state) => state.comune.loading);
  const [dati, setDati] = useState([]);

  const comune = useSelector((state) => state.comune.comune)

  const [tipoEnteLista, setTipoEnteLista] = useState();

  const refreshList = () => {
    dispatch(getComune());
  };

  useEffect(() => {
    if (comune === undefined) {
      dispatch(getComune());
    } else {

      const tmp = [];
      _.forEach(comune, (ts) => {
        ts.actions = (
          <section>
            <span className="rounded-icon iconbottom"
              onClick={() => {
                showActionModal("edit", "Modifica Comune", updateComune, ts);
              }}
            >
            <FontAwesomeIcon icon={faEdit} />
            </span>
            <span className="rounded-icon iconbottomcancel"
             onClick={() =>
              showActionPopUp(
                "confirm",
                "ATTENZIONE",
                `Comune ${ts.denominazione} verra' cancellato. Procedere?`,
                delComune,
                ts.ente.id
              )
            }
            >
            <FontAwesomeIcon icon={faTrashAlt} />
            </span>
          </section>
        );
        tmp.push(ts);
      });
      setDati(tmp);
    }
  }, [comune, dispatch]);

  const headers = [
    {
      label: "Comune",
      field: "denominazione"
    },
    {
      label: "Telefono",
      field: "telefono",
    },
    {
      label: "Email",
      field: "email",
    },
    {
      label: "Pec",
      field: "emailPec",
    },
    {
      label: "Azioni",
      field: "actions",
    },
  ];



  return (
    <React.Fragment>
      <ComuneModal></ComuneModal>
      {loading ? (
        <div className="customTableContainer">
          <div className="container_spinner"><Spinner active  className="spinnerwebe"/></div>
        </div>
      ) : (
        <div className="customTableContainer">
          <Row className="justify-content-md-center">
            <Col lg="9" xl="9" md="9" sm="9" xs="12">
              <h1 className="">Comuni <FontAwesomeIcon icon={faRefresh} className="padding-lr-icon linkeffect" onClick={()=> refreshList()}/></h1>
            </Col>
            <Col lg="3" xl="3" md="3" sm="3" xs="12" className="text-right">
              <Button
                color="primary"
                onClick={() =>
                  showActionModal(
                    "insert",
                    "Inserimento nuovo Comune",
                    insertComune
                  )
                }
                icon
                id="addNewEnte"
                style={{ marginBottom: "8px" }}
              >
                <FontAwesomeIcon icon={faBuilding} className="padding-lr-icon"/> {' '}Aggiungi
              </Button>
              <UncontrolledTooltip placement="top" target="addNewEnte">
                Aggiungi un nuovo Ente
              </UncontrolledTooltip>
            </Col>
          </Row>
          <CustomTable headers={headers} data={dati} />
        </div>
      )}
    </React.Fragment>
  );
};

export default Comune;
