import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Spinner,
  Row,
  Col,
  Icon,
  UncontrolledTooltip,
} from "design-react-kit";
import { getQrCodes, insertQrCode, updateQrCode, deleteQrCode } from "../actions/qrCode_actions";
import CustomTable from "../../table/component/CustomTable";
import { showPopup } from "../../popup/actions/popup_actions";
import _ from "lodash";
import { showQrCodeModal } from "../../qrCode/actions/qrCodeModal_actions";
import QrCodeModal from "./QrCodeModal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQrcode, faRefresh, faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { getUserInfo } from "../../dashboard/dashboard_Action";

const QrCode = () => {

  const dispatch = useDispatch();

  const qrCodes = useSelector((state) => state.qrCode.qrCodes);
  const loading = useSelector((state) => state.qrCode.loading);
  const [dati, setDati] = useState([]);

  const delQrCode = (arg) => {
    dispatch(deleteQrCode(arg));
  };

  const userInfo = useSelector(
    (state) => state.userInfo.userInfo
  );

  const showActionPopUp = (type, header, message, f, arg) => {
    dispatch(showPopup(type, header, message, f, arg));
  };

  const showActionModal = (type, header, f, obj) => {
    dispatch(showQrCodeModal(type, header, f, obj));
  };

  const statoQrCode = (qrCode) => {
    if (qrCode.stato === true) {
      return "assegnato";
    } else {
      return "libero";
    }
  }; 

  useEffect(() => {
    if (userInfo === undefined) {
      dispatch(getUserInfo());
    }
  }, [userInfo]);

  useEffect(() => {
    if (qrCodes === undefined) {
      dispatch(getQrCodes());
    } else {
      const tmp = [];
      _.forEach(qrCodes, (q) => {
        q.statoQr = statoQrCode(q);
        q.actions = (
          <section>
            <span className="rounded-icon iconbottom"
               onClick={() => {
                showActionModal("edit", "Modifica QrCode", updateQrCode, q);
              }}
            >
            <FontAwesomeIcon icon={faEdit} />
            </span>
            {/* <Button
              color="primary"
              onClick={() => {
                showActionModal("edit", "Modifica Utenza", updateUtenza, u);
              }}
            >
              edit
            </Button>{" "} */}
            <span className="rounded-icon iconbottomcancel"
              onClick={() =>
                showActionPopUp(
                  "confirm",
                  "ATTENZIONE",
                  `Il QrCode ${q.id} verra' cancellato. Procedere?`,
                  delQrCode,
                  q.id
                )
              }
            >
            <FontAwesomeIcon icon={faTrashAlt} />
            </span>
            {/* <Button
              color="primary"
              onClick={() => {
                showActionModal("edit", "Modifica QrCode", updateQrCode, q);
              }}
            >
              edit
            </Button>{" "}
            <Button
              color="primary"
              onClick={() =>
                showActionPopUp(
                  "confirm",
                  "ATTENZIONE",
                  `Il QrCode ${q.id} verra' cancellato. Procedere?`,
                  delQrCode,
                  q.id
                )
              }
            >
              delete
            </Button> */}
          </section>
        );
        tmp.push(q);
      });
      setDati(tmp);
    }
  }, [dispatch, qrCodes]);

  const headers = [
    {
      label: "Valore",
      field: "valore",
    },
    {
      label: "Stato",
      field: "statoQr",
    },
    {
      label: "Azioni",
      field: "actions",
    },
  ];

  const refreshList = () => {
    dispatch(getQrCodes());
  };

  return (
    <React.Fragment>
      <QrCodeModal></QrCodeModal>
      {loading ? (
        <div className="customTableContainer">
         <div className="container_spinner"><Spinner active  className="spinnerwebe"/></div>
        </div>
      ) : (
        <div className="customTableContainer">
          <Row className="justify-content-md-center">
            <Col lg="9" xl="9" md="9" sm="9" xs="12">
              <h1 className="">QrCode <FontAwesomeIcon icon={faRefresh} className="padding-lr-icon linkeffect" onClick={()=> refreshList()}/></h1>
            </Col>
            <Col lg="3" xl="3" md="3" sm="3" xs="12" className="text-right">
              <Button
                color="primary"
                onClick={() =>
                  showActionModal(
                    "insert",
                    "Inserimento nuovo QrCode",
                    insertQrCode
                  )
                }
                icon
                id="addNewQrCode"
                style={{ marginBottom: "8px" }}
              >
                <FontAwesomeIcon icon={faQrcode} className="padding-lr-icon"/> {' '}Aggiungi
              </Button>
              <UncontrolledTooltip placement="top" target="addNewQrCode">
                Aggiungi un nuovo QrCode
              </UncontrolledTooltip>
            </Col>
          </Row>

          <CustomTable headers={headers} data={dati} />
        </div>
      )}
    </React.Fragment>
  );
};

export default QrCode;
