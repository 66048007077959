export const SHOW_USER_MODAL = "showUserModal"
export const HIDE_USER_MODAL = "hideUserModal"

export function showUserModal(type, header, f, obj){
  return {
      type: SHOW_USER_MODAL,
      payload:{
        type: type,
        header: header,
        function: f,
        obj: obj
    }
  }
}

export function hideUserModal(){
  return {
      type: HIDE_USER_MODAL
  }
}