import CommonService from "../../../service/common_service"
import { put } from "redux-saga/effects"
import { showPopup } from "../../popup/actions/popup_actions"

export const GET_ALL_UTENTI = "getAllUtenti"
export const GET_ALL_UTENTI_SUCCESS = "get_allUtenti_success"
export const GET_ALL_UTENTI_FAIL = "get_allUtenti_fail"

const headers = {
    Authorization: 'Bearer ' + localStorage.getItem("jwtToken")
}

export function getAllUtenti() {
    return {
        type: GET_ALL_UTENTI
    }
}

export function* getAllUtentiSaga() {
    try {

        const request = {
            method: "GET",
            url: "/api/v1/utente/all",
            headers: headers
        }

        const response = yield CommonService.execute(request)
        yield put({
            type: GET_ALL_UTENTI_SUCCESS,
            payload: response.data.data
        })

    } catch (error) {
        yield put({
            type: GET_ALL_UTENTI_FAIL,
            payload: error.response.data
        })
    }
}

export const GET_UTENTI_COMUNE_UTENZA = "getUtentiComuneUtenza"
export const GET_UTENTI_COMUNE_UTENZA_SUCCESS = "getUtentiComuneUtenza_success"
export const GET_UTENTI_COMUNE_UTENZA_FAIL = "getUtentiComuneUtenza_fail"

export function getUtentiComuneUtenza() {
    return {
        type: GET_UTENTI_COMUNE_UTENZA
    }
}

export function* getUtentiComuneUtenzaSaga() {
    try {

        const request = {
            method: "GET",
            url: "/api/v1/utente/cerca/comune-utenza",
            headers: headers
        }

        const response = yield CommonService.execute(request)
        yield put({
            type: GET_UTENTI_COMUNE_UTENZA_SUCCESS,
            payload: response.data.data
        })

    } catch (error) {
        yield put({
            type: GET_UTENTI_COMUNE_UTENZA_FAIL,
            payload: error.response.data
        })
        yield put(
            showPopup(
                "error",
                "OPERAZIONE FALLITA",
                error.response.data.data.error
            )
        )
    }
}

export const GET_UTENTE_BY_USERNAME = "getUtenteByUsername"
export const GET_UTENTE_BY_USERNAME_SUCCESS = "getUtenteByUsername_success"
export const GET_UTENTE_BY_USERNAME_FAIL = "getUtenteByUsername_fail"

export function getUtenteByUsername(username) {
    return {
        type: GET_UTENTE_BY_USERNAME,
        payload: username
    }
}

export function* getUtenteByUsernameSaga(action) {
    try {
        const url = "/api/v1/utente/cerca/username/" + action.payload
        const request = {
            method: "GET",
            url: url
        }

        const response = yield CommonService.execute(request)
        yield put({
            type: GET_UTENTE_BY_USERNAME_SUCCESS,
            payload: response.data.data
        })

    } catch (error) {
        yield put({
            type: GET_UTENTE_BY_USERNAME_FAIL,
            payload: error.response.data.data.error
        })
    }
}

export const GET_UTENTE_BY_USERNAME_CLEAN = "getUtenteByUsernameClean"

export function getUtenteByUsernameClean(){
    return {
        type: GET_UTENTE_BY_USERNAME_CLEAN 
    }
}

export const INSERT_UTENTE = "insertUtente"
export const INSERT_UTENTE_SUCCESS = "insertUtente_success"
export const INSERT_UTENTE_FAILED = "insertUtente_failed"

export function insertUtente(cognome, nome, telefono, email, tipoPersona, comuneResidenza, username, password, tipologiaLogin) {
    return {
        type: INSERT_UTENTE,
        payload: {
            cognome: cognome,
            nome: nome,
            telefono: telefono,
            email: email,
            tipoPersona: tipoPersona,
            comuneResidenza: comuneResidenza,
            username: username,
            password: password,
            tipologiaLogin: tipologiaLogin
        }
    }
}

export function* insertUtenteSaga(action) {
    try {
        const request = {
            url: "/api/v1/utente/insert",
            method: "POST",
            data: {
                cognome: action.payload.cognome,
                nome: action.payload.nome,
                telefono: action.payload.telefono,
                email: action.payload.email,
                tipoPersona: action.payload.tipoPersona,
                comuneResidenzaId: action.payload.comuneResidenza,
                login: {
                    username: action.payload.username,
                    password: action.payload.password,
                    tipologiaLogin: action.payload.tipologiaLogin
                }
            }
        }

        const response = yield CommonService.execute(request)
        yield put({
            type: INSERT_UTENTE_SUCCESS,
            payload: response.data.data
        })

    } catch (error) {
        yield put({
            type: INSERT_UTENTE_FAILED,
            payload: error.response.data.data.error
        })
        yield put(
            showPopup(
                "error",
                "OPERAZIONE FALLITA",
                error.response.data.message
            )
        )
    }
}

export const UPDATE_UTENTE = "updateUtente"
export const UPDATE_UTENTE_SUCCESS = "updateUtente_success"
export const UPDATE_UTENTE_FAILED = "updateUtente_failed"

export function updateUtente(utenteId, cognome, nome, telefono, email, tipoPersona, comuneResidenza, username, password, tipologiaLogin) {
    return {
        type: UPDATE_UTENTE,
        payload: {
            utenteId: utenteId,
            cognome: cognome,
            nome: nome,
            telefono: telefono,
            email: email,
            tipoPersona: tipoPersona,
            comuneResidenza: comuneResidenza,
            username: username,
            password: password,
            tipologiaLogin: tipologiaLogin
        }
    }
}

export function* updateUtenteSaga(action) {
    try {
        console.log(action)
        const request = {
            url: "/api/v1/utente/update",
            method: "PUT",
            data: {
                id: action.payload.utenteId,
                cognome: action.payload.cognome,
                nome: action.payload.nome,
                telefono: action.payload.telefono,
                email: action.payload.email,
                tipoPersona: action.payload.tipoPersona,
                comuneResidenzaId: action.payload.comuneResidenza,
                login: {
                    username: action.payload.username,
                    password: action.payload.password,
                    tipologiaLogin: action.payload.tipologiaLogin
                }
            }
        }

        const response = yield CommonService.execute(request)
        yield put({
            type: GET_UTENTI_COMUNE_UTENZA
        })
        yield put({
            type: GET_ALL_UTENTI
        })
        yield put(
            showPopup(
                "notify",
                "OPERAZIONE RIUSCITA",
                response.data.message
            )
        )

    } catch (error) {
        yield put({
            type: UPDATE_UTENTE_FAILED,
            payload: error.response.data.data.error
        })
        yield put(
            showPopup(
                "error",
                "OPERAZIONE FALLITA",
                error.response.data.message
            )
        )
    }
    
}

export const DELETE_UTENTE = "deleteUtente"
export const DELETE_UTENTE_SUCCESS = "deleteUtente_success"
export const DELETE_UTENTE_FAILED = "deleteUtente_fail"

export function deleteUtente(utenteId) {
    return {
        type: DELETE_UTENTE,
        payload: utenteId
    }
}

export function* deleteUtenteSaga(action) {
    try {
        const url = "/api/v1/utente/delete/" + action.payload
        const request = {
            method: "DELETE",
            url: url,
        }

        const response = yield CommonService.execute(request)
        yield put({
            type: GET_UTENTI_COMUNE_UTENZA
        })
        yield put({
            type: GET_ALL_UTENTI
        })
        yield put(
            showPopup(
                "notify",
                "OPERAZIONE RIUSCITA",
                response.data.message
            )
        )  

    } catch (error) {
        yield put({
            type: DELETE_UTENTE_FAILED,
            payload: error.response.data.data.error
        })
        yield put(
            showPopup(
                "error",
                "OPERAZIONE FALLITA",
                error.response.data.data.error
            )
        )
    }
}



