import CommonService from "../../../service/common_service";
import { put } from "redux-saga/effects"

export const GET_TIPI_IMMOBILI = "getTipiImmobili"
export const GET_TIPI_IMMOBILI_SUCCESS = "get_tipiImmbili_success"
export const GET_TIPI_IMMOBILI_FAIL = "get_tipiImmbili__fail"

const headers = {
    Authorization: 'Bearer ' + localStorage.getItem("jwtToken")
}

export function getTipiImmobili() {
  return {
      type: GET_TIPI_IMMOBILI
  }
}

export function* getTipiImmobiliSaga() {
  try {

      const request = {
          method: "GET",
          url: "/api/v1/tipologia-immobile/all",
          headers: headers
      }

      const response = yield CommonService.execute(request)
      yield put({
          type: GET_TIPI_IMMOBILI_SUCCESS,
          payload: response.data.data
      })

  } catch (error) {
      yield put({
          type: GET_TIPI_IMMOBILI_FAIL,
          payload: error
      })
  }
}