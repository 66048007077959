
import {
  GET_ENTE_COMUNE, GET_ENTE_COMUNE_SUCCESS, GET_ENTE_COMUNE_FAIL, INSERT_ENTE, INSERT_ENTE_SUCCESS, INSERT_ENTE_FAILED,
  UPDATE_ENTE, UPDATE_ENTE_SUCCESS, UPDATE_ENTE_FAILED,
  DELETE_ENTE, DELETE_ENTE_SUCCESS, DELETE_ENTE_FAILED
} from "../actions/enti_actions";

import _ from "lodash"

export function EnteReducer(state = { loading: false, error: undefined, entecomune: undefined }, action) {

  if (_.isEmpty(action) || _.isEmpty(action.type)) return state

  const newState = state

  switch (action.type) {

    case GET_ENTE_COMUNE:
      newState.loading = true
      newState.error = undefined
      newState.entecomune = undefined
      break;
    case GET_ENTE_COMUNE_SUCCESS:
      newState.loading = false
      newState.error = undefined
      newState.entecomune = action.payload
      break;
    case GET_ENTE_COMUNE_FAIL:
      newState.loading = false
      newState.error = action.payload
      newState.entecomune = undefined
      break;
    case INSERT_ENTE:
      break;
    case INSERT_ENTE_SUCCESS:
      break;
    case INSERT_ENTE_FAILED:
      break;
    case UPDATE_ENTE:
      break;
    case UPDATE_ENTE_SUCCESS:
      break;
    case UPDATE_ENTE_FAILED:
      break;
    case DELETE_ENTE:
      break;
    case DELETE_ENTE_SUCCESS:
      break;
    case DELETE_ENTE_FAILED:
      break;
    default:
      break;
  }

  return Object.assign({}, state, newState)
}
