import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Spinner,
  Row,
  Col,
  Icon,
  UncontrolledTooltip,
  Table
} from "design-react-kit";
import {
  getRaccolta,
  insertRaccolta,
  updateRaccolta,
  deleteRaccolta,
  stampaRaccolta
} from "../actions/raccolta_actions";
import CustomTable from "../../table/component/CustomTable";
import _ from "lodash";
import { showPopup } from "../../popup/actions/popup_actions";
import { showRaccoltaModal } from "../actions/raccoltaModal_actions";
import RaccoltaModal from "./RaccoltaModal";
import { getUtenze } from "../../utenze/actions/utenze_actions";
import { getTipologieSpazzatura } from "../../tipologiaSpazzatura/actions/tipologiaSpazzatura_actions";
import { getQrCodes, getQrCodesValore } from "../../qrCode/actions/qrCode_actions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArchive, faRecycle, faRefresh } from '@fortawesome/free-solid-svg-icons'
import { getUserInfo } from "../../dashboard/dashboard_Action";
import { showSearchPopup } from "../../search_popup/actions/search_popup_actions";
import SearchPopup from "../../search_popup/components/SearchPopup";


const Raccolta = () => {

  const dispatch = useDispatch();

  const userInfo = useSelector(
    (state) => state.userInfo.userInfo
  );

  const raccolta = useSelector(
    (state) => state.raccolta.raccolta
  );

  const mastelli = useSelector((state) => state.mastello.mastelli);
  const loading = useSelector((state) => state.pesata.loading);
  const [dati, setDati] = useState([]);

  const utenze = useSelector((state) => state.utenze.utenze);
  const tipologieSpazzatura = useSelector((state) => state.tipologiaSpazzatura.tipologieSpazzatura);
  const qrCodes = useSelector((state) => state.qrCode.qrCodes);

  const qrCodeToSearch = useSelector((state) => state.qrCode.qrCodesRicerca);

  const delRaccolta = (arg) => {
    console.log("delete button")
    dispatch(deleteRaccolta(arg));
  };

  const showActionPopUp = (type, header, message, f, arg) => {
    dispatch(showPopup(type, header, message, f, arg));
  };

  const showActionModal = (type, header, f, obj) => {
    dispatch(showRaccoltaModal(type, header, f, obj));
  };


  const infoRaccolta = (u) => {
    return (
      <Table size="sm">
        <tbody>
          <tr>
            <th scope="row">Data</th>
            <td>{u.data}</td>
          </tr>
          <tr>
            <th scope="row">Tipo Mastello</th>
            <td>{u.mastello.tipologiaSpazzatura.descrizione}</td>
          </tr>
          <tr>
            <th scope="row">Quantità</th>
            <td>{u.quantita}</td>
          </tr>
          <tr>
            <th scope="row">Tariffa</th>
            {u.scontistica != null &&
             <td>{u.scontistica.tariffa * u.quantita}</td>
            }
            {u.scontistica == null &&
             <td>Non Definita</td>
            } 
          </tr>
          <tr>
            <th scope="row">Tariffa Ridotta</th>
            {u.scontistica != null &&
             <td>{u.scontistica.sconto * u.quantita}</td>
            }
            {u.scontistica == null &&
             <td>Non Definita</td>
            } 
          </tr>
          <tr>
            <th scope="row">Intestatario</th>
            <td>{u.proprietario}</td>
          </tr>
          <tr>
            <th scope="row">Indirizzo</th>
            <td>{u.mastello.utenza.indirizzo}</td>
          </tr>
          <tr>
            <th scope="row">Comune</th>
            <td>{u.comune}</td>
          </tr>
        </tbody>
      </Table>
    );
  };
  

  useEffect(() => {
    if (raccolta === undefined) {
      dispatch(getRaccolta());
    } else {
      //dispatch(getUserInfo());
      const tmp = [];
      _.forEach(raccolta.raccolteComplete, (m) => {
        m.proprietario = m.mastello.utenza.intestatario.cognome + " " + m.mastello.utenza.intestatario.nome;
        m.comune = m.mastello.utenza.comune.denominazione;
        if(userInfo != null){
          m.actions = (
            <section>
              <span className="rounded-icon iconbottom"
              onClick={() =>
                dispatch(showPopup(
                  "notify",
                  "Informazioni Raccolta",
                  infoRaccolta(m)
                ))
              }
            >
            <FontAwesomeIcon icon={faRecycle} />
            </span>
            </section>
          );
        }else{
          m.actions =(
            <span className="rounded-icon iconbottom"></span>
          )
        }
        
        tmp.push(m);
      });
      setDati(tmp);
    }
  }, [dispatch, raccolta]);

  useEffect(() => {
    if (utenze === undefined) {
      dispatch(getUtenze());
    }
  }, [utenze]);

  useEffect(() => {
    if (userInfo === undefined) {
      dispatch(getUserInfo());
    }
  }, [userInfo]);

  useEffect(() => {
    if (tipologieSpazzatura === undefined) {
      dispatch(getTipologieSpazzatura());
    }
  }, [tipologieSpazzatura]);


  const refreshList = () => {
    dispatch(getRaccolta());
    dispatch(getUtenze());
    dispatch(getTipologieSpazzatura());
  };

  useEffect(() => {
    if(userInfo != null && (userInfo.tipologiaUtente === "COMUNE" || userInfo.tipologiaUtente === "ADMIN" || userInfo.tipologiaUtente === "ENTE" || userInfo.tipologiaUtente === "OPERATORE" )){
      if (qrCodes === undefined) {
        dispatch(getQrCodes());
      }
    }
    
  },[qrCodes]);

  useEffect(() => {
    if (qrCodeToSearch !== undefined) {
      //setUserNameUser(errorUtenteToSearch)
      dispatch(showRaccoltaModal(
          "insert",
          "QrCode trovato",
          insertRaccolta,
          qrCodeToSearch,
          "INSERISCI",
          "Inserire nuova Raccolta?",
      ))
    }
  },[qrCodeToSearch]);

  const headers = [
    {
      label: "Data",
      field: "dataString",
    },
    {
      label: "Intestario",
      field: "proprietario",
    },
    {
      label: "Indirizzo",
      field: "mastello.utenza.indirizzo",
    },
    {
      label: "Comune",
      field: "comune"
    },
    {
      label: "Tipologia",
      field: "mastello.tipologiaSpazzatura.descrizione",
    },
    {
      label: "Quantità",
      field: "quantita",
    },
    {
      label: "Azioni",
      field: "actions",
    },
  ];

  return (
    <React.Fragment>
      <SearchPopup />
      <RaccoltaModal
        utenzeOptions={utenze}
        tipologieSpazzaturaOptions={tipologieSpazzatura}
        qrCodeOptions={qrCodes}
      />
      {loading ? (
        <div className="customTableContainer">
          <div className="container_spinner"><Spinner active  className="spinnerwebe"/></div>
        </div>
      ) : (
        <div className="customTableContainer">
          <Row className="justify-content-md-center">
            <Col lg="9" xl="9" md="9" sm="9" xs="12">
              <h1 className="">Pesate <FontAwesomeIcon icon={faRefresh} className="padding-lr-icon linkeffect" onClick={()=> refreshList()}/></h1>
            </Col>
            <Col lg="3" xl="3" md="3" sm="3" xs="12" className="text-right">
             
            {
              userInfo != null && userInfo.tipologiaUtente != "UTENTE" &&
              <>
              
              <Button
                color="primary"
                onClick={() =>
                  dispatch(showSearchPopup(
                    "Ricerca Mastello",
                    "QrCode",
                    getQrCodesValore
                  ))
                }
                icon
                id="addNewRaccolta"
                style={{ marginBottom: "8px" }}
              >
                <FontAwesomeIcon icon={faArchive} className="padding-lr-icon"/> {' '}Aggiungi
              </Button>
              <UncontrolledTooltip placement="top" target="addNewRaccolta">
              Aggiungi un nuovo mastello
            </UncontrolledTooltip>
            </>
            }  
              
              
            </Col>
          </Row>
          <CustomTable headers={headers} data={dati} />
        </div>
      )}
    </React.Fragment>
  );
};

export default Raccolta;
