import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
  ModalFooter,
  Row,
  Col,
  Collapse,
  Card,
  CardBody,
  Select
} from "design-react-kit";
import { hideUtenteModal } from "../actions/utenteModal_actions";
import { getUtenteByUsernameClean } from "../actions/utente_actions";
import { getUserInfo} from "../../dashboard/dashboard_Action";

const UtenteModal = (props) => {
  const dispatch = useDispatch();

  const [cognome, setCognome] = useState();
  const [nome, setNome] = useState();
  const [telefono, setTelefono] = useState();
  const [email, setEmail] = useState();
  const [tipoPersona, setTipoPersona] = useState();
  const [comuneResidenza, setcomuneResidenza] = useState();
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [tipologiaLogin, setTipologiaLogin] = useState();

  const utenteToEdit = useSelector((state) => state.utenteModal.obj);

  const type = useSelector((state) => state.utenteModal.type);
  const header = useSelector((state) => state.utenteModal.header);
  const body = useSelector((state) => state.utenteModal.body);
  const buttonMessage = useSelector((state) => state.utenteModal.buttonMessage);
  const show = useSelector((state) => state.utenteModal.show);
  const f = useSelector((state) => state.utenteModal.function);

  const usernameuser = useSelector((state) => state.utenteModal.obj);

  const [tipoPersonaSelect, setTipoPersonaSelect] = useState()
  const [listaPersonaSelect, setListaPersonaSelect] = useState()

  const [collapse, setCollapse] = useState(false);

  let listaErroriTmp = [];
  const [listaErrori, setListaErrori] = useState([]);

  const userInfo = useSelector(
    (state) => state.userInfo.userInfo
  );

  useEffect(() => {
    if (userInfo === undefined) {
      dispatch(getUserInfo());
    }
  })

  function close() {
    dispatch(hideUtenteModal());
    dispatch(getUtenteByUsernameClean());
    setCollapse(false);
  }

  function erroriForm(listaErroriInfo){
    return listaErroriInfo.map(function (errore) {
      return(
        <p className="text-mess-alert">{errore}</p>
      )})  
  }
  function isValidEmailAddress(address) {
    if(address == null || address === '') return false;
    return !! address.match(/.+@.+/);
  }

  

  function confirm() {
    let comuneRegistrazione = userInfo.id

    listaErroriTmp = []
    let telefonoIsNum = /^\d+$/.test(telefono);
    if(type === "insert"){
      if((username != null || username != '') || (usernameuser != null || usernameuser != '')){
        let user = username;
        if(user == null || user === '') user = usernameuser
        let codiceFiscale = /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/.test(user);
        let partitaIva = /^[0-9]{11}$/.test(user);
        if(!codiceFiscale && !partitaIva) listaErroriTmp.push("- Inserisci una Partita Iva o Codice Fiscale valido")
      }
    }
    let emailValid = isValidEmailAddress(email);

    let nomeTesto =  /^\d+$/.test(nome);
    let cognomeTesto =  /^\d+$/.test(cognome);

    if(!telefonoIsNum) listaErroriTmp.push("- Telefono deve essere un numero valido")
    if(!emailValid) listaErroriTmp.push("- Email non è nel formato corretto")
    if(telefonoIsNum && telefono.length < 6) listaErroriTmp.push("- Telefono deve essere almeno di 6 caratteri")
    if(cognome == null || cognome === '') listaErroriTmp.push("- Cognome è un campo obbligatorio")
    if(cognome != null && cognome.length < 4) listaErroriTmp.push("- Cognome deve essere almeno di 2 caratteri")
    if(nome == null || nome === '') listaErroriTmp.push("- Nome è un campo obbligatorio")
    if(nome != null && nome.length < 4) listaErroriTmp.push("- Nome deve essere almeno di 2 caratteri")

    if(nomeTesto) listaErroriTmp.push("- Nome deve essere valido")
    if(cognomeTesto) listaErroriTmp.push("- Cognome deve essere valido")


    //if(username == null || username === '') listaErroriTmp.push("- Username è un campo obbligatorio")
    //if(username != null && (username.length != 11 && username.length != 16 )) listaErroriTmp.push("- Username deve essere di 11 (PIva) o 16 (CodFisc) caratteri")
    //if(password == null || password === '') listaErroriTmp.push("- Password è un campo obbligatorio")
    //if(password != null && password.length < 8) listaErroriTmp.push("- Password deve essere almeno di 8 caratteri")

    //if(tipoPersona == null || tipoPersona === '') listaErroriTmp.push("- Tipologia Persona è un campo obbligatorio")
    if(tipoPersonaSelect == null || tipoPersonaSelect.value === '') listaErroriTmp.push("- Tipologia Persona è un campo obbligatorio")
    //if(password == null || password === '') listaErroriTmp.push("- Password è un campo obbligatorio")

    if(listaErroriTmp.length == 0){
      switch (type) {
        case "insert":
          let usernamenew = username
          if(usernamenew === undefined) usernamenew = usernameuser;
            dispatch(
              f(cognome, nome, telefono, email, tipoPersonaSelect.value, comuneRegistrazione, usernamenew, password, tipologiaLogin)
            );
          close();
          break;
        case "edit":
          //console.log("EDIT", utenteToEdit.id, cognome, nome, telefono, email, tipoPersona, comuneResidenza, username, password, tipologiaLogin );
            dispatch(f(utenteToEdit.id, cognome, nome, telefono, email, tipoPersona, comuneRegistrazione, username, password, tipologiaLogin));
          close();
          break;
        default:
          close();
      }
      setCollapse(!collapse);
      close();
    }else{
      setListaErrori(listaErroriTmp)
    }
  }

  useEffect(() => {
    if (type !== "edit" && utenteToEdit === undefined) {
      setCognome(undefined);
      setNome(undefined);
      setTelefono(undefined);
      setEmail(undefined);
      setTipoPersona(undefined);
      setcomuneResidenza(undefined);
      setUsername(undefined);
      setPassword(undefined);
      setTipologiaLogin("utente");
      const tmp = [
        // {value: "INVIATA", label: "INVIATA"},
        {value: "FISICA", label: "FISICA"},
        {value: "GIURIDICA", label: "GIURIDICA"},
      ]
  
      setListaPersonaSelect(tmp)

    } else {
      setCognome(utenteToEdit.cognome);
      setNome(utenteToEdit.nome);
      setTelefono(utenteToEdit.telefono);
      setEmail(utenteToEdit.email);
      setTipoPersona(utenteToEdit.tipoPersona);
      setcomuneResidenza(utenteToEdit.comuneResidenzaId);
      setUsername(utenteToEdit.username);
      setPassword(utenteToEdit.password);
      setTipologiaLogin("utente");

      const tmp = [
        // {value: "INVIATA", label: "INVIATA"},
        {value: "FISICA", label: "FISICA"},
        {value: "GIURIDICA", label: "GIURIDICA"},
      ]
  
      setListaPersonaSelect(tmp)
    }
  }, [type, utenteToEdit]);

  const checkAriaExpanded = () => {
    return (
      <>
        <ModalBody>
          <p className='mb-0'>{body}</p>
        </ModalBody>
        <ModalFooter>
          <span>
            <Button outline color="primary" onClick={() => close()}>
              ANNULLA
            </Button>
          </span>
          <span>
            <Button outline color='primary' onClick={() => setCollapse(!collapse)} aria-expanded={collapse}>
              {buttonMessage}
            </Button>
          </span>
        </ModalFooter>
      </>
    );
  };


  const defaultSetListaTipoPersona = () => {
    switch (type) {
      case "insert":
        break;
      case "edit":
        return {
          label: utenteToEdit.tipoPersona,
          value: utenteToEdit.tipoPersona
        };
      default:
        return {
          label: undefined,
          value: undefined,
        };
    }
  };

  const utenteForm = () => {
    return (
      <>
        <ModalBody>
          <FormGroup>
            <div className="bootstrap-select-wrapper">
              <label htmlFor="inputUsername">Codice Fiscale/Partita Iva</label>
              <Input
                id="username"
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
                value={username}
                defaultValue={usernameuser}
                type="text"
                placeholder="Inserisci Codice Fiscale"
                aria-label="Inserisci Codice Fiscale"
                className="textupper"
              />
            </div>
          </FormGroup>
          <FormGroup>
            <div className="bootstrap-select-wrapper">
              <label htmlFor="inputCognome">Cognome</label>
              <Input
                id="cognome"
                onChange={(e) => {
                  setCognome(e.target.value);
                }}
                value={cognome}
                type="text"
                placeholder="Inserisci cognome"
                aria-label="Inserisci cognome"
              />
            </div>
          </FormGroup>
          <FormGroup>
            <div className="bootstrap-select-wrapper">
              <label htmlFor="inputNome">Nome</label>
              <Input
                id="nome"
                onChange={(e) => {
                  setNome(e.target.value);
                }}
                value={nome}
                type="text"
                placeholder="Inserisci nome"
                aria-label="Inserisci nome"
              />
            </div>
          </FormGroup>
          <FormGroup>
            <div className="bootstrap-select-wrapper">
              <label htmlFor="inputTelefono">Telefono</label>
              <Input
                id="telefono"
                onChange={(e) => {
                  setTelefono(e.target.value);
                }}
                value={telefono}
                type="text"
                placeholder="Inserisci telefono"
                aria-label="Inserisci telefono"
              />
            </div>
          </FormGroup>
          <FormGroup>
            <div className="bootstrap-select-wrapper">
              <label htmlFor="inputEmail">Email</label>
              <Input
                id="email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                value={email}
                type="text"
                placeholder="Inserisci email"
                aria-label="Inserisci email"
              />
            </div>
          </FormGroup>
          <FormGroup>
            <div className="bootstrap-select-wrapper">
              <label htmlFor="inputTipoPersona">Tipologia Persona</label>
              {/* <Input
                id="tipoPersona"
                onChange={(e) => {
                  setTipoPersona(e.target.value);
                }}
                value={tipoPersona}
                type="text"
                placeholder="Inserisci tipologia persona"
                aria-label="Inserisci tipologia persona"
              /> */}
              <Select
                  id="statoRichiesta"
                  onChange={(selectedOption) => {
                    setTipoPersonaSelect(selectedOption);
                  }}
                  options={listaPersonaSelect}
                  defaultValue={defaultSetListaTipoPersona()}
                  placeholder="Seleziona Stato Richiesta"
                  aria-label="Seleziona Stato Richiesta"
                />
            </div>
          </FormGroup>
          {/* <FormGroup>
            <div className="bootstrap-select-wrapper">
              <label htmlFor="inputComuneResidenza">Comune Residenza</label>
              <Input
                id="comuneResidenza"
                onChange={(e) => {
                  setcomuneResidenza(e.target.value);
                }}
                value={comuneResidenza}
                type="text"
                placeholder="Inserisci comune residenza"
                aria-label="Inserisci comune residenza"
              />
            </div>
          </FormGroup> */}
          {listaErrori.length>0 &&
            <Row>
                <Col>
                  {erroriForm(listaErrori)}
                </Col>
            </Row>
          }
        </ModalBody>
        <ModalFooter>
          <span>
            <Button outline color="primary" onClick={() => close()}>
              ANNULLA
            </Button>
          </span>
          <span>
            <Button outline color="primary" onClick={() => confirm()}>
              CONFERMA
            </Button>
          </span>
        </ModalFooter>
      </>
    );
  };

  const utenteModal = () => {
    return (
      <div>
        <Modal isOpen={show} labelledBy="utenteModal">
          <ModalHeader id="utenteModal">{header}</ModalHeader>
            {checkAriaExpanded()}
          <Collapse isOpen={collapse}>
            <Card>
              <CardBody>{utenteForm()}</CardBody>
            </Card>
          </Collapse>
        </Modal>
      </div>
    );
  };

  return utenteModal();

};

export default UtenteModal;
