import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Spinner, Row, Col, Icon, UncontrolledTooltip, Table } from "design-react-kit";
import {
  getCollaborazioni,
  deleteCollaborazione,
  insertCollaborazione,
} from "../actions/collaborazioni_actions";
import { getCalendariByCollaborazione } from "../../calendario/actions/calendario_actions";
import { showCollaborazioneModal } from "../actions/collaborazioneModal_actions";
import CollaborazioneModal from "./CollaborazioneModal";
import CustomTable from "../../table/component/CustomTable";
import { showPopup } from "../../popup/actions/popup_actions";
import _ from "lodash";
import { getEnti } from "../../../../ente/actions/ente_actions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faRefresh, faTrashAlt, faAddressCard } from '@fortawesome/free-solid-svg-icons'

const Collaborazioni = () => {

  const dispatch = useDispatch();

  const collaborazioni = useSelector((state) => state.collaborazioni.collaborazioni);
  const loading = useSelector((state) => state.collaborazioni.loading);
  const enti = useSelector((state) => state.ente.enti);

  const [dati, setDati] = useState([]);

  const delCollaborazione = (arg) => {
    dispatch(deleteCollaborazione(arg));
  };

  const deleteCollaborazioneButton = (collaborazioneId) => {
    dispatch(getCalendariByCollaborazione(collaborazioneId, delCollaborazione))
  };

  const showActionPopUp = (type, header, message, f, arg) => {
    dispatch(showPopup(type, header, message, f, arg));
  };

  const showActionModal = (type, header, f, obj) => {
    dispatch(showCollaborazioneModal(type, header, f, obj));
  };

  const infoEnte = (e) => {
    return (
      <Table size="sm">
        <tbody>
          <tr>
            <th scope="row">Denominazione</th>
            <td>{e.denominazione}</td>
          </tr>
          <tr>
            <th scope="row">Tipo Ente</th>
            <td>{e.tipoEnte}</td>
          </tr>
          <tr>
            <th scope="row">Telefono</th>
            <td>{e.telefono}</td>
          </tr>
          <tr>
            <th scope="row">Email</th>
            <td>{e.email}</td>
          </tr>
        </tbody>
      </Table>
    );
  };

  useEffect(() => {
    if (enti === undefined) {
      dispatch(getEnti());
    }
  }, [enti]);

  useEffect(() => {
    if (collaborazioni === undefined) {
      dispatch(getCollaborazioni());
    }
  }, [collaborazioni]);

  useEffect(() => {
    if (collaborazioni === undefined) {
      dispatch(getCollaborazioni());
      //dispatch(getEnti());
    } else {
      dispatch(getEnti());
      const tmp = [];
      _.forEach(collaborazioni, (c) => {
        c.entecollaborazione = c.ente.denominazione + " - " + c.ente.tipoEnte;
        tmp.push(c);
        c.actions = (
          <section>
            <span className="rounded-icon iconbottom"
             onClick={() =>
              showActionPopUp(
                "notify",
                "Informazioni Ente",
                infoEnte(c.ente)
              )
            }
            >
            <FontAwesomeIcon icon={faAddressCard} />
            </span>
              {/* <Button
              color="primary"
              onClick={() =>
                showActionPopUp(
                  "notify",
                  "Informazioni Ente",
                  infoEnte(c.ente)
                )
              }
            >
              info
            </Button>{" "} */}
            <span className="rounded-icon iconbottomcancel"
              onClick={() => deleteCollaborazioneButton(c.id)}
            >
            <FontAwesomeIcon icon={faTrashAlt} />
            </span>
            {/* <Button
              color="primary"
              onClick={() => deleteCollaborazioneButton(c.id)}
            >
              delete
            </Button> */}
          </section>
        );
      });
      setDati(tmp);
    }
  }, [collaborazioni]);

 

  const headers = [
    {
      label: "Ente - Tipo",
      field: "entecollaborazione",
    },
    {
      label: "Azioni",
      field: "actions",
    },
  ];

  const refreshList = () => {
    dispatch(getCollaborazioni());
    dispatch(getEnti());
  };

  return (
    <React.Fragment>
      <CollaborazioneModal options={enti} />
      {loading ? (
         <div className="customTableContainer">
          <div className="container_spinner"><Spinner active  className="spinnerwebe"/></div>
        </div>
      ) : (
        <div className="customTableContainer">
          <Row className="justify-content-md-center">
          <Col lg="9" xl="9" md="9" sm="9" xs="12">
              <h1 className="">Collaborazioni <FontAwesomeIcon icon={faRefresh} className="padding-lr-icon linkeffect" onClick={()=> refreshList()}/></h1>
            </Col>
            <Col lg="3" xl="3" md="3" sm="3" xs="12" className="text-right">
              <Button
                color="primary"
                onClick={() =>
                  showActionModal(
                    "insert",
                    "Inserimento nuova Collaborazione",
                    insertCollaborazione
                  )
                }
                icon
                id="addNewCollaborazione"
                style={{ marginBottom: "8px" }}
              >
                <FontAwesomeIcon icon={faUser} className="padding-lr-icon"/> {' '}Aggiungi
              </Button>
              <UncontrolledTooltip placement="top" target="addNewCollaborazione">
                Aggiungi una nuova collaborazione
              </UncontrolledTooltip>
            </Col>
          </Row>
          <CustomTable headers={headers} data={dati} />
        </div>
      )}
    </React.Fragment>
  );
};

export default Collaborazioni;
