import { SHOW_SEARCH_POPUP, HIDE_SEARCH_POPUP } from "../actions/search_popup_actions";
import _ from "lodash";

export function searchPopupReducer(state = { show: false, header: undefined, label: undefined, function: undefined }, action) {
  if (_.isEmpty(action) || _.isEmpty(action.type)) return state
  const newState = state
  switch (action.type) {
    case SHOW_SEARCH_POPUP:
      newState.show = true
      newState.header = action.payload.header
      newState.label = action.payload.label
      newState.function = action.payload.function
      break;
    case HIDE_SEARCH_POPUP:
      newState.show = false
      newState.header = undefined
      newState.label = undefined
      newState.function = undefined
      break;
    default:
      break;
  }
  return Object.assign({}, state, newState)
}