import CommonService from "../../common/service/common_service"
import { put } from "redux-saga/effects"
import { showPopup } from "../../common/components/popup/actions/popup_actions";

export const RESET_PASSWORD_REQUEST = "reset_password_request"
export const RESET_PASSWORD_SUCCESS = "reset_password_success"
export const RESET_PASSWORD_FAILED = "reset_password_failed"

export function resetPassword(username ) {
    return {
        type: RESET_PASSWORD_REQUEST,
        payload: {
            username: username,
        }
    }

}

export function* resetPasswordSaga(action) {
    try {

        const request = {
            url: "/api/v1/reset-password",
            method: "POST",
            data: {
                username: action.payload.username,
            }
        }

        const response = yield CommonService.execute(request)

        yield put(
            showPopup(
                "redirect",
                "OPERAZIONE RIUSCITA",
                "Controlla la tua email per poter proseguire con il recupero password. Grazie"
            )
        )

    } catch (error) {
        yield put({
            type: RESET_PASSWORD_FAILED,
            payload: error.response.data.data.error
        })
    }
}


export const RECUPERA_PASSWORD_REQUEST = "recupera_password_request"
export const RECUPERA_PASSWORD_SUCCESS = "recupera_password_success"
export const RECUPERA_PASSWORD_FAILED = "recupera_password_failed"

export function recuperaPassword(password, confermapassword, accessToken ) {
    return {
        type: RECUPERA_PASSWORD_REQUEST,
        payload: {
            password: password,
            confermapassword: confermapassword,
            accessToken: accessToken
        }
    }

}

export function* recuperaPasswordSaga(action) {
    try {

        const request = {
            url: "/api/v1/recupera-password",
            method: "POST",
            data: {
                password: action.payload.password,
                confermapassword: action.payload.confermapassword,
                accessToken:action.payload.accessToken
            }
        }

        const response = yield CommonService.execute(request)

        // yield put({
        //     type: RECUPERA_PASSWORD_SUCCESS,
        // })

        yield put(
            showPopup(
                "redirect",
                "OPERAZIONE RIUSCITA",
                response.data.message
            )
        )
    } catch (error) {
        yield put({
            type: RECUPERA_PASSWORD_FAILED,
            payload: error.response.data.data.error
        })
        yield put(
            showPopup(
                "notify",
                "ERRORE",
                "Cambio Password non effettuato con successo. Riproare"
            )
        )
    }
}

