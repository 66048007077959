
import CommonService from "../../service/common_service";
import { put } from "redux-saga/effects";
import { showPopup } from "../popup/actions/popup_actions";

export const GET_USER_INFO = "getUserInfo"
export const GET_USER_INFO_SUCCESS = "get_userinfo_success"
export const GET_USER_INFO_FAIL = "get_userinfo_fail"

export function getUserInfo() {
    return {
        type: GET_USER_INFO
    }
}

export function* getUserInfoSaga() {
    try {

        const request = {
            method: "GET",
            url: "/api/v1/user-info",
        }

        const response = yield CommonService.execute(request)
        yield put({
            type: GET_USER_INFO_SUCCESS,
            payload: response.data.data
        })

    } catch (error) {
        yield put({
            type: GET_USER_INFO_FAIL,
            payload: error
        })
        yield put(
            showPopup(
                "error",
                "OPERAZIONE FALLITA",
                error.response.data.data.error
            )
        )
       
    }
}

export const UPDATE_USER = "updateUser"
export const UPDATE_USER_SUCCESS = "update_user_success"
export const UPDATE_USER_FAILED = "update_user_fail"

export function updateUser(userId, idSecondary, telefono, email, emailpec, tipologiaUtente ) {
    return {
        type: UPDATE_USER,
        payload: {
            userId: userId,
            idSecondary: idSecondary,
            telefono: telefono,
            email: email,
            emailpec: emailpec,
            tipologiaUtente: tipologiaUtente
        }
    }
}

export function* updateUserSaga(action) {
    try {
        const request = {
            url: "/api/v1/update-contact-info",
            method: "PUT",
            data: {
                id: action.payload.userId,
                idSecondary: action.payload.idSecondary,
                telefono: action.payload.telefono,
                email: action.payload.email,
                emailPec: action.payload.emailpec,
                tipologiaUtente: action.payload.tipologiaUtente
            }
        }

        const response = yield CommonService.execute(request)
        yield put({
            type: GET_USER_INFO,
            payload: response.data.data
        })
        yield put(
            showPopup(
                "notify",
                "OPERAZIONE RIUSCITA",
                response.data.message
            )
        )

    } catch (error) {
        yield put({
            type: UPDATE_USER_FAILED,
            payload: error
        })
        yield put(
            showPopup(
                "error",
                "OPERAZIONE FALLITA",
                error.response.data.message
            )
        )
    }
}
