import {
  GET_QRCODES, GET_QRCODES_SUCCESS, GET_QRCODES_FAIL,
  GET_QRCODES_VALORE, GET_QRCODES_VALORE_SUCCESS, GET_QRCODES_VALORE_FAIL,
  INSERT_QRCODE, INSERT_QRCODE_SUCCESS, INSERT_QRCODE_FAILED,
  UPDATE_QRCODE, UPDATE_QRCODE_SUCCESS, UPDATE_QRCODE_FAILED,
  DELETE_QRCODE, DELETE_QRCODE_SUCCESS, DELETE_QRCODE_FAILED
} from "../actions/qrCode_actions";
import _ from "lodash";

export function qrCodesReducer(state = { loading: false, error: undefined, qrCodes: undefined }, action) {

  if (_.isEmpty(action) || _.isEmpty(action.type)) return state

  const newState = state

  switch (action.type) {

    case GET_QRCODES:
      newState.loading = true
      newState.error = undefined
      newState.qrCodes = undefined
      break;
    case GET_QRCODES_SUCCESS:
      newState.loading = false
      newState.error = undefined
      newState.qrCodes = action.payload
      break;
    case GET_QRCODES_FAIL:
      newState.loading = false
      newState.error = action.payload
      newState.qrCodes = undefined
      break;
    case GET_QRCODES_VALORE:
      newState.loading = true
      newState.error = undefined
      newState.qrCodesrRicerca = undefined
      break;
    case GET_QRCODES_VALORE_SUCCESS:
      newState.loading = false
      newState.error = undefined
      newState.qrCodesRicerca = action.payload
      break;
    case GET_QRCODES_VALORE_FAIL:
      newState.loading = false
      newState.error = action.payload
      newState.qrCodesRicerca = undefined
      break;
    case INSERT_QRCODE:
      break;
    case INSERT_QRCODE_SUCCESS:
      break;
    case INSERT_QRCODE_FAILED:
      break;
    case UPDATE_QRCODE:
      break;
    case UPDATE_QRCODE_SUCCESS:
      break;
    case UPDATE_QRCODE_FAILED:
      break;
    case DELETE_QRCODE:
      break;
    case DELETE_QRCODE_SUCCESS:
      break;
    case DELETE_QRCODE_FAILED:
      break;
    default:
      break;
  }

  return Object.assign({}, state, newState)
}