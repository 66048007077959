import React from "react";
import { Routes, Route } from "react-router-dom";
import adminRoutes from "./routes";
import CustomSidebar from "../../layout/components/sidebar/CustomSidebar";
import PopUp from "../../common/components/popup/components/PopUp";
import CustomHeader from "../../layout/components/CustomHeader";
import {
    Button,
    Spinner,
    Row,
    Col,
    Icon,
    UncontrolledTooltip,
  } from "design-react-kit";

const AdminLayout = (props) => {

    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            return (
                <Route
                    path={prop.path}
                    element={<prop.component {...props} />}
                    key={key}
                />
            )

        })
    }

    return (
        <>
        <CustomHeader></CustomHeader>
        <div style={{display:"flex", alignItems: "flex-start"}}>
            <PopUp />
            <CustomSidebar routes={adminRoutes} />
            <Routes>
                {
                    getRoutes(adminRoutes)
                }
            </Routes>
        </div>
        </>
        

    )
}

export default AdminLayout