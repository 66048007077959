import _ from "lodash"
import { LOGIN_REQUEST, LOGIN_FAILED, LOGIN_SUCCESS } from "../actions/login_actions"

export function loginReducer(state = { username: undefined, success: false, loading: false, error: undefined, jwt: undefined }, action) {
    if (_.isEmpty(action) || _.isEmpty(action.type)) return state
    const newState = state
    switch (action.type) {
       
        case LOGIN_REQUEST:
            newState.loading = true
            newState.error = undefined
            newState.success = false
            break
        case LOGIN_FAILED:
            newState.loading = false
            newState.error = action.payload
            newState.success = false
            break
        case LOGIN_SUCCESS:
            newState.success = true
            newState.error = undefined
            newState.loading = false
            newState.username = action.payload.username
            newState.jwt = action.payload.jwt
            break;
        default:
            break
    }
    return Object.assign({}, state, newState)
}
