import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  ModalFooter,
  Input,
  Select,
  Row,
  Col
} from "design-react-kit";
import { hidePwdModal, showPwdModal } from "../dashboard/pwdModal_action"
import _ from "lodash";

const PwdModal = (props) => {

  const dispatch = useDispatch();

  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [oldPassword, setOldPassword] = useState();
  const [pwdconfirm, setPwdConfirm] = useState();
  const [tipologiaLogin, setTipoLogiaLogin] = useState();
  //const [emailPec, setMailPec] = useState();

  const [coincidono, setCoincidono] = useState(true);

  const pwdReducersEdit = useSelector((state) => state.pwdChangeModal);

  console.log(localStorage)

  const type = useSelector((state) => state.pwdChangeModal.type);
  const header = useSelector((state) => state.pwdChangeModal.header);
  const show = useSelector((state) => state.pwdChangeModal.show);
  const f = useSelector((state) => state.pwdChangeModal.function);


  const confirm = () => {

    if(password != pwdconfirm){
        setCoincidono(false)
    }else{
        setCoincidono(true)
        switch (type) {
            case "insert":
              //dispatch(f(telefono, email, emailPec));
              break;
            case "edit":
              // let enteId = ente.value
              // if(ente.value === undefined || ente.value === null){
              //   enteId = ente.id
              // }
              dispatch(f(pwdReducersEdit.obj.idSecondary, pwdReducersEdit.obj.userName, oldPassword, password, pwdconfirm, pwdReducersEdit.obj.tipologiaUtente));
              close();
              break;
            default:
              close();
          }
          close();
    }

    // switch (type) {
    //   case "insert":
    //     //dispatch(f(telefono, email, emailPec));
    //     break;
    //   case "edit":
    //     // let enteId = ente.value
    //     // if(ente.value === undefined || ente.value === null){
    //     //   enteId = ente.id
    //     // }
    //     dispatch(f(pwdReducersEdit.obj.idSecondary, pwdReducersEdit.obj.userName, oldPassword, password, pwdconfirm, pwdReducersEdit.obj.tipologiaUtente));
    //     close();
    //     break;
    //   default:
    //     close();
    // }
    // close();
  };

  const close = () => dispatch(hidePwdModal());

  //console.log(userInfoToEdit.obj)

  useEffect(() => {
    if (type !== "edit" && pwdReducersEdit.obj === undefined) {
      //setTelefono(undefined);
      //setMail(undefined);
      //setMailPec(undefined);

    } else {
    
      //setPassword(pwdReducersEdit.obj.password);
      //setOldPassword(pwdReducersEdit.obj.oldPassword);
      //setMailPec(pwdReducersEdit.obj.emailPec);

    }
  }, [type, pwdReducersEdit]);

  
  return (
    <div>
      <Modal isOpen={show} labelledBy="pwdModal">
        <ModalHeader id="pwdModal">{header}</ModalHeader>
        <ModalBody>
          <FormGroup>
            <div className="bootstrap-select-wrapper">
              <Input
                id="oldPassword"
                onChange={(e) => {
                  setOldPassword(e.target.value);
                }}
                //value={oldPassword}
                type="password"
                placeholder="Password Attuale"
                aria-label="Password Attuale"
              />
              <Input
                id="password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                //value={password}
                type="password"
                placeholder="Nuova Password"
                aria-label="Nuova Password"
              />
              <Input
                id="pwdconfirm"
                onChange={(e) => {
                  setPwdConfirm(e.target.value);
                }}
                //value={pwdconfirm}
                type="password"
                placeholder="Conferma Password"
                aria-label="Conferma Password"
              />
            </div>
          </FormGroup>
          {
            !coincidono &&
            <Row>
                <Col>
                    <p className="text-mess-alert">Attenzione! Password e Conferma Password non coincidono</p>
                    <p className="text-mess-alert">Controlla e riprova</p>
                </Col>
            </Row>
          }
          

        </ModalBody>
        <ModalFooter>
          <span>
            <Button outline color='primary' onClick={() => close()}>ANNULLA</Button>
          </span>
          <span>
            <Button outline color='primary' onClick={() => confirm()}>CONFERMA</Button>
          </span>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default PwdModal;
