export const SHOW_ENTE_MODAL = "showEnteModal"
export const HIDE_ENTE_MODAL = "hideEnteModal"

export function showEnteModal(type, header, f, obj){
  return {
      type: SHOW_ENTE_MODAL,
      payload:{
        type: type,
        header: header,
        function: f,
        obj: obj
    }
  }
}

export function hideEnteModal(){
  return {
      type: HIDE_ENTE_MODAL
  }
}