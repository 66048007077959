export const SHOW_TIPO_SPAZZATURA_MODAL = "showTipologiaSpazzaturaModal"
export const HIDE_TIPO_SPAZZATURA_MODAL = "hide_tipologiaSpazzatura_modal"

export function showTipologiaSpazzaturaModal(type, header, f, obj){
  return {
      type: SHOW_TIPO_SPAZZATURA_MODAL,
      payload:{
        type: type,
        header: header,
        function: f,
        obj: obj
    }
  }
}

export function hideTipologiaSpazzaturaModal(){
  return {
      type: HIDE_TIPO_SPAZZATURA_MODAL
  }
}