import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Spinner, Row,
  Col,
  Icon,
  UncontrolledTooltip, } from "design-react-kit";
import { getTipologieSpazzatura, insertTipologiaSpazzatura, updateTipologiaSpazzatura, deleteTipologiaSpazzatura } from "../actions/tipologiaSpazzatura_actions";
import CustomTable from "../../table/component/CustomTable";
import _ from "lodash";
import { showPopup } from "../../popup/actions/popup_actions";
import { showTipologiaSpazzaturaModal } from "../actions/tipologiaSpazzaturaModal_actions";
import TipologiaSpazzaturaModal from "./TipologiaSpazzaturaModal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRefresh, faEdit, faRecycle, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

const TipologiaSpazzatura = () => {

  const dispatch = useDispatch();

  const delTipologiaSpazzatura = (arg) => {
    dispatch(deleteTipologiaSpazzatura(arg));
  };

  const showActionPopUp = (type, header, message, f, arg) => {
    dispatch(showPopup(type, header, message, f, arg));
  };

  const showActionModal = (type, header, f, obj) => {
    dispatch(showTipologiaSpazzaturaModal(type, header, f, obj));
  };

  const tipologieSpazzatura = useSelector(
    (state) => state.tipologiaSpazzatura.tipologieSpazzatura
  );
  const loading = useSelector((state) => state.tipologiaSpazzatura.loading);
  const [dati, setDati] = useState([]);

  useEffect(() => {
    if (tipologieSpazzatura === undefined) {
      dispatch(getTipologieSpazzatura());
    } else {
      const tmp = [];
      _.forEach(tipologieSpazzatura, (ts) => {
        ts.actions = (
          <section>
            <span className="rounded-icon iconbottom"
              onClick={() => {
                showActionModal("edit", "Modifica Tipologia Spazzatura", updateTipologiaSpazzatura, ts);
              }}
            >
            <FontAwesomeIcon icon={faEdit} />
            </span>
            <span className="rounded-icon iconbottomcancel"
             onClick={() =>
              showActionPopUp(
                "confirm",
                "ATTENZIONE",
                `La Tipologia Spazzatura ${ts.descrizione} verra' cancellata. Procedere?`,
                delTipologiaSpazzatura,
                ts.descrizione
              )
            }
            >
            <FontAwesomeIcon icon={faTrashAlt} />
            </span>

          </section>
        );
        tmp.push(ts);
      });
      setDati(tmp);
    }
  }, [tipologieSpazzatura, dispatch]);

  const headers = [
    {
      label: "Descrizione",
      field: "descrizione",
    },
    {
      label: "Azioni",
      field: "actions",
    },
  ];

  const refreshList = () => {
    dispatch(getTipologieSpazzatura());
  };


  return (
    <React.Fragment>
      <TipologiaSpazzaturaModal />
      {loading ? (
        <div className="customTableContainer">
          <div className="container_spinner"><Spinner active  className="spinnerwebe"/></div>
        </div>
      ) : (
        <div className="customTableContainer">
          <Row className="justify-content-md-center">
            <Col lg="9" xl="9" md="9" sm="9" xs="12">
              <h1 className="">Tipologia Rifiuti <FontAwesomeIcon icon={faRefresh} className="padding-lr-icon linkeffect" onClick={()=> refreshList()}/></h1>
            </Col>
            <Col lg="3" xl="3" md="3" sm="3" xs="12" className="text-right">
              <Button
                color="primary"
                onClick={() =>
                  showActionModal(
                    "insert",
                    "Inserimento nuova Tipologia Spazzatura",
                    insertTipologiaSpazzatura
                  )
                }
                icon
                id="addNewTipologiaSpazzatura"
                style={{ marginBottom: "8px" }}
              >
                <FontAwesomeIcon icon={faRecycle} className="padding-lr-icon"/> {' '}Aggiungi
              </Button>
              <UncontrolledTooltip placement="top" target="addNewTipologiaSpazzatura">
                Aggiungi un nuova Tipologia Spazzatura
              </UncontrolledTooltip>
            </Col>
          </Row>
          <CustomTable headers={headers} data={dati} />
        </div>
      )}
    </React.Fragment>
  );
};

export default TipologiaSpazzatura;
