export const SHOW_MASTELLO_MODAL = "showMastelloModal"
export const HIDE_MASTELLO_MODAL = "hide_mastello_modal"

export function showMastelloModal(type, header, f, obj){
  return {
      type: SHOW_MASTELLO_MODAL,
      payload:{
        type: type,
        header: header,
        function: f,
        obj: obj
    }
  }
}

export function hideMastelloModal(){
  return {
      type: HIDE_MASTELLO_MODAL
  }
}