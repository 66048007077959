import {
  GET_DISPOSITIVI, GET_DISPOSITIVI_SUCCESS, GET_DISPOSITIVI_FAIL,
  INSERT_DISPOSITIVO, INSERT_DISPOSITIVO_SUCCESS, INSERT_DISPOSITIVO_FAILED,
  UPDATE_DISPOSITIVO, UPDATE_DISPOSITIVO_SUCCESS, UPDATE_DISPOSITIVO_FAILED,
  DELETE_DISPOSITIVO, DELETE_DISPOSITIVO_SUCCESS, DELETE_DISPOSITIVO_FAILED,
  STAMPA_DISPOSITIVO, STAMPA_DISPOSITIVO_SUCCESS, STAMPA_DISPOSITIVO_FAILED
} from "../actions/dispositivo_actions";
import _ from "lodash";

export function dispositivoReducer(state = { loading: false, error: undefined, insertSuccessData: undefined, dispositivi: undefined }, action) {

  if (_.isEmpty(action) || _.isEmpty(action.type)) return state

  const newState = state

  switch (action.type) {

    case GET_DISPOSITIVI:
      newState.loading = true
      newState.error = undefined
      newState.dispositivi = undefined
      break;
    case GET_DISPOSITIVI_SUCCESS:
      newState.loading = false
      newState.error = undefined
      newState.dispositivi = action.payload
      break;
    case GET_DISPOSITIVI_FAIL:
      newState.loading = false
      newState.error = action.payload
      newState.dispositivi = undefined
      break;
    case INSERT_DISPOSITIVO:
      break;
    case INSERT_DISPOSITIVO_SUCCESS:
      break;
    case INSERT_DISPOSITIVO_FAILED:
      break;
    case UPDATE_DISPOSITIVO:
      break;
    case UPDATE_DISPOSITIVO_SUCCESS:
      break;
    case UPDATE_DISPOSITIVO_FAILED:
      break;
    case DELETE_DISPOSITIVO:
      break;
    case DELETE_DISPOSITIVO_SUCCESS:
      break;
    case DELETE_DISPOSITIVO_FAILED:
      break;
    case STAMPA_DISPOSITIVO:
      break;
    case STAMPA_DISPOSITIVO_SUCCESS:
      break;
    case STAMPA_DISPOSITIVO_FAILED:
      break;
    default:
      break;
  }

  return Object.assign({}, state, newState)
}