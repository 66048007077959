import { GET_ENTI, GET_ENTI_SUCCES, GET_ENTI_FAIL } from "../actions/ente_actions";
import _ from "lodash";

export function enteReducer(state = { loading: false, error: undefined, enti: undefined }, action) {
  if (_.isEmpty(action) || _.isEmpty(action.type)) return state
  const newState = state
  switch (action.type) {
     
      case GET_ENTI:
          newState.loading = true
          newState.error = undefined
          newState.enti = undefined
          break
      case GET_ENTI_SUCCES:
          newState.loading = false
          newState.error = undefined
          newState.enti = action.payload
          break
      case GET_ENTI_FAIL:
        newState.loading = false
        newState.error = action.payload
        newState.enti = undefined
          break;
      default:
          break
  }
  return Object.assign({}, state, newState)
}
