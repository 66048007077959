import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Select,
  ModalFooter,
  Input,
  Col,
  Row
} from "design-react-kit";
import { hideDispositivoModal } from "../actions/dispositivoModal_actions";
import { getUserInfo } from "../../dashboard/dashboard_Action";
import _ from "lodash";

const DispositivoModal = (props) => {
  const dispatch = useDispatch();

  const userInfo = useSelector(
    (state) => state.userInfo.userInfo
  );

  const [utenza, setUtenza] = useState();
  const [tipologiaSpazzatura, setTipologiaSpazzatura] = useState();
  const [valoreQrCode, setValoreQrCode] = useState();
  const [statoQrCode, setStatoQrCode] = useState();

  const [utenzeOptions, setUtenzeOptions] = useState();
  const [tipologieSpazzaturaOptions, setTipologieSpazzaturaOptions] = useState();
  const [valoriQrOptions, setValoriQrOptions] = useState();
  const [statiQrCodeOptions, setStatiQrCodeOptions] = useState()

  const [tipoDispositivo, setTipoDispositivo] = useState()
  const [listaente, setListaEnte] = useState()

  const [ente, setEnte] = useState()

  const [marca, setMarca] = useState()
  const [modello, setModello] = useState()
  const [codiceInterno, setCodiceInterno] = useState()
  const [matricola, setMatricola] = useState()

  const [comune, setComune] = useState()
  const [operatore, setOperatore] = useState()
  const [listaoperatore, setListaOperatore] = useState()

  const dispositivoToEdit = useSelector((state) => state.dispositivoModal.obj);

  const type = useSelector((state) => state.dispositivoModal.type);
  const header = useSelector((state) => state.dispositivoModal.header);
  const show = useSelector((state) => state.dispositivoModal.show);
  const f = useSelector((state) => state.dispositivoModal.function);

  const [listaErrori, setListaErrori] = useState([]);
  let listaErroriTmp = [];

  function erroriForm(listaErroriInfo){
    return listaErroriInfo.map(function (errore) {
      return(
        <p className="text-mess-alert">{errore}</p>
      )})  
  }



  function close() {
    dispatch(hideDispositivoModal());
  }

  function confirm() {
    listaErroriTmp = [];
    

    switch (type) {
      case "insert":
        let enteid = ente;
        let operatoreid = operatore;
        let comuneid = comune;
        if( userInfo!=null && userInfo.tipologiaUtente === "ENTE"){
          enteid = ente
          operatoreid = operatore.value

          if(enteid == null || enteid == '') listaErroriTmp.push("- Ente è un campo obbligatorio")
          if(operatoreid == null || operatoreid == '') listaErroriTmp.push("- Ente è un campo obbligatorio")

        }else if(userInfo!=null && userInfo.tipologiaUtente === "OPERATORE"){
          enteid = userInfo.ente.id
          operatoreid = userInfo.id
          
        }else{
          if(ente != null) enteid = ente.value
          if(operatore != null) operatoreid = operatore.value
          
          if(enteid == null || enteid == '') listaErroriTmp.push("- Ente è un campo obbligatorio")
          //if(operatoreid == null || operatoreid == '') listaErroriTmp.push("- Operatore è un campo obbligatorio")
        }
        
        
        if(codiceInterno == null || codiceInterno === '') listaErroriTmp.push("- Codice Interno è un campo obbligatorio")
        if(marca == null || marca === '') listaErroriTmp.push("- Marca è un campo obbligatorio")
        if(matricola == null || matricola === '') listaErroriTmp.push("- Matricola è un campo obbligatorio")
        if(modello == null || modello === '') listaErroriTmp.push("- Modello è un campo obbligatorio")
        if(tipoDispositivo == null || tipoDispositivo.value === '') listaErroriTmp.push("- Tipo Dispositivo è un campo obbligatorio")

        
        if(listaErroriTmp.length == 0){
          dispatch(f(codiceInterno, marca, matricola, modello, tipoDispositivo.value, comune, enteid, operatoreid));
          setListaErrori([])
          close();
        }else{
          setListaErrori(listaErroriTmp)
        }
        
        break;
      case "edit":
        let enteModifica = ente.value
        if(enteModifica === undefined)enteModifica = ente.id
        if(enteModifica == null || enteModifica == '') listaErroriTmp.push("- Ente è un campo obbligatorio")
        let operatoreModifica = operatore
        if( userInfo!=null && userInfo.tipologiaUtente === "ENTE"){
          operatoreModifica = operatore.value
          if(operatoreModifica === undefined)operatoreModifica = operatore.id
          if(operatoreModifica == null || operatoreModifica == '') listaErroriTmp.push("- Operatore è un campo obbligatorio")
        }else{
          if( dispositivoToEdit.operatore === null ) {
            operatoreModifica = null
          }else{
            operatoreModifica = dispositivoToEdit.operatore.id
            if(operatoreModifica == null || operatoreModifica == '') listaErroriTmp.push("- Operatore è un campo obbligatorio")
          }
         
        }

        if( userInfo!=null && userInfo.tipologiaUtente === "ENTE"){
          enteModifica = ente
        }
        let tipodispositivoModifica = dispositivoToEdit.tipoDispositivo
        if(tipoDispositivo != undefined ) tipodispositivoModifica = tipoDispositivo.value

        if(codiceInterno == null || codiceInterno === '') listaErroriTmp.push("- Codice Interno è un campo obbligatorio")
        if(marca == null || marca === '') listaErroriTmp.push("- Marca è un campo obbligatorio")
        if(matricola == null || matricola === '') listaErroriTmp.push("- Matricola è un campo obbligatorio")
        if(modello == null || modello === '') listaErroriTmp.push("- Modello è un campo obbligatorio")
        if(tipodispositivoModifica == null || tipodispositivoModifica.value === '') listaErroriTmp.push("- Tipo Dispositivo è un campo obbligatorio")

        if(listaErroriTmp.length == 0){
          dispatch(f(dispositivoToEdit.id, codiceInterno, marca, matricola, modello, tipodispositivoModifica, comune.id, enteModifica, operatoreModifica));
          setListaErrori([])
          close();
        }else{
          setListaErrori(listaErroriTmp)
        }
        break;
      default:
        close();
    }
  }

  const defaultTipoDispositivoValue = () => {
    switch (type) {
      case "insert":
        break;
      case "edit":
        let labeldispositivo = dispositivoToEdit.tipoDispositivo
        if(dispositivoToEdit.tipoDispositivo === 'tablet')labeldispositivo = "Tablet"
        if(dispositivoToEdit.tipoDispositivo === 'smartphone')labeldispositivo = "Smartphone"
        if(dispositivoToEdit.tipoDispositivo === 'computer')labeldispositivo = "Computer Fisso"
        if(dispositivoToEdit.tipoDispositivo === 'notebook')labeldispositivo = "Computer Portatile"
        return {
          label: labeldispositivo,
          value: dispositivoToEdit.tipoDispositivo
        };
      default:
        return {
          label: undefined,
          value: undefined,
        };
    }
  };

  const defaultTipologiaSpazzaturaValue = () => {
    switch (type) {
      case "insert":
        break;
      case "edit":
        return {
          label: dispositivoToEdit.tipologiaSpazzatura.descrizione,
          value: dispositivoToEdit.tipologiaSpazzatura.id
        };
      default:
        return {
          label: undefined,
          value: undefined,
        };
    }
  };

  const defaultEnteValue = () => {
    switch (type) {
      case "insert":
        break;
      case "edit":
        return {
          label: dispositivoToEdit.ente.denominazione,
          value: dispositivoToEdit.ente.id
        };
      default:
        return {
          label: undefined,
          value: undefined,
        };
    }
  };

  const defaultOperatoreValue = () => {
    switch (type) {
      case "insert":
        break;
      case "edit":
        if(dispositivoToEdit.operatore != null){
          return {
            label: dispositivoToEdit.operatore.cognome + " " + dispositivoToEdit.operatore.nome,
            value: dispositivoToEdit.operatore.id
          };
        }
        
      default:
        return {
          label: undefined,
          value: undefined,
        };
    }
  };

  useEffect(() => {
    if (userInfo === undefined) {
      dispatch(getUserInfo());
    }
  }, [userInfo]);


  useEffect(() => {
      const tmp = [];
      _.forEach(props.enti, (u) => {
        tmp.push({ value: u.ente.id, label: u.ente.denominazione });
      });
      setListaEnte(tmp);
  }, [props.enti]);

  useEffect(() => {
    const tmp = [];
    _.forEach(props.operatori, (u) => {
      tmp.push({ value: u.id, label: u.cognome + " " + u.nome });
    });
    setListaOperatore(tmp);
}, [props.operatori]);

  useEffect(() => {
    if (type !== "edit" && dispositivoToEdit === undefined) {
      setTipoDispositivo(undefined)
      
      setMarca(undefined)
      setModello(undefined)
      setCodiceInterno(undefined)
      setMatricola(undefined)

      

      if( userInfo!=null && userInfo.tipologiaUtente === "COMUNE"){
        //setListaEnte(props.enti);
        setComune(userInfo.id);
      }
      if( userInfo!=null && userInfo.tipologiaUtente === "ENTE"){
        //setListaEnte(props.enti)
        setEnte(userInfo.id)
        setComune(userInfo.comuneResidenzaId);
      }
      if( userInfo!=null && userInfo.tipologiaUtente === "OPERATORE"){
        setComune(userInfo.comuneResidenzaId);
      }

    } else {
      console.log(dispositivoToEdit)
      setMarca(dispositivoToEdit.marca)
      setModello(dispositivoToEdit.modello)
      setCodiceInterno(dispositivoToEdit.codiceIdentificativo)
      setMatricola(dispositivoToEdit.matricola)
      setEnte(dispositivoToEdit.ente)
      setComune(dispositivoToEdit.comune)

      if( userInfo!=null && userInfo.tipologiaUtente === "ENTE"){
        setEnte(userInfo.id)
        setOperatore(dispositivoToEdit.operatore)
      }
      
    }
  }, [type, dispositivoToEdit]);

  return (
    <div>
      <Modal isOpen={show} labelledBy="dispositivoModal" size="lg">
        <ModalHeader id="dispositivoModal">{header}</ModalHeader>
        <ModalBody>
          <FormGroup>
          {userInfo!=null &&  userInfo.tipologiaUtente === "COMUNE" &&
          <div className="bootstrap-select-wrapper form-group">
            {/* <label htmlFor="selectUtenza">Tipo Dispositivo</label> */}
            <Select
              id="ente"
              onChange={(selectedOption) => {
                setEnte(selectedOption);
              }}
              options={listaente}
              defaultValue={defaultEnteValue()}
              placeholder="Seleziona Ente"
              aria-label="Seleziona Ente"
            />
          </div>
          }
          {userInfo!=null &&  userInfo.tipologiaUtente === "ENTE" &&
          <div className="bootstrap-select-wrapper form-group">
            {/* <label htmlFor="selectUtenza">Tipo Dispositivo</label> */}
            <Select
              id="ente"
              onChange={(selectedOption) => {
                setOperatore(selectedOption);
              }}
              options={listaoperatore}
              defaultValue={defaultOperatoreValue()}
              placeholder="Seleziona Operatore"
              aria-label="Seleziona Operatore"
            />
          </div>
          }
          
            <div className="bootstrap-select-wrapper form-group">
              {/* <label htmlFor="selectUtenza">Tipo Dispositivo</label> */}
              <Select
                id="tipodispositivo"
                onChange={(selectedOption) => {
                  setTipoDispositivo(selectedOption);
                }}
                options={props.tipodispositivo}
                defaultValue={defaultTipoDispositivoValue()}
                placeholder="Seleziona Tipo Dispositivo"
                aria-label="Seleziona Tipo Dispositivo"
              />
            </div>
            <Input
                id="marca"
                onChange={(e) => {
                  setMarca(e.target.value);
                }}
                value={marca}
                type="text"
                placeholder="Inserisci Marca"
                aria-label="Inserisci Marca"
              />
              <Input
                id="modello"
                onChange={(e) => {
                  setModello(e.target.value);
                }}
                value={modello}
                type="text"
                placeholder="Inserisci Modello"
                aria-label="Inserisci Modello"
              />
              <Input
                id="codiceinterno"
                onChange={(e) => {
                  setCodiceInterno(e.target.value);
                }}
                value={codiceInterno}
                type="text"
                placeholder="Inserisci Codice Interno"
                aria-label="Inserisci Codice Interno"
              />
               <Input
                id="matricola"
                onChange={(e) => {
                  setMatricola(e.target.value);
                }}
                value={matricola}
                type="text"
                placeholder="Inserisci Matricola"
                aria-label="Inserisci Matricola"
              />
          </FormGroup>
          
          {/* <FormGroup>
            <div className="bootstrap-select-wrapper">
              <label htmlFor="selectValoreQr">Valore QrCode</label>
              <Select
                id="valoreQr"
                onChange={(selectedOption) => {
                  setValoreQrCode(selectedOption);
                }}
                options={valoriQrOptions}
                defaultValue={defaultValoreQrValue()}
                placeholder="Seleziona valore qrCode"
                aria-label="Seleziona valore qrCode"
              />
            </div>
          </FormGroup> */}
          {listaErrori.length>0 &&
            <Row>
                <Col>
                  {erroriForm(listaErrori)}
                </Col>
            </Row>
          }
        </ModalBody>
        <ModalFooter>
          <span>
            <Button outline color='primary' onClick={() => close()}>ANNULLA</Button>
          </span>
          <span>
            <Button outline color='primary' onClick={() => confirm()}>CONFERMA</Button>
          </span>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DispositivoModal;
