import React , { Component} from 'react';
import * as d3 from 'd3';
//const colors = [ '#8ce8ad', '#57e188', '#34c768', '#2db757', '#27acaa', '#42c9c2', '#60e6e1', '#93f0e6', '#87d3f2', '#4ebeeb', '#35a4e8', '#188ce5', '#542ea5', '#724bc3', '#9c82d4', '#c981b2', '#b14891', '#ff6d00', '#ff810a', '#ff9831', '#ffb46a', '#ff9a91', '#ff736a', '#f95d54', '#ff4136', '#c4c4cd' ];

const colors = [ '#8ce8ad', '#57e188', '#60e6e1', '#93f0e6', '#87d3f2', '#4ebeeb',  '#c981b2', '#ff9831', '#ffb46a', '#ff9a91', '#ff736a', '#f95d54' ];


class DonutChart extends Component {

    constructor(props) {
        super(props);
        this.chRef = React.createRef();
    }


    // Chart load after component Mount
    componentDidMount() {
        this.drawChart()
    }


    // DrawChart 
    drawChart(){
        var test = this.props.idgrafico
        d3.selectAll('#'+test+' svg').remove();
        // var data = [
        //     {name: "Carta", value: 25},
        //     {name: "Plastica", value: 15},
        //     {name: "Vetro", value: 30},
        //     {name: "Umido", value: 10},
        //     {name: "Indifferenziata", value: 20},
        //   ];

        var data = this.props.dati

        var vuoto = this.props.vuoto
          var text = "";
          
          var width = 290;
          var height = 290;
          var thickness = 40;
          var duration = 750;
          
          var radius = Math.min(width, height) / 2;
          var color = d3.scaleOrdinal(this.props.colori);
          var test = this.props.idgrafico
          var svg = d3.select('#'+test)
          .append('svg')
          .attr('class', 'pie')
          .attr('width', width)
          .attr('height', height);
          
          var g = svg.append('g')
          .attr('transform', 'translate(' + (width/2) + ',' + (height/2) + ')');
          
          var arc = d3.arc()
          .innerRadius(radius - thickness)
          .outerRadius(radius);
          
          var pie = d3.pie()
          .value(function(d) { return d.value; })
          .sort(null);
          
          var path = g.selectAll('path')
          .data(pie(data))
          .enter()
          .append("g")
          .attr("id", (d) => d.data.name)
          .on("mouseover", function(d) {

            
                let g = d3.select(this)
                  .style("cursor", "pointer")
                  .style("fill", "#0059b3")
                  .append("g")
                  .attr("class", "text-group");
           
                g.append("text")
                  .attr("class", "name-text")
                  .text((d) => d.data.name)
                  .attr('text-anchor', 'middle')
                  .attr('dy', '-1.2em');
                  if(vuoto === false){
                    g.append("text")
                      .attr("class", "value-text")
                      .text((d) => d.data.value)
                      .attr('text-anchor', 'middle')
                      .attr('dy', '.6em');
                    }
              })
            .on("mouseout", function(d) {
                d3.select(this)
                  .style("cursor", "none")  
                  .style("fill", color(this._current))
                  .select(".text-group").remove();
              })
            .append('path')
            .attr('d', arc)
            .attr('fill', (d,i) => color(i))
            .attr("value", (d) => d.data.value)
            .on("mouseover", function(d) {

                console.log(this)
                d3.select(this)     
                  .style("cursor", "pointer")
                  .style("fill", "#0059b3");
              })
            .on("mouseout", function(d) {
                d3.select(this)
                  .style("cursor", "none")  
                  .style("fill", color(this._current));
              })
            .on("click", function(d) {

                console.log(this)
                d3.select(this)     
                  .style("cursor", "pointer")
                  .style("fill", "#0059b3");
              })
            .each(function(d, i) { this._current = i; });
          
            if(vuoto === false){
              g.append('text')
                .attr('text-anchor', 'middle')
                .attr('dy', '.35em')
                .text(text);
            }
            
    }



    render() {
        return <>
            <div id={this.props.idgrafico} className='posizione-grafico'></div>
            {/* <div ref={this.chRef}></div> */}
             </>
    }


}

export default DonutChart;