
import {
  GET_COMUNE, GET_COMUNE_SUCCESS, GET_COMUNE_FAIL, INSERT_COMUNE, INSERT_COMUNE_SUCCESS, INSERT_COMUNE_FAILED,
  UPDATE_COMUNE, UPDATE_COMUNE_SUCCESS, UPDATE_COMUNE_FAILED,
  DELETE_COMUNE, DELETE_COMUNE_SUCCESS, DELETE_COMUNE_FAILED
} from "../actions/comune_actions";

import _ from "lodash"

export function ComuneReducer(state = { loading: false, error: undefined, entecomune: undefined }, action) {

  if (_.isEmpty(action) || _.isEmpty(action.type)) return state

  const newState = state

  switch (action.type) {

    case GET_COMUNE:
      newState.loading = true
      newState.error = undefined
      newState.comune = undefined
      break;
    case GET_COMUNE_SUCCESS:
      newState.loading = false
      newState.error = undefined
      newState.comune = action.payload
      break;
    case GET_COMUNE_FAIL:
      newState.loading = false
      newState.error = action.payload
      newState.comune = undefined
      break;
    case INSERT_COMUNE:
      break;
    case INSERT_COMUNE_SUCCESS:
      break;
    case INSERT_COMUNE_FAILED:
      break;
    case UPDATE_COMUNE:
      break;
    case UPDATE_COMUNE_SUCCESS:
      break;
    case UPDATE_COMUNE_FAILED:
      break;
    case DELETE_COMUNE:
      break;
    case DELETE_COMUNE_SUCCESS:
      break;
    case DELETE_COMUNE_FAILED:
      break;
    default:
      break;
  }

  return Object.assign({}, state, newState)
}
