export const SHOW_SCONTISTICA_MODAL = "showScontisticaModal"
export const HIDE_SCONTISTICA_MODAL = "hide_scontistica_modal"

export function showScontisticaModal(type, header, f, obj){
  return {
      type: SHOW_SCONTISTICA_MODAL,
      payload:{
        type: type,
        header: header,
        function: f,
        obj: obj
    }
  }
}

export function hideScontisticaModal(){
  return {
      type: HIDE_SCONTISTICA_MODAL
  }
}