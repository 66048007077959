import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Spinner,
  Row,
  Col,
  Icon,
  UncontrolledTooltip,
  Table
} from "design-react-kit";
import {
  getDispositivi,
  insertDispositivo,
  updateDispositivo,
  deleteDispositivo,
} from "../actions/dispositivo_actions";
import CustomTable from "../../table/component/CustomTable";
import _ from "lodash";
import { showPopup } from "../../popup/actions/popup_actions";
import { showDispositivoModal } from "../actions/dispositivoModal_actions";
import DispositivoModal from "./DispositivoModal";
import { getUtenze } from "../../utenze/actions/utenze_actions";
import { getTipologieSpazzatura } from "../../tipologiaSpazzatura/actions/tipologiaSpazzatura_actions";
import { getQrCodes } from "../../qrCode/actions/qrCode_actions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faRefresh, faTrashAlt, faTabletAlt } from '@fortawesome/free-solid-svg-icons'
import { getUserInfo } from "../../dashboard/dashboard_Action";
import { getEnti } from "../../../../ente/actions/ente_actions";
import { getOperatore } from "../../operatori/actions/operatori_actions";
import { getCollaborazioni } from "../../collaborazioni/actions/collaborazioni_actions";

const Dispositivo = () => {

  const dispatch = useDispatch();

  const userInfo = useSelector(
    (state) => state.userInfo.userInfo
  );

  
  const dispositivi = useSelector((state) => state.dispositivo.dispositivi);
  const loading = useSelector((state) => state.dispositivo.loading);
  const [dati, setDati] = useState([]);

  const utenze = useSelector((state) => state.utenze.utenze);

  const enti = useSelector((state) => state.collaborazioni.collaborazioni);
  const operatori = useSelector((state) => state.operatori.operatori);

  const [tipoDispositivo, setTipoDispositivo] = useState()
  

  const delDispositivo = (arg) => {
    console.log("delete button")
    dispatch(deleteDispositivo(arg));
  };

  useEffect(() => {
    if (enti === undefined) {
      //dispatch(getEnti());
      dispatch(getCollaborazioni())
    }
  }, [enti]);

  useEffect(() => {
    if( userInfo!=null && userInfo.tipologiaUtente === "ENTE"){
      if (operatori === undefined) {
        dispatch(getOperatore());
      }
    }
  }, [operatori]);

  const showActionPopUp = (type, header, message, f, arg) => {
    dispatch(showPopup(type, header, message, f, arg));
  };

  const showActionModal = (type, header, f, obj) => {
    dispatch(showDispositivoModal(type, header, f, obj));
  };


  const infoDispositivo = (u) => {
    return (
      <Table size="sm">
        <tbody>
          <tr>
            <th scope="row">Comune</th>
            <td>{u.comune.denominazione}</td>
          </tr>
          <tr>
            <th scope="row">Ente</th>
            <td>{u.ente.denominazione}</td>
          </tr>
          {
            u.operatore !=null ? (
            <tr>
              <th scope="row">Operatore</th>
              <td>{u.operatore.cognome} {u.operatore.nome}</td>
            </tr>
            ):(
              <tr>
              <th scope="row">Operatore</th>
              <td>Non Assegnato</td>
              </tr>
            )
          }
         
          <tr>
            <th scope="row">Tipo</th>
            <td>{u.tipoDispositivo}</td>
          </tr>
          <tr>
            <th scope="row">Marca - Modello</th>
            <td>{u.marca} {u.modello}</td>
          </tr>
          <tr>
            <th scope="row">Matricola</th>
            <td>{u.matricola}</td>
          </tr>
          <tr>
            <th scope="row">Codice Identificativo</th>
            <td>{u.codiceIdentificativo}</td>
          </tr>
        </tbody>
      </Table>
    );
  };

  
  useEffect(() => {
    if (dispositivi === undefined) {
      dispatch(getDispositivi());
      //dispatch(getUserInfo());
    } else {
      //dispatch(getUserInfo());
      setTipoDispositivo([
        {value: "tablet", label: "Tablet"},
        {value: "smartphone", label: "Smartphone"},
        {value: "computer", label: "Computer Fisso"},
        {value: "portatile", label: "Computer Portatile"},
        {value: "lettoreqr", label: "Lettore QrCode"},
      ])
      const tmp = [];
      _.forEach(dispositivi, (m) => {
          m.actions = (
            <section>
              <span className="rounded-icon iconbottom"
                onClick={() =>
                  dispatch(showPopup(
                    "notify",
                    "Dati Dispositivo",
                    infoDispositivo(m)
                  ))
                }
              >
              <FontAwesomeIcon icon={faTabletAlt} />
              </span>
              {userInfo != null && (userInfo.tipologiaUtente === "COMUNE" || userInfo.tipologiaUtente === "ADMIN" || userInfo.tipologiaUtente === "ENTE" ) &&
                <span className="rounded-icon iconbottom"
                onClick={() => {
                  showActionModal("edit", "Modifica Dispositivo", updateDispositivo, m);
                }}
                >
                <FontAwesomeIcon icon={faEdit} />
                </span>
              }
              {userInfo != null && (userInfo.tipologiaUtente === "COMUNE" || userInfo.tipologiaUtente === "ADMIN" ) &&
              <span className="rounded-icon iconbottomcancel"
                onClick={() =>
                  showActionPopUp(
                    "confirm",
                    "ATTENZIONE",
                    `Il dispositivo ${m.id} verra' cancellato. Procedere?`,
                    delDispositivo,
                    m.id
                  )
                }
              >
              <FontAwesomeIcon icon={faTrashAlt} />
              </span>
              }
              {/* <Button
                color="primary"
                onClick={() =>
                  showActionPopUp(
                    "confirm",
                    "ATTENZIONE",
                    `Il mastello ${m.id} verra' cancellato. Procedere?`,
                    delMastello,
                    m.id
                  )
                }
              >
                delete
              </Button> */}
            </section>
          );
        
        
        tmp.push(m);
      });
      setDati(tmp);
    }
  }, [dispatch, dispositivi]);

  useEffect(() => {
    if (utenze === undefined) {
      dispatch(getUtenze());
    }
  }, [utenze]);

  useEffect(() => {
    if (userInfo === undefined) {
      dispatch(getUserInfo());
    }
  }, [userInfo]);



  const headers = [
    {
      label: "Tipo",
      field: "tipoDispositivo",
    },
    {
      label: "Marca",
      field: "marca",
    },
    {
      label: "Modello",
      field: "modello"
    },
    {
      label: "Codice Interno",
      field: "codiceIdentificativo",
    },
    // {
    //   label: "Valore QrCode",
    //   field: "qrCode.valore",
    // },
    {
      label: "Matricola",
      field: "matricola",
    },
    {
      label: "Azioni",
      field: "actions",
    },
  ];

  const refreshList = () => {
    dispatch(getUtenze());
    dispatch(getTipologieSpazzatura());
    dispatch(getDispositivi());
    if( userInfo!=null && userInfo.tipologiaUtente === "ENTE"){
        dispatch(getOperatore());
      }
  };

  return (
    <React.Fragment>
      <DispositivoModal tipodispositivo={tipoDispositivo} enti={enti} operatori={operatori}/>
      {loading ? (
        <div className="customTableContainer">
          <div className="container_spinner"><Spinner active  className="spinnerwebe"/></div>
        </div>
      ) : (
        <div className="customTableContainer">
          <Row className="justify-content-md-center">
            <Col lg="9" xl="9" md="9" sm="9" xs="12">
              <h1 className="">Dispositivi <FontAwesomeIcon icon={faRefresh} className="padding-lr-icon linkeffect" onClick={()=> refreshList()}/></h1>
            </Col>
            <Col lg="3" xl="3" md="3" sm="3" xs="12" className="text-right">
             
            {
              userInfo != null && userInfo.tipologiaUtente != "UTENTE" &&
              <>
              
              <Button
                color="primary"
                onClick={() =>
                  showActionModal(
                    "insert",
                    "Inserimento nuovo Dispositivo",
                    insertDispositivo
                  )
                }
                icon
                id="addNewDispositivo"
                style={{ marginBottom: "8px" }}
              >
                <FontAwesomeIcon icon={faTabletAlt} className="padding-lr-icon"/> {' '}Aggiungi
              </Button>
              <UncontrolledTooltip placement="top" target="addNewDispositivo">
              Aggiungi un nuovo mastello
            </UncontrolledTooltip>
            </>
            }  
              
              
            </Col>
          </Row>
          <CustomTable headers={headers} data={dati} />
        </div>
      )}
    </React.Fragment>
  );
};

export default Dispositivo;
