
import {
  GET_OPERATORE, GET_OPERATORE_SUCCESS, GET_OPERATORE_FAIL, INSERT_OPERATORE, INSERT_OPERATORE_SUCCESS, INSERT_OPERATORE_FAILED,
  UPDATE_OPERATORE, UPDATE_OPERATORE_SUCCESS, UPDATE_OPERATORE_FAILED,
  DELETE_OPERATORE, DELETE_OPERATORE_SUCCESS, DELETE_OPERATORE_FAILED
} from "../actions/operatori_actions";

import _ from "lodash"

export function OperatoreReducer(state = { loading: false, error: undefined, operatori: undefined }, action) {

  if (_.isEmpty(action) || _.isEmpty(action.type)) return state

  const newState = state

  switch (action.type) {

    case GET_OPERATORE:
      newState.loading = true
      newState.error = undefined
      newState.operatori = undefined
      break;
    case GET_OPERATORE_SUCCESS:
      newState.loading = false
      newState.error = undefined
      newState.operatori = action.payload
      break;
    case GET_OPERATORE_FAIL:
      newState.loading = false
      newState.error = action.payload
      newState.operatori = undefined
      break;
    case INSERT_OPERATORE:
      break;
    case INSERT_OPERATORE_SUCCESS:
      break;
    case INSERT_OPERATORE_FAILED:
      break;
    case UPDATE_OPERATORE:
      break;
    case UPDATE_OPERATORE_SUCCESS:
      break;
    case UPDATE_OPERATORE_FAILED:
      break;
    case DELETE_OPERATORE:
      break;
    case DELETE_OPERATORE_SUCCESS:
      break;
    case DELETE_OPERATORE_FAILED:
      break;
    default:
      break;
  }

  return Object.assign({}, state, newState)
}
