import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Spinner, Row,
  Col,
  Icon,
  UncontrolledTooltip, } from "design-react-kit";
import CustomTable from "../../table/component/CustomTable";
import _ from "lodash";
import { showPopup } from "../../popup/actions/popup_actions";
import OperatoriModal from "./OperatoriModal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRefresh, faEdit, faTrashAlt, faAddressCard } from "@fortawesome/free-solid-svg-icons";
import { deleteOperatore, getOperatore, insertOperatore, updateOperatore} from "../actions/operatori_actions";
import { showOperatoriModal } from "../actions/operatoriModal_actions";
import { getCollaborazioni } from "../../collaborazioni/actions/collaborazioni_actions";


const Operatori = () => {

  const dispatch = useDispatch();

  const delOperatore = (arg) => {
    dispatch(deleteOperatore(arg));
  };

  const showActionPopUp = (type, header, message, f, arg) => {
    dispatch(showPopup(type, header, message, f, arg));
  };

  const showActionModal = (type, header, f, obj) => {
    dispatch(showOperatoriModal(type, header, f, obj));
  };

  const operatori = useSelector((state) => state.operatori.operatori);
  const loading = useSelector((state) => state.operatori.loading);
  const [dati, setDati] = useState([]);

  const collaborazioni = useSelector((state) => state.collaborazioni.collaborazioni)

  useEffect(() => {
    if(collaborazioni === undefined) {
      dispatch(getCollaborazioni())
    }
  },[collaborazioni])

  useEffect(() => {
    if (operatori === undefined) {
      dispatch(getOperatore());
    } else {
      console.log(collaborazioni)
      const tmp = [];
      _.forEach(operatori, (ts) => {
        ts.enteriferimento = ts.ente.denominazione + " - " + ts.ente.tipoEnte;
        ts.identificativo = ts.login.username
        ts.actions = (
          <section>
            <span className="rounded-icon iconbottom"
              onClick={() => {
                showActionModal("edit", "Modifica Operatore", updateOperatore, ts);
              }}
            >
            <FontAwesomeIcon icon={faEdit} />
            </span>
            <span className="rounded-icon iconbottomcancel"
             onClick={() =>
              showActionPopUp(
                "confirm",
                "ATTENZIONE",
                `Opertaore con ${ts.id} verra' cancellato. Procedere?`,
                delOperatore,
                ts.id
              )
            }
            >
            <FontAwesomeIcon icon={faTrashAlt} />
            </span>
          </section>
        );
        tmp.push(ts);
      });
      setDati(tmp);
    }
  }, [operatori, dispatch]);

  const headers = [
    {
      label: "Ente",
      field: "enteriferimento"
    },
    {
      label: "Cognome",
      field: "cognome",
    },
    {
      label: "Nome",
      field: "nome",
    },
    {
      label: "Identificativo",
      field: "identificativo",
    },
    {
      label: "Email",
      field: "email",
    },
    {
      label: "Telefono",
      field: "telefono",
    },
    {
      label: "Azioni",
      field: "actions",
    },
  ];

  const refreshList = () => {
    dispatch(getOperatore());
    dispatch(getCollaborazioni())
  };

  return (
    <React.Fragment>
      <OperatoriModal enti={collaborazioni}/>
      {loading ? (
        <div className="customTableContainer">
          <div className="container_spinner"><Spinner active  className="spinnerwebe"/></div>
        </div>
      ) : (
        <div className="customTableContainer">
          <Row className="justify-content-md-center">
            <Col lg="9" xl="9" md="9" sm="9" xs="12">
              <h1 className="">Operatori <FontAwesomeIcon icon={faRefresh} className="padding-lr-icon linkeffect" onClick={()=> refreshList()}/></h1>
            </Col>
            <Col lg="3" xl="3" md="3" sm="3" xs="12" className="text-right">
              <Button
                color="primary"
                onClick={() =>
                  showActionModal(
                    "insert",
                    "Inserimento nuovo Operatore",
                    insertOperatore
                  )
                }
                icon
                id="addNewOperatore"
                style={{ marginBottom: "8px" }}
              >
                <FontAwesomeIcon icon={faAddressCard} className="padding-lr-icon"/> {' '}Aggiungi
              </Button>
              <UncontrolledTooltip placement="top" target="addNewOperatore">
                Aggiungi un nuovo Operatore
              </UncontrolledTooltip>
            </Col>
          </Row>
          <CustomTable headers={headers} data={dati} />
        </div>
      )}
    </React.Fragment>
  );
};

export default Operatori;
