import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  ModalFooter,
  Input,
  Col,
  Row
} from "design-react-kit";
import { hideTipologiaSpazzaturaModal } from "../actions/tipologiaSpazzaturaModal_actions"
import _ from "lodash";

const TipologiaSpazzaturaModal = () => {

  const dispatch = useDispatch();

  const [descrizione, setDescrizione] = useState();

  const tipologiaSpazzaturaToEdit = useSelector((state) => state.tipologiaSpazzaturaModal.obj);

  const type = useSelector((state) => state.tipologiaSpazzaturaModal.type);
  const header = useSelector((state) => state.tipologiaSpazzaturaModal.header);
  const show = useSelector((state) => state.tipologiaSpazzaturaModal.show);
  const f = useSelector((state) => state.tipologiaSpazzaturaModal.function);

  const [listaErrori, setListaErrori] = useState([]);
  let listaErroriTmp = [];

  function erroriForm(listaErroriInfo){
    return listaErroriInfo.map(function (errore) {
      return(
        <p className="text-mess-alert">{errore}</p>
      )})  
  }

  const confirm = () => {
    listaErroriTmp = [];
    if(descrizione == null || descrizione === '') listaErroriTmp.push("- Descrizione è un campo obbligatorio")
    if(listaErroriTmp.length == 0 ){
      switch (type) {
        case "insert":
          dispatch(f(descrizione));
          break;
        case "edit":
          dispatch(f(tipologiaSpazzaturaToEdit.id, descrizione));
          close();
          break;
        default:
          close();
      }
      setListaErrori([])
      close();

    }else{
      setListaErrori(listaErroriTmp)
    }
    
  };

  const close = () => dispatch(hideTipologiaSpazzaturaModal());

  useEffect(() => {
    if (type !== "edit" && tipologiaSpazzaturaToEdit === undefined) {
      setDescrizione(undefined);
    } else {
      setDescrizione(tipologiaSpazzaturaToEdit.descrizione);
    }
  }, [type, tipologiaSpazzaturaToEdit]);

  
  return (
    <div>
      <Modal isOpen={show} labelledBy="tipologiaSpazzaturaModal">
        <ModalHeader id="tipologiaSpazzaturaModal">{header}</ModalHeader>
        <ModalBody>
          <FormGroup>
            <div className="bootstrap-select-wrapper">
              <label htmlFor="selectDescrizione">Descrizione</label>
              <Input
                id="descrizione"
                onChange={(e) => {
                  setDescrizione(e.target.value);
                }}
                value={descrizione}
                type="text"
                placeholder="Inserisci descrizione"
                aria-label="Inserisci descrizione"
              />
            </div>
          </FormGroup>
          {listaErrori.length>0 &&
            <Row>
                <Col>
                  {erroriForm(listaErrori)}
                </Col>
            </Row>
          }
        </ModalBody>
        <ModalFooter>
          <span>
            <Button outline color='primary' onClick={() => close()}>ANNULLA</Button>
          </span>
          <span>
            <Button outline color='primary' onClick={() => confirm()}>CONFERMA</Button>
          </span>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default TipologiaSpazzaturaModal;
