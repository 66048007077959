import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Select,
  ModalFooter,
  Input,
  TextArea,
  Col,
  Row
} from "design-react-kit";
import { hideIngombrantiModal } from "../actions/richiestaIngombrantiModal_actions";
import { getUserInfo } from "../../dashboard/dashboard_Action";
import _ from "lodash";

const RichiestaIngombrantiModal = (props) => {
  const dispatch = useDispatch();

  const userInfo = useSelector(
    (state) => state.userInfo.userInfo
  );

  const [utenza, setUtenza] = useState();
  const [tipologiaSpazzatura, setTipologiaSpazzatura] = useState();

  const [tipoDispositivo, setTipoDispositivo] = useState()
  const [listaUtente, setListaUtente] = useState()

  const [utente, setUtente] = useState()

  const [comune, setComune] = useState()
  const [listaUtenze, setListaUtenze] = useState()
  const [listaTipoSpazzatura, setListaTipoSpazzatura] = useState()

  const [note, setNote] = useState()
  const [disponibilita, setDisponibilita] = useState()

  const tipologiaSpazzaturaModal = useSelector((state) => state.tipologiaSpazzaturaModal);
  const richiestaIngombrantiToEdit = useSelector((state) => state.richiestaIngombrantiModal.obj);

  const [attivaStato, setAttivaStato] = useState()
  const [statoRichiesta, setStatoRichiesta] = useState()
  const [listaStatoRichiesta, setListaStatoRichiesta] = useState()

  const type = useSelector((state) => state.richiestaIngombrantiModal.type);
  const header = useSelector((state) => state.richiestaIngombrantiModal.header);
  const show = useSelector((state) => state.richiestaIngombrantiModal.show);
  const f = useSelector((state) => state.richiestaIngombrantiModal.function);

  const [listaErrori, setListaErrori] = useState([]);
  let listaErroriTmp = [];

  function erroriForm(listaErroriInfo){
    return listaErroriInfo.map(function (errore) {
      return(
        <p className="text-mess-alert">{errore}</p>
      )})  
  }

  function close() {
    dispatch(hideIngombrantiModal());
  }

  function confirm() {
    listaErroriTmp = [];
    if( userInfo!=null && userInfo.tipologiaUtente != "UTENTE"){
      if(utente == null || utente.value === '') listaErroriTmp.push("- Utente è un campo obbligatorio")
      if(utenza == null || utenza.value === '') listaErroriTmp.push("- Utenza è un campo obbligatorio")
    }
    if(tipologiaSpazzatura == null || tipologiaSpazzatura.value === '') listaErroriTmp.push("- Tipologia Rifiuto è un campo obbligatorio")
    if(disponibilita == null || disponibilita === '') listaErroriTmp.push("- Disponibilità è un campo obbligatorio")
    if(note == null || note === '') listaErroriTmp.push("- Note è un campo obbligatorio")



    if(listaErroriTmp.length == 0){
      switch (type) {

        case "insert":
          listaErroriTmp = [];
          let utenteid = utente;
          let utenzaid = utenza;
          if( userInfo!=null && userInfo.tipologiaUtente === "UTENTE"){
            utenteid = userInfo.id
            utenzaid = utenza.value
          }else{
            utenteid = utente.value
            utenzaid = utenza.value
          }
          dispatch(f(utenteid, utenzaid, tipologiaSpazzatura.label, disponibilita, note));
          break;
        case "edit":
          listaErroriTmp = [];
          dispatch(f(richiestaIngombrantiToEdit.id, utente.id, utenza.id, tipologiaSpazzatura.descrizione, disponibilita, note, statoRichiesta.value));
          close();
          break;
        default:
          close();
      }
      setListaErrori([])
      close();

    }else{
      setListaErrori(listaErroriTmp)
    }
        
  }

  // const defaultTipoDispositivoValue = () => {
  //   switch (type) {
  //     case "insert":
  //       break;
  //     case "edit":
  //       return {
  //         label: labeldispositivo,
  //         value: dispositivoToEdit.tipoDispositivo
  //       };
  //     default:
  //       return {
  //         label: undefined,
  //         value: undefined,
  //       };
  //   }
  // };

  const defaultTipologiaSpazzaturaValue = () => {
    switch (type) {
      case "insert":
        break;
      case "edit":
        return {
          label: richiestaIngombrantiToEdit.tipologiaSpazzatura.descrizione,
          value: richiestaIngombrantiToEdit.tipologiaSpazzatura.id
        };
      default:
        return {
          label: undefined,
          value: undefined,
        };
    }
  };

  const defaultStatoRichiestaValue = () => {
    switch (type) {
      case "insert":
        break;
      case "edit":
        return {
          label: richiestaIngombrantiToEdit.statoRichiesta.descrizione,
          value: richiestaIngombrantiToEdit.statoRichiesta.id
        };
      default:
        return {
          label: undefined,
          value: undefined,
        };
    }
  };

  const defaultUtenteValue = () => {
    switch (type) {
      case "insert":
        break;
      case "edit":
        return {
          label: richiestaIngombrantiToEdit.utente.cognome,
          value: richiestaIngombrantiToEdit.utente.id
        };
      default:
        return {
          label: undefined,
          value: undefined,
        };
    }
  };

  const defaultUtenzeValue = () => {
    switch (type) {
      case "insert":
        break;
      case "edit":
        return {
          label: richiestaIngombrantiToEdit.utenza.comune.denominazione + " - " + richiestaIngombrantiToEdit.utenza.tipoImmobile.descrizione + " " + richiestaIngombrantiToEdit.utenza.indirizzo,
          value: richiestaIngombrantiToEdit.utenza.id
        };
      default:
        return {
          label: undefined,
          value: undefined,
        };
    }
  };

  const defaultOperatoreValue = () => {
    switch (type) {
      case "insert":
        break;
      case "edit":
        if(tipologiaSpazzaturaModal.operatore != null){
          return {
            label: tipologiaSpazzaturaModal.operatore.cognome + " " + tipologiaSpazzaturaModal.operatore.nome,
            value: tipologiaSpazzaturaModal.operatore.id
          };
        }
        
      default:
        return {
          label: undefined,
          value: undefined,
        };
    }
  };

  useEffect(() => {
    if (userInfo === undefined) {
      dispatch(getUserInfo());
    }
  }, [userInfo]);


  useEffect(() => {
    getStatoRichiesta()  
  }, [listaStatoRichiesta]);


  useEffect(() => {
      const tmp = [];
      _.forEach(props.utenti, (u) => {
        tmp.push({ value: u.id, label: u.cognome + " " + u.nome });
      });
      setListaUtente(tmp);
  }, [props.utenti]);

  useEffect(() => {
    const tmp = [];
    _.forEach(props.utenze, (u) => {
      tmp.push({ value: u.id, label: u.comune.denominazione + " - " + u.tipoImmobile.descrizione + " " + u.indirizzo, intestatario: u.intestatario.id });
    });
    setListaUtenze(tmp);
}, [props.utenze]);



useEffect(() => {
  const tmp = [];
  _.forEach(props.tipologieSpazzatura, (u) => {
    tmp.push({ value: u.id, label: u.descrizione });
  });
  setListaTipoSpazzatura(tmp);
}, [props.tipologieSpazzatura]);



  useEffect(() => {
    if (type !== "edit" && richiestaIngombrantiToEdit === undefined) {
      setTipoDispositivo(undefined)
      setNote(undefined)
      setTipologiaSpazzatura(undefined)
      setDisponibilita(undefined)
      if( userInfo!=null && userInfo.tipologiaUtente === "COMUNE"){
        setComune(userInfo.id);
      }
      if( userInfo!=null && userInfo.tipologiaUtente === "UTENTE"){
        setComune(userInfo.comuneResidenzaId);
      }
      setAttivaStato(false)

    } else {
      console.log(richiestaIngombrantiToEdit)
      setNote(richiestaIngombrantiToEdit.note)
      setTipologiaSpazzatura(richiestaIngombrantiToEdit.tipologiaSpazzatura)
      setDisponibilita(richiestaIngombrantiToEdit.disponibilita)
      setUtente(richiestaIngombrantiToEdit.utente)
      setUtenza(richiestaIngombrantiToEdit.utenza)
      setAttivaStato(true) 
      const tmp = [
        // {value: "INVIATA", label: "INVIATA"},
        {value: "ANNULLATA", label: "ANNULLATA"},
        {value: "RIFIUTATA", label: "RIFIUTATA"},
        {value: "ACCETTATA", label: "ACCETTATA"},
        {value: "EVASA", label: "EVASA"},
      ]
  
      setListaStatoRichiesta(tmp)
    }
  }, [type, tipologiaSpazzaturaModal]);



  const setUtenteRichiesta = (selectedOption) =>{

    const tmp = [];
      _.forEach(listaUtenze, (ut) => {

        if(ut.intestatario === selectedOption.value){
          tmp.push(ut)
        }

      })
      setListaUtenze(tmp)
  }

  const getStatoRichiesta = () =>{

    
  }

  return (
    <div>
      <Modal isOpen={show} labelledBy="dispositivoModal" size="lg">
        <ModalHeader id="dispositivoModal">{header}</ModalHeader>
        <ModalBody>
          <FormGroup>
          {userInfo!=null &&  (userInfo.tipologiaUtente === "COMUNE" ) &&
          <div className="bootstrap-select-wrapper form-group">
            {/* <label htmlFor="selectUtenza">Tipo Dispositivo</label> */}
            <Select
              id="ente"
              onChange={(selectedOption) => {
                setUtente(selectedOption);
                setUtenteRichiesta(selectedOption);
              }}
              options={listaUtente}
              defaultValue={defaultUtenteValue()}
              placeholder="Seleziona Utente"
              aria-label="Seleziona Utente"
            />
          </div>
          }
          {userInfo!=null && (userInfo.tipologiaUtente === "COMUNE" || userInfo.tipologiaUtente === "UTENTE" ) &&
          <div className="bootstrap-select-wrapper form-group">
            {/* <label htmlFor="selectUtenza">Tipo Dispositivo</label> */}
            <Select
              id="ente"
              onChange={(selectedOption) => {
                setUtenza(selectedOption);
              }}
              options={listaUtenze}
              defaultValue={defaultUtenzeValue()}
              placeholder="Seleziona Utenza"
              aria-label="Seleziona Utenza"
            />
          </div>
          }
          {userInfo!=null &&  (userInfo.tipologiaUtente === "COMUNE" || userInfo.tipologiaUtente === "UTENTE" ) &&
            <div className="bootstrap-select-wrapper form-group">
              {/* <label htmlFor="selectUtenza">Tipo Dispositivo</label> */}
              <Select
                id="tipodispositivo"
                onChange={(selectedOption) => {
                  setTipologiaSpazzatura(selectedOption);
                }}
                options={listaTipoSpazzatura}
                defaultValue={defaultTipologiaSpazzaturaValue()}
                placeholder="Seleziona Tipo Rifiuto"
                aria-label="Seleziona Tipo Rifiuto"
              />
            </div>
            }
            {
              attivaStato && 
              <div className="bootstrap-select-wrapper form-group">
                <Select
                  id="statoRichiesta"
                  onChange={(selectedOption) => {
                    setStatoRichiesta(selectedOption);
                  }}
                  options={listaStatoRichiesta}
                  defaultValue={defaultStatoRichiestaValue()}
                  placeholder="Seleziona Stato Richiesta"
                  aria-label="Seleziona Stato Richiesta"
                />
              </div>
            }
            {userInfo!=null &&  (userInfo.tipologiaUtente === "COMUNE" || userInfo.tipologiaUtente === "UTENTE" ) &&
            <>
            <Input
                id="disponibilita"
                onChange={(e) => {
                  setDisponibilita(e.target.value);
                }}
                value={disponibilita}
                type="text"
                placeholder="Inserisci Disponibilità"
                aria-label="Inserisci Disponibilità"
              />
              <TextArea
                id="note"
                onChange={(e) => {
                  setNote(e.target.value);
                }}
                value={note}
                rows={5}
                placeholder="Inserisci Note"
                aria-label="Inserisci Note"
              />
            </>
            
              }
              {/* <Input
                id="statorichiesta"
                onChange={(e) => {
                  setStatoRichiesta(e.target.value);
                }}
                value={codiceInterno}
                type="text"
                placeholder=" Codice Interno"
                aria-label="Inserisci Codice Interno"
              /> */}
          </FormGroup>
          
          {/* <FormGroup>
            <div className="bootstrap-select-wrapper">
              <label htmlFor="selectValoreQr">Valore QrCode</label>
              <Select
                id="valoreQr"
                onChange={(selectedOption) => {
                  setValoreQrCode(selectedOption);
                }}
                options={valoriQrOptions}
                defaultValue={defaultValoreQrValue()}
                placeholder="Seleziona valore qrCode"
                aria-label="Seleziona valore qrCode"
              />
            </div>
          </FormGroup> */}
           {listaErrori.length>0 &&
            <Row>
                <Col>
                  {erroriForm(listaErrori)}
                </Col>
            </Row>
          }
        </ModalBody>
        <ModalFooter>
          <span>
            <Button outline color='primary' onClick={() => close()}>ANNULLA</Button>
          </span>
          <span>
            <Button outline color='primary' onClick={() => confirm()}>CONFERMA</Button>
          </span>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default RichiestaIngombrantiModal;
