import CommonService from "../../../service/common_service";
import { put } from "redux-saga/effects"
import { showPopup } from "../../popup/actions/popup_actions";
import { GET_COLLABORAZIONI } from "../../collaborazioni/actions/collaborazioni_actions";

export const GET_COMUNE = "getComune"
export const GET_COMUNE_SUCCESS = "get_comune_success"
export const GET_COMUNE_FAIL = "get_comune_fail"

export function getComune() {
    return {
        type: GET_COMUNE
    }
}

const headers = {
    Authorization: 'Bearer ' + localStorage.getItem("jwtToken")
}

export function* getComuneSaga() {
    try {

        const request = {
            method: "GET",
            url: "/api/v1/comune/all",
            headers: headers
        }

        const response = yield CommonService.execute(request)
        yield put({
            type: GET_COMUNE_SUCCESS,
            payload: response.data.data
        })
        yield put({
            type: GET_COLLABORAZIONI,
        })

    } catch (error) {
        yield put({
            type: GET_COMUNE_FAIL,
            payload: error
        })
        yield put(
            showPopup(
                "error",
                "OPERAZIONE FALLITA",
                error.response.data.data.error
            )
        )
    }
}


export const INSERT_COMUNE = "insertComune"
export const INSERT_COMUNE_SUCCESS = "insertComune_success"
export const INSERT_COMUNE_FAILED = "insertComune_failed"

export function insertComune(denominazione, indirizzo, citta, provincia, regione, telefono, email, emailpec, login) {
    return {
        type: INSERT_COMUNE,
        payload: {
            denominazione: denominazione,
            indirizzo: indirizzo,
            citta: citta,
            provincia: provincia,
            regione: regione,
            telefono: telefono,
            email: email,
            emailPec: emailpec,
            login: login,
        }
    }
}

export function* insertComuneSaga(action) {
    try {
        const request = {
            url: "/api/v1/comune/insert",
            method: "POST",
            data: {
                denominazione: action.payload.denominazione,
                indirizzo: action.payload.indirizzo,
                citta: action.payload.citta,
                provincia: action.payload.provincia,
                regione: action.payload.regione,
                telefono: action.payload.telefono,
                email: action.payload.email,
                emailPec: action.payload.emailPec,
                login: action.payload.login,
            }
        }

        const response = yield CommonService.execute(request)
        yield put({
            type: GET_COMUNE
        })
        yield put(
            showPopup(
                "notify",
                "OPERAZIONE RIUSCITA",
                response.data.message
            )
        )

    } catch (error) {
        yield put({
            type: INSERT_COMUNE_FAILED,
            payload: error.response.data.data.error
        })
        yield put(
            showPopup(
                "error",
                "OPERAZIONE FALLITA",
                error.response.data.message
            )
        )
    }
}

export const UPDATE_COMUNE = "updateComune"
export const UPDATE_COMUNE_SUCCESS = "update_comune_success"
export const UPDATE_COMUNE_FAILED = "update_comune_fail"

export function updateComune(comuneId, denominazione, indirizzo, citta, provincia, regione, telefono, email, emailpec, login) {
    return {
        type: UPDATE_COMUNE,
        payload: {
            id: comuneId,
            denominazione: denominazione,
            indirizzo: indirizzo,
            citta: citta,
            provincia: provincia,
            regione: regione,
            telefono: telefono,
            email: email,
            emailpec: emailpec,
            login: login,
        }
    }
}

export function* updateComuneSaga(action) {
    try {
        const request = {
            url: "/api/v1/comune/update",
            method: "PUT",
            data: {
                id: action.payload.id,
                denominazione: action.payload.denominazione,
                indirizzo: action.payload.indirizzo,
                citta: action.payload.citta,
                provincia: action.payload.provincia,
                regione: action.payload.regione,
                telefono: action.payload.telefono,
                email: action.payload.email,
                emailPec: action.payload.emailpec,
                login: action.payload.login,
            }
        }

        const response = yield CommonService.execute(request)
        yield put({
            type: GET_COMUNE
        })
        yield put(
            showPopup(
                "notify",
                "OPERAZIONE RIUSCITA",
                response.data.message
            )
        )

    } catch (error) {
        yield put({
            type: UPDATE_COMUNE_FAILED,
            payload: error
        })
        yield put(
            showPopup(
                "error",
                "OPERAZIONE FALLITA",
                error.response.data.message
            )
        )
    }
}

export const DELETE_COMUNE = "deleteComune"
export const DELETE_COMUNE_SUCCESS = "delete_comune_success"
export const DELETE_COMUNE_FAILED = "delete_comune_fail"

export function deleteComune(comuneId) {
    return {
        type: DELETE_COMUNE,
        payload: comuneId
    }
}

export function* deleteComuneSaga(action) {
    try {
        const url = "/api/v1/comune/delete/" + action.payload
        const request = {
            method: "DELETE",
            url: url,
        }

        const response = yield CommonService.execute(request)
        yield put({
            type: GET_COMUNE
        })
        yield put(
            showPopup(
                "notify",
                "OPERAZIONE RIUSCITA",
                response.data.message
            )
        )  

    } catch (error) {
        yield put({
            type: DELETE_COMUNE_FAILED,
            payload: error.response.data.data.error
        })
        yield put(
            showPopup(
                "error",
                "OPERAZIONE FALLITA",
                error.response.data.data.error
            )
        )
    }
}
