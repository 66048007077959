export const SHOW_COLLABORAZIONE_MODAL = "showCollaborazioneModal"
export const HIDE_COLLABORAZIONE_MODAL = "hide_collaborazione_modal"

export function showCollaborazioneModal(type, header, f, obj){
  return {
      type: SHOW_COLLABORAZIONE_MODAL,
      payload:{
        type: type,
        header: header,
        function: f,
        obj: obj
    }
  }
}

export function hideCollaborazioneModal(){
  return {
      type: HIDE_COLLABORAZIONE_MODAL
  }
}


