import CommonService from "../../common/service/common_service"
import { put } from "redux-saga/effects"

export const LOGIN_REQUEST = "login_request"
export const LOGIN_SUCCESS = "login_success"
export const LOGIN_FAILED = "login_failed"

export function login(username, password ) {
    return {
        type: LOGIN_REQUEST,
        payload: {
            username: username,
            password: password
        }
    }

}

export function* loginSaga(action) {
    try {

        const request = {
            url: "/api/v1/auth",
            method: "POST",
            data: {
                username: action.payload.username,
                password: action.payload.password
            }
        }

        const response = yield CommonService.execute(request)
        localStorage.setItem("username", action.payload.username)
        console.log(response.data.data.jwtToken)
        localStorage.setItem("jwtToken", response.data.data.jwtToken)
        yield put({
            type: LOGIN_SUCCESS,
            payload: {
                username: action.payload.username,
                jwt: response.data.data.jwtToken
            }
        })

    } catch (error) {
        yield put({
            type: LOGIN_FAILED,
            payload: error.response.data.data.error
        })
    }
}

