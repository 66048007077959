import { SHOW_UTENZA_MODAL, HIDE_UTENZA_MODAL } from "../actions/utenzaModal_actions";
import _ from "lodash";

export function utenzaModalReducer(state = { type:'', header: undefined, show: false, function: undefined, obj: undefined, buttonMessage: undefined, body: undefined }, action) {
  if (_.isEmpty(action) || _.isEmpty(action.type)) return state
  const newState = state
  switch (action.type) {
    case SHOW_UTENZA_MODAL:
      newState.type = action.payload.type
      newState.header = action.payload.header
      newState.show = true
      newState.function = action.payload.function
      newState.obj = action.payload.obj
      newState.buttonMessage = action.payload.buttonMessage
      newState.body = action.payload.body
      break;
    case HIDE_UTENZA_MODAL:
      newState.type = ''
      newState.header = undefined
      newState.show = false
      newState.function = undefined
      newState.obj = undefined
      newState.buttonMessage = undefined
      newState.body = undefined
      break;
    default:
      break;
  }
  return Object.assign({}, state, newState)
}
