import React, { useEffect, useState } from "react";
import {
  Button,
  Spinner,
  Row,
  Col
} from "design-react-kit";
import _ from "lodash";
import DonutChart from "../../grafici/DonutChart";
import { getUserInfo} from "../dashboard_Action";
import { getUtenze } from "../../utenze/actions/utenze_actions"
import { getMastelli } from "../../mastello/actions/mastello_actions";
import { useDispatch, useSelector } from "react-redux";
import Mappa from "../../grafici/Mappa";

import { Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
  } from 'chart.js';
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
  );


const DashboardOperatore = () => {


  const dispatch = useDispatch();

  const userInfo = useSelector(
    (state) => state.userInfo.userInfo
  );
  let loading = true//useSelector((state) => state.userInfo.loading);
  const [dati, setDati] = useState();

  const utenze = useSelector((state) => state.utenze.utenze);

  const mastelli = useSelector((state) => state.mastello.mastelli)


  const countType = (type) => {
        if(type != null){
          const countTypes =  type.length
          return countTypes;
        }else{
          return -1
        }
        
  }

  useEffect(() => {
    if (userInfo === undefined) {
      dispatch(getUserInfo());
    } else {
      const tmp = [];
      _.forEach(userInfo, (ts) => {
        tmp.push(ts);
      });
      setDati(userInfo);
      console.log(userInfo)
      loading = false
    }
  }, [userInfo, utenze, mastelli, dispatch]);



  return (
    <React.Fragment>
      {userInfo == null ? (
        <div className="customTableContainer">
          <div className="container_spinner"><Spinner active  className="spinnerwebe"/></div>
        </div>
      ) : (
        <>
        <div className="customTableContainer">
        <>
          <Row>
            <Col xs="12" sm="4" md="4" className="map-profilo">
              <Mappa regione={userInfo.comune.regione} width="100%"/>
            </Col>
            <Col xs="12" sm="8" md="8">
              <h3 className="title-grafico">Operatore: {userInfo.cognome.toUpperCase()} {userInfo.nome.toUpperCase()}</h3>
              <h5 className="title-grafico">Telefono: {userInfo.ente.telefono}</h5>
              <h5 className="title-grafico">Email: {userInfo.ente.email}</h5>
              <br></br>
              <h5 className="title-grafico">Ente: {userInfo.ente.denominazione.toUpperCase()}</h5>
              <h5 className="title-grafico">Tipo Ente: {userInfo.ente.tipoEnte.toUpperCase()}</h5>
              <h5 className="title-grafico">Telefono: {userInfo.ente.telefono}</h5>
              <h5 className="title-grafico">Email: {userInfo.ente.email}</h5>
              <br></br>
              <h5 className="title-grafico">Comune Riferimento: {userInfo.comune.denominazione.toUpperCase()}</h5>
              <h5 className="title-grafico">Ubicazione: {userInfo.comune.indirizzo} - {userInfo.comune.citta} ({userInfo.comune.provincia})</h5>
              <h5 className="title-grafico">Telefono: {userInfo.comune.telefono}</h5>
              <h5 className="title-grafico">Email: {userInfo.comune.email}</h5>
              <h5 className="title-grafico">Pec: {userInfo.comune.emailPec}</h5>
            </Col>
          </Row>
          <Row>
            <Col xs="12" sm="8" md="8">
              
            </Col>
          </Row>
        </>
        </div>
        </>
      )}
        
    </React.Fragment>
  );
};

export default DashboardOperatore;
