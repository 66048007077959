import {
  GET_SCONTISTICA, GET_SCONTISTICA_SUCCESS, GET_SCONTISTICA_FAIL,
  INSERT_SCONTISTICA, INSERT_SCONTISTICA_SUCCESS, INSERT_SCONTISTICA_FAILED,
  UPDATE_SCONTISTICA, UPDATE_SCONTISTICA_SUCCESS, UPDATE_SCONTISTICA_FAILED,
  DELETE_SCONTISTICA, DELETE_SCONTISTICA_SUCCESS, DELETE_SCONTISTICA_FAILED,
  STAMPA_SCONTISTICA, STAMPA_SCONTISTICA_SUCCESS, STAMPA_SCONTISTICA_FAILED
} from "../actions/scontistica_actions";
import _ from "lodash";

export function scontisticaReducer(state = { loading: false, error: undefined, insertSuccessData: undefined, scontistica: undefined }, action) {

  if (_.isEmpty(action) || _.isEmpty(action.type)) return state

  const newState = state

  switch (action.type) {

    case GET_SCONTISTICA:
      newState.loading = true
      newState.error = undefined
      newState.scontistica = undefined
      break;
    case GET_SCONTISTICA_SUCCESS:
      newState.loading = false
      newState.error = undefined
      newState.scontistica = action.payload
      break;
    case GET_SCONTISTICA_FAIL:
      newState.loading = false
      newState.error = action.payload
      newState.scontistica = undefined
      break;
    case INSERT_SCONTISTICA:
      break;
    case INSERT_SCONTISTICA_SUCCESS:
      break;
    case INSERT_SCONTISTICA_FAILED:
      break;
    case UPDATE_SCONTISTICA:
      break;
    case UPDATE_SCONTISTICA_SUCCESS:
      break;
    case UPDATE_SCONTISTICA_FAILED:
      break;
    case DELETE_SCONTISTICA:
      break;
    case DELETE_SCONTISTICA_SUCCESS:
      break;
    case DELETE_SCONTISTICA_FAILED:
      break;
    case STAMPA_SCONTISTICA:
      break;
    case STAMPA_SCONTISTICA_SUCCESS:
      break;
    case STAMPA_SCONTISTICA_FAILED:
      break;
    default:
      break;
  }

  return Object.assign({}, state, newState)
}