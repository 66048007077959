export const SHOW_CALENDARIO_MODAL = "showCalendarioModal"
export const HIDE_CALENDARIO_MODAL = "hide_calendario_modal"

export function showCalendarioModal(type, header, f, obj){
  return {
      type: SHOW_CALENDARIO_MODAL,
      payload:{
        type: type,
        header: header,
        function: f,
        obj: obj
    }
  }
}

export function hideCalendarioModal(){
  return {
      type: HIDE_CALENDARIO_MODAL
  }
}


