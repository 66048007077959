import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  ModalFooter,
  Select,
  Col,
  Row
} from "design-react-kit";
import { hideCollaborazioneModal } from "../actions/collaborazioneModal_actions";
import _ from "lodash";

const CollaborazioneModal = (props) => {
  const dispatch = useDispatch();

  const [ente, setEnte] = useState({});

  const [options, setOptions] = useState();

  const collaborazioneToEdit = useSelector(
    (state) => state.collaborazioneModal.obj
  );

  const type = useSelector((state) => state.collaborazioneModal.type);
  const header = useSelector((state) => state.collaborazioneModal.header);
  const show = useSelector((state) => state.collaborazioneModal.show);
  const f = useSelector((state) => state.collaborazioneModal.function);

  const [listaErrori, setListaErrori] = useState([]);
  let listaErroriTmp = [];

  function erroriForm(listaErroriInfo){
    return listaErroriInfo.map(function (errore) {
      return(
        <p className="text-mess-alert">{errore}</p>
      )})  
  }

  const confirm = () => {
    listaErroriTmp = []
    if(ente == null || ente.value == '')listaErroriTmp.push("- Ente è un campo obbligatorio")
    if(listaErroriTmp.length == 0){
      switch (type) {
        case "insert":
          dispatch(f(ente.value));
          break;
        case "edit":
          console.log("coll", collaborazioneToEdit);
          close();
          break;
        default:
          close();
      }
      setListaErrori([])
      close();

    }else{
      setListaErrori(listaErroriTmp)
    }
    
  };

  const close = () => dispatch(hideCollaborazioneModal());

  const defaultEnteValue = () => {
    switch (type) {
      case "insert":
        break;
      case "edit":
        return {
          label: collaborazioneToEdit.ente.denominazione,
          value: collaborazioneToEdit.ente.id,
        };
      default:
        return {
          label: undefined,
          value: undefined,
        };
    }
  };

  useEffect(() => {
    if (props.options === undefined) {
      setOptions([]);
    } else {
      const tmp = [];
      _.forEach(props.options, (e) => {
        tmp.push({ value: e.id, label: e.denominazione });
      });
      setOptions(tmp);
    }
  }, [props.options]);

  return (
    <div>
      <Modal isOpen={show} labelledBy="collaborazioneModal">
        <ModalHeader id="collaborazioneModal">{header}</ModalHeader>
        <ModalBody>
          <FormGroup>
            <div className="bootstrap-select-wrapper">
              <label htmlFor="selectEnte">Ente</label>
              <Select
                classNamePrefix="react-select"
                id="ente"
                onChange={(selectedOption) => {
                  setEnte(selectedOption);
                }}
                options={options}
                defaultValue={defaultEnteValue()}
                placeholder="Seleziona ente"
                aria-label="Seleziona ente"
              />
            </div>
          </FormGroup>
          {listaErrori.length>0 &&
            <Row>
                <Col>
                  {erroriForm(listaErrori)}
                </Col>
            </Row>
          }
        </ModalBody>
        <ModalFooter>
          <span>
            <Button outline color="primary" onClick={() => close()}>
              ANNULLA
            </Button>
          </span>
          <span>
            <Button outline color="primary" onClick={() => confirm()}>
              CONFERMA
            </Button>
          </span>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default CollaborazioneModal;
