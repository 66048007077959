import CommonService from "../../../service/common_service";
import { put } from "redux-saga/effects"
import { showPopup } from "../../popup/actions/popup_actions";

export const GET_OPERATORE = "getOperatore"
export const GET_OPERATORE_SUCCESS = "get_operatore_success"
export const GET_OPERATORE_FAIL = "get_operatore_fail"

export function getOperatore() {
    return {
        type: GET_OPERATORE
    }
}

const headers = {
    Authorization: 'Bearer ' + localStorage.getItem("jwtToken")
}

export function* getOperatoreSaga() {
    try {

        const request = {
            method: "GET",
            url: "/api/v1/operatore/cerca",
            headers: headers
        }

        const response = yield CommonService.execute(request)
        yield put({
            type: GET_OPERATORE_SUCCESS,
            payload: response.data
        })

    } catch (error) {
        yield put({
            type: GET_OPERATORE_FAIL,
            payload: error
        })
        yield put(
            showPopup(
                "error",
                "OPERAZIONE FALLITA",
                error.response.data.data.error
            )
        )
    }
}


export const INSERT_OPERATORE = "insertOperatore"
export const INSERT_OPERATORE_SUCCESS = "insertOperatore_success"
export const INSERT_OPERATORE_FAILED = "insertOperatore_failed"

export function insertOperatore(cognome, nome, telefono, email, login, ente, comune) {
    return {
        type: INSERT_OPERATORE,
        payload: {
            cognome: cognome,
            nome: nome,
            telefono: telefono,
            email: email, // SUPERUSER -> deve inserire anche l'id del comune per creare una nuova utenza
            login: login,
            ente: ente,
            comune: comune,
        }
    }
}

export function* insertOperatoreSaga(action) {
    try {
        const request = {
            url: "/api/v1/operatore/insert",
            method: "POST",
            data: {
                cognome: action.payload.cognome,
                nome: action.payload.nome,
                telefono: action.payload.telefono,
                email: action.payload.email, // SUPERUSER -> deve inserire anche l'id del comune per creare una nuova utenza
                login: action.payload.login,
                ente: action.payload.ente.value,
                comune: action.payload.comune,
            }
        }

        const response = yield CommonService.execute(request)
        yield put({
            type: GET_OPERATORE,
            payload: response.data
        })
        yield put({
            type: GET_OPERATORE
        })
        yield put(
            showPopup(
                "notify",
                "OPERAZIONE RIUSCITA",
                response.data.message
            )
        )

    } catch (error) {
        yield put({
            type: INSERT_OPERATORE_FAILED,
            payload: error.response.data.data.error
        })
        yield put(
            showPopup(
                "error",
                "OPERAZIONE FALLITA",
                error.response.data.message
            )
        )
    }
}

export const UPDATE_OPERATORE = "updateOperatore"
export const UPDATE_OPERATORE_SUCCESS = "update_operatore_success"
export const UPDATE_OPERATORE_FAILED = "update_operatore_fail"

export function updateOperatore(operatoreId, cognome, nome, telefono, email, login, ente, comune) {
    return {
        type: UPDATE_OPERATORE,
        payload: {
            operatoreId: operatoreId,
            cognome: cognome,
            nome: nome,
            telefono: telefono,
            email: email,
            login: login,
            ente: ente,
            comune: comune,
        }
    }
}

export function* updateOperatoreSaga(action) {
    try {
        const request = {
            url: "/api/v1/operatore/update",
            method: "PUT",
            data: {
                id: action.payload.operatoreId,
                cognome: action.payload.cognome,
                nome: action.payload.nome,
                telefono: action.payload.telefono,
                email: action.payload.email,
                login: action.payload.login,
                ente: action.payload.ente,
                comune: action.payload.comune,
            }
        }

        const response = yield CommonService.execute(request)
        yield put({
            type: GET_OPERATORE,
            payload: response.data.data
        })
        yield put(
            showPopup(
                "notify",
                "OPERAZIONE RIUSCITA",
                response.data.message
            )
        )

    } catch (error) {
        yield put({
            type: UPDATE_OPERATORE_FAILED,
            payload: error
        })
        yield put(
            showPopup(
                "error",
                "OPERAZIONE FALLITA",
                error.response.data.message
            )
        )
    }
}

export const DELETE_OPERATORE = "deleteOperatore"
export const DELETE_OPERATORE_SUCCESS = "delete_operatore_success"
export const DELETE_OPERATORE_FAILED = "delete_operatore_fail"

export function deleteOperatore(operatoreId) {
    return {
        type: DELETE_OPERATORE,
        payload: operatoreId
    }
}

export function* deleteOperatoreSaga(action) {
    try {
        const url = "/api/v1/operatore/delete/" + action.payload
        const request = {
            method: "DELETE",
            url: url,
        }

        const response = yield CommonService.execute(request)
        yield put({
            type: GET_OPERATORE
        })
        yield put({
            type: GET_OPERATORE
        })
        yield put(
            showPopup(
                "notify",
                "OPERAZIONE RIUSCITA",
                response.data.message
            )
        )  

    } catch (error) {
        yield put({
            type: DELETE_OPERATORE_FAILED,
            payload: error.response.data.data.error
        })
        yield put(
            showPopup(
                "error",
                "OPERAZIONE FALLITA",
                error.response.data.data.error
            )
        )
    }
}
