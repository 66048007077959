import * as d3 from "d3";
import React, { Component } from 'react'
import {
    Button,
    Spinner,
    Row,
    Col
  } from "design-react-kit";

import italia from '../grafici/italia_regioni2.svg'

class Mappa extends Component {
  
  constructor(props) {
    super(props);
    this.chRef = React.createRef();
  }

  getRegioneCode(regione) {

    if(regione === null || regione === undefined) regione = "" 
    var regcode = ""
    if("abruzzo" === regione.toLowerCase()) regcode = "#polygon6006"
    if("basilicata" === regione.toLowerCase()) regcode = "#polygon5875"
    if("calabria" === regione.toLowerCase()) regcode = "#polygon5964"
    if("campania" === regione.toLowerCase()) regcode = "#polygon5912"
    if("emilia romagna" === regione.toLowerCase()) regcode = "#polygon6015"
    if("friuli venezia giulia" === regione.toLowerCase()) regcode = "#polygon5921"
    if("lazio" === regione.toLowerCase()) regcode = "#polygon5972"
    if("liguria" === regione.toLowerCase()) regcode = "#polygon6022"
    if("lombardia" === regione.toLowerCase()) regcode = "#polygon5885"
    if("marche" === regione.toLowerCase()) regcode = "#polygon6033"
    if("molise" === regione.toLowerCase()) regcode = "#polygon6012"
    if("piemonte" === regione.toLowerCase()) regcode = "#polygon5961"
    if("puglia" === regione.toLowerCase()) regcode = "#polygon5894"
    if("sardegna" === regione.toLowerCase()) regcode = "#polygon5840"
    if("sicilia" === regione.toLowerCase()) regcode = "#polygon5926"
    if("toscana" === regione.toLowerCase()) regcode = "#polygon5987"
    if("trentino alto adige" === regione.toLowerCase()) regcode = "#polygon5909"
    if("umbria" === regione.toLowerCase()) regcode = "#polygon5880"
    if("valle d'aosta" === regione.toLowerCase()) regcode = "#polygon6009"
    if("veneto" === regione.toLowerCase()) regcode = "#polygon5969"


    return regcode
  }

  componentDidMount() {

    const regionecodice = this.getRegioneCode(this.props.regione);

    //console.log(regionecodice)
    
    let dimensione = "100%";
    if(this.props.width != null) dimensione = this.props.width;
    this.mappaItalia(regionecodice, dimensione)

    d3.select("#map")
    .append("div")
    .attr("class", "tooltip-chart");
  }

    


  mappaItalia(regionecodice, dimensione) {
    d3.svg(italia).then(function(xml) {
    d3.selectAll('#map svg').remove();
    d3.selectAll('#map div').empty();
    var mappa = d3.select("#map").node().appendChild(xml.documentElement);
    d3.selectAll('#map svg').attr("width", dimensione);
    d3.select(regionecodice).style("fill", "#0059b3");
    });


  }

  render(){
    return(
        <>
        <Row>
          <Col md={12}>
            <div className="chart-container" id="map" ></div>
          </Col>
        </Row>
        </>
    )
  }
}

  
export default Mappa;