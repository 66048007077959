import React, { useEffect } from "react";
import { Button, Icon } from "design-react-kit";
import { useDispatch, useSelector} from "react-redux";
import { logout } from "../../logout/actions/logout_actions";
import { Navigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAddressCard, faEdit, faSignOut } from '@fortawesome/free-solid-svg-icons'

const Logout = () => {

  const dispatch = useDispatch();

  const success = useSelector((state) => state.logout.success);

  const handleLogout = () => {
    console.log("logout");
    localStorage.clear()
    localStorage.removeItem("jwtToken")
    dispatch(logout())
    return <Navigate to="/" />;
  }

  const logoutButton = () => {
    return (
      <Button color="primary" icon onClick={() => handleLogout()} type="link" href="/">
        <span className="rounded-icon">
          <FontAwesomeIcon icon={faSignOut} className="color-icon-brand"/>
        </span>
        <span>Esci</span>
      </Button>
    );
  };

  useEffect(() =>{
    if (success === true) {
      //return <Navigate to="/" />;
    }
  },[success])
  

  return logoutButton();
};

export default Logout;
