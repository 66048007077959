import {
    UPDATE_PWD, UPDATE_PWD_SUCCESS, UPDATE_PWD_FAILED
  } from "./pwd_action"
  import _ from "lodash"
  
  export function pwdReducer(state = { loading: false, error: undefined, userInfo: undefined }, action) {
  
    if (_.isEmpty(action) || _.isEmpty(action.type)) return state
  
    const newState = state
  
    switch (action.type) {
  
      case UPDATE_PWD:
        newState.loadingInterno = true
        // newState.error = undefined
        // newState.userInfo = undefined
        break;
      case UPDATE_PWD_SUCCESS:
        newState.loadingInterno = false
        // newState.error = undefined
        // newState.userInfo = action.payload
        break;
      case UPDATE_PWD_FAILED:
        newState.loading = false
        // newState.error = action.payload
        // newState.userInfo = undefined
        break;
      default:
        break;
    }
  
    return Object.assign({}, state, newState)
  }
  