import React, { useState, useEffect } from "react";
import {
  Input,
  Card,
  CardBody,
  CardFooter,
  Button,
  CardHeader,
  Row,
  Col,
  Spinner,
} from "design-react-kit";
import _ from "lodash";
import { login } from "../actions/password_actions";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import jwtDecode from 'jwt-decode';
import CustomHeader from "../../layout/components/CustomHeader";
import { resetPassword } from "../actions/password_actions";
import PopUp from "../../common/components/popup/components/PopUp";



const ResetPassword = (props) => {
  
  const dispatch = useDispatch();

  const [username, setUsername] = useState();
  const [errors, setErrors] = useState({});

  const success = useSelector((state) => state.login.success);
  const loading = useSelector((state) => state.login.loading);
  const error = useSelector((state) => state.login.error);


  function handleClick() {
    dispatch(resetPassword(username));
  }

  useEffect(() => {
    checkErrors();
  }, [username]);

  function checkErrors() {
    const tmp = {};
    if (username === undefined || username === "") {
      tmp.username = "Username non puo' essere vuota";
    }
    setErrors(tmp);
  }

  function checkDisabled() {
    return !_.isEmpty(errors);
  }


    return (
      <>
      <PopUp />
      <CustomHeader loggato={false}></CustomHeader>
      <div className="container-fluid container-wide">
        <Row className="login-row">
          <Col xs="12" sm="3" md="3" />
          <Col xs="12" sm="6" md="6">
            <Card className="shadowed">
              <CardHeader>Recupera Password</CardHeader>
              <CardBody>
                <form>
                <Input
                    label="Username"
                    type="text"
                    placeholder="Username"
                    id="username"
                    name="username"
                    onChange={(e) => {
                      setUsername(e.target.value);
                    }}
                    invalid={!_.isEmpty(errors.username)}
                    infoText={errors.username ? errors.username : undefined}
                  />
                  {/* <Input
                    label="Conferma Password"
                    type="password"
                    placeholder="Conferma Password"
                    id="confermaPassword"
                    name="confermaPassword"
                    onChange={(e) => {
                      setUsername(e.target.value);
                    }}
                    invalid={!_.isEmpty(errors.username)}
                    infoText={errors.username ? errors.username : undefined}
                  /> */}
                  {/* {errors.diverse &&
                  <p>{errors.diverse}</p>
                  } */}
                </form>
              </CardBody>
              <CardFooter>
                {!_.isEmpty(error) && <div>{error}</div>}
                {loading ? (
                  <Spinner active />
                ) : (
                  <Button
                    color="primary"
                    onClick={handleClick}
                    disabled={checkDisabled()}
                  >
                    Reset Password
                  </Button>
                )}
              </CardFooter>
            </Card>
          </Col>
          <Col xs="12" sm="3" md="3"/>
        </Row>
      </div>
      
      </>
      
    );
};

export default ResetPassword;
