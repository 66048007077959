import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Spinner,
  Row,
  Col,
  Icon,
  UncontrolledTooltip,
} from "design-react-kit";
import {
  getScontistica,
  insertScontistica,
  updateScontistica,
  deleteScontistica,
  stampaScontistica
} from "../actions/scontistica_actions";
import CustomTable from "../../table/component/CustomTable";
import _ from "lodash";
import { showPopup } from "../../popup/actions/popup_actions";
import { showScontisticaModal } from "../actions/scontisticaModal_actions";
import ScontisticaModal from "./ScontisticaModal";
import { getUtenze } from "../../utenze/actions/utenze_actions";
import { getTipologieSpazzatura } from "../../tipologiaSpazzatura/actions/tipologiaSpazzatura_actions";
import { getQrCodes } from "../../qrCode/actions/qrCode_actions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faRefresh, faArchive, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { getUserInfo } from "../../dashboard/dashboard_Action";
import PopUp from "../../popup/components/PopUp";

const Scontistica = () => {

  const dispatch = useDispatch();

  const userInfo = useSelector(
    (state) => state.userInfo.userInfo
  );


  const scontistica = useSelector((state) => state.scontistica.scontistica);

  const mastelli = useSelector((state) => state.mastello.mastelli);
  const loading = useSelector((state) => state.scontistica.loading);
  const [dati, setDati] = useState([]);

  const utenze = useSelector((state) => state.utenze.utenze);
  const tipologieSpazzatura = useSelector((state) => state.tipologiaSpazzatura.tipologieSpazzatura);
  const qrCodes = useSelector((state) => state.qrCode.qrCodes);

  const delScontistica = (arg) => {
    console.log("delete button")
    dispatch(deleteScontistica(arg));
  };

  const stampaRicevutaScontistica = (arg) => {
    console.log(arg)

    dispatch(stampaScontistica(String(arg.utenza.id), arg.utenza.comune.denominazione, 
      arg.utenza.comune.indirizzo, new Date().toString(), 
      arg.qrCode.valore, arg.tipologiaSpazzatura.descrizione, arg.utenza.intestatario.cognome +" "+ arg.utenza.intestatario.nome,
      arg.utenza.intestatario.userName,arg.utenza.indirizzo,arg.utenza.comune.citta ));
  };

  const showActionPopUp = (type, header, message, f, arg) => {
    dispatch(showPopup(type, header, message, f, arg));
  };

  const showActionModal = (type, header, f, obj) => {
    dispatch(showScontisticaModal(type, header, f, obj));
  };

  useEffect(() => {
    if (tipologieSpazzatura === undefined) {
      dispatch(getTipologieSpazzatura());
    }
  }, [tipologieSpazzatura]);
  
  useEffect(() => {
    if (scontistica === undefined) {
      dispatch(getScontistica());
      //dispatch(getUserInfo());
    } else {
      //dispatch(getUserInfo());
      const tmp = [];
      _.forEach(scontistica, (m) => {
        // m.proprietario = m.utenza.intestatario.userName + " - " + m.utenza.intestatario.cognome + " " + m.utenza.intestatario.nome;
        // m.comune = m.utenza.comune.denominazione;
        if(userInfo != null && (userInfo.tipologiaUtente === "COMUNE" || userInfo.tipologiaUtente === "ADMIN" )){
          m.actions = (
            <section>
              {/* <Button
                color="primary"
                onClick={() => {
                  showActionModal("edit", "Modifica Scontistica", updateScontistica, m);
                }}
              >
                edit
              </Button>{" "} */}
              <span className="rounded-icon iconbottom"
                onClick={() => {
                  showActionModal("edit", "Modifica Scontistica", updateScontistica, m);
                }}
              >
              <FontAwesomeIcon icon={faEdit} />
              </span>
              <span className="rounded-icon iconbottomcancel"
                onClick={() =>
                  showActionPopUp(
                    "confirm",
                    "ATTENZIONE",
                    `Le tariffe per ${m.tipologiaSpazzatura.descrizione} verranno cancellate. Procedere?`,
                    delScontistica,
                    m.id
                  )
                }
              >
              <FontAwesomeIcon icon={faTrashAlt} />
              </span>
            </section>
          );
        }else{
          m.actions =(
            <span className="rounded-icon iconbottom"></span>
          )
        }
        
        tmp.push(m);
      });
      setDati(tmp);
    }
  }, [dispatch, scontistica]);

  useEffect(() => {
    if (userInfo === undefined) {
      dispatch(getUserInfo());
    }
  }, [userInfo]);

  const refreshList = () => {
    dispatch(getScontistica());
    dispatch(getTipologieSpazzatura());
  };

  const headers = [
    {
      label: "Comune",
      field: "comune.denominazione",
    },
    {
      label: "Tipo Raccolta",
      field: "tipologiaSpazzatura.descrizione",
    },
    {
      label: "Tariffa Piena",
      field: "tariffa"
    },
    {
      label: "Tariffa Scontata",
      field: "sconto",
    },
    {
      label: "Azioni",
      field: "actions",
    },
  ];

  return (
    <React.Fragment>
      <ScontisticaModal
        tipologieSpazzaturaOptions={tipologieSpazzatura}
        comune={userInfo}
        scontistica={scontistica}
      />
      <PopUp></PopUp>
      {loading ? (
        <div className="customTableContainer">
          <div className="container_spinner"><Spinner active  className="spinnerwebe"/></div>
        </div>
      ) : (
        <div className="customTableContainer">
          <Row className="justify-content-md-center">
            <Col lg="9" xl="9" md="9" sm="9" xs="12">
              <h1 className="">Tariffe e Scontistica <FontAwesomeIcon icon={faRefresh} className="padding-lr-icon linkeffect" onClick={()=> refreshList()}/></h1>
            </Col>
            <Col lg="3" xl="3" md="3" sm="3" xs="12" className="text-right">
             
            {
              userInfo != null && userInfo.tipologiaUtente === "COMUNE" &&
              <>
              {(scontistica != null && tipologieSpazzatura != null) && (scontistica.length < tipologieSpazzatura.length) &&
              <>
                <Button
                  color="primary"
                  onClick={() =>
                    showActionModal(
                      "insert",
                      "Inserimento Tariffe Raccolta",
                      insertScontistica
                    )
                  }
                  icon
                  id="addNewScontistica"
                  style={{ marginBottom: "8px" }}
                >
                  <FontAwesomeIcon icon={faArchive} className="padding-lr-icon"/> {' '}Aggiungi
                </Button>
                <UncontrolledTooltip placement="top" target="addNewScontistica">
                Aggiungi nuova tariffa o sconto
              </UncontrolledTooltip>
              </>
              }
              {(scontistica != null && tipologieSpazzatura != null) && (scontistica.length === tipologieSpazzatura.length) &&
              <>
                <Button
                  color="primary"
                  onClick={() =>
                    showActionPopUp(
                      "notify",
                      "INFORMAZIONE",
                      "Tipologie Rifiuti tutte presenti. E' possibile solo modificare le tariffe",
                      null,
                    )
                  }
                  icon
                  id="addNewScontistica"
                  style={{ marginBottom: "8px" }}
                >
                  <FontAwesomeIcon icon={faArchive} className="padding-lr-icon"/> {' '}Aggiungi
                </Button>
                <UncontrolledTooltip placement="top" target="addNewScontistica">
                Aggiungi nuova tariffa o sconto
              </UncontrolledTooltip>
              </>
              }
              
            </>
            }  
              
              
            </Col>
          </Row>
          <CustomTable headers={headers} data={dati} />
        </div>
      )}
    </React.Fragment>
  );
};

export default Scontistica;
