import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Spinner,
  Row,
  Col,
  Icon,
  UncontrolledTooltip,
  Table,
} from "design-react-kit";
import { getRichiesteIngombranti, insertIngombranti, updateIngombranti, deleteIngombranti } from "../actions/richiestaIngombranti_actions";
import CustomTable from "../../table/component/CustomTable";
import _ from "lodash";
import { showPopup } from "../../popup/actions/popup_actions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileText, faEdit, faTrashAlt, faRefresh } from "@fortawesome/free-solid-svg-icons";
import RichiestaIngombrantiModal from "./RichiestaIngombrantiModal";
import {showIngombrantiModal} from "../actions/richiestaIngombrantiModal_actions"
import { getUtentiComuneUtenza } from "../../utente/actions/utente_actions";
import { getUserInfo } from "../../dashboard/dashboard_Action";
import { getUtenze } from "../../utenze/actions/utenze_actions";
import { getTipologieSpazzatura } from "../../tipologiaSpazzatura/actions/tipologiaSpazzatura_actions";

const RichiestaIngombranti = (props) => {
  const dispatch = useDispatch();

  const richiesteIngombranti = useSelector(
    (state) => state.richiestaIngombranti.richiesteIngombranti
  );
  const loading = useSelector(
    (state) => state.richiestaIngombranti.loading
  );
  const [dati, setDati] = useState([]);

  const delRichiestaIngombranti = (arg) => {
    dispatch(deleteIngombranti(arg));
  };

  const showActionPopUp = (type, header, message, f, arg) => {
    dispatch(showPopup(type, header, message, f, arg));
  };

  const showActionModal = (type, header, f, obj) => {
    dispatch(showIngombrantiModal(type, header, f, obj));
  };

  const utenze = useSelector((state) => state.utenze.utenze);
  const userInfo = useSelector(
    (state) => state.userInfo.userInfo
  );
  const utenti = useSelector((state) => state.utente.utentiComuneUtenza);

  const tipologieSpazzatura = useSelector(
    (state) => state.tipologiaSpazzatura.tipologieSpazzatura
  );

  useEffect(() => {
    if (utenze === undefined) {
      dispatch(getUtenze());
    }
  }, [utenze]);

  useEffect(() => {
    if (tipologieSpazzatura === undefined) {
      dispatch(getTipologieSpazzatura());
    }
  }, [tipologieSpazzatura]);

  useEffect(() => {
    if (userInfo === undefined) {
      dispatch(getUserInfo());
    }
  }, [userInfo]);

  useEffect(() => {
    if(userInfo!=null &&  (userInfo.tipologiaUtente === "COMUNE" || userInfo.tipologiaUtente === "ENTE" || userInfo.tipologiaUtente === "ADMIN"  ) ){
      if (utenti === undefined) {
        dispatch(getUtentiComuneUtenza());
      }
    }
  }, [utenti]);

  console.log(loading)

  useEffect(() => {
    if (richiesteIngombranti === undefined) {
      dispatch(getRichiesteIngombranti());
    } else {
      const tmp = [];
      _.forEach(richiesteIngombranti, (ri) => {
        ri.proprietario = ri.utente.userName + " - " + ri.utente.cognome + " " + ri.utente.nome;
        ri.comune = ri.utenza.comune.denominazione;
        ri.actions = (
          <section>
              <span className="rounded-icon iconbottom"
                onClick={() =>
                  dispatch(showPopup(
                    "notify",
                    "Dati Dispositivo",
                    infoRichiesta(ri)
                  ))
                }
              >
              <FontAwesomeIcon icon={faFileText} />
              </span>
            <span className="rounded-icon iconbottom"
               onClick={() => {
                showActionModal(
                  "edit",
                  "Modifica Richiesta Ingombranti",
                  updateIngombranti,
                  ri
                ); // updateRichiestaIngombranti
              }}
            >
            <FontAwesomeIcon icon={faEdit} />
            </span>
            {
              userInfo!=null &&  (userInfo.tipologiaUtente === "COMUNE" || userInfo.tipologiaUtente === "UTENTE"  || userInfo.tipologiaUtente === "ADMIN" ) &&
              (ri.statoRichiesta.descrizione === "INVIATA") &&
              <span className="rounded-icon iconbottomcancel"
                onClick={() =>
                  showActionPopUp(
                    "confirm",
                    "ATTENZIONE",
                    `La Richiesta Ingombranti ${ri.id} verra' cancellata. Procedere?`,
                    delRichiestaIngombranti,
                    ri.id
                  )
                }
                >
                <FontAwesomeIcon icon={faTrashAlt} />
              </span>
            }
            
          </section>
        );
        tmp.push(ri);
      });
      setDati(tmp);
    }
  }, [richiesteIngombranti, dispatch]);

  const headers = [
    {
      label: "Utente",
      field: "proprietario",
    },
    {
      label: "Utenza",
      field: "utenza.indirizzo",
    },
    {
      label: "Comune",
      field: "comune",
    },
    {
      label: "Tipologia",
      field: "tipologiaSpazzatura.descrizione",
    },
    // {
    //   label: "Disponibilità",
    //   field: "disponibilita",
    // },
    // {
    //   label: "Note",
    //   field: "note",
    // },
    {
      label: "Stato",
      field: "statoRichiesta.descrizione",
    },
    {
      label: "Azioni",
      field: "actions",
    },
  ];

  const infoRichiesta = (ri) => {
    return (
      <Table size="sm">
        <tbody>
        <tr>
            <th scope="row">Stato</th>
            <td>{ri.statoRichiesta.descrizione}</td>
          </tr>
          <tr>
            <th scope="row">Utente</th>
            <td>{ri.utente.userName + " - " + ri.utente.cognome + " " + ri.utente.nome}</td>
          </tr>
          <tr>
            <th scope="row">Utenza</th>
            <td>{ri.utenza.indirizzo}</td>
          </tr>
          <tr>
            <th scope="row">Comune</th>
            <td>{ri.utente.comuneResidenzaDen}</td>
          </tr>
          <tr>
            <th scope="row">Tipologia Rifiuto</th>
            <td>{ri.tipologiaSpazzatura.descrizione}</td>
          </tr>
          <tr>
            <th scope="row">Disponibilità</th>
            <td>{ri.disponibilita}</td>
          </tr>
          <tr>
            <th scope="row">Note</th>
            <td>{ri.note}</td>
          </tr>
        </tbody>
      </Table>
    );
  };

  const refreshList = () => {
    dispatch(getRichiesteIngombranti());
    dispatch(getTipologieSpazzatura());
    dispatch(getUtenze());
  };

  return (
    <React.Fragment>
      {loading === true ? (
        <div className="customTableContainer">
         <div className="container_spinner"><Spinner active  className="spinnerwebe"/></div>
        </div>
      ) : (
        <>
        <RichiestaIngombrantiModal utenti={utenti} utenze={utenze} tipologieSpazzatura={tipologieSpazzatura}/>
        <div className="customTableContainer">
          <Row className="justify-content-md-center">
          <Col lg="9" xl="9" md="9" sm="9" xs="12">
              <h1 className="">Richiesta Ingombranti <FontAwesomeIcon icon={faRefresh} className="padding-lr-icon linkeffect" onClick={()=> refreshList()}/></h1>
            </Col>
            <Col lg="3" xl="3" md="3" sm="3" xs="12" className="text-right">
              {
                userInfo!=null &&  (userInfo.tipologiaUtente === "COMUNE" || userInfo.tipologiaUtente === "UTENTE" ) &&
                <>
                 <Button
                color="primary"
                onClick={() =>
                  showActionModal(
                    "insert",
                    "Inserimento nuova Richiesta Ingombranti",
                    insertIngombranti
                  )
                }
                icon
                id="addNewRichiestaIngombranti"
                style={{ marginBottom: "8px" }}
              >
                <FontAwesomeIcon icon={faFileText} className="padding-lr-icon"/> {' '}Aggiungi
              </Button>
              <UncontrolledTooltip
                placement="top"
                target="addNewRichiestaIngombranti"
              >
                Aggiungi una nuova Richiesta Ingombranti
              </UncontrolledTooltip>
                </>

              }
             
            </Col>
          </Row>
          <CustomTable headers={headers} data={dati} />
        </div>
        </>
        
      )}
    </React.Fragment>
  );
};

export default RichiestaIngombranti;
