import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  ModalFooter,
  Input,
  Select,
  Col,
  Row
} from "design-react-kit";
import { hideQrCodeModal } from "../actions/qrCodeModal_actions"

const QrCodeModal = () => {
  const dispatch = useDispatch();

  const [valoreQrCode, setValoreQrCode] = useState();
  const [statoQrCode, setStatoQrCode] = useState();

  const [statiQrCodeOptions, setStatiQrCodeOptions] = useState()

  const qrCodeToEdit = useSelector((state) => state.qrCodeModal.obj);

  const type = useSelector((state) => state.qrCodeModal.type);
  const header = useSelector((state) => state.qrCodeModal.header);
  const show = useSelector((state) => state.qrCodeModal.show);
  const f = useSelector((state) => state.qrCodeModal.function);

  const [listaErrori, setListaErrori] = useState([]);
  let listaErroriTmp = [];

  function erroriForm(listaErroriInfo){
    return listaErroriInfo.map(function (errore) {
      return(
        <p className="text-mess-alert">{errore}</p>
      )})  
  }


  function close() {
    dispatch(hideQrCodeModal());
  }

  function confirm() {
    listaErroriTmp = [];
    if(valoreQrCode == null || valoreQrCode.value === '') listaErroriTmp.push("- QrCode è un campo obbligatorio")

    if(listaErroriTmp.length == 0 ){
      switch (type) {
        case "insert":
          dispatch(f(valoreQrCode, false));
          break;
        case "edit":
          dispatch(f(qrCodeToEdit.id, valoreQrCode, statoQrCode.value));
          close();
          break;
        default:
          close();
      }
      setListaErrori([])
      close();
    }else{
      setListaErrori(listaErroriTmp)
    }
    
  }

  useEffect(() => {
    setStatiQrCodeOptions([
      {value: true, label: "assegnato"},
      {value: false, label: "libero"}
    ]);
  }, []);

  useEffect(() => {
    if (type !== "edit" && qrCodeToEdit === undefined) {
      setValoreQrCode(undefined);
      setStatoQrCode(undefined);
    } else {
      console.log(qrCodeToEdit)
      setValoreQrCode(qrCodeToEdit.valore);
      setStatoQrCode({
        label: qrCodeToEdit.statoQr,
        value: qrCodeToEdit.stato
      });
    }
  }, [type, qrCodeToEdit]);

  return (
    <div>
      <Modal isOpen={show} labelledBy="qrCodeModal">
        <ModalHeader id="qrCodeModal">{header}</ModalHeader>
        <ModalBody>
          <FormGroup>
            <div className="bootstrap-select-wrapper">
              <label htmlFor="inputValoreQrCode">Valore QrCode</label>
              <Input
                id="valoreQrCode"
                onChange={(e) => {
                  setValoreQrCode(e.target.value);
                }}
                value={valoreQrCode}
                type="text"
                placeholder="Inserisci Valore QrCode"
                aria-label="Inserisci Valore QrCode"
              />
            </div>
          </FormGroup>
          {listaErrori.length>0 &&
            <Row>
                <Col>
                  {erroriForm(listaErrori)}
                </Col>
            </Row>
          }
        </ModalBody>
        <ModalFooter>
          <span>
            <Button outline color='primary' onClick={() => close()}>ANNULLA</Button>
          </span>
          <span>
            <Button outline color='primary' onClick={() => confirm()}>CONFERMA</Button>
          </span>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default QrCodeModal;
