import CommonService from "../../../service/common_service"
import { put } from "redux-saga/effects"
import { showPopup } from "../../popup/actions/popup_actions";
import { GET_UTENTI_COMUNE_UTENZA } from "../../utente/actions/utente_actions";

export const GET_UTENZE = "getUtenze"
export const GET_UTENZE_SUCCES = "get_utenze_success"
export const GET_UTENZE_FAIL = "get_utenze_fail"

const headers = {
    Authorization: 'Bearer ' + localStorage.getItem("jwtToken")
}

export function getUtenze() {
    return {
        type: GET_UTENZE
    }
}

export function* getUtenzeSaga() {
    try {

        const request = {
            method: "GET",
            url: "/api/v1/utenza/cerca",
            headers: headers
        }

        const response = yield CommonService.execute(request)
        yield put({
            type: GET_UTENZE_SUCCES,
            payload: response.data
        })

    } catch (error) {
        yield put({
            type: GET_UTENZE_FAIL,
            payload: error
        })
        yield put(
            showPopup(
                "error",
                "OPERAZIONE FALLITA",
                error.response.data.data.error
            )
        )
    }
}

export const INSERT_UTENZA = "insertUtenza"
export const INSERT_UTENZA_SUCCESS = "insertUtenza_success"
export const INSERT_UTENZA_FAILED = "insertUtenza_failed"

export function insertUtenza(intestatario, tipoImmobile, indirizzo, comune) {
    return {
        type: INSERT_UTENZA,
        payload: {
            intestatario: intestatario,
            tipoImmobile: tipoImmobile,
            indirizzo: indirizzo,
            comune: comune // SUPERUSER -> deve inserire anche l'id del comune per creare una nuova utenza
        }
    }
}

export function* insertUtenzaSaga(action) {
    try {
        const request = {
            url: "/api/v1/utenza/insert",
            method: "POST",
            data: {
                intestatarioId: action.payload.intestatario,
                tipoImmobileId: action.payload.tipoImmobile,
                indirizzo: action.payload.indirizzo,
                comuneId: action.payload.comune
            }
        }

        const response = yield CommonService.execute(request)
        yield put({
            type: GET_UTENZE
        })
        yield put({
            type: GET_UTENTI_COMUNE_UTENZA
        })
        yield put(
            showPopup(
                "notify",
                "OPERAZIONE RIUSCITA",
                response.data.message
            )
        )

    } catch (error) {
        yield put({
            type: INSERT_UTENZA_FAILED,
            payload: error.response.data.data.error
        })
        yield put(
            showPopup(
                "error",
                "OPERAZIONE FALLITA",
                error.response.data.message
            )
        )
    }
}

export const UPDATE_UTENZA = "updateUtenza"
export const UPDATE_UTENZA_SUCCESS = "updateUtenza_success"
export const UPDATE_UTENZA_FAILED = "updateUtenza_failed"

export function updateUtenza(utenzaId, intestatario, tipoImmobile, indirizzo, comune) {
    return {
        type: UPDATE_UTENZA,
        payload: {
            utenzaId: utenzaId,
            intestatario: intestatario,
            tipoImmobile: tipoImmobile,
            indirizzo: indirizzo,
            comune: comune // SUPERUSER -> deve inserire anche l'id del comune per creare una nuova utenza
        }
    }
}

export function* updateUtenzaSaga(action) {
    try {
        const request = {
            url: "/api/v1/utenza/update",
            method: "PUT",
            data: {
                id: action.payload.utenzaId,
                intestatarioId: action.payload.intestatario,
                tipoImmobileId: action.payload.tipoImmobile,
                indirizzo: action.payload.indirizzo,
                comuneId: action.payload.comune
            }
        }

        const response = yield CommonService.execute(request)
        yield put({
            type: GET_UTENZE
        })
        yield put({
            type: GET_UTENTI_COMUNE_UTENZA
        })
        yield put(
            showPopup(
                "notify",
                "OPERAZIONE RIUSCITA",
                response.data.message
            )
        )
        

    } catch (error) {
        yield put({
            type: UPDATE_UTENZA_FAILED,
            payload: error.response.data.data.error
        })
        yield put(
            showPopup(
                "error",
                "OPERAZIONE FALLITA",
                error.response.data.message
            )
        )
    }
    
}

export const DELETE_UTENZA = "deleteUtenza"
export const DELETE_UTENZA_SUCCESS = "delete_utenza_success"
export const DELETE_UTENZA_FAILED = "delete_utenza_fail"

export function deleteUtenza(utenzaId) {
    return {
        type: DELETE_UTENZA,
        payload: utenzaId
    }
}

export function* deleteUtenzaSaga(action) {
    try {
        const url = "/api/v1/utenza/delete/" + action.payload
        const request = {
            method: "DELETE",
            url: url,
        }

        const response = yield CommonService.execute(request)
        yield put({
            type: GET_UTENZE
        })
        yield put(
            showPopup(
                "notify",
                "OPERAZIONE RIUSCITA",
                response.data.message
            )
        )  

    } catch (error) {
        yield put({
            type: DELETE_UTENZA_FAILED,
            payload: error.response.data.data.error
        })
        yield put(
            showPopup(
                "error",
                "OPERAZIONE FALLITA",
                error.response.data.data.error
            )
        )
    }
}



