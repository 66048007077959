export const SHOW_DISPOSITIVO_MODAL = "showDispositivoModal"
export const HIDE_DISPOSITIVO_MODAL = "hide_Dispositivo_modal"

export function showDispositivoModal(type, header, f, obj){
  return {
      type: SHOW_DISPOSITIVO_MODAL,
      payload:{
        type: type,
        header: header,
        function: f,
        obj: obj
    }
  }
}

export function hideDispositivoModal(){
  return {
      type: HIDE_DISPOSITIVO_MODAL
  }
}