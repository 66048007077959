//import logo from './logo.svg';
import './App.css';
import Login from './login/components/Login';
import { Routes, Route } from "react-router-dom"
import CustomHeader from './layout/components/CustomHeader';
import React from 'react';
import ComuneLayout from './comune/components/ComuneLayout';
import UtenteLayout from './common/components/utente/components/UtenteLayout';
import EnteLayout from './ente/components/EnteLayout';
//import comuneRoutes from './comune/components/routes';
//import Utenze from './common/components/utenze/components/Utenze';
import * as d3 from 'd3'
import OperatoreLayout from './operatore/components/OperatoreLayout';
import RecuperaPassword from './password/components/RecuperaPassword';
import ResetPassword from './password/components/ResetPassword';
import AdminLayout from './admin/components/AdminLayout';

function App(props) {

  console.log("reload")

  return (
    <React.Fragment>
      {/* <CustomHeader/> */}
      <Routes>
        <Route path="/" exact element={<Login />} />
        <Route path="comune/*" element={<ComuneLayout />} />
        <Route path="utente/*" element={<UtenteLayout />} />
        <Route path="ente/*" element={<EnteLayout />} />
        <Route path="operatore/*" element={<OperatoreLayout />} />
        <Route path="recupera-password" element={<RecuperaPassword />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="admin/*" element={<AdminLayout />} />
      </Routes>
    </React.Fragment>
  )
}

export default App;
