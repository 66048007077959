import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Spinner, Row, Col } from "design-react-kit";
import { useDispatch, useSelector } from "react-redux";
import { hidePopup } from "../actions/popup_actions";
import { Navigate, redirect, useNavigate } from "react-router-dom";

const PopUp = () => {

    const dispatch = useDispatch();

    const navigate = useNavigate()

    const show = useSelector(state => state.popup.show)  
    const header = useSelector(state => state.popup.header)
    const message = useSelector(state => state.popup.message)
    const type = useSelector(state => state.popup.type)
    const f = useSelector(state => state.popup.function)
    const arg = useSelector(state => state.popup.arg)

    function close() {
        dispatch(hidePopup())
    }

    function confirm(){
        f(arg)
        close()
    }

    function redirectHome(){
        navigate('/')
        close()
    }

    function actionButton() {
        switch(type){
            case 'notify':
                return <Button outline color='primary' onClick={() => close()}>OK</Button>
            case 'confirm':
                return(
                    <React.Fragment>
                        <span><Button outline color='primary' onClick={() => close()}>ANNULLA</Button></span>
                        <span><Button outline color='primary' onClick={() => confirm()}>CONFERMA</Button></span>
                    </React.Fragment>
                )
            case 'error':
                return <Button outline color='primary' onClick={() => close()}>OK</Button>
            case 'loading':
                return <span><Spinner active /></span>
            case 'redirect':
                return <Button outline color='primary' onClick={() => redirectHome()}>OK</Button>
            default:
                return <Button outline color='primary' onClick={() => close()}>OK</Button>
        }
    }

    return (
        <Modal
            centered
            isOpen={show}
        >
            <ModalHeader>
                {header}
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        {message}
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                {actionButton()}
            </ModalFooter>
        </Modal>
    )


}

export default PopUp