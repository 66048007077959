import CommonService from "../../../service/common_service";
import { put } from "redux-saga/effects"
import { showPopup } from "../../popup/actions/popup_actions";

export const GET_TIPO_SPAZZATURA = "getTipologieSpazzatura"
export const GET_TIPO_SPAZZATURA_SUCCESS = "get_tipologieSpazzatura_success"
export const GET_TIPO_SPAZZATURA_FAIL = "get_tipologieSpazzatura_fail"

export function getTipologieSpazzatura() {
    return {
        type: GET_TIPO_SPAZZATURA
    }
}

export function* getTipologieSpazzaturaSaga() {
    try {

        const request = {
            method: "GET",
            url: "/api/v1/tipologia-spazzatura/all",
        }

        const response = yield CommonService.execute(request)
        yield put({
            type: GET_TIPO_SPAZZATURA_SUCCESS,
            payload: response.data.data
        })

    } catch (error) {
        yield put({
            type: GET_TIPO_SPAZZATURA_FAIL,
            payload: error
        })
        yield put(
            showPopup(
                "error",
                "OPERAZIONE FALLITA",
                error.response.data.data.error
            )
        )
    }
}

export const INSERT_TIPO_SPAZZATURA = "insertTipologiaSpazzatura"
export const INSERT_TIPO_SPAZZATURA_SUCCESS = "insert_tipologiaSpazzatura_success"
export const INSERT_TIPO_SPAZZATURA_FAIL = "insert_tipologiaSpazzatura_fail"

export function insertTipologiaSpazzatura(descrizione) {
    return {
        type: INSERT_TIPO_SPAZZATURA,
        payload: {
            descrizione: descrizione
        }
    }
}

export function* insertTipologiaSpazzaturaSaga(action) {
    try {
        const request = {
            url: "/api/v1/tipologia-spazzatura/insert",
            method: "POST",
            data: {
                descrizione: action.payload.descrizione
            }
        }

        const response = yield CommonService.execute(request)
        yield put({
            type: GET_TIPO_SPAZZATURA,
            payload: response.data.data
        })
        yield put(
            showPopup(
                "notify",
                "OPERAZIONE RIUSCITA",
                response.data.message
            )
        )

    } catch (error) {
        yield put({
            type: INSERT_TIPO_SPAZZATURA_FAIL,
            payload: error
        })
        yield put(
            showPopup(
                "error",
                "OPERAZIONE FALLITA",
                error.response.data.message
            )
        )
    }
}

export const UPDATE_TIPO_SPAZZATURA = "updateTipologiaSpazzatura"
export const UPDATE_TIPO_SPAZZATURA_SUCCESS = "update_tipologiaSpazzatura_success"
export const UPDATE_TIPO_SPAZZATURA_FAIL = "update_tipologiaSpazzatura_fail"

export function updateTipologiaSpazzatura(tipologiaSpazzaturaId, descrizione) {
    return {
        type: UPDATE_TIPO_SPAZZATURA,
        payload: {
            tipologiaSpazzaturaId: tipologiaSpazzaturaId,
            descrizione: descrizione
        }
    }
}

export function* updateTipologiaSpazzaturaSaga(action) {
    try {
        const request = {
            url: "/api/v1/tipologia-spazzatura/update",
            method: "PUT",
            data: {
                id: action.payload.tipologiaSpazzaturaId,
                descrizione: action.payload.descrizione
            }
        }

        const response = yield CommonService.execute(request)
        yield put({
            type: GET_TIPO_SPAZZATURA,
            payload: response.data.data
        })
        yield put(
            showPopup(
                "notify",
                "OPERAZIONE RIUSCITA",
                response.data.message
            )
        )

    } catch (error) {
        yield put({
            type: UPDATE_TIPO_SPAZZATURA_FAIL,
            payload: error
        })
        yield put(
            showPopup(
                "error",
                "OPERAZIONE FALLITA",
                error.response.data.message
            )
        )
    }
}

export const DELETE_TIPO_SPAZZATURA = "deleteTipologiaSpazzatura"
export const DELETE_TIPO_SPAZZATURA_SUCCESS = "delete_tipologiaSpazzatura_success"
export const DELETE_TIPO_SPAZZATURA_FAIL = "delete_tipologiaSpazzatura_fail"

export function deleteTipologiaSpazzatura(tipologiaSpazzaturaDescrizione) {
    return {
        type: DELETE_TIPO_SPAZZATURA,
        payload: tipologiaSpazzaturaDescrizione
    }
}

export function* deleteTipologiaSpazzaturaSaga(action) {
    try {
        const url = "/api/v1/tipologia-spazzatura/delete/" + action.payload
        const request = {
            method: "DELETE",
            url: url,
        }

        const response = yield CommonService.execute(request)
        yield put({
            type: GET_TIPO_SPAZZATURA
        })
        yield put(
            showPopup(
                "notify",
                "OPERAZIONE RIUSCITA",
                response.data.message
            )
        )

    } catch (error) {
        yield put({
            type: DELETE_TIPO_SPAZZATURA_FAIL,
            payload: error.response.data.data.error
        })
        yield put(
            showPopup(
                "error",
                "OPERAZIONE FALLITA",
                error.response.data.message
            )
        )
    }
}