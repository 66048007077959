import { LOGOUT_REQUEST, LOGOUT_SUCCESS, LOGOUT_FAILED } from "../actions/logout_actions";
import _ from "lodash";

export function logoutReducer(state = { loading: false, success: false}, action) {

  if (_.isEmpty(action) || _.isEmpty(action.type)) return state 

  const newState = state

  switch (action.type) {

    case LOGOUT_REQUEST:
      newState.loading = false
      newState.success = false
      break;
    case LOGOUT_SUCCESS:
      newState.loading = false
      newState.success = true
      break;
    case LOGOUT_FAILED:
      newState.loading = false
      newState.success = false
      break;
    
    default:
      break;
  }
  return Object.assign({}, state, newState)
}