import React from 'react'
import { Table as BTable, Pager, PagerItem, PagerLink, Icon } from 'design-react-kit'

import { useTable, useFilters, useGlobalFilter, useAsyncDebounce, useSortBy, usePagination } from 'react-table'
// A great library for fuzzy filtering/sorting items
import { matchSorter } from 'match-sorter'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

// Define a default UI for filtering
function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
}) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)

    return (
        <span>
            <span>Cerca <FontAwesomeIcon icon={faSearch} /></span> {' '}
            <input
                value={value || ""}
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder={`cerca tra i ${count} risultati...`}
                style={{
                    fontSize: '1.1rem',
                    border: '0',
                }}
            />
        </span>
    )
}

// Define a default UI for filtering
function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
}) {
    const count = preFilteredRows.length

    return (
        <input
            value={filterValue || ''}
            onChange={e => {
                setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
            }}
            placeholder={`Cerca tra i ${count} risultati...`}
        />
    )
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
}) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
        const options = new Set()
        preFilteredRows.forEach(row => {
            options.add(row.values[id])
        })
        return [...options.values()]
    }, [id, preFilteredRows])

    // Render a multi-select box
    return (
        <select
            value={filterValue}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
        >
            <option value="">All</option>
            {options.map((option, i) => (
                <option key={i} value={option}>
                    {option}
                </option>
            ))}
        </select>
    )
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
function SliderColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
}) {
    // Calculate the min and max
    // using the preFilteredRows

    const [min, max] = React.useMemo(() => {
        let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
        let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
        preFilteredRows.forEach(row => {
            min = Math.min(row.values[id], min)
            max = Math.max(row.values[id], max)
        })
        return [min, max]
    }, [id, preFilteredRows])

    return (
        <>
            <input
                type="range"
                min={min}
                max={max}
                value={filterValue || min}
                onChange={e => {
                    setFilter(parseInt(e.target.value, 10))
                }}
            />
            <button onClick={() => setFilter(undefined)}>Off</button>
        </>
    )
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({
    column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
    const [min, max] = React.useMemo(() => {
        let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
        let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
        preFilteredRows.forEach(row => {
            min = Math.min(row.values[id], min)
            max = Math.max(row.values[id], max)
        })
        return [min, max]
    }, [id, preFilteredRows])

    return (
        <div
            style={{
                display: 'flex',
            }}
        >
            <input
                value={filterValue[0] || ''}
                type="number"
                onChange={e => {
                    const val = e.target.value
                    setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]])
                }}
                placeholder={`Min (${min})`}
                style={{
                    width: '70px',
                    marginRight: '0.5rem',
                }}
            />
            to
            <input
                value={filterValue[1] || ''}
                type="number"
                onChange={e => {
                    const val = e.target.value
                    setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined])
                }}
                placeholder={`Max (${max})`}
                style={{
                    width: '70px',
                    marginLeft: '0.5rem',
                }}
            />
        </div>
    )
}

function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val

// Our table component
function Table({ columns, data, selectable, selectFunction, selectID, className }) {
    const filterTypes = React.useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id]
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true
                })
            },
        }),
        []
    )

    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        // rows,
        prepareRow,
        state,
        visibleColumns,
        preGlobalFilteredRows,
        setGlobalFilter,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page

        // The rest of these things are super handy, too ;)
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            defaultColumn, // Be sure to pass the defaultColumn option
            filterTypes,
            initialState: { pageIndex: 0 },
        },
        useFilters, // useFilters!
        useGlobalFilter, // useGlobalFilter!
        useSortBy,
        usePagination
    )

    // We don't want to render all of the rows for this example, so cap
    // it for this use case
    // const firstPageRows = rows.slice(0, 10)

    function handleClick(event) {
    }

    function getPages() {
        const pages = []
        if (pageCount <= 35) {
            for (let i = 0; i < pageCount; i++) {
                pages.push(
                    <PagerItem
                        onClick={() => gotoPage(i)}
                        active={i === pageIndex}
                    >
                        <PagerLink >{i + 1}</PagerLink>
                    </PagerItem>
                )
            }
        } else {
            if (pageIndex <= 5) {
                for (let i = 0; i < 6; i++) {
                    pages.push(
                        <PagerItem
                            onClick={() => gotoPage(i)}
                            active={i === pageIndex}
                        >
                            <PagerLink >{i + 1}</PagerLink>
                        </PagerItem>
                    )
                }
                pages.push(
                    <PagerItem className='d-none d-sm-block'>
                        <PagerLink tag='span'>…</PagerLink>
                    </PagerItem>
                )
            } else {

                for (let z = 0; z < 6 && z < pageIndex - 5; z++) {
                    pages.push(
                        <PagerItem
                            onClick={() => gotoPage(z)}
                            active={z === pageIndex}
                        >
                            <PagerLink active >{z + 1}</PagerLink>
                        </PagerItem>
                    )
                }
                if (pageIndex >= 11) {
                    pages.push(
                        <PagerItem className='d-none d-sm-block'>
                            <PagerLink tag='span'>…</PagerLink>
                        </PagerItem>
                    )
                }
            }
            if (pageIndex > 5 && pageIndex < pageCount - 5) {
                for (let j = pageIndex - 5; j < pageIndex + 5; j++) {
                    pages.push(
                        <PagerItem
                            onClick={() => gotoPage(j)}
                            active={j === pageIndex}
                        >
                            <PagerLink >{j + 1}</PagerLink>
                        </PagerItem>
                    )
                }
                pages.push(
                    <PagerItem className='d-none d-sm-block'>
                        <PagerLink tag='span'>…</PagerLink>
                    </PagerItem>
                )
            }
            for (let k = pageCount - 5; k < pageCount; k++) {
                pages.push(
                    <PagerItem
                        onClick={() => gotoPage(k)}
                        active={k === pageIndex}
                    >
                        <PagerLink >{k + 1}</PagerLink>
                    </PagerItem>
                )
            }
        }
        return pages
    }

    return (
        <div className='customTableContainer'>
            <BTable
                striped
                bordered
                {...getTableProps()}
                className={className}
            >
                <thead>

                    {
                        // data.length > 10 &&
                        <tr>
                            <th
                                className='select-align'
                                colSpan="1" //{visibleColumns.length-2}
                                style={{
                                    textAlign: 'left!important',
                                }}
                            >
                                <select
                                    value={pageSize}
                                    onChange={e => {
                                        setPageSize(Number(e.target.value))
                                    }}
                                >
                                    {[10, 20, 30, 40, 50].map(pageSize => (
                                        <option key={pageSize} value={pageSize}>
                                            Numero Righe {pageSize}
                                        </option>
                                    ))}
                                </select>
                            </th>
                            <th
                                className='select-align'
                                colSpan={visibleColumns.length-1}
                                style={{
                                    textAlign: 'right',
                                }}
                            >
                                <GlobalFilter
                                    preGlobalFilteredRows={preGlobalFilteredRows}
                                    globalFilter={state.globalFilter}
                                    setGlobalFilter={setGlobalFilter}
                                />
                            </th>
                        </tr>
                    }

                    {/* <tr>
                        <th
                            colSpan={visibleColumns.length}
                            style={{
                                textAlign: 'right',
                            }}
                        >
                            <GlobalFilter
                                preGlobalFilteredRows={preGlobalFilteredRows}
                                globalFilter={state.globalFilter}
                                setGlobalFilter={setGlobalFilter}
                            />
                        </th>
                    </tr> */}

                </thead>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}
                                    style={{ textAlign: "left", fontSize: '1.0em', padding: '10px 0px' }}>
                                    {column.render('Header')}
                                    {/* Add a sort direction indicator */}
                                    <span style={{ fontSize: "0.6em" }}>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? ' 🔽'
                                                : ' 🔼'
                                            : ''}
                                    </span>
                                    {/* Render the columns filter UI */}
                                    {/* <hr /> */}
                                    <div style={{ fontSize: '0.7em' }}>{column.canFilter ? column.render('Filter') : null}</div>
                                </th>
                            ))}
                        </tr>
                    ))}

                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}
                                style={{ textAlign: "left", cursor: (selectable ? 'pointer' : "")}}
                                {...(selectable && { onClick: () => { selectFunction(row.original[selectID]) } })}
                                className={selectable ? 'selectableRow' : ""}
                            >
                                {row.cells.map(cell => {
                                    return <td style={{padding: '2px 0px'}} className='font-table' {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </BTable>
            {
                pageCount > 1 &&
                <Pager className='mb-3' style={{justifyContent:"center"}} >
                    <PagerItem>
                        <PagerLink previous onClick={() => previousPage()} disabled={!canPreviousPage}>
                            <Icon icon='it-chevron-left' aria-hidden />
                        </PagerLink>
                    </PagerItem>
                    {
                        getPages()
                    }
                    <PagerItem>
                        <PagerLink next onClick={() => nextPage()} disabled={!canNextPage}>
                            <Icon icon='it-chevron-right' aria-hidden />
                        </PagerLink>
                    </PagerItem>
                </Pager>
            }
        </div>
    )
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
    return rows.filter(row => {
        const rowValue = row.values[id]
        return rowValue >= filterValue
    })
}

function getFilter(filterLabel) {
    return SliderColumnFilter
}

function createHeaders(headers) {
    const columns = []
    _.forEach(headers, (header, index) => {
        columns.push(
            {

                Header: header.label,
                accessor: header.field,
                // Filter: getFilter(header.Filter),
                // filter: header.filter
                filter: 'fuzzyText'

            }
        )
    })
    return columns
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = val => typeof val !== 'number'

function CustomTable(props) {
    const columns = React.useMemo(
        () => createHeaders(props.headers),
        []
    )
    return (
        <Table
            columns={columns}
            data={props.data}
            selectable={props.selectable}
            selectFunction={props.selectFunction}
            selectID={props.selectID}
            className={props.className}
        />
    )
}

export default CustomTable
