import {
  GET_PESATE, GET_PESATE_SUCCESS, GET_PESATE_FAIL,
  INSERT_PESATA, INSERT_PESATA_SUCCESS, INSERT_PESATA_FAILED,
  UPDATE_PESATA, UPDATE_PESATA_SUCCESS, UPDATE_PESATA_FAILED,
  DELETE_PESATA, DELETE_PESATA_SUCCESS, DELETE_PESATA_FAILED,
  STAMPA_PESATA, STAMPA_PESATA_SUCCESS, STAMPA_PESATA_FAILED
} from "../actions/pesata_actions";
import _ from "lodash";

export function pesateReducer(state = { loading: false, error: undefined, insertSuccessData: undefined, mastelli: undefined }, action) {

  if (_.isEmpty(action) || _.isEmpty(action.type)) return state

  const newState = state

  switch (action.type) {

    case GET_PESATE:
      newState.loading = true
      newState.error = undefined
      newState.mastelli = undefined
      break;
    case GET_PESATE_SUCCESS:
      newState.loading = false
      newState.error = undefined
      newState.mastelli = action.payload
      break;
    case GET_PESATE_FAIL:
      newState.loading = false
      newState.error = action.payload
      newState.mastelli = undefined
      break;
    case INSERT_PESATA:
      break;
    case INSERT_PESATA_SUCCESS:
      break;
    case INSERT_PESATA_FAILED:
      break;
    case UPDATE_PESATA:
      break;
    case UPDATE_PESATA_SUCCESS:
      break;
    case UPDATE_PESATA_FAILED:
      break;
    case DELETE_PESATA:
      break;
    case DELETE_PESATA_SUCCESS:
      break;
    case DELETE_PESATA_FAILED:
      break;
    case STAMPA_PESATA:
      break;
    case STAMPA_PESATA_SUCCESS:
      break;
    case STAMPA_PESATA_FAILED:
      break;
    default:
      break;
  }

  return Object.assign({}, state, newState)
}