import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router} from "react-router-dom"
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import { legacy_createStore as createStore, applyMiddleware, compose } from "redux"
import reducers from "./reducers/index"
import wbdSaga from "./sagas/sagas"
import "bootstrap-italia/dist/css/bootstrap-italia.min.css"
import "./style/additional.css"
import "@fortawesome/free-solid-svg-icons"


const root = ReactDOM.createRoot(document.getElementById('root'));
const BASE = "/"
//DA GIT
//const sagaMiddleware = createSagaMiddleware()
//const store = createStore(reducers, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(), applyMiddleware(sagaMiddleware))


const sagaMiddleware = createSagaMiddleware();


const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, composeEnhancer(applyMiddleware(sagaMiddleware)))
sagaMiddleware.run(wbdSaga)

root.render(
  <Provider store={store} >
    <Router base={BASE}>
      <App />
    </Router>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
