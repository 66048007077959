import React, { useEffect, useState } from "react";
import {
  Button,
  Spinner,
  Row,
  Col
} from "design-react-kit";
import _ from "lodash";
import DonutChart from "../../grafici/DonutChart";
import { getUserInfo} from "../dashboard_Action";
import { getUtenze } from "../../utenze/actions/utenze_actions"
import { getMastelli } from "../../mastello/actions/mastello_actions";
import { useDispatch, useSelector } from "react-redux";
import Mappa from "../../grafici/Mappa";

import { Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
  } from 'chart.js';
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
  );


const DashboardEnte = () => {


  const dispatch = useDispatch();

  const userInfo = useSelector(
    (state) => state.userInfo.userInfo
  );
  let loading = true//useSelector((state) => state.userInfo.loading);
  const [dati, setDati] = useState();

  const utenze = useSelector((state) => state.utenze.utenze);

  const mastelli = useSelector((state) => state.mastello.mastelli)


  const countType = (type) => {
        if(type != null){
          const countTypes =  type.length
          return countTypes;
        }else{
          return -1
        }
        
  }

  const [datiAnno, setDatiAnno] = useState()


  useEffect(() =>{
      if (utenze == null){
        //dispatch(getUserInfo());
        dispatch(getUtenze());
        //dispatch(getMastelli());
      } 
    } , [utenze, dispatch]
  )

  useEffect(() =>{
    if (mastelli == null){
      //dispatch(getUserInfo());
      //dispatch(getUtenze());
      dispatch(getMastelli());
    } 
  }, [mastelli, dispatch]
)

  useEffect(() => {
    if (userInfo === undefined) {
      dispatch(getUserInfo());
      dispatch(getUtenze());
      dispatch(getMastelli());
    } else {
      const tmp = [];
      _.forEach(userInfo, (ts) => {
        tmp.push(ts);
      });
      setDati(userInfo);
      let datiAnno_tmp = []
      if(utenze !=null) datiAnno_tmp.push({'name': "Utenze", 'value': countType(utenze)})
      if(mastelli !=null) datiAnno_tmp.push({'name': "Mastelli Assegnati", 'value': countType(mastelli)})
      setDatiAnno(datiAnno_tmp);
      console.log(userInfo)
      console.log(utenze)
      console.log(mastelli)


      loading = false
    }
  }, [userInfo, utenze, mastelli, dispatch]);

    const colors = [ '#8ce8ad', '#60e6e1', '#87d3f2', '#93f0e6', '#ffb46a', '#ff736a', ];

    const colors_bis = ['#57e188', '#93f0e6', '#4ebeeb',  '#ff9831',  '#ff9a91', '#f95d54' ];

    var dati_anno = [
        {name: "Carta", value: 345},
        {name: "Plastica", value: 123},
        {name: "Vetro", value: 342},
        {name: "Umido", value: 565},
        {name: "Indifferenziata", value: 234},
      ];

      var dati_mese = [
        {name: "Carta", value: 43},
        {name: "Plastica", value: 67},
        {name: "Vetro", value: 123},
        {name: "Umido", value: 310},
        {name: "Indifferenziata", value: 54},
      ];
    
    var dati_mese_carta = [
        {name: "gen", value: '25'},
        {name: "feb", value: '15'},
        {name: "mar", value: '30'},
        {name: "apr", value: '100'},
        {name: "mag", value: '45'},
        {name: "giu", value: '67'},
        {name: "lug", value: '12'},
        {name: "ago", value: '20'},
        {name: "set", value: '76'},
        {name: "ott", value: '54'},
        {name: "nov", value: '78'},
        {name: "dic", value: '36'},
      ];

      var dati_mese_umido = [
        {name: "gen", value: '34'},
        {name: "feb", value: '65'},
        {name: "mar", value: '21'},
        {name: "apr", value: '67'},
        {name: "mag", value: '76'},
        {name: "giu", value: '12'},
        {name: "lug", value: '43'},
        {name: "ago", value: '56'},
        {name: "set", value: '49'},
        {name: "ott", value: '32'},
        {name: "nov", value: '33'},
        {name: "dic", value: '65'},
      ];

      var dati_mese_vetro = [
        {name: "gen", value: '55'},
        {name: "feb", value: '43'},
        {name: "mar", value: '52'},
        {name: "apr", value: '32'},
        {name: "mag", value: '36'},
        {name: "giu", value: '51'},
        {name: "lug", value: '56'},
        {name: "ago", value: '39'},
        {name: "set", value: '44'},
        {name: "ott", value: '55'},
        {name: "nov", value: '66'},
        {name: "dic", value: '77'},
      ];

      var dati_mese_plastica = [
        {name: "gen", value: '65'},
        {name: "feb", value: '34'},
        {name: "mar", value: '44'},
        {name: "apr", value: '67'},
        {name: "mag", value: '33'},
        {name: "giu", value: '66'},
        {name: "lug", value: '23'},
        {name: "ago", value: '41'},
        {name: "set", value: '52'},
        {name: "ott", value: '47'},
        {name: "nov", value: '76'},
        {name: "dic", value: '12'},
      ];

      var dati_mese_indifferenziata = [
        {name: "gen", value: '87'},
        {name: "feb", value: '67'},
        {name: "mar", value: '54'},
        {name: "apr", value: '43'},
        {name: "mag", value: '21'},
        {name: "giu", value: '89'},
        {name: "lug", value: '55'},
        {name: "ago", value: '78'},
        {name: "set", value: '46'},
        {name: "ott", value: '41'},
        {name: "nov", value: '76'},
        {name: "dic", value: '34'},
      ];

      const riciclato = {
        labels: dati_mese_carta.map((data) => data.name),
        datasets: [
          {
            fill: true,
            label: "Carta",
            data: dati_mese_carta.map((data) => data.value),
            borderColor: '#60e6e1',
            backgroundColor: 'transparent'//'rgb(96, 230, 225, 0.5)'
          },
          {
            fill: true,
            label: "Vetro",
            data: dati_mese_vetro.map((data) => data.value),
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'transparent'//'rgba(53, 162, 235, 0.5)',
          },
          {
            fill: true,
            label: "Plastica",
            data: dati_mese_plastica.map((data) => data.value),
            borderColor: 'rgb(87, 225, 136)',
            backgroundColor: 'transparent'//'rgb(87, 225, 136, 0.5)',
          },
        ],
      };

      const differenziato = {
        labels: dati_mese_carta.map((data) => data.name),
        datasets: [
          {
            fill: true,
            label: "Umido",
            data: dati_mese_umido.map((data) => data.value),
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'transparent'//'rgba(53, 162, 235, 0.5)',
          },
          {
            fill: true,
            label: "Indifferenziata",
            data: dati_mese_indifferenziata.map((data) => data.value),
            borderColor: 'rgb(255, 154, 145)',
            backgroundColor: 'transparent'//'rgb(255, 154, 145, 0.5)',
          },
        ],
      };

      var dati_raccolta_vuoto = [
        {name: "Nessun Valore", value: 100},
      ];

      const color_empty = [ '#efefefef' ]

  return (
    <React.Fragment>
      {userInfo == null || datiAnno == null || utenze == null || mastelli == null || countType(datiAnno) < 2 ? (
        <div className="customTableContainer">
          <div className="container_spinner"><Spinner active  className="spinnerwebe"/></div>
        </div>
      ) : (
        <>
        <div className="customTableContainer">
        <>
          <Row>
            <Col xs="12" sm="4" md="4" className="map-profilo">
              <Mappa regione={userInfo.comuneResidenzaInfo.regione} width="90%"/>
            </Col>
            <Col xs="12" sm="8" md="8">
              <h3 className="title-grafico">Ente: {userInfo.denominazione.toUpperCase()}</h3>
              <h5 className="title-grafico">Tipo Ente: {userInfo.tipoEnte.toUpperCase()}</h5>
              <h5 className="title-grafico">Regione Comune Principale: {userInfo.comuneResidenzaInfo.regione.toUpperCase()}</h5>
              {/* <h5 className="title-grafico">Indirizzo: {userInfo.indirizzo.toUpperCase()} - {userInfo.citta.toUpperCase()} ({userInfo.provincia.toUpperCase()})</h5> */}
              <h5 className="title-grafico">Telefono: {userInfo.telefono}</h5>
              <h5 className="title-grafico">Email: {userInfo.email}</h5>
              {/* <h5 className="title-grafico">Email Pec: {userInfo.emailPec}</h5> */}
            </Col>
          </Row>
          <Row>
            <Col xs="12" sm="4" md="4">
                <h4 className="title-grafico text-center title-grafico-size">Rapporto Utenze Mastelli</h4>
                {/* <DonutChart idgrafico="ch2" dati={datiAnno} colori={colors_bis} vuoto={false}></DonutChart> */}
                {datiAnno.length < 1 &&
                      <DonutChart idgrafico="ch1" dati={dati_raccolta_vuoto} colori={color_empty} vuoto={true}></DonutChart>
                }
                {datiAnno.length > 0 &&
                  <DonutChart idgrafico="ch1" dati={datiAnno} colori={colors} vuoto={false}></DonutChart>
                }
            </Col>
            <Col xs="12" sm="8" md="8">
              <h3 className="title-grafico">Comune Riferimento: {userInfo.comuneResidenzaInfo.denominazione.toUpperCase()}</h3>
              <h5 className="title-grafico">Ubicazione: {userInfo.comuneResidenzaInfo.indirizzo} - {userInfo.comuneResidenzaInfo.citta} ({userInfo.comuneResidenzaInfo.provincia})</h5>
              <h5 className="title-grafico">Telefono: {userInfo.comuneResidenzaInfo.telefono}</h5>
              <h5 className="title-grafico">Email: {userInfo.comuneResidenzaInfo.email}</h5>
              <h5 className="title-grafico">Pec: {userInfo.comuneResidenzaInfo.emailPec}</h5>
              
                  {utenze != null ? (
                    <h4 className="title-grafico">Utenze Comune: {countType(utenze)} </h4>
                  )
                  :(<h4 className="title-grafico">Utenze Attuali: in elaborazione </h4>)
                  }
            </Col>
          </Row>
        </>
        </div>
        </>
      )}
        
    </React.Fragment>
  );
};

export default DashboardEnte;
