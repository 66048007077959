import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Spinner,
  Row,
  Col,
  Icon,
  UncontrolledTooltip,
  Table
} from "design-react-kit";
import { getUtentiComuneUtenza, getUtenteByUsername, insertUtente } from "../actions/utente_actions";
import CustomTable from "../../table/component/CustomTable";
import _ from "lodash";
import { showPopup } from "../../popup/actions/popup_actions";
import UtenteModal from "./UtenteModal";
import { showUtenteModal } from "../actions/utenteModal_actions";
import { showUtenzaModal } from "../../utenze/actions/utenzaModal_actions";
import UtenzaModal from "../../utenze/components/UtenzaModal";
import { insertUtenza } from "../../utenze/actions/utenze_actions"
import { getTipiImmobili } from "../../tipoImmobile/actions/tipoImmobile_actions";
import SearchPopup from "../../search_popup/components/SearchPopup";
import { showSearchPopup } from "../../search_popup/actions/search_popup_actions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAddressCard, faRefresh, faUser } from '@fortawesome/free-solid-svg-icons'

const Utente = () => {

  const dispatch = useDispatch();

  const utenti = useSelector((state) => state.utente.utentiComuneUtenza);
  const loading = useSelector((state) => state.utente.loading);
  const [dati, setDati] = useState([]);

  const tipiImmobili = useSelector((state) => state.tipoImmobile.tipiImmobili);

  const utenteToSearch = useSelector((state) => state.utente.utenteToSearch);
  const errorUtenteToSearch = useSelector((state) => state.utente.errorUtenteToSearch);

  const savedUser = useSelector((state) => state.utente.savedUser);

  const [userNameUser, setUserNameUser] = useState()

  const infoUtente = (u) => {
    return (
      <Table size="sm">
        <tbody>
          <tr>
            <th scope="row">Codice Fiscale</th>
            {u.userName === undefined &&
              <td>{u.login.username}</td>
            }
            {u.login === undefined &&
              <td>{u.userName}</td>
            }
          </tr>
          <tr>
            <th scope="row">Cognome</th>
            <td>{u.cognome}</td>
          </tr>
          <tr>
            <th scope="row">Nome</th>
            <td>{u.nome}</td>
          </tr>
          <tr>
            <th scope="row">Telefono</th>
            <td>{u.telefono}</td>
          </tr>
          <tr>
            <th scope="row">Email</th>
            <td>{u.email}</td>
          </tr>
          <tr>
            <th scope="row">Tipo Persona</th>
            <td>{u.tipoPersona}</td>
          </tr>
          <tr>
            <th scope="row">Comune di Registrazione</th>
            <td>{u.comuneResidenzaDen}</td>
          </tr>
        </tbody>
      </Table>
    );
  };

  useEffect(() => {
    if (utenti === undefined) {
      dispatch(getUtentiComuneUtenza());
    } else {
      const tmp = [];
      _.forEach(utenti, (u) => {
        u.proprietario = u.userName + " - " + u.cognome + " " + u.nome;
        u.actions = (
          <section>
            <span className="rounded-icon iconbottom"
              onClick={() =>
                dispatch(showPopup(
                  "notify",
                  "Informazioni Utente",
                  infoUtente(u)
                ))
              }
            >
            <FontAwesomeIcon icon={faUser} />
            </span>
          </section>
        );

        u.associa = (
          <section>
            <span className="rounded-icon iconbottom"
              onClick={() =>
                dispatch(showUtenzaModal(
                  "associate",
                  "Inserimento nuova Utenza",
                  insertUtenza,
                  u
                ))
              }
            >
            <FontAwesomeIcon icon={faAddressCard} />
            </span>
          </section>
        );


        tmp.push(u);
      });
      setDati(tmp);
    }
  }, [dispatch, utenti]);

  useEffect(() => {
    if (tipiImmobili === undefined) {
      dispatch(getTipiImmobili());
    }
  }, [tipiImmobili]);

  useEffect(() => {
    if (utenteToSearch !== undefined) {
      utenteToSearch.proprietario = utenteToSearch.userName + " - " + utenteToSearch.cognome + " " + utenteToSearch.nome;
      dispatch(showUtenzaModal(
          "associate",
          "Utente trovato con successo",
          insertUtenza,
          utenteToSearch, 
          "ASSOCIA UTENZA",
          infoUtente(utenteToSearch)
      ))
    }
  },[utenteToSearch]);

  useEffect(() => {
    if (errorUtenteToSearch !== undefined) {
      setUserNameUser(errorUtenteToSearch)
      dispatch(showUtenteModal(
          "insert",
          "Utente non trovato",
          insertUtente,
          errorUtenteToSearch,
          "INSERISCI",
          "Inserire nuovo utente?",
      ))
    }
  },[errorUtenteToSearch]);

  useEffect(() => {
    if (savedUser !== undefined) {
      savedUser.userName = savedUser.login.userName;
      savedUser.proprietario = savedUser.login.username + " - " + savedUser.cognome + " " + savedUser.nome;
      dispatch(showUtenzaModal(
          "forcedAssociation",
          "Utente salvato con successo",
          insertUtenza,
          savedUser, 
          "ASSOCIA UTENZA",
          infoUtente(savedUser)
      ))
    } 
  },[savedUser]);

  const refreshList = () => {
    dispatch(getUtentiComuneUtenza());
    dispatch(getTipiImmobili());
  };

  
  const headers = [
    {
      label: "Intestatario",
      field: "proprietario",
    },
    {
      label: "Associa",
      field: "associa",
    },
    {
      label: "Info Utenza",
      field: "actions",
    },
  ];


  return (
    <React.Fragment>
      <SearchPopup />
      <UtenteModal utenzaUserName={userNameUser}/>
      <UtenzaModal
        intestatarioOptions={utenti}
        tipoImmobileOptions={tipiImmobili}
      />
      {loading ? (
        <div className="customTableContainer">
          <div className="container_spinner"><Spinner active  className="spinnerwebe"/></div>
        </div>
      ) : (
        <div className="customTableContainer">
          <Row className="justify-content-md-center">
            <Col lg="9" xl="9" md="9" sm="9" xs="12">
              <h1 className="">Utenti <FontAwesomeIcon icon={faRefresh} className="padding-lr-icon linkeffect" onClick={()=> refreshList()}/></h1>
            </Col>
            <Col lg="3" xl="3" md="3" sm="3" xs="12" className="text-right">
              <Button
                color="primary"
                onClick={() =>
                  dispatch(showSearchPopup(
                    "Verifica esistenza Utente",
                    "Codice Fiscale",
                    getUtenteByUsername
                  ))
                }
                icon
                id="addNewUtente"
                style={{ marginBottom: "8px" }}
              >
                <FontAwesomeIcon icon={faUser} className="padding-lr-icon"/> {' '}Aggiungi
              </Button>
              <UncontrolledTooltip placement="top" target="addNewUtente">
                Aggiungi una nuovo Utente
              </UncontrolledTooltip>
            </Col>
          </Row>
          <CustomTable headers={headers} data={dati} />
        </div>
      )}
    </React.Fragment>
  );
};

export default Utente;
