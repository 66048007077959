import CommonService from "../../../service/common_service";
import { put } from "redux-saga/effects";
import { showPopup } from "../../popup/actions/popup_actions";
import { GET_QRCODES } from "../../qrCode/actions/qrCode_actions"

import axios from "axios"
import CommonServicePdf from "../../../service/common_service_pdf";

export const GET_DISPOSITIVI = "getDispositivi"
export const GET_DISPOSITIVI_SUCCESS = "get_dispositivi_success"
export const GET_DISPOSITIVI_FAIL = "get_dispositivi_fail"


export function getDispositivi() {
  return {
      type: GET_DISPOSITIVI
  }
}

export function* getDispositiviSaga() {
  try {

      const request = {
          method: "GET",
          url: "/api/v1/dispositivo/cerca",
      }

      const response = yield CommonService.execute(request)
      yield put({
          type: GET_DISPOSITIVI_SUCCESS,
          payload: response.data
      })

  } catch (error) {
      yield put({
          type: GET_DISPOSITIVI_FAIL,
          payload: error
      })
      yield put(
        showPopup(
            "error",
            "OPERAZIONE FALLITA",
            error.response.data.data.error
        )
    )
  }
}

export const INSERT_DISPOSITIVO = "insertDispositivo"
export const INSERT_DISPOSITIVO_SUCCESS = "insertDispositivo_success"
export const INSERT_DISPOSITIVO_FAILED = "insertDispositivo_failed"

export function insertDispositivo(codiceInterno, marca, matricola, modello, tipoDispositivo, comune, ente, operatore) {
    return {
        type: INSERT_DISPOSITIVO,
        payload: {
            codiceIdentificativo:codiceInterno, 
            marca:marca, 
            matricola:matricola, 
            modello:modello, 
            tipoDispositivo:tipoDispositivo, 
            comune:comune, 
            ente:ente, 
            operatore:operatore
        }
    }
}

export function* insertDispositivoSaga(action) {
    try {
        const request = {
            url: "/api/v1/dispositivo/insert", // crea un mastello e lo assegna all'utenza
            method: "POST",
            data: {
                codiceIdentificativo:action.payload.codiceIdentificativo, 
                marca:action.payload.marca, 
                matricola:action.payload.matricola, 
                modello:action.payload.modello, 
                tipoDispositivo:action.payload.tipoDispositivo, 
                comuneId:action.payload.comune, 
                enteId:action.payload.ente, 
                operatoreId:action.payload.operatore
            }
        }

        const response = yield CommonService.execute(request)
        yield put({
            type: GET_DISPOSITIVI
        })
        yield put(
            showPopup(
                "notify",
                "OPERAZIONE RIUSCITA",
                response.data.message
            )
        )
        yield put({
            type: GET_QRCODES
        })

    } catch (error) {
        yield put({
            type: INSERT_DISPOSITIVO_FAILED,
            payload: error.response.data.data.error
        })
        yield put(
            showPopup(
                "error",
                "OPERAZIONE FALLITA",
                error.response.data.message
            )
        )
    }
}

export const UPDATE_DISPOSITIVO = "updateDispositivo"
export const UPDATE_DISPOSITIVO_SUCCESS = "updateDispositivo_success"
export const UPDATE_DISPOSITIVO_FAILED = "updateDispositivo_failed"

export function updateDispositivo(id, codiceInterno, marca, matricola, modello, tipoDispositivo, comune, ente, operatore) {
    return {
        type: UPDATE_DISPOSITIVO,
        payload: {
            id:id,
            codiceIdentificativo:codiceInterno, 
            marca:marca, 
            matricola:matricola, 
            modello:modello, 
            tipoDispositivo:tipoDispositivo, 
            comune:comune, 
            ente:ente, 
            operatore:operatore
        }
    }
}

export function* updateDispositivoSaga(action) {
    try {
        const request = {
            url: "/api/v1/dispositivo/update",
            method: "PUT",
            data: {
                id:action.payload.id,
                codiceIdentificativo:action.payload.codiceIdentificativo, 
                marca:action.payload.marca, 
                matricola:action.payload.matricola, 
                modello:action.payload.modello, 
                tipoDispositivo:action.payload.tipoDispositivo, 
                comuneId:action.payload.comune, 
                enteId:action.payload.ente, 
                operatoreId:action.payload.operatore
            }
        }

        const response = yield CommonService.execute(request)
        yield put({
            type: GET_DISPOSITIVI
        })
        yield put(
            showPopup(
                "notify",
                "OPERAZIONE RIUSCITA",
                response.data.message
            )
        )

    } catch (error) {
        yield put({
            type: UPDATE_DISPOSITIVO_FAILED,
            payload: error.response.data.data.error
        })
        yield put(
            showPopup(
                "error",
                "OPERAZIONE FALLITA",
                error.response.data.message
            )
        )
    }
}

export const DELETE_DISPOSITIVO = "deleteDispositivo"
export const DELETE_DISPOSITIVO_SUCCESS = "delete_mastello_success"
export const DELETE_DISPOSITIVO_FAILED = "delete_mastello_fail"

export function deleteDispositivo(dispositivoId) {
    return {
        type: DELETE_DISPOSITIVO,
        payload: dispositivoId
    }
}

export function* deleteDispositivoSaga(action) {
    try {
        const url = "/api/v1/dispositivo/delete/" + action.payload // Elimina un Dispositivo e riporta a false lo stato del QrCode associato
        const request = {
            method: "DELETE",
            url: url,
        }

        const response = yield CommonService.execute(request)
        yield put({
            type: GET_DISPOSITIVI
        })
        yield put({
            type: GET_QRCODES
        })
        yield put(
            showPopup(
                "notify",
                "OPERAZIONE RIUSCITA",
                response.data.message
            )
        )  

    } catch (error) {
        yield put({
            type: DELETE_DISPOSITIVO_FAILED,
            payload: error.response.data.data.error
        })
        yield put(
            showPopup(
                "error",
                "OPERAZIONE FALLITA",
                error.response.data.data.error
            )
        )
    }
}

export const STAMPA_DISPOSITIVO = "stampaDispositivo"
export const STAMPA_DISPOSITIVO_SUCCESS = "stampa_mastello_success"
export const STAMPA_DISPOSITIVO_FAILED = "stampa_mastello_fail"

export function stampaDispositivo(id_utenza,comune, 
                                comune_indirizzo, 
                                data, 
                                num_mastello, 
                                tipo_mastello, 
                                nome_cognome, 
                                cod_fisc,
                                indirizzo, 
                                comune_utenza) {
    return {
        type: STAMPA_DISPOSITIVO,
        payload: {
             id_utenza: id_utenza,
             comune: comune, 
             comune_indirizzo: comune_indirizzo, 
             data: data, 
             num_mastello: num_mastello, 
             tipo_mastello: tipo_mastello, 
             nome_cognome: nome_cognome, 
             cod_fisc: cod_fisc,
             indirizzo: indirizzo, 
             comune_utenza: comune_utenza

        }
    }
}

export function* stampaDispositivoSaga(action) {
    try {
        //const url = "/api/v1/mastello/export/pdf/" + action.payload // Elimina un Dispositivo e riporta a false lo stato del QrCode associato generatePdfFile
        const url = "/api/v1/mastello/generatePdfFile"// + action.payload // Elimina un Dispositivo e riporta a false lo stato del QrCode associato generatePdfFile
        const request = {
            method: "POST",
            url: url,
            data: {
                id_utenza: action.payload.id_utenza,
                comune: action.payload.comune, 
                comune_indirizzo: action.payload.comune_indirizzo, 
                data: action.payload.data, 
                num_mastello: action.payload.num_mastello, 
                tipo_mastello: action.payload.tipo_mastello, 
                nome_cognome: action.payload.nome_cognome, 
                cod_fisc: action.payload.cod_fisc,
                indirizzo: action.payload.indirizzo, 
                comune_utenza: action.payload.comune_utenza,
            }
        }

        const response_pdf = yield CommonServicePdf.execute(request)

        const file = new Blob([response_pdf.data], { type: 'application/pdf' })
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, '_blank');


        // axios.get(`http://localhost:8080/api/v1/mastello/generatePdfFile`, {
        //     request,
        //     responseType: 'blob'
        //   }).then((response) => {
        //     window.open(URL.createObjectURL(response.data));
        //   })
        
        // yield put({
        //   type: GET_DISPOSITIVI,
        //   payload: undefined
        // })
        // yield put({
        //     type: GET_QRCODES
        // })
        yield put(
            showPopup(
                "notify",
                "OPERAZIONE RIUSCITA - Stampa ricevuta generata ed inviata",
                response_pdf.data.message
            )
        )  

        // let blobData = new Blob([file.body], {
        //     type: 'application/pdf'
        // });


        // console.warn(urlFile);
        // this.uploadService.getFile(urlFile).subscribe((responseMessage) => {
    
        //   const extensionFile = urlFile.slice((Math.max(0, urlFile.lastIndexOf(".")) || Infinity) + 1);
        //   console.warn(extensionFile);
        //   let typeFile = 'application/pdf';
        //   if(extensionFile === 'jpg' ){ typeFile = 'image/jpeg'}
        //   if(extensionFile === 'png' ){ typeFile = 'image/png'}
        //   const file = new Blob([responseMessage], { type: typeFile })
        //   const fileURL = URL.createObjectURL(file);
        //   window.open(fileURL, '_blank');
        // });

        


        // let fileData = new File([response.data], "name", {type: 'application/pdf'});
        // let fileURL = window.URL.createObjectURL(fileData);
        // window.open(fileURL,"_blank")

    } catch (error) {
        yield put({
            type: STAMPA_DISPOSITIVO_FAILED,
            payload: error.response.data.data.error
        })
        yield put(
            showPopup(
                "error",
                "OPERAZIONE FALLITA",
                error.response.data.data.error
            )
        )
    }
}


