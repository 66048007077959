import {
  GET_CALENDARI, GET_CALENDARI_SUCCESS, GET_CALENDARI_FAIL,
  GET_CALENDARI_BY_COLLABORAZIONE, GET_CALENDARI_BY_COLLABORAZIONE_SUCCESS, GET_CALENDARI_BY_COLLABORAZIONE_FAIL,
  INSERT_CALENDARIO, INSERT_CALENDARIO_SUCCESS, INSERT_CALENDARIO_FAILED,
  UPDATE_CALENDARIO, UPDATE_CALENDARIO_SUCCESS, UPDATE_CALENDARIO_FAILED,
  DELETE_CALENDARIO, DELETE_CALENDARIO_SUCCESS, DELETE_CALENDARIO_FAILED
} from "../actions/calendario_actions"
import _ from "lodash"

export function calendarioReducer(state = { loading: false, error: undefined, calendari: undefined, calendariByCollaborazione: undefined }, action) {

  if (_.isEmpty(action) || _.isEmpty(action.type)) return state

  const newState = state

  switch (action.type) {
    case GET_CALENDARI:
      newState.loading = true
      newState.error = undefined
      newState.calendari = undefined
      break;
    case GET_CALENDARI_SUCCESS:
      newState.loading = false
      newState.error = undefined
      newState.calendari = action.payload
      break;
    case GET_CALENDARI_FAIL:
      newState.loading = false
      newState.error = action.payload
      newState.calendari = undefined
      break;
    case GET_CALENDARI_BY_COLLABORAZIONE:
      newState.calendariByCollaborazione = undefined
      break;
    case GET_CALENDARI_BY_COLLABORAZIONE_SUCCESS:
      newState.calendariByCollaborazione = action.payload
      break;
    case GET_CALENDARI_BY_COLLABORAZIONE_FAIL:
      newState.calendariByCollaborazione = undefined
      break;
    case INSERT_CALENDARIO:
      break;
    case INSERT_CALENDARIO_SUCCESS:
      break;
    case INSERT_CALENDARIO_FAILED:
      break;
    case UPDATE_CALENDARIO:
      break;
    case UPDATE_CALENDARIO_SUCCESS:
      break;
    case UPDATE_CALENDARIO_FAILED:
      break;
    case DELETE_CALENDARIO:
      break;
    case DELETE_CALENDARIO_SUCCESS:
      break;
    case DELETE_CALENDARIO_FAILED:
      break;
    default:
      break;
  }

  return Object.assign({}, state, newState)
}
