import CommonService from "../../common/service/common_service"
import { put } from "redux-saga/effects"

export const GET_ENTI = "getEnti"
export const GET_ENTI_SUCCES = "get_enti_success"
export const GET_ENTI_FAIL = "get_enti_fail"

export function getEnti() {
    return {
        type: GET_ENTI
    }
}

export function* getEntiSaga() {
    try {

        const request = {
            method: "GET",
            url: "/api/v1/ente/cerca",
        }

        const response = yield CommonService.execute(request)
        yield put({
            type: GET_ENTI_SUCCES,
            payload: response.data
        })

    } catch (error) {
        yield put({
            type: GET_ENTI_FAIL,
            payload: error
        })
    }
}