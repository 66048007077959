import {
    GET_ALL_UTENTI, GET_ALL_UTENTI_SUCCESS, GET_ALL_UTENTI_FAIL,
    GET_UTENTI_COMUNE_UTENZA, GET_UTENTI_COMUNE_UTENZA_SUCCESS, GET_UTENTI_COMUNE_UTENZA_FAIL,
    GET_UTENTE_BY_USERNAME, GET_UTENTE_BY_USERNAME_SUCCESS, GET_UTENTE_BY_USERNAME_FAIL, GET_UTENTE_BY_USERNAME_CLEAN,
    INSERT_UTENTE, INSERT_UTENTE_SUCCESS, INSERT_UTENTE_FAILED,
    UPDATE_UTENTE, UPDATE_UTENTE_SUCCESS, UPDATE_UTENTE_FAILED,
    DELETE_UTENTE, DELETE_UTENTE_SUCCESS, DELETE_UTENTE_FAILED
} from "../actions/utente_actions"
import _ from "lodash"

export function utenteReducer(state = { 
    loading: false, savedUser: undefined ,allUtenti: undefined, utentiComuneUtenza: undefined, utenteToSearch: undefined, errorUtenteToSearch: undefined}, 
    action) {
    if (_.isEmpty(action) || _.isEmpty(action.type)) return state
    const newState = state
    switch (action.type) {
        case GET_ALL_UTENTI:
            newState.allUtenti = undefined
            newState.utenteToSearch = undefined
            break;
        case GET_ALL_UTENTI_SUCCESS:
            newState.allUtenti = action.payload
            newState.utenteToSearch = undefined
            break;
        case GET_ALL_UTENTI_FAIL:
            newState.allUtenti = undefined
            newState.utenteToSearch = undefined
            break;
        case GET_UTENTI_COMUNE_UTENZA:
            newState.loading = true
            newState.utentiComuneUtenza = undefined
            newState.utenteToSearch = undefined
            newState.savedUser = undefined
            break;
        case GET_UTENTI_COMUNE_UTENZA_SUCCESS:
            newState.loading = false
            newState.utentiComuneUtenza = action.payload
            newState.utenteToSearch = undefined
            newState.savedUser = undefined
            break;
        case GET_UTENTI_COMUNE_UTENZA_FAIL:
            newState.loading = false
            newState.utentiComuneUtenza = undefined
            newState.utenteToSearch = undefined
            newState.savedUser = undefined
            break;
        case GET_UTENTE_BY_USERNAME:
            newState.utenteToSearch = undefined
            break;
        case GET_UTENTE_BY_USERNAME_SUCCESS:
            newState.utenteToSearch = action.payload
            newState.errorUtenteToSearch = undefined
            break;
        case GET_UTENTE_BY_USERNAME_FAIL:
            newState.utenteToSearch = undefined
            newState.errorUtenteToSearch = action.payload
            break;
        case GET_UTENTE_BY_USERNAME_CLEAN:
            newState.utenteToSearch = undefined
            newState.errorUtenteToSearch = undefined
            break;
        case INSERT_UTENTE:
            newState.utenteToSearch = undefined
            newState.savedUser = undefined
            break;
        case INSERT_UTENTE_SUCCESS:
            newState.utenteToSearch = undefined
            newState.savedUser = action.payload
            break;
        case INSERT_UTENTE_FAILED:
            newState.utenteToSearch = undefined
            newState.savedUser = undefined
            break;
        case UPDATE_UTENTE:
            newState.utenteToSearch = undefined
            break;
        case UPDATE_UTENTE_SUCCESS:
            newState.utenteToSearch = undefined
            break;
        case UPDATE_UTENTE_FAILED:
            newState.utenteToSearch = undefined
            break;
        case DELETE_UTENTE:
            newState.utenteToSearch = undefined
            break;
        case DELETE_UTENTE_SUCCESS:
            newState.utenteToSearch = undefined
            break;
        case DELETE_UTENTE_FAILED:
            newState.utenteToSearch = undefined
            break;
        default:
            break;
    }
    return Object.assign({}, state, newState)
}