import axios from "axios"

export default class CommonServicePdf {

    static url(relativePath){
        if(process.env.REACT_APP_API_LOCATION){
            return process.env.REACT_APP_API_LOCATION+relativePath
        }
        return relativePath
    }

    static initAxios(){

        const b = localStorage.getItem("jwtToken")
        axios.defaults.withCredentials = true
        axios.defaults.crossDomain = true
        axios.defaults.headers.post['Content-Type'] = 'application/json'
        axios.defaults.headers.post['Accept'] = 'application/json'
        axios.defaults.headers.post['Authorization'] = 'Bearer ' + localStorage.getItem("jwtToken")
        axios.defaults.responseType = 'blob'
    }

    static execute(request){
        this.initAxios()
        request.headers = {
            Authorization: 'Bearer ' + localStorage.getItem("jwtToken")
        }
        request.url = this.url(request.url)
        return axios(request)
    }


}