import axios from "axios"


export default class CommonService {

    static url(relativePath){
        if(process.env.REACT_APP_API_LOCATION){
            return process.env.REACT_APP_API_LOCATION+relativePath
        }
        return relativePath
    }

    static initAxios(){

        const b = localStorage.getItem("jwtToken")
        axios.defaults.withCredentials = true
        axios.defaults.crossDomain = true
        axios.defaults.headers.post['Content-Type'] = 'application/json'
        axios.defaults.headers.post['Accept'] = 'application/json'
        axios.defaults.headers.post['Authorization'] = 'Bearer ' + localStorage.getItem("jwtToken")
    }

    static execute(request){
        if(localStorage.getItem("jwtToken") == null || localStorage.getItem("jwtToken") == ""){
            if(request.url !== '/api/v1/auth'){
                const u = process.env.REACT_APP_BASE_URL_WEB;
                return window.location.replace(u);
            }
        }
        this.initAxios()
        request.headers = {
            Authorization: 'Bearer ' + localStorage.getItem("jwtToken")
        }
        request.url = this.url(request.url)
        return axios(request)
    }


}