export const SHOW_PESATA_MODAL = "showPesataModal"
export const HIDE_PESATA_MODAL = "hide_pesata_modal"

export function showPesataModal(type, header, f, obj){
  return {
      type: SHOW_PESATA_MODAL,
      payload:{
        type: type,
        header: header,
        function: f,
        obj: obj
    }
  }
}

export function hidePesataModal(){
  return {
      type: HIDE_PESATA_MODAL
  }
}