import Calendario from "../../common/components/calendario/components/Calendario"
//import DashboardUtente from "../../dashboard/utente"
import Mastello from "../../common/components/mastello/components/Mastello"
import QrCode from "../../common/components/qrCode/components/QrCode"
import RichiestaIngombranti from "../../common/components/richiestaIngombranti/components/RichiestaIngombranti"
import Utenze from "../../common/components/utenze/components/Utenze"
import ProfiloUtente from "../../common/components/profilo/"

import Operatori from "../../common/components/operatori/components/Operatori"
import DashboardEnte from "../../common/components/dashboard/ente"
import Dispositivo from "../../common/components/dispositivi/components/Dispositivo"
import Pesata from "../../common/components/pesata/components/Pesata"
import Raccolta from "../../common/components/raccolta/components/Raccolta"


const enteRoutes = [
    {
        path: "/dashboard",
        name: "Home",
        icon: "",
        component: DashboardEnte,
        layout: "/ente",
        icon: "faHome"
    },
    {
        path: "/profilo",
        name: "Profilo",
        icon: "",
        component: ProfiloUtente,
        layout: "/ente",
        icon: "faHome"
    },
    {
        path: "/gestione-pesata",
        name: "Gestione Pesata",
        icon: "",
        component: Raccolta,
        layout: "/ente",
        icon: "faHome"
    },
    {
        path: "/opertatori",
        name: "Operatori",
        icon: "",
        component: Operatori,
        layout: "/ente",
        icon: "faHome"
    },
    {
        path: "/dispositivi",
        name: "Dispositivi",
        icon: "",
        component: Dispositivo,
        layout: "/ente",
        icon: "faHome"
    },
    {
        path: "/calendari",
        name: "Calendari",
        icon: "",
        component: Calendario,
        layout: "/ente",
        icon: "faCalendar"
    },
    {
        path: "/mastelli",
        name: "Mastelli",
        icon: "",
        component: Mastello,
        layout: "/ente",
        icon: "faCalendar"
    },
    {
        path: "/richieste-ingombranti",
        name: "Richieste Ingombranti",
        icon: "",
        component: RichiestaIngombranti,
        layout: "/ente",
        icon: "faCalendar"
    },
]

export default enteRoutes