
import DashboardOperatore from "../../dashboard/operatore"
import Calendario from "../../calendario/components/Calendario"
import Mastello from "../../mastello/components/Mastello"
import RichiestaIngombranti from "../../richiestaIngombranti/components/RichiestaIngombranti"
import ProfiloOperatore from "../../../../common/components/profilo"
import Dispositivo from "../../dispositivi/components/Dispositivo"
import Pesata from "../../pesata/components/Pesata"
import Raccolta from "../../raccolta/components/Raccolta"


const operatoreRoutes = [
    {
        path: "/dashboard",
        name: "Home",
        //icon: "",
        component: DashboardOperatore,
        layout: "/operatore",
        ////icon: "faHome"
    },
    {
        path: "/profilo",
        name: "Profilo",
        //icon: "",
        component: ProfiloOperatore,
        layout: "/operatore",
        //////icon: "faHome"
    },
    {
        path: "/gestione-pesata",
        name: "Gestione Pesata",
        //icon: "",
        component: Raccolta,
        layout: "/operatore",
        //////icon: "faHome"
    },
    {
        path: "/calendari",
        name: "Calendari",
        //icon: "",
        component: Calendario,
        layout: "/operatore",
        icon: "faCalendar"
    },
    {
        path: "/mastelli",
        name: "Mastelli",
        //icon: "",
        component: Mastello,
        layout: "/operatore",
        icon: "faCalendar"
    },
    {
        path: "/dispositivi",
        name: "Dispositivi",
        //icon: "",
        component: Dispositivo,
        layout: "/operatore",
        ////icon: "faHome"
    },
    {
        path: "/richieste-ingombranti",
        name: "Richieste Ingombranti",
        //icon: "",
        component: RichiestaIngombranti,
        layout: "/operatore",
        //icon: "faCalendar"
    },
]

export default operatoreRoutes