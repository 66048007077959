import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Select,
  ModalFooter,
  Input,
  Row,
  Col
} from "design-react-kit";
import { hideScontisticaModal } from "../actions/scontisticaModal_actions";
import _ from "lodash";
import { getScontistica } from "../actions/scontistica_actions";
import { showPopup } from "../../popup/actions/popup_actions";
import PopUp from "../../popup/components/PopUp";

const ScontisticaModal = (props) => {
  const dispatch = useDispatch();

  const [scontistica, setScontistica] = useState();
  const [tipologiaSpazzatura, setTipologiaSpazzatura] = useState();
  const [valoreQrCode, setValoreQrCode] = useState();
  const [statoQrCode, setStatoQrCode] = useState();

  const [tariffa, setTariffa] = useState();
  const [sconto, setSconto] = useState();

  const [tipologieSpazzaturaOptions, setTipologieSpazzaturaOptions] = useState();


  const scontisticaToEdit = useSelector((state) => state.scontisticaModal.obj);

  const type = useSelector((state) => state.scontisticaModal.type);
  const header = useSelector((state) => state.scontisticaModal.header);
  const show = useSelector((state) => state.scontisticaModal.show);
  const f = useSelector((state) => state.scontisticaModal.function);

  let listaErroriTmp = [];
  const [listaErrori, setListaErrori] = useState([]);

  function erroriForm(listaErroriInfo){
    return listaErroriInfo.map(function (errore) {
      return(
        <p className="text-mess-alert">{errore}</p>
      )})  
  }

  function close() {
    dispatch(hideScontisticaModal());
  }

  function confirm() {

    
    
    let tariffaN = 0
    let scontoN = 0

    if(tariffa == null || tariffa === '') listaErroriTmp.push("- Devi inserire una tariffa valida")
    if(sconto == null && sconto == '')listaErroriTmp.push("- Devi inserire una tariffa scontata valida")

    // if(tariffa == null) setTariffa(0)
    // if(sconto == null) setSconto(0)
    listaErroriTmp = []
    let tariffaNumero = '';
    if(tariffa != null) tariffaNumero = String(tariffa).replaceAll(",","")
    if(tariffaNumero != null) tariffaNumero = tariffaNumero.replaceAll(".","")
    let scontoNumero = '';
    if(sconto != null) scontoNumero = String(sconto).replaceAll(",","")
    if(scontoNumero != null) scontoNumero = scontoNumero.replaceAll(".","")

    let tariffaNum = /^\d+$/.test(tariffaNumero);
    let scontoNum = /^\d+$/.test(scontoNumero);

    if(!tariffaNum) listaErroriTmp.push("- Tariffa deve essere un numero")
    if(!scontoNum) listaErroriTmp.push("- Tariffa Scontata deve essere un numero")
    if(tariffa !=null && tariffa != '' && Number(tariffa) < 0)listaErroriTmp.push("- Tariffa non può essere un numero regativo")
    if(sconto !=null && sconto != '' && Number(sconto) < 0)listaErroriTmp.push("- Tariffa scontata non può essere un numero regativo")

    if(tipologiaSpazzatura == null || tipologiaSpazzatura.value === '') listaErroriTmp.push("- Devi inserire una tipologia di rifiuto valida")

    if(Number(tariffa) < Number(sconto)) listaErroriTmp.push("- Tariffa scontata non può essere maggiore della tariffa piena!")

    if(listaErroriTmp.length == 0){
      switch (type) {
        case "insert":
          
          var tariffaS = tariffa.toString()
          tariffaS = tariffaS.replaceAll(",",".")
          
          var scontoS = sconto.toString()
          scontoS = scontoS.replaceAll(",",".")
  
          tariffaN = Number(tariffaS)
          scontoN = Number(scontoS)
          let presente = false;
          _.forEach(props.scontistica, (s) => {

            if(s.tipologiaSpazzatura.id == tipologiaSpazzatura.value) presente = true

          })
          
          if(presente){
            const messaggio = "Hai già inserito la tariffa per il rifiuto: " + tipologiaSpazzatura.label
            dispatch(showPopup(
              "notify",
              "Attenzione",
              messaggio
            ))
          }else{
            dispatch(f(props.comune.id, tipologiaSpazzatura.value, tariffaN, scontoN));
          }
          
          break;
        case "edit":
          
          var tariffaS = tariffa.toString()
          tariffaS = tariffaS.replaceAll(",",".")
          
          var scontoS = sconto.toString()
          scontoS = scontoS.replaceAll(",",".")
  
           tariffaN = Number(tariffaS)
           scontoN = Number(scontoS)
  
          dispatch(f(scontisticaToEdit.id, scontisticaToEdit.comune.id, tipologiaSpazzatura.value, tariffaN, scontoN));
          close();
          break;
        default:
          close();
      }
      setListaErrori([])
      close();
    }else{
      setListaErrori(listaErroriTmp)
    }
    
  }

  useEffect(() => {
    if (scontistica === undefined) {
      dispatch(getScontistica());
    }
  }, [scontistica])

  
  const defaultTipologiaSpazzaturaValue = () => {
    switch (type) {
      case "insert":
        break;
      case "edit":
        return {
          label: scontisticaToEdit.tipologiaSpazzatura.descrizione,
          value: scontisticaToEdit.tipologiaSpazzatura.id
        };
      default:
        return {
          label: undefined,
          value: undefined,
        };
    }
  };




  useEffect(() => {
    if (props.tipologieSpazzaturaOptions === undefined) {
      setTipologieSpazzaturaOptions([]);
    } else {
      const tmp = [];
      _.forEach(props.tipologieSpazzaturaOptions, (ts) => {
        // _.forEach(props.scontistica, (sc) => {
        //   let trovato = false
        //   if(!trovato){
        //   if(sc.tipologiaSpazzatura.descrizione == ts.descrizione){
              tmp.push({ value: ts.id, label: ts.descrizione });
        //       trovato = true;
        //   }
        // }
        // })
        
      });

      setTipologieSpazzaturaOptions(tmp);
    }
  }, [props.tipologieSpazzaturaOptions]);


  useEffect(() => {
    if (type !== "edit" && scontisticaToEdit === undefined) {
      setTipologiaSpazzatura(undefined);
      setSconto(undefined)
      setTariffa(undefined)
    } else {

      setTipologiaSpazzatura({
        label: scontisticaToEdit.tipologiaSpazzatura.descrizione,
        value: scontisticaToEdit.tipologiaSpazzatura.id
      });

      setSconto(scontisticaToEdit.sconto)
      setTariffa(scontisticaToEdit.tariffa)

    }
  }, [type, scontisticaToEdit]);

  return (
    <div>
      <PopUp></PopUp>
      <Modal isOpen={show} labelledBy="utenzaModal" size="lg">
        <ModalHeader id="mastelloModal">{header}</ModalHeader>
        <ModalBody>
          <FormGroup>
            <div className="bootstrap-select-wrapper">
              <label htmlFor="selectTipologiaSpazzatura">Tipologia spazzatura</label>
              <Select
                id="tipologiaSpazzatura"
                onChange={(selectedOption) => {
                  setTipologiaSpazzatura(selectedOption);
                }}
                options={tipologieSpazzaturaOptions}
                defaultValue={defaultTipologiaSpazzaturaValue()}
                placeholder="Seleziona tipologia spazzatura"
                aria-label="Seleziona tipologia spazzatura"
              />
            </div>
          </FormGroup>
          <FormGroup>
          <div className="bootstrap-select-wrapper">
          <Input
                id="tariffa"
                onChange={(e) => {
                  setTariffa(e.target.value);
                }}
                value={tariffa}
                type="text"
                placeholder="Inserisci Tariffa"
                aria-label="Inserisci Tariffa"
              />
          </div>
          
          </FormGroup>
          <FormGroup>
          <div className="bootstrap-select-wrapper">
          <Input
                id="sconto"
                onChange={(e) => {
                  setSconto(e.target.value);
                }}
                value={sconto}
                type="text"
                placeholder="Inserisci Tariffa Scontata"
                aria-label="Inserisci Tariffa Scontata"
              />
          </div>
          
          </FormGroup>
          {listaErrori.length>0 &&
            <Row>
                <Col>
                  {erroriForm(listaErrori)}
                </Col>
            </Row>
          }
        </ModalBody>
        <ModalFooter>
          <span>
            <Button outline color='primary' onClick={() => close()}>ANNULLA</Button>
          </span>
          <span>
            <Button outline color='primary' onClick={() => confirm()}>CONFERMA</Button>
          </span>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ScontisticaModal;
