import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  ModalFooter,
  Input,
  Select,
  Row,
  Col
} from "design-react-kit";
import { hideEnteModal, showEnteModal } from "../actions/entiModal_actions"
import { getUserInfo } from "../../dashboard/dashboard_Action";
import _ from "lodash";

const EntiModal = (props) => {

  const dispatch = useDispatch();

  const userInfo = useSelector(
    (state) => state.userInfo.userInfo
  );

  const [denominanzione, setDenominanzione] = useState();
  const [tipoEnte, setTipoEnte] = useState();
  const [telefono, setTelefono] = useState();
  const [email, setMail] = useState();

  const [username, setUsername] = useState();
  const [password, setPassword] = useState();

  const [loginOp, setLoginOP] = useState();
  const [ente, setEnte] = useState();
  const [comune, setComune] = useState();

  const entiToEdit = useSelector((state) => state.entiModal);
  const [collaborazioniOptions, setCollaborazioniOptions] = useState();

  const type = useSelector((state) => state.entiModal.type);
  const header = useSelector((state) => state.entiModal.header);
  const show = useSelector((state) => state.entiModal.show);
  const f = useSelector((state) => state.entiModal.function);


  const login = {
    "username": username,
    "password": password,
    "tipologiaLogin": "ENTE"
  }

  const [listaErrori, setListaErrori] = useState([]);
  let listaErroriTmp = [];
  function isValidEmailAddress(address) {
    if(address == null || address === '') return false;
    return !! address.match(/.+@.+/);
  }
  function erroriForm(listaErroriInfo){
    return listaErroriInfo.map(function (errore) {
      return(
        <p className="text-mess-alert">{errore}</p>
      )})  
  }

  const confirm = () => {

    listaErroriTmp = []
    let telefonoIsNum = /^\d+$/.test(telefono);
    if(type === "insert" ){
      if(username != null || username != ''){
        let codiceFiscale = /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/.test(username);
        let partitaIva = /^[0-9]{11}$/.test(username);
        if(!codiceFiscale && !partitaIva) listaErroriTmp.push("- Inserire una Partita Iva o Codice Fiscale")
      }
    }
    
    let emailValid = isValidEmailAddress(email);
    if(denominanzione == null || denominanzione === '') listaErroriTmp.push("- Denominazione è un campo obbligatorio")
    if(denominanzione != null && denominanzione.length < 4) listaErroriTmp.push("- Denominazione deve essere almeno di 2 caratteri")
    if(tipoEnte == null || tipoEnte.value === '') listaErroriTmp.push("- Tipo Ente è un campo obbligatorio")

    if(!telefonoIsNum) listaErroriTmp.push("- Telefono deve essere un numero valido")
    if(!emailValid) listaErroriTmp.push("- Email non è nel formato corretto")
    if(telefonoIsNum && telefono.length < 6) listaErroriTmp.push("- Telefono deve essere almeno di 6 caratteri")
    if(type === "insert" ){
      if(username == null || username === '') listaErroriTmp.push("- Codice Fiscale/Piva è un campo obbligatorio")
      if(username != null && (username.length != 11 && username.length != 16 )) listaErroriTmp.push("- Codice Fiscale/Piva deve essere di 16 o 11 caratteri")
      if(password == null || password === '') listaErroriTmp.push("- Password è un campo obbligatorio")
      if(password != null && password.length < 8) listaErroriTmp.push("- Password deve essere almeno di 8 caratteri")
    }
    
    //if(ente == null || ente === '') listaErroriTmp.push("- Ente è un campo obbligatorio")
   

    if(listaErroriTmp.length == 0){
      switch (type) {
        case "insert":
          const loginInsert = {
            "username": username,
            "password": password,
            "tipologiaLogin": "ENTE"
          }
          dispatch(f(denominanzione, tipoEnte.value, telefono, email, loginInsert, comune));
          break;
        case "edit":
          let enteId = ente.value
          if(ente.value === undefined || ente.value === null){
            enteId = ente.id
          }
  
          const loginEdit = {
            "id": entiToEdit.obj.ente.login.id,
            "username": entiToEdit.obj.ente.login.username,
            "password": entiToEdit.obj.ente.login.password,
            "tipologiaLogin": "ENTE"
          }
  
          dispatch(f(entiToEdit.obj.ente.id, denominanzione, tipoEnte.value, telefono, email, loginEdit, comune.id));
          close();
          break;
        default:
          close();
      }
      setListaErrori([])
      close();

    }else{
      setListaErrori(listaErroriTmp)
    }
    
  };

  const close = () => dispatch(hideEnteModal());

  console.log(entiToEdit.obj)

  useEffect(() => {
    if (userInfo === undefined) {
      dispatch(getUserInfo());
    }
  }, [userInfo]);

  useEffect(() => {
    if (type !== "edit" && entiToEdit.obj === undefined) {
      setDenominanzione(undefined);
      setTipoEnte(undefined);
      setTelefono(undefined);
      setMail(undefined);
      setLoginOP(undefined);
      setEnte(undefined);
      if( userInfo!=null && userInfo.tipologiaUtente === "COMUNE"){
        setComune(userInfo.id);
      }
      if(userInfo!=null && userInfo.tipologiaUtente === "ENTE"){
        setComune(userInfo.comuneResidenzaId);
      }

    } else {
      setDenominanzione(entiToEdit.obj.enteriferimento);
      setTipoEnte(entiToEdit.obj.tipoEnte);
      setTelefono(entiToEdit.obj.telefono);
      setMail(entiToEdit.obj.email);
      setLoginOP(entiToEdit.obj.loginOp);
      setEnte(entiToEdit.obj.ente);
      setComune(entiToEdit.obj.comune);
    }
  }, [type, entiToEdit]);

  useEffect(() => {
    if (props.enti === undefined) {
      setCollaborazioniOptions([]);
    } else {
      const tmp = [];
      _.forEach(props.enti, (c) => {
        tmp.push({ value: c.ente.id, label: c.ente.denominazione });
      });
      setCollaborazioniOptions(tmp);
    }
  }, [props.enti]);

  const defaultCollaborazioneValue = () => {

    console.log(entiToEdit)
    switch (type) {
      case "insert":
        break;
      case "edit":
        return {
          label: entiToEdit.obj.ente.denominazione,
          value: entiToEdit.id,
        };
      default:
        return {
          label: undefined,
          value: undefined,
        };
    }
  };

  const defaultTipoEnteValue = () => {
    switch (type) {
      case "insert":
        break;
      case "edit":
        return {
          label: entiToEdit.obj.tipoEnte,
          value: entiToEdit.obj.tipoEnte
        };
      default:
        return {
          label: undefined,
          value: undefined,
        };
    }
  };

  
  return (
    <div>
      <Modal isOpen={show} labelledBy="operatoreModal">
        <ModalHeader id="operatoreModal">{header}</ModalHeader>
        <ModalBody>
          <FormGroup>
            <div className="bootstrap-select-wrapper">
              {/* <label htmlFor="selectDescrizione">Descrizione</label> */}
              {
                 userInfo!=null && userInfo.tipologiaUtente === "SUPER_USER" &&

                 <div className="form-group">
                <Select
                  classNamePrefix="react-select form-group"
                  id="comune"
                  onChange={(selectedOption) => {
                    setComune(selectedOption);
                  }}
                  options={collaborazioniOptions}
                  defaultValue={defaultCollaborazioneValue()}
                  placeholder="Seleziona Comune"
                  aria-label="Seleziona Comune"
                />
              </div>
              }
              
              <Input
                id="denominazione"
                onChange={(e) => {
                  setDenominanzione(e.target.value);
                }}
                value={denominanzione}
                type="text"
                placeholder="Inserisci Denominazione"
                aria-label="Inserisci Denominazione"
              />
              {/* <Input
                id="tipoEnte"
                onChange={(e) => {
                  setTipoEnte(e.target.value);
                }}
                value={tipoEnte}
                type="text"
                placeholder="Inserisci Tipo Ente"
                aria-label="Inserisci Tipo Ente"
              /> */}
              <div className="bootstrap-select-wrapper form-group">
                <Select
                id="tipoEnte"
                onChange={(selectedOption) => {
                  setTipoEnte(selectedOption);
                }}
                options={props.tipoEnteLista}
                defaultValue={defaultTipoEnteValue()}
                placeholder="Seleziona Tipo Ente"
                aria-label="Seleziona Tipo Ente"
                />
              </div>
              <Input
                id="telefono"
                onChange={(e) => {
                  setTelefono(e.target.value);
                }}
                value={telefono}
                type="text"
                placeholder="Inserisci Telefono"
                aria-label="Inserisci Telefono"
              />
              <Input
                id="Email"
                onChange={(e) => {
                  setMail(e.target.value);
                }}
                value={email}
                type="text"
                placeholder="Inserisci Email"
                aria-label="Inserisci Email"
              />
              {type == "insert" &&
              <>
              <Input
                id="Username"
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
                value={username}
                type="text"
                placeholder="Inserisci Codice Fiscale/P/va"
                aria-label="Inserisci Codice Fiscale/PIva"
              />
              <Input
                id="Password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                value={password}
                type="text"
                placeholder="Inserisci Password"
                aria-label="Inserisci Password"
              />
              </>
              }
              {type == "edit" &&
              <>
              <Input
                id="Username"
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
                value={username}
                type="text"
                placeholder="Non Modificabile"
                aria-label="Non Modificabile"
                disabled
              />
              <Input
                id="Password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                value={password}
                type="text"
                placeholder="Non Modificabile"
                aria-label="Non Modificabile"
                disabled
              />
              </>
              }
              
            </div>
          </FormGroup>
          {listaErrori.length>0 &&
            <Row>
                <Col>
                  {erroriForm(listaErrori)}
                </Col>
            </Row>
          }
        </ModalBody>
        <ModalFooter>
          <span>
            <Button outline color='primary' onClick={() => close()}>ANNULLA</Button>
          </span>
          <span>
            <Button outline color='primary' onClick={() => confirm()}>CONFERMA</Button>
          </span>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default EntiModal;
