import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
  ModalFooter,
  Row,
  Col
} from "design-react-kit";
import { useDispatch, useSelector } from "react-redux";
import { hideSearchPopup } from "../actions/search_popup_actions";

const SearchPopup = () => {

  const dispatch = useDispatch();

  const [value, setValue] = useState();

  const show = useSelector((state) => state.searchPopup.show);
  const header = useSelector((state) => state.searchPopup.header);
  const label = useSelector((state) => state.searchPopup.label);
  const f = useSelector((state) => state.searchPopup.function);

  let listaErroriTmp = [];
  const [listaErrori, setListaErrori] = useState([]);

  function erroriForm(listaErroriInfo){
    return listaErroriInfo.map(function (errore) {
      return(
        <p className="text-mess-alert">{errore}</p>
      )})  
  }

  function close() {
    setValue(undefined)
    dispatch(hideSearchPopup());
  }

  function confirm() {
    listaErroriTmp = []
    if(value == null || value === '') listaErroriTmp.push("- Inserire il valore di ricerca")
    if(listaErroriTmp.length == 0){
      dispatch(f(value));
      close();
    }else{
      setListaErrori(listaErroriTmp)
    }
    
  }

  return (
    <div>
      <Modal isOpen={show} labelledBy="searchUtente">
        <ModalHeader id="searchUtente">{header}</ModalHeader>
        <ModalBody>
          <FormGroup>
            <div className="bootstrap-select-wrapper">
              <label htmlFor="inputvalue">{label}</label>
              <Input
                id="value"
                onChange={(e) => {
                  setValue(e.target.value);
                }}
                value={value}
                type="text"
                placeholder={`Inserisci ${label}`}
                aria-label={`Inserisci ${label}`}
                className="textupper"
              />
            </div>
          </FormGroup>
          {listaErrori.length>0 &&
            <Row>
                <Col>
                  {erroriForm(listaErrori)}
                </Col>
            </Row>
          }
        </ModalBody>
        <ModalFooter>
          <span>
            <Button outline color="primary" onClick={() => close()}>
              ANNULLA
            </Button>
          </span>
          <span>
            <Button outline color="primary" onClick={() => confirm()}>
              CERCA
            </Button>
          </span>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default SearchPopup;
