import React, { useEffect, useState } from "react";
import {
  Header,
  HeaderBrand,
  HeaderContent,
  HeaderRightZone,
  Button,
  Icon,
} from "design-react-kit";
import Logout from "../../common/components/logout/components/Logout";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAddressCard, faEdit, faUser, faBars } from '@fortawesome/free-solid-svg-icons'
import StickyBox from "react-sticky-box";
import { Navigate, Route } from "react-router-dom";
import ProfiloComune from "../../common/components/profilo";
import { Link } from "react-router-dom";




const CustomHeader = (props) => {

  // const [loggato, setLoggato] = useState(false);
  // //let loggato = true
  // const username = localStorage.getItem("username")
  //   if(username != null || username != ''){
  //     //loggato = false
  //   }


  // useEffect (() =>{
  //   const username = localStorage.getItem("username")
  //   if(username != null || username != ''){
  //     setLoggato(false)
  //   }
  // })
  let loggato = true
  if(props.loggato != null && !props.loggato) loggato = false
  
  //const[enable, setEnable] = useState(false)

  const handleMenu = () => {
    const enable = document.getElementById('sidebar-wrapper').classList.contains("d-none");
    if(enable){
      document.getElementById('sidebar-wrapper').classList.remove("d-none");
    }else{
      document.getElementById('sidebar-wrapper').classList.add("d-none");
    }  
  }

  const handleProfilo = () => {
    //return <Route path="/comune/dashboard/profilo" element={<ProfiloComune></ProfiloComune>}></Route>
    <Navigate to="/utenze" replace={true}/>;
  } 

  return (
    // <StickyBox>
      <Header theme="" type="center" className="dark-blue">
      <HeaderContent>
        <FontAwesomeIcon icon={faBars} className="menu-icon responsive" onClick={() =>handleMenu()}/>
        <HeaderBrand>
          <img src="https://webedifferent.it/wp-content/uploads/2022/01/cropped-sustainable.png" width={'50px'} className="align-float-left desktop"/>
          <h2 className="align-text-logo align-float-left desktop">WeBeDifferent</h2>
        </HeaderBrand>
        <HeaderRightZone>
          {
            loggato ?
            <>
            {/* <Button className="margin-lr-button" color="primary" icon onClick={() => handleProfilo()} type="link">
              <span className="rounded-icon">
                <FontAwesomeIcon icon={faUser} className="color-icon-brand"/>
              </span>
              <span>Profilo</span>
            </Button> */}
            <Link to="profilo/" className="nav-link margin-lr-button btn-icon btn btn-primary">
              <span className="rounded-icon margin-r-button">
                <FontAwesomeIcon icon={faUser} className="color-icon-brand"/>
              </span>
              Profilo
            </Link>
            
            <Logout></Logout> 
            </>
              
          :
            <Button className="btn-icon" color="primary" href="/" size="full">
              <span className="rounded-icon">
                <FontAwesomeIcon icon={faUser} className="color-icon-brand"/>
              </span>
              <span className="d-none d-lg-block">Accedi</span>
            </Button>
          }
          
        </HeaderRightZone>
      </HeaderContent>
    </Header>

    // </StickyBox>
    
  );
};

export default CustomHeader;
