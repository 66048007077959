import {
  GET_MASTELLI, GET_MASTELLI_SUCCESS, GET_MASTELLI_FAIL,
  INSERT_MASTELLO, INSERT_MASTELLO_SUCCESS, INSERT_MASTELLO_FAILED,
  UPDATE_MASTELLO, UPDATE_MASTELLO_SUCCESS, UPDATE_MASTELLO_FAILED,
  DELETE_MASTELLO, DELETE_MASTELLO_SUCCESS, DELETE_MASTELLO_FAILED,
  STAMPA_MASTELLO, STAMPA_MASTELLO_SUCCESS, STAMPA_MASTELLO_FAILED
} from "../actions/mastello_actions";
import _ from "lodash";

export function mastelliReducer(state = { loading: false, error: undefined, insertSuccessData: undefined, mastelli: undefined }, action) {

  if (_.isEmpty(action) || _.isEmpty(action.type)) return state

  const newState = state

  switch (action.type) {

    case GET_MASTELLI:
      newState.loading = true
      newState.error = undefined
      newState.mastelli = undefined
      break;
    case GET_MASTELLI_SUCCESS:
      newState.loading = false
      newState.error = undefined
      newState.mastelli = action.payload
      break;
    case GET_MASTELLI_FAIL:
      newState.loading = false
      newState.error = action.payload
      newState.mastelli = undefined
      break;
    case INSERT_MASTELLO:
      break;
    case INSERT_MASTELLO_SUCCESS:
      break;
    case INSERT_MASTELLO_FAILED:
      break;
    case UPDATE_MASTELLO:
      break;
    case UPDATE_MASTELLO_SUCCESS:
      break;
    case UPDATE_MASTELLO_FAILED:
      break;
    case DELETE_MASTELLO:
      break;
    case DELETE_MASTELLO_SUCCESS:
      break;
    case DELETE_MASTELLO_FAILED:
      break;
    case STAMPA_MASTELLO:
      break;
    case STAMPA_MASTELLO_SUCCESS:
      break;
    case STAMPA_MASTELLO_FAILED:
      break;
    default:
      break;
  }

  return Object.assign({}, state, newState)
}