import React, { useState, useEffect } from "react";
import {
  Input,
  Card,
  CardBody,
  CardFooter,
  Button,
  CardHeader,
  Row,
  Col,
  Spinner,
} from "design-react-kit";
import _ from "lodash";
import { login } from "../actions/login_actions";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import jwtDecode from 'jwt-decode';
import CustomHeader from "../../layout/components/CustomHeader";
import { Link } from "react-router-dom";



const Login = (props) => {
  
  const dispatch = useDispatch();

  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [errors, setErrors] = useState({});

  const success = useSelector((state) => state.login.success);
  const loading = useSelector((state) => state.login.loading);
  const error = useSelector((state) => state.login.error);

  const jwt = useSelector((state) => state.login.jwt);

  const getRoles = () => {
    const decoded = jwtDecode(jwt);
    const roles = decoded["roles"];
    return roles;
  }

  function handleClick() {
    dispatch(login(username, password));
  }

  useEffect(() => {
    checkErrors();
  }, [username, password]);

  function checkErrors() {
    const tmp = {};
    if (password === undefined || password === "") {
      tmp.password = "La password non puo' essere vuota";
    }

    if (username === undefined || username === "") {
      tmp.username = "Lo username non puo' essere vuoto";
    }

    setErrors(tmp);
  }

  function checkDisabled() {
    return !_.isEmpty(errors);
  }

  return returnRender();

  function returnRender() {
    if (success === true) {
      const roles = getRoles();
      switch (roles) {
        case "ROLE_COMUNE":
          return <Navigate to="/comune/dashboard" />;
        case "ROLE_UTENTE":
          return <Navigate to="/utente/dashboard" />;
        case "ROLE_ENTE":
          return <Navigate to="/ente/dashboard" />;
        case "ROLE_OPERATORE":
            return <Navigate to="/operatore/dashboard" />;
        case "ROLE_SUPERUSER":
          return <Navigate to="/admin/dashboard" />;
        default:
          break;
      }
    } else {
      return loginForm();
    }
  }

  function loginForm() {
    return (
      <>
      <CustomHeader loggato={false}></CustomHeader>
      <div className="container-fluid container-wide">
        <Row className="login-row">
          <Col xs="12" sm="3" md="3" />
          <Col xs="12" sm="6" md="6">
            <Card className="shadowed">
              <CardHeader>Accedi</CardHeader>
              <CardBody className="padding-b0">
                <form className="accesso">
                  <Input
                    label="Username"
                    type="text"
                    placeholder="Username"
                    id="username"
                    name="username"
                    onChange={(e) => {
                      setUsername(e.target.value);
                    }}
                    invalid={!_.isEmpty(errors.username)}
                    infoText={errors.username ? errors.username : undefined}
                  />
                  <Input
                    label="Password"
                    type="password"
                    placeholder="Password"
                    id="password"
                    name="password"
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    invalid={!_.isEmpty(errors.password)}
                    infoText={errors.password ? errors.password : undefined}
                    
                  />
                  <>
                  <Row className="login-row">
                    <Col xs="12" sm="6" md="6" />
                    <Col xs="12" sm="6" md="6" >
                      <Link to="reset-password" className="nav-link text-pwd-dimenticata">Password dimenticata?</Link>
                    </Col>
                  </Row>
                  </>
                  
                  
                </form>
              </CardBody>
              <CardFooter>
                {!_.isEmpty(error) && <div>{error}</div>}
                {loading ? (
                  <Spinner active />
                ) : (
                  <Button
                    color="primary"
                    onClick={handleClick}
                    disabled={checkDisabled()}
                  >
                    Login
                  </Button>
                )}
              </CardFooter>
            </Card>
          </Col>
          <Col xs="12" sm="3" md="3"/>
        </Row>
      </div>
      
      </>
      
    );
  }
};

export default Login;
