export const SHOW_UTENTE_MODAL = "showUtenteModal"
export const HIDE_UTENTE_MODAL = "hide_utente_modal"

export function showUtenteModal(type, header, f, obj, buttonMessage, body) {
  return {
      type: SHOW_UTENTE_MODAL,
      payload:{
        type: type,
        header: header,
        function: f,
        obj: obj,
        buttonMessage: buttonMessage,
        body: body
    }
  }
}

export function hideUtenteModal(){
  return {
      type: HIDE_UTENTE_MODAL
  }
}