import { SHOW_POPUP, HIDE_POPUP } from "../actions/popup_actions";
import _ from "lodash"

export function popUpReducer(state = { type: '', show: false, header: undefined, message: undefined, function: undefined, arg: undefined }, action) {
    if (_.isEmpty(action) || _.isEmpty(action.type)) return state
    const newState = state
    switch (action.type) {
        case SHOW_POPUP:
            newState.show = true
            newState.header = action.payload.header
            newState.message = action.payload.message
            newState.type = action.payload.type
            newState.function = action.payload.function
            newState.arg = action.payload.arg
            break;
        case HIDE_POPUP:
            newState.show = false
            newState.header = undefined
            newState.message = undefined
            newState.type = ''
            newState.function = undefined
            newState.arg = undefined
            break;
        default:
            break;
    }
    return Object.assign({}, state, newState)
}