import { SHOW_RACCOLTA_MODAL, HIDE_RACCOLTA_MODAL } from "../actions/raccoltaModal_actions";
import _ from "lodash";

export function raccoltaModalReducer(state = { type:'', header: undefined, show: false, function: undefined, obj: undefined  }, action) {
  if (_.isEmpty(action) || _.isEmpty(action.type)) return state
  const newState = state
  switch (action.type) {
    case SHOW_RACCOLTA_MODAL:
      newState.type = action.payload.type
      newState.header = action.payload.header
      newState.show = true
      newState.function = action.payload.function
      newState.obj = action.payload.obj
      break;
    case HIDE_RACCOLTA_MODAL:
      newState.type = ''
      newState.header = undefined
      newState.show = false
      newState.function = undefined
      newState.obj = undefined
      break;
    default:
      break;
  }
  return Object.assign({}, state, newState)
}
