import { combineReducers } from "redux";
import { loginReducer} from "../login/reducers/login_reduces"

import { utenzeReducer} from "../common/components/utenze/reducers/utenze_reducers"
import { utenzaModalReducer } from "../common/components/utenze/reducers/utenzaModal_reducers";

import { collaborazioniReducer } from "../common/components/collaborazioni/reducers/collaborazioni_reducers";
import { collaborazioneModalReducer } from "../common/components/collaborazioni/reducers/collaborazioneModal_reducers";

import { calendarioReducer } from "../common/components/calendario/reducers/calendario_reducers";
import { calendarioModalReducer } from "../common/components/calendario/reducers/calendarioModal_reducers";

import { mastelliReducer } from "../common/components/mastello/reducers/mastello_reducers";
import { mastelloModalReducer } from "../common/components/mastello/reducers/mastelloModal_reducer";

import { qrCodesReducer } from "../common/components/qrCode/reducers/qrCode_reducers";
import { qrCodeModalReducer } from "../common/components/qrCode/reducers/qrCodeModal_reducers";

import { richiestaIngombrantiReducer } from "../common/components/richiestaIngombranti/reducers/richiestaIngombranti_reducers";

import { tipologiaSpazzaturaReducer } from "../common/components/tipologiaSpazzatura/reducers/tipologiaSpazzatura_reducers";
import { tipologiaSpazzaturaModalReducer } from "../common/components/tipologiaSpazzatura/reducers/tipologiaSpazzaturaModal_reducers";

import { utenteReducer } from "../common/components/utente/reducers/utente_reducers";
import { utenteModalReducer } from "../common/components/utente/reducers/utenteModal_reducer";
import { enteReducer } from "../ente/reducers/ente_reducers";
import { logoutReducer } from "../common/components/logout/reducers/logout_reducers";
import { tipoImmobileReducer } from "../common/components/tipoImmobile/reducers/tipoImmobile_reducers";
import { popUpReducer } from "../common/components/popup/reducers/popup_reducers";
import { searchPopupReducer } from "../common/components/search_popup/reducers/search_popup_reducers";

import { OperatoreReducer } from "../common/components/operatori/reducers/operatori_reducers";
import { operatoreModalReducer } from "../common/components/operatori/reducers/operatoriModal_reducers";
import { useReducer } from "react";
import { userReducer } from "../common/components/dashboard/user_reducers";
import { userModalReducer } from "../common/components/dashboard/userModal_reducers";

import { pwdModalReducer } from "../common/components/dashboard/pwdModal_reduces";
import { pwdReducer } from "../common/components/dashboard/pwd_reducers";
import { dispositivoReducer } from "../common/components/dispositivi/reducers/dispositivo_reducers";
import { dispositivoModalReducer } from "../common/components/dispositivi/reducers/dispositivoModal_reducer";
import { richiestaIngombrantiModalReducer } from "../common/components/richiestaIngombranti/reducers/richiestaIngombrantiModal_reducer";
import { pesateReducer } from "../common/components/pesata/reducers/pesata_reducers";
import { pesataModalReducer } from "../common/components/pesata/reducers/pesataModal_reducer";
import { EnteModalReducer } from "../common/components/enti/reducers/entiModal_reducers";
import { EnteReducer } from "../common/components/enti/reducers/enti_reducers";
import { raccoltaReducer } from "../common/components/raccolta/reducers/raccolta_reducers";
import { raccoltaModalReducer } from "../common/components/raccolta/reducers/raccoltaModal_reducer";
import { resetPassword } from "../password/actions/password_actions";
import { recuperaPasswordReducer, resetPasswordReducer } from "../password/reducers/password_reduces";
import { scontisticaReducer } from "../common/components/scontistica/reducers/scontistica_reducers";
import { scontisticaModalReducer } from "../common/components/scontistica/reducers/scontisticaModal_reducer";
import { ComuneReducer } from "../common/components/comune/reducers/comune_reducers";
import { ComuneModalReducer } from "../common/components/comune/reducers/comuneModal_reducers";

const rootReducers = combineReducers({
    login: loginReducer,

    utenze: utenzeReducer,
    utenzaModal: utenzaModalReducer,

    collaborazioni: collaborazioniReducer,
    collaborazioneModal: collaborazioneModalReducer,

    calendario: calendarioReducer,
    calendarioModal: calendarioModalReducer,

    mastello: mastelliReducer,
    mastelloModal: mastelloModalReducer,

    qrCode: qrCodesReducer,
    qrCodeModal: qrCodeModalReducer,

    richiestaIngombranti: richiestaIngombrantiReducer,
    richiestaIngombrantiModal: richiestaIngombrantiModalReducer,

    tipologiaSpazzatura: tipologiaSpazzaturaReducer,
    tipologiaSpazzaturaModal: tipologiaSpazzaturaModalReducer,

    utente: utenteReducer,
    utenteModal: utenteModalReducer,
    
    ente: enteReducer,
    logout: logoutReducer,
    tipoImmobile: tipoImmobileReducer,

    popup: popUpReducer,
    searchPopup: searchPopupReducer,

    operatori: OperatoreReducer,
    operatoreModal: operatoreModalReducer,

    userInfo: userReducer,
    userInfoModal: userModalReducer,

    pwdChangeModal: pwdModalReducer,
    pwdReducers: pwdReducer,

    dispositivo: dispositivoReducer,
    dispositivoModal: dispositivoModalReducer,

    pesata: pesateReducer,
    pesataModal: pesataModalReducer,

    enticomune: EnteReducer,
    entiModal: EnteModalReducer,

    raccolta: raccoltaReducer,
    raccoltaModal: raccoltaModalReducer,

    resetPassword: resetPasswordReducer,
    recuperaPassord: recuperaPasswordReducer,

    scontistica: scontisticaReducer,
    scontisticaModal: scontisticaModalReducer,

    comune: ComuneReducer,
    comuneModal: ComuneModalReducer


})

export default rootReducers
