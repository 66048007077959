import React, { useEffect, useState } from "react";
import {
  Button,
  Spinner,
  Row,
  Col
} from "design-react-kit";
import _ from "lodash";
import DonutChart from "../../grafici/DonutChart";
import { getUserInfo} from "../dashboard_Action";
import { getUtenze } from "../../utenze/actions/utenze_actions"
import { useDispatch, useSelector } from "react-redux";
import Mappa from "../../grafici/Mappa";
import { getRaccolta } from "../../raccolta/actions/raccolta_actions";

import { Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
  } from 'chart.js';
import CustomTable from "../../table/component/CustomTable";
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
  );

const DashboardUtente = () => {

  const dispatch = useDispatch();

  const userInfo = useSelector(
    (state) => state.userInfo.userInfo
  );
  let loading = true//useSelector((state) => state.userInfo.loading);
  const [dati, setDati] = useState();

  const utenze = useSelector((state) => state.utenze.utenze);


  const countType = (type) => {
        const countTypes =  type.length
        return countTypes;
  }


  const raccolta = useSelector(
    (state) => state.raccolta.raccolta
  );


  const [datiRaccoltaAnno, setDatiRaccoltaAnno] = useState([]);
  const [datiRaccoltaMese, setDatiRaccoltaMese] = useState([]);

  const [tariffaPienaMese, setTariffaPienaMese] = useState([]);
  const [tariffaScontoMese, setTariffaScontoMese] = useState([]);

  const [tariffaPienaAnno, setTariffaAnno] = useState([]);
  const [tariffaScontoAnno, setScontoAnno] = useState([]);

  const [datiRaccoltaMetalli, setDatiRaccoltaMetalli] = useState([]);
  const [datiRaccoltaCartaCartone, setDatiCartaCartone] = useState([]);
  const [datiRaccoltaElettrici, setDatiElettrici] = useState([]);
  const [datiRaccoltaIngombranti, setDatiRaccoltaIngombranti] = useState([]);
  const [datiRaccoltaSeccoIndifferenziato, setDatiRaccoltaSeccoIndifferenziato] = useState([]);
  const [datiRaccoltaSpeciali, setDatiRaccoltaSpeciali] = useState([]);
  const [datiRaccoltaUmido, setDatiRaccoltaUmido] = useState([]);
  const [datiRaccoltaVetro, setDatiRaccoltaVetro] = useState([]);
  const [datiRaccoltaPlastica, setDatiRaccoltaPlastica] = useState([]);


  useEffect(() => {
    if (raccolta === undefined) {
      dispatch(getRaccolta());
    }else{
      console.log(raccolta)

      const datiRaccoltaComuneMeseCorrente = []
      const datiRaccoltaComuneAnnoCorrente = []

      let tariffaAnno = 0
      let scontoAnno = 0

      _.forEach(raccolta.raccolteTipoQtyAnno, (racc) => {

        const r = {
          "name": racc.tipoSpazzatura,
          "value": racc.quantita,
          "tariffaPiena": racc.tariffa,
          "tariffaScontata": racc.sconto
        }
        tariffaAnno += racc.tariffa
        scontoAnno += racc.sconto
        setTariffaAnno(tariffaAnno)
        setScontoAnno(scontoAnno)

        datiRaccoltaComuneAnnoCorrente.push(r)
      });

      let tariffaMese = 0
      let scontoMese = 0

      _.forEach(raccolta.raccolteTipoQtyMese, (racc) => {

        const r = {
          "name": racc.tipoSpazzatura,
          "value": racc.quantita,
          "tariffaPiena": racc.tariffa,
          "tariffaScontata": racc.sconto
        }
        tariffaMese += racc.tariffa
        scontoMese += racc.sconto
        setTariffaPienaMese(tariffaMese)
        setTariffaScontoMese(scontoMese)
        datiRaccoltaComuneMeseCorrente.push(r)
      });

      _.forEach(raccolta.raccoltaMeseAnno, (racc) => {


        switch(racc.tipoSpazzatura){
          case "ALLUMINIO E METALLI":
            setDatiRaccoltaMetalli(racc.raccoltaSingolaMeseQty.listaRaccolta)
            break;
          case "CARTA E CARTONE":
              setDatiCartaCartone(racc.raccoltaSingolaMeseQty.listaRaccolta)
              break;
          case "ELETTRICI":
            setDatiElettrici(racc.raccoltaSingolaMeseQty.listaRaccolta)
            break;
          case "PLASTICA":
            setDatiRaccoltaPlastica(racc.raccoltaSingolaMeseQty.listaRaccolta)
            break;
          case "INGOMBRANTE":
            setDatiRaccoltaIngombranti(racc.raccoltaSingolaMeseQty.listaRaccolta)
            break;
          case "SECCO INDIFFERENZIATO":
            setDatiRaccoltaSeccoIndifferenziato(racc.raccoltaSingolaMeseQty.listaRaccolta)
            break;
          case "SPECIALI":
            setDatiRaccoltaSpeciali(racc.raccoltaSingolaMeseQty.listaRaccolta)
            break;
          case "UMIDO O ORGANICO":
            setDatiRaccoltaUmido(racc.raccoltaSingolaMeseQty.listaRaccolta)
            break;
          case "VETRO":
            setDatiRaccoltaVetro(racc.raccoltaSingolaMeseQty.listaRaccolta)
            break;
        }

        // const r = {
        //   "name": racc.tipoSpazzatura,
        //   "value": racc.quantita
        // }
        // datiRaccoltaComuneMeseCorrente.push(r)
      });

      setDatiRaccoltaMese(datiRaccoltaComuneMeseCorrente)
      setDatiRaccoltaAnno(datiRaccoltaComuneAnnoCorrente)

    }
  }, [raccolta]);



  useEffect(() => {
    if (userInfo === undefined) {
      dispatch(getUserInfo());
      dispatch(getUtenze());
    } else {
      const tmp = [];
      _.forEach(userInfo, (ts) => {
        tmp.push(ts);
      });
      setDati(userInfo);
      console.log(userInfo)
      console.log(utenze)
      loading = false
    }
  }, [userInfo, dispatch]);

    const colors = [ '#8ce8ad', '#60e6e1', '#87d3f2', '#93f0e6', '#ffb46a', '#ff736a', ];

    const colors_bis = ['#57e188', '#93f0e6', '#4ebeeb',  '#ff9831',  '#ff9a91', '#f95d54' ];

    const color_empty = [ '#efefefef' ]

    var dati_anno = [
        {name: "Carta", value: 345},
        {name: "Plastica", value: 123},
        {name: "Vetro", value: 342},
        {name: "Umido", value: 565},
        {name: "Indifferenziata", value: 234},
      ];

      var dati_mese = [
        {name: "Carta", value: 43},
        {name: "Plastica", value: 67},
        {name: "Vetro", value: 123},
        {name: "Umido", value: 310},
        {name: "Indifferenziata", value: 54},
      ];

      var dati_anno_vuoto = [
        {name: "Nessun Valore", value: 100},
      ];

      var dati_mese_vuoto = [
        {name: "Nessun Valore", value: 100},
      ];
    
    var dati_mese_carta = [
        {name: "gen", value: '25'},
        {name: "feb", value: '15'},
        {name: "mar", value: '30'},
        {name: "apr", value: '100'},
        {name: "mag", value: '45'},
        {name: "giu", value: '67'},
        {name: "lug", value: '12'},
        {name: "ago", value: '20'},
        {name: "set", value: '76'},
        {name: "ott", value: '54'},
        {name: "nov", value: '78'},
        {name: "dic", value: '36'},
      ];

      var dati_mese_umido = [
        {name: "gen", value: '34'},
        {name: "feb", value: '65'},
        {name: "mar", value: '21'},
        {name: "apr", value: '67'},
        {name: "mag", value: '76'},
        {name: "giu", value: '12'},
        {name: "lug", value: '43'},
        {name: "ago", value: '56'},
        {name: "set", value: '49'},
        {name: "ott", value: '32'},
        {name: "nov", value: '33'},
        {name: "dic", value: '65'},
      ];

      var dati_mese_vetro = [
        {name: "gen", value: '55'},
        {name: "feb", value: '43'},
        {name: "mar", value: '52'},
        {name: "apr", value: '32'},
        {name: "mag", value: '36'},
        {name: "giu", value: '51'},
        {name: "lug", value: '56'},
        {name: "ago", value: '39'},
        {name: "set", value: '44'},
        {name: "ott", value: '55'},
        {name: "nov", value: '66'},
        {name: "dic", value: '77'},
      ];

      var dati_mese_plastica = [
        {name: "gen", value: '65'},
        {name: "feb", value: '34'},
        {name: "mar", value: '44'},
        {name: "apr", value: '67'},
        {name: "mag", value: '33'},
        {name: "giu", value: '66'},
        {name: "lug", value: '23'},
        {name: "ago", value: '41'},
        {name: "set", value: '52'},
        {name: "ott", value: '47'},
        {name: "nov", value: '76'},
        {name: "dic", value: '12'},
      ];

      var dati_mese_indifferenziata = [
        {name: "gen", value: '87'},
        {name: "feb", value: '67'},
        {name: "mar", value: '54'},
        {name: "apr", value: '43'},
        {name: "mag", value: '21'},
        {name: "giu", value: '89'},
        {name: "lug", value: '55'},
        {name: "ago", value: '78'},
        {name: "set", value: '46'},
        {name: "ott", value: '41'},
        {name: "nov", value: '76'},
        {name: "dic", value: '34'},
      ];

      const riciclato = {
        labels: dati_mese_carta.map((data) => data.name),
        datasets: [
          {
            fill: true,
            label: "Metalli Alluminio",
            data: datiRaccoltaMetalli.map((data) => data.value),
            borderColor: '#60e6e1',
            backgroundColor: 'transparent'//'rgb(96, 230, 225, 0.5)'
          },
          {
            fill: true,
            label: "Vetro",
            data: datiRaccoltaVetro.map((data) => data.value),
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'transparent'//'rgba(53, 162, 235, 0.5)',
          },
          {
            fill: true,
            label: "Plastica",
            data: datiRaccoltaPlastica.map((data) => data.value),
            borderColor: 'rgb(87, 225, 136)',
            backgroundColor: 'transparent'//'rgb(87, 225, 136, 0.5)',
          },
          {
            fill: true,
            label: "Carta",
            data: datiRaccoltaCartaCartone.map((data) => data.value),
            borderColor: 'rgb(255, 152, 49)',
            backgroundColor: 'transparent'//'rgb(87, 225, 136, 0.5)',
          },
        ],
      };

      const differenziato = {
        labels: dati_mese_carta.map((data) => data.name),
        datasets: [
          {
            fill: true,
            label: "Umido",
            data: datiRaccoltaUmido.map((data) => data.value),
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'transparent'//'rgba(53, 162, 235, 0.5)',
          },
          {
            fill: true,
            label: "Indifferenziata",
            data: datiRaccoltaSeccoIndifferenziato.map((data) => data.value),
            borderColor: 'rgb(255, 154, 145)',
            backgroundColor: 'transparent'//'rgb(255, 154, 145, 0.5)',
          },
        ],
      };

      const speciali_ingombranti = {
        labels: dati_mese_carta.map((data) => data.name),
        datasets: [
          {
            fill: true,
            label: "Speciali",
            data: datiRaccoltaSpeciali.map((data) => data.value),
            borderColor: 'rgb(140, 232, 173)',
            backgroundColor: 'transparent'//'rgba(53, 162, 235, 0.5)',
          },
          {
            fill: true,
            label: "Ingombranti",
            data: datiRaccoltaIngombranti.map((data) => data.value),
            borderColor: 'rgb(78, 190, 235)',
            backgroundColor: 'transparent'//'rgb(255, 154, 145, 0.5)',
          },
        ],
      };


      const elettrici = {
        labels: dati_mese_carta.map((data) => data.name),
        datasets: [
          {
            fill: true,
            label: "Eletrici",
            data: datiRaccoltaElettrici.map((data) => data.value),
            borderColor: 'rgb(140, 232, 173)',
            backgroundColor: 'transparent'//'rgba(53, 162, 235, 0.5)',
          },
          // {
          //   fill: true,
          //   label: "Ingombranti",
          //   data: datiRaccoltaIngombranti.map((data) => data.value),
          //   borderColor: 'rgb(78, 190, 235)',
          //   backgroundColor: 'transparent'//'rgb(255, 154, 145, 0.5)',
          // },
        ],
      };

      const headers = [
        {
          label: "Intestario",
          field: "proprietario",
        },
        {
          label: "Indirizzo",
          field: "utenza.indirizzo",
        },
        {
          label: "Comune",
          field: "comune"
        },
        {
          label: "Tipologia",
          field: "tipologiaSpazzatura.descrizione",
        },
        // {
        //   label: "Valore QrCode",
        //   field: "qrCode.valore",
        // },
        {
          label: "Stato",
          field: "statoQrCode",
        },
        {
          label: "Azioni",
          field: "actions",
        },
      ];
    

  return (
    <React.Fragment>
      {userInfo == null ? (
        <div className="customTableContainer">
          <div className="container_spinner"><Spinner active  className="spinnerwebe"/></div>
        </div>
      ) : (
        <>
        <div className="customTableContainer">
          <Row className="justify-content-md-center box-dashboard box-margin">
            <Col xs="12" sm="5" md="5">
              <Mappa regione={userInfo.comuneResidenzaInfo.regione}/>
            </Col>
            <Col xs="12" sm="7" md="7">
              <Row>
                <>
                <Col xs="12" sm="12" md="12">
                  <h3 className="title-grafico">Utente: {userInfo.cognome.toUpperCase()}{" "}{userInfo.nome.toUpperCase()}</h3>
                  <h5 className="title-grafico">Regione Residenza: {userInfo.comuneResidenzaInfo.regione.toUpperCase()}</h5>
                  {utenze != null ? (
                    <h4 className="title-grafico">Utenze Attuali: {countType(utenze)} </h4>
                  )
                  :(<h4 className="title-grafico">Utenze Attuali: in elaborazione </h4>)
                  }
                </Col>
                <Col xs="12" sm="6" md="6">
                    <p className="title-grafico text-center padding-title-grafico">Raccolta Mese Corrente</p>
                    {datiRaccoltaMese.length == 0 &&
                      <DonutChart idgrafico="ch1" dati={dati_mese_vuoto} colori={color_empty} vuoto={true}></DonutChart>
                    }
                    {datiRaccoltaMese.length > 0 &&
                      <DonutChart idgrafico="ch1" dati={datiRaccoltaMese} colori={colors} vuoto={false}></DonutChart>
                    }
                    
                </Col>
                <Col xs="12" sm="6" md="6">
                    <p className="title-grafico text-center padding-title-grafico">Raccolta Annuale</p>
                    {/* {
                      datiRaccoltaAnno.length > 0 &&
                      <DonutChart idgrafico="ch2" dati={datiRaccoltaAnno} colori={colors_bis} vuoto={false}></DonutChart>
                    } */}
                    {datiRaccoltaAnno.length == 0 &&
                      <DonutChart idgrafico="ch2" dati={dati_anno_vuoto} colori={color_empty} vuoto={true}></DonutChart>
                    }
                    {datiRaccoltaAnno.length > 0 &&
                      <DonutChart idgrafico="ch2" dati={datiRaccoltaAnno} colori={colors} vuoto={false}></DonutChart>
                    }
                    
                </Col>
                {/* <Col xs="12" sm="6" md="6">
                    <p className="title-grafico text-center padding-title-grafico">Raccolta Mese Corrente</p>
                    {datiRaccoltaMese.length > 0 &&
                      <DonutChart idgrafico="ch1" dati={datiRaccoltaMese} colori={colors}></DonutChart>
                    }
                    
                </Col>
                <Col xs="12" sm="6" md="6">
                    <p className="title-grafico text-center padding-title-grafico">Raccolta Annuale</p>
                    {
                      datiRaccoltaAnno.length > 0 &&
                      <DonutChart idgrafico="ch2" dati={datiRaccoltaAnno} colori={colors_bis}></DonutChart>
                    }
                    
                </Col> */}
                </>
              </Row>
              
            </Col>
          </Row>
          <Row className="justify-content-md-center padding-top-row box-dashboard  box-margin">
                <Col xs="12" sm="6" md="6">
                    
                {datiRaccoltaMese.length > 0 &&
                    <>
                    <h5 className="title-grafico text-center padding-title-grafico">Mensile</h5>
                    <p className="title-grafico text-center ">Tariffa Piena: € {Number(tariffaPienaMese).toFixed(2)}</p>
                    <p className="title-grafico text-center ">Tariffa Scontata: € {Number(tariffaScontoMese).toFixed(2)}</p>
                    </>
                      
                    }
                    {datiRaccoltaMese.length < 1 &&
                    <>
                    <h5 className="title-grafico text-center padding-title-grafico">Mensile</h5>
                    <p className="title-grafico text-center ">Tariffa Piena: € 0</p>
                    <p className="title-grafico text-center ">Tariffa Scontata: € 0</p>
                    </>
                      
                    }
                    
                </Col>
                <Col xs="12" sm="6" md="6">
                    {datiRaccoltaAnno.length > 0 &&
                    <>
                    <h5 className="title-grafico text-center padding-title-grafico">Annuale</h5>
                    <p className="title-grafico text-center ">Tariffa Piena: € {Number(tariffaPienaAnno).toFixed(2)}</p>
                    <p className="title-grafico text-center ">Tariffa Scontata: € {Number(tariffaScontoAnno).toFixed(2)}</p>
                    </>
                      
                    }
                    {datiRaccoltaAnno.length < 1 &&
                    <>
                    <h5 className="title-grafico text-center padding-title-grafico">Annuale</h5>
                    <p className="title-grafico text-center ">Tariffa Piena: € 0</p>
                    <p className="title-grafico text-center ">Tariffa Scontata: € 0</p>
                    </>
                      
                    }
                    
                </Col>
            </Row>
          {/* <Row className="justify-content-md-center box-dashboard box-margin">
                <Col xs="12" sm="6" md="6">
                    <p className="title-grafico">Raccolta Mese Corrente</p>
                    <DonutChart idgrafico="ch1" dati={dati_mese} colori={colors}></DonutChart>
                </Col>
                <Col xs="12" sm="6" md="6">
                    <p className="title-grafico">Raccolta Annuale</p>
                    <DonutChart idgrafico="ch2" dati={dati_anno} colori={colors_bis}></DonutChart>
                </Col>
            </Row> */}
            <Row className="justify-content-md-center padding-top-row box-dashboard  box-margin">
                <Col xs="12" sm="6" md="6">
                    <p className="text-center title-grafico">Riciclabile Annuale</p>
                    {/* <LineChart idgrafico="ch2" dati={dati_anno}></LineChart> */}
                    <Line data={riciclato}/>
                </Col>
                <Col xs="12" sm="6" md="6">
                    <p className="text-center title-grafico">Differenziata Annuale</p>
                    {/* <LineChart idgrafico="ch2" dati={dati_anno}></LineChart> */}
                    <Line data={differenziato}/>
                </Col>
            </Row>
            <Row className="justify-content-md-center padding-top-row box-dashboard  box-margin">
                <Col xs="12" sm="6" md="6">
                    <p className="text-center title-grafico">Speciali e Ingombranti Annuale</p>
                    {/* <LineChart idgrafico="ch2" dati={dati_anno}></LineChart> */}
                    <Line data={speciali_ingombranti}/>
                </Col>
                <Col xs="12" sm="6" md="6">
                    <p className="text-center title-grafico">Elettrici</p>
                    {/* <LineChart idgrafico="ch2" dati={dati_anno}></LineChart> */}
                    <Line data={elettrici}/>
                </Col>
            </Row>
            {/* <Row className="justify-content-md-center padding-top-row box-dashboard  box-margin">
                <Col xs="12" sm="4" md="4">
                    <DonutChart idgrafico="ch3" dati={dati_mese} colori={colors}></DonutChart>
                </Col>
                <Col xs="12" sm="8" md="8">
                    <h3 className="title-grafico">Utente: {userInfo.cognome.toUpperCase()}{" "}{userInfo.nome.toUpperCase()}</h3>
                    <h5 className="title-grafico">Regione Residenza: {userInfo.comuneResidenzaInfo.regione.toUpperCase()}</h5>
                    {/* <h5 className="title-grafico">Indirizzo: {userInfo.indirizzo.toUpperCase()} - {userInfo.citta.toUpperCase()} ({userInfo.provincia.toUpperCase()})</h5> */}
                    {/* <h5 className="title-grafico">Telefono: {userInfo.telefono}</h5>
                    <h5 className="title-grafico">Email: {userInfo.email}</h5>
                </Col>
                <Col xs="12" sm="12" md="12"> */}
                  {/* <CustomTable  headers={headers}/> */}
                {/* </Col>
            </Row> */}
        </div>
        </>
      )}
        
    </React.Fragment>
  );
};

export default DashboardUtente;
