export const SHOW_UTENZA_MODAL = "showUtenzaModal"
export const HIDE_UTENZA_MODAL = "hide_utenza_modal"

export function showUtenzaModal(type, header, f, obj, buttonMessage, body){
  return {
      type: SHOW_UTENZA_MODAL,
      payload:{
        type: type,
        header: header,
        function: f,
        obj: obj,
        buttonMessage: buttonMessage,
        body: body
    }
  }
}

export function hideUtenzaModal(){
  return {
      type: HIDE_UTENZA_MODAL
  }
}
