import React, { useState, useEffect } from "react";
import {
  Input,
  Card,
  CardBody,
  CardFooter,
  Button,
  CardHeader,
  Row,
  Col,
  Spinner,
} from "design-react-kit";
import _ from "lodash";
import { login, recuperaPassword } from "../actions/password_actions";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import jwtDecode from 'jwt-decode';
import CustomHeader from "../../layout/components/CustomHeader";
import { resetPassword } from "../actions/password_actions";
import PopUp from "../../common/components/popup/components/PopUp";



const RecuperaPassword = (props) => {
  
  const dispatch = useDispatch();

  const [confermaPassword, setConfermaPassword] = useState();
  const [password, setPassword] = useState();
  const [diverse, setDiverse] = useState();
  const [errors, setErrors] = useState({});

  const success = useSelector((state) => state.login.success);
  const loading = useSelector((state) => state.login.loading);
  const error = useSelector((state) => state.login.error);

  const searchParam = window.location.search;
  const params = new URLSearchParams(searchParam)

  const accessToken = params.get('access-token')


  function handleClick() {
    dispatch(recuperaPassword(password, confermaPassword, accessToken));
  }

  useEffect(() => {
    checkErrors();
  }, [password, confermaPassword, diverse]);

  function checkErrors() {
    const tmp = {};
    if (password === undefined || password === "") {
      tmp.password = "La password non puo' essere vuota";
    }
    else if (confermaPassword === undefined || confermaPassword === "") {
      tmp.confermaPassword = "La confema password  non puo' essere vuoto";
    }
    else if(password != confermaPassword){
      tmp.diverse = "Password e Confema Passoword non coincidono"
    }
    setErrors(tmp);
  }

  function checkDisabled() {
    return !_.isEmpty(errors);
  }


    return (
      <>
      <PopUp />
      <CustomHeader loggato={false}></CustomHeader>
      <div className="container-fluid container-wide">
        <Row className="login-row">
          <Col xs="12" sm="3" md="3" />
          <Col xs="12" sm="6" md="6">
            <Card className="shadowed">
              <CardHeader>Recupera Password</CardHeader>
              <CardBody>
                <form>
                <Input
                    label="Password"
                    type="password"
                    placeholder="Password"
                    id="password"
                    name="password"
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    invalid={!_.isEmpty(errors.password)}
                    infoText={errors.password ? errors.password : undefined}
                  />
                  <Input
                    label="Conferma Password"
                    type="password"
                    placeholder="Conferma Password"
                    id="confermaPassword"
                    name="confermaPassword"
                    onChange={(e) => {
                      setConfermaPassword(e.target.value);
                    }}
                    invalid={!_.isEmpty(errors.confermaPassword)}
                    infoText={errors.confermaPassword ? errors.confermaPassword : undefined}
                  />
                  {errors.diverse &&
                  <p>{errors.diverse}</p>
                  }
                  {!errors.diverse &&
                  <p>Password e Conferma Password coincidono</p>
                  }
                </form>
              </CardBody>
              <CardFooter>
                {!_.isEmpty(error) && <div>{error}</div>}
                {loading ? (
                  <Spinner active />
                ) : (
                  <Button
                    color="primary"
                    onClick={handleClick}
                    disabled={checkDisabled()}
                  >
                    Recupera Password
                  </Button>
                )}
              </CardFooter>
            </Card>
          </Col>
          <Col xs="12" sm="3" md="3"/>
        </Row>
      </div>
      
      </>
      
    );
};

export default RecuperaPassword;
