import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Spinner, Row, Col, Icon, UncontrolledTooltip } from "design-react-kit";
import { getCalendari, insertCalendario, updateCalendario, deleteCalendario } from "../actions/calendario_actions";
import CustomTable from "../../table/component/CustomTable";
import _ from "lodash";
import CalendarioModal from "./CalendarioModal";
import { getCollaborazioni } from "../../collaborazioni/actions/collaborazioni_actions";
import { getTipologieSpazzatura } from "../../tipologiaSpazzatura/actions/tipologiaSpazzatura_actions";
import { showCalendarioModal } from "../actions/calendarioModal_actions";
import { showPopup } from "../../popup/actions/popup_actions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRefresh, faEdit, faCalendar, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { getUserInfo } from "../../dashboard/dashboard_Action";
import CalendarioUtente from "./CalendarioUtente";

const Calendario = (props) => {

  const dispatch = useDispatch();

  const calendari = useSelector((state) => state.calendario.calendari);
  const loading = useSelector((state) => state.calendario.loading);
  const [dati, setDati] = useState([]);

  const collaborazioni = useSelector((state) => state.collaborazioni.collaborazioni)
  const tipologieSpazzatura = useSelector((state) => state.tipologiaSpazzatura.tipologieSpazzatura);

  const userInfo = useSelector(
    (state) => state.userInfo.userInfo
  );

  const delCalendario = (arg) => {
    dispatch(deleteCalendario(arg));
  };

  const showActionPopUp = (
    (type, header, message, f, arg) => {
      dispatch(showPopup(type, header, message, f, arg));
  })

  const showActionModal = (type, header, f, obj) => {
    console.log("insert button")
    dispatch(showCalendarioModal(type, header, f, obj));
  };

  useEffect(() => {
    if (calendari === undefined) {
      dispatch(getCalendari());
    } else {
      const tmp = [];
      _.forEach(calendari, (c) => {
        c.actions = (
          <section>
            <span className="rounded-icon iconbottom"
              onClick={() => {
                showActionModal("edit", "Modifica Calendario", updateCalendario, c);
              }}
            >
            <FontAwesomeIcon icon={faEdit} />
            </span>
            {/* <Button
              color="primary"
              onClick={() => {
                showActionModal("edit", "Modifica Calendario", updateCalendario, c);
              }}
            >
              edit
            </Button>{' '} */}
            <span className="rounded-icon iconbottomcancel"
              onClick={() =>
                showActionPopUp(
                  "confirm",
                  "ATTENZIONE",
                  `Il calendario ${c.id} verra' cancellato. Procedere?`,
                  delCalendario,
                  c.id
                )
              }
            >
            <FontAwesomeIcon icon={faTrashAlt} />
            </span>
            {/* <Button
              color="primary"
              onClick={() =>
                showActionPopUp(
                  "confirm",
                  "ATTENZIONE",
                  `Il calendario ${c.id} verra' cancellato. Procedere?`,
                  delCalendario,
                  c.id
                )
              }
            >
              delete
            </Button> */}
          </section>
        );
        tmp.push(c);
      });
      setDati(tmp);
    }
  }, [calendari, dispatch]);

  useEffect(() => {
    if (userInfo === undefined) {
      dispatch(getUserInfo());
    }
  }, [userInfo]);

  useEffect(() => {
    console.log(userInfo)
    if(userInfo != null && (userInfo.tipologiaUtente === "COMUNE" || userInfo.tipologiaUtente === "ADMIN" || userInfo.tipologiaUtente === "ENTE" )){
      if(collaborazioni === undefined) {
        dispatch(getCollaborazioni())
      }
    }
  },[collaborazioni])

  useEffect(() => {
    if(tipologieSpazzatura === undefined) {
      dispatch(getTipologieSpazzatura())
    }
  },[tipologieSpazzatura])


  const headers = [
    {
      label: "Ente",
      field: "collaborazione.ente.denominazione",
    },
    {
      label: "Zona",
      field: "zona",
    },
    {
      label: "Lunedi",
      field: "lunedi",
    },
    {
      label: "Martedi",
      field: "martedi",
    },
    {
      label: "Mercoledi",
      field: "mercoledi",
    },
    {
      label: "Giovedi",
      field: "giovedi",
    },
    {
      label: "Venerdi",
      field: "venerdi",
    },
    {
      label: "Sabato",
      field: "sabato",
    },
    {
      label: "Domenica",
      field: "domenica",
    },
    {
      label: "Azioni",
      field: "actions",
    },
  ];

  const refreshList = () => {
    dispatch(getTipologieSpazzatura())
    if(userInfo !=null && userInfo.tipologiaUtente != "UTENTE")dispatch(getCollaborazioni())
    dispatch(getCalendari());
  };

  return (
    <React.Fragment>
      {userInfo != null && userInfo.tipologiaUtente != "UTENTE" &&
      <CalendarioModal collaborazioniOptions={collaborazioni} tipologieSpazzaturaOptions={tipologieSpazzatura}></CalendarioModal>
      }
      {loading ? (
        <div className="customTableContainer">
          <div className="container_spinner"><Spinner active  className="spinnerwebe"/></div>
        </div>
      ) : (
        <>
        <div className="customTableContainer">
          <Row className="justify-content-md-center">
          <Col lg="9" xl="9" md="9" sm="9" xs="12">
              <h1 className="">Calendario Raccolta <FontAwesomeIcon icon={faRefresh} className="padding-lr-icon linkeffect" onClick={()=> refreshList()}/></h1>
            </Col>
            <Col lg="3" xl="3" md="3" sm="3" xs="12" className="text-right">
            {userInfo != null && userInfo.tipologiaUtente != "UTENTE" &&
            <>
            <Button
                color="primary"
                onClick={() =>
                  showActionModal(
                    "insert",
                    "Inserimento nuovo Calendario",
                    insertCalendario
                  )
                }
                icon
                id="addNewCalendario"
                style={{ marginBottom: "8px" }}
              >
                <FontAwesomeIcon icon={faCalendar} className="padding-lr-icon"/> {' '}Aggiungi
              </Button>
              <UncontrolledTooltip placement="top" target="addNewCalendario">
                Aggiungi un nuovo calendario
              </UncontrolledTooltip>
            </>
            }
              

            </Col>
          </Row>
          <>
          {userInfo != null && userInfo.tipologiaUtente === "UTENTE" && (dati != null && dati.length>0 ) ?
            (
              
              dati.map((cal, i) =>{
                //console.log(cal)
                return (
                <Row className="box-calendario shadow">
                  <Col lg="1" xl="1" md="1" sm="1" xs="12" className="text-center margin-auto">
                    <FontAwesomeIcon icon={faCalendar} className="font-icon-xl icon-color-green"/>
                  </Col>
                  <Col lg="11" xl="11" md="11" sm="11" xs="12">
                    <Row>
                      <Col lg="12" xl="12" md="12" sm="12" xs="12">
                        <Row>
                          <Col lg="4" xl="4" md="4" sm="4" xs="12">
                          <p><span className="color-icon-brand">Comune: </span><span className="color-icon-brand text-bold">{cal.collaborazione.comune.denominazione.toUpperCase()} </span></p>
                          </Col>
                          <Col lg="4" xl="4" md="4" sm="4" xs="12">
                            <p><span className="color-icon-brand">Ente Raccolta: </span><span className="color-icon-brand text-bold">{cal.collaborazione.ente.denominazione.toUpperCase()}</span></p>
                          </Col>
                          <Col lg="4" xl="4" md="4" sm="4" xs="12">
                            <p><span className="color-icon-brand">Zona: </span><span className="color-icon-brand text-bold">{cal.zona.toUpperCase()}</span></p>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg="4" xl="4" md="4" sm="4" xs="12">
                        <p><span className="icon-color-green">Lun: </span><span className="icon-color-green text-bold">{cal.lunedi?.toUpperCase()}</span></p>
                      </Col>
                      <Col lg="4" xl="4" md="4" sm="4" xs="12">
                        <p><span className="icon-color-green">Mar: </span><span className="icon-color-green text-bold">{cal.martedi?.toUpperCase()}</span></p>
                      </Col>
                      <Col lg="4" xl="4" md="4" sm="4" xs="12">
                        <p><span className="icon-color-green">Mer: </span><span className="icon-color-green text-bold">{cal.mercoledi?.toUpperCase()}</span></p>
                      </Col>
                      <Col lg="4" xl="4" md="4" sm="4" xs="12">
                        <p><span className="icon-color-green">Giò: </span><span className="icon-color-green text-bold">{cal.giovedi?.toUpperCase()}</span></p>
                      </Col>
                      <Col lg="4" xl="4" md="4" sm="4" xs="12">
                        <p><span className="icon-color-green">Ven:</span><span className="icon-color-green text-bold">{cal.venerdi?.toUpperCase()}</span></p>
                      </Col>
                      <Col lg="4" xl="4" md="4" sm="4" xs="12">
                        <p><span className="icon-color-green">Sab: </span><span className="icon-color-green text-bold">{cal.sabato?.toUpperCase()}</span></p>
                      </Col>
                      <Col lg="4" xl="4" md="4" sm="4" xs="12">
                        <p><span className="icon-color-green">Dom: </span><span className="icon-color-green text-bold">{cal.domenica?.toUpperCase()}</span></p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                )
              })

            ) : (<></>)
          }
          </>
          <>
          {userInfo != null && userInfo.tipologiaUtente != "UTENTE" &&
            <CustomTable headers={headers} data={dati} />
          }
          </>
        </div>
          </>
        
      ) 
      }
    </React.Fragment>
  );
};

export default Calendario;
