import {
  GET_TIPO_SPAZZATURA, GET_TIPO_SPAZZATURA_SUCCESS, GET_TIPO_SPAZZATURA_FAIL,
  INSERT_TIPO_SPAZZATURA, INSERT_TIPO_SPAZZATURA_SUCCESS, INSERT_TIPO_SPAZZATURA_FAIL,
  UPDATE_TIPO_SPAZZATURA, UPDATE_TIPO_SPAZZATURA_SUCCESS, UPDATE_TIPO_SPAZZATURA_FAIL,
  DELETE_TIPO_SPAZZATURA, DELETE_TIPO_SPAZZATURA_SUCCESS, DELETE_TIPO_SPAZZATURA_FAIL
} from "../actions/tipologiaSpazzatura_actions"
import _ from "lodash"

export function tipologiaSpazzaturaReducer(state = { loading: false, error: undefined, tipologieSpazzatura: undefined }, action) {

  if (_.isEmpty(action) || _.isEmpty(action.type)) return state

  const newState = state

  switch (action.type) {

    case GET_TIPO_SPAZZATURA:
      newState.loading = true
      newState.error = undefined
      newState.tipologieSpazzatura = undefined
      break;
    case GET_TIPO_SPAZZATURA_SUCCESS:
      newState.loading = false
      newState.error = undefined
      newState.tipologieSpazzatura = action.payload
      break;
    case GET_TIPO_SPAZZATURA_FAIL:
      newState.loading = false
      newState.error = action.payload
      newState.tipologieSpazzatura = undefined
      break;
    case INSERT_TIPO_SPAZZATURA:
      break;
    case INSERT_TIPO_SPAZZATURA_SUCCESS:
      break;
    case INSERT_TIPO_SPAZZATURA_FAIL:
      break;
    case UPDATE_TIPO_SPAZZATURA:
      break;
    case UPDATE_TIPO_SPAZZATURA_SUCCESS:
      break;
    case UPDATE_TIPO_SPAZZATURA_FAIL:
      break;
    case DELETE_TIPO_SPAZZATURA:
      break;
    case DELETE_TIPO_SPAZZATURA_SUCCESS:
      break;
    case DELETE_TIPO_SPAZZATURA_FAIL:
      break;
    default:
      break;
  }

  return Object.assign({}, state, newState)
}
