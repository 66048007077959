import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Select,
  ModalFooter,
  Input,
  Table,
  Row,
  Col
} from "design-react-kit";
import { hideRaccoltaModal } from "../actions/raccoltaModal_actions";
import {hideQrCodeModal} from "../../qrCode/actions/qrCodeModal_actions"
import _, { intersection } from "lodash";

const RaccoltaModal = (props) => {
  const dispatch = useDispatch();

  const [utenza, setUtenza] = useState();
  const [tipologiaSpazzatura, setTipologiaSpazzatura] = useState();
  const [valoreQrCode, setValoreQrCode] = useState();
  const [statoQrCode, setStatoQrCode] = useState();

  const [utenzeOptions, setUtenzeOptions] = useState();
  const [tipologieSpazzaturaOptions, setTipologieSpazzaturaOptions] = useState();
  const [valoriQrOptions, setValoriQrOptions] = useState();
  const [statiQrCodeOptions, setStatiQrCodeOptions] = useState()

  const [tipologia, setTipologia] = useState();
  const [indirizzo, setIndirizzo] = useState();
  const [comune, setComune] = useState();
  const [intestatario, setIntestatario] = useState();
  const [quantita, setQuantita] = useState();


  const mastelloToEdit = useSelector((state) => state.raccoltaModal.obj);

  const qrCodeToSearch = useSelector((state) => state.qrCode.qrCodesRicerca);
  //console.log(qrCodeToSearch)

  const type = useSelector((state) => state.raccoltaModal.type);
  const header = useSelector((state) => state.raccoltaModal.header);
  const show = useSelector((state) => state.raccoltaModal.show);
  const f = useSelector((state) => state.raccoltaModal.function);

  let listaErroriTmp = [];
  const [listaErrori, setListaErrori] = useState([]);

  function close() {
    dispatch(hideQrCodeModal())
    dispatch(hideRaccoltaModal());
  }

  function erroriForm(listaErroriInfo){
    return listaErroriInfo.map(function (errore) {
      return(
        <p className="text-mess-alert">{errore}</p>
      )})  
  }



  function confirm() {
    listaErroriTmp = []
    let quantitaNumero = '';
    if(quantita != null) quantitaNumero = String(quantita).replaceAll(",","")
    if(quantitaNumero != null) quantitaNumero = quantitaNumero.replaceAll(".","")

    let quantitaNum = /^\d+$/.test(quantitaNumero);
    if(!quantitaNum) listaErroriTmp.push("- Quantità deve essere un numero")
    if(quantita !=null && quantita != '' && Number(quantitaNumero) < 0)listaErroriTmp.push("- Quantità non può essere un numero regativo")

    if(listaErroriTmp.length == 0){
      switch (type) {
        case "insert":
          const today = new Date();
          let data = "2023-01-01";//today.getFullYear() + '-' + (today.get() + 1) + '-' + today.getDate();
          let qtyNum = String(quantita).replaceAll(",",".")
          //setQuantita(Number(qtyNum))
          qtyNum = Number(qtyNum)
          dispatch(f( mastelloToEdit.data.mastello.id, data, qtyNum));
          close();
          break;
        case "edit":
          //console.log("edit", mastelloToEdit.id, utenza.value, tipologiaSpazzatura.value, valoreQrCode.label, statoQrCode.value);
          //dispatch(f(mastelloToEdit.id, utenza.value, tipologiaSpazzatura.value, valoreQrCode.label, statoQrCode.value));
          dispatch(f( mastelloToEdit.data.mastello.id, data, quantita));
          close();
          break;
        default:
          close();
      }
      setListaErrori([])
      close();
    }else{
      setListaErrori(listaErroriTmp)
    }
   
  }

  const defaultUtenzaValue = () => {
    switch (type) {
      case "insert":
        break;
      case "edit":
        return {
          label: mastelloToEdit.utenza.intestatario.cognome + " " + mastelloToEdit.utenza.intestatario.nome + ": " + mastelloToEdit.utenza.tipoImmobile.descrizione,
          value: mastelloToEdit.utenza.id
        };
      default:
        return {
          label: undefined,
          value: undefined,
        };
    }
  };

  const defaultTipologiaSpazzaturaValue = () => {
    switch (type) {
      case "insert":
        break;
      case "edit":
        return {
          label: mastelloToEdit.tipologiaSpazzatura.descrizione,
          value: mastelloToEdit.tipologiaSpazzatura.id
        };
      default:
        return {
          label: undefined,
          value: undefined,
        };
    }
  };

  const defaultValoreQrValue = () => {
    switch (type) {
      case "insert":
        break;
      case "edit":
        return {
          label: mastelloToEdit.qrCode.valore,
          value: mastelloToEdit.qrCode.id
        };
      default:
        return {
          label: undefined,
          value: undefined,
        };
    }
  };


  useEffect(() => {
    if (props.utenzeOptions === undefined) {
      setUtenzeOptions([]);
    } else {
      const tmp = [];
      _.forEach(props.utenzeOptions, (u) => {
        tmp.push({ value: u.id, label: "(CF): " + u.intestatario.userName + " (Cognome Nome): " + u.intestatario.cognome + " " + u.intestatario.nome + " " + "(Tipo Immobile): " + u.tipoImmobile.descrizione });
      });
      setUtenzeOptions(tmp);
    }
  }, [props.utenzeOptions]);

  useEffect(() => {
    if (props.tipologieSpazzaturaOptions === undefined) {
      setTipologieSpazzaturaOptions([]);
    } else {
      const tmp = [];
      _.forEach(props.tipologieSpazzaturaOptions, (ts) => {
        tmp.push({ value: ts.id, label: ts.descrizione });
      });
      setTipologieSpazzaturaOptions(tmp);
    }
  }, [props.tipologieSpazzaturaOptions]);

  useEffect(() => {
    if (props.qrCodeOptions === undefined) {
      setValoriQrOptions([]);
      setStatiQrCodeOptions([])
    } else {
      const tmp = [];
      _.forEach(props.qrCodeOptions, (qrc) => {
        if(qrc.stato === false) // se il qrCode è libero (non assegnato ad altro mastello)
          tmp.push({ value: qrc.valore, label: qrc.valore });
      });
      setValoriQrOptions(tmp);
      setStatiQrCodeOptions([
        {value: true, label: "assegnato"},
        {value: false, label: "libero"}
      ])
    }
  }, [props.qrCodeOptions]);

  useEffect(() => {
    if (type !== "edit" && mastelloToEdit != undefined) {
      setComune(mastelloToEdit.data.comune.denominazione)
      setIntestatario(mastelloToEdit.data.mastello.utenza.intestatario.userName + " " + mastelloToEdit.data.mastello.utenza.intestatario.cognome + " " + mastelloToEdit.data.mastello.utenza.intestatario.nome)
      setIndirizzo(mastelloToEdit.data.mastello.utenza.indirizzo)
      setTipologia(mastelloToEdit.data.mastello.tipologiaSpazzatura.descrizione)
    } else {
      // setComune(mastelloToEdit.data.comune.denominazione)
      // setIntestatario(mastelloToEdit.data.mastello.utenza.userName + " " + mastelloToEdit.data.mastello.utenza.cognome + " " + mastelloToEdit.data.mastello.utenza.nome)
      // setIndirizzo(mastelloToEdit.data.mastello.utenza.indirizzo)
      // setTipologia(mastelloToEdit.data.mastello.tipologiaSpazzatura.descrizione)
    }
  }, [type, mastelloToEdit]);

  return (
    <div>
      <Modal isOpen={show} labelledBy="utenzaModal" size="lg">
        <ModalHeader id="mastelloModal">{header}</ModalHeader>
        <ModalBody>
          <FormGroup>
          <div className="bootstrap-select-wrapper">
          <Input
                id="valoreQrCode"
                onChange={(e) => {
                  setQuantita(e.target.value);
                }}
                value={quantita}
                type="text"
                placeholder="Inserisci Quantità"
                aria-label="Inserisci Quantità"
              />
          </div>
          
          </FormGroup>

          <Table size="sm">
          <tbody>
            <tr>
              <th scope="row">Tipo Spazzatura</th>
              <td>{tipologia}</td>
            </tr>
            <tr>
              <th scope="row">Intestatario</th>
              <td>{intestatario}</td>
            </tr>
            <tr>
              <th scope="row">Indirizzo</th>
              <td>{indirizzo}</td>
            </tr>
            <tr>
              <th scope="row">Comune</th>
              <td>{comune}</td>
            </tr>
          </tbody>
        </Table>
        {listaErrori.length>0 &&
            <Row>
                <Col>
                  {erroriForm(listaErrori)}
                </Col>
            </Row>
          }
        </ModalBody>
        <ModalFooter>
          <span>
            <Button outline color='primary' onClick={() => close()}>ANNULLA</Button>
          </span>
          <span>
            <Button outline color='primary' onClick={() => confirm()}>CONFERMA</Button>
          </span>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default RaccoltaModal;
