export const SHOW_OPERATORE_MODAL = "showOperatoriModal"
export const HIDE_OPERATORE_MODAL = "hideOperatoriModal"

export function showOperatoriModal(type, header, f, obj){
  return {
      type: SHOW_OPERATORE_MODAL,
      payload:{
        type: type,
        header: header,
        function: f,
        obj: obj
    }
  }
}

export function hideOperatoreModal(){
  return {
      type: HIDE_OPERATORE_MODAL
  }
}