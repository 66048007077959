import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Select,
  ModalFooter,
  Row,
  Col
} from "design-react-kit";
import { hideMastelloModal } from "../actions/mastelloModal_actions";
import _ from "lodash";

const MastelloModal = (props) => {
  const dispatch = useDispatch();

  const [utenza, setUtenza] = useState();
  const [tipologiaSpazzatura, setTipologiaSpazzatura] = useState();
  const [valoreQrCode, setValoreQrCode] = useState();
  const [statoQrCode, setStatoQrCode] = useState();

  const [utenzeOptions, setUtenzeOptions] = useState();
  const [tipologieSpazzaturaOptions, setTipologieSpazzaturaOptions] = useState();
  const [valoriQrOptions, setValoriQrOptions] = useState();
  const [statiQrCodeOptions, setStatiQrCodeOptions] = useState()

  const mastelloToEdit = useSelector((state) => state.mastelloModal.obj);

  const type = useSelector((state) => state.mastelloModal.type);
  const header = useSelector((state) => state.mastelloModal.header);
  const show = useSelector((state) => state.mastelloModal.show);
  const f = useSelector((state) => state.mastelloModal.function);

  const [listaErrori, setListaErrori] = useState([]);
  let listaErroriTmp = [];

  function erroriForm(listaErroriInfo){
    return listaErroriInfo.map(function (errore) {
      return(
        <p className="text-mess-alert">{errore}</p>
      )})  
  }

  function close() {
    dispatch(hideMastelloModal());
  }

  function confirm() {
    listaErroriTmp = [];

    if(utenza == null || utenza.value === '') listaErroriTmp.push("- Utenza è un campo obbligatorio")
    if(tipologiaSpazzatura == null || tipologiaSpazzatura.value === '') listaErroriTmp.push("- Tipologia Spazzatura è un campo obbligatorio")
    if(valoreQrCode == null || valoreQrCode.value === '') listaErroriTmp.push("- Valore QrCode è un campo obbligatorio")

    if(listaErroriTmp.length == 0){
      switch (type) {
        case "insert":
          dispatch(f(utenza.value, tipologiaSpazzatura.value, valoreQrCode.label));
          break;
        case "edit":
          console.log("edit", mastelloToEdit.id, utenza.value, tipologiaSpazzatura.value, valoreQrCode.label, statoQrCode.value);
          dispatch(f(mastelloToEdit.id, utenza.value, tipologiaSpazzatura.value, valoreQrCode.label, statoQrCode.value));
          close();
          break;
        default:
          close();
      }
      setListaErrori([])
      close();
    }else{
      setListaErrori(listaErroriTmp)
    }
    
  }

  const defaultUtenzaValue = () => {
    switch (type) {
      case "insert":
        break;
      case "edit":
        return {
          label: mastelloToEdit.utenza.intestatario.cognome + " " + mastelloToEdit.utenza.intestatario.nome + ": " + mastelloToEdit.utenza.tipoImmobile.descrizione,
          value: mastelloToEdit.utenza.id
        };
      default:
        return {
          label: undefined,
          value: undefined,
        };
    }
  };

  const defaultTipologiaSpazzaturaValue = () => {
    switch (type) {
      case "insert":
        break;
      case "edit":
        return {
          label: mastelloToEdit.tipologiaSpazzatura.descrizione,
          value: mastelloToEdit.tipologiaSpazzatura.id
        };
      default:
        return {
          label: undefined,
          value: undefined,
        };
    }
  };

  const defaultValoreQrValue = () => {
    switch (type) {
      case "insert":
        break;
      case "edit":
        return {
          label: mastelloToEdit.qrCode.valore,
          value: mastelloToEdit.qrCode.id
        };
      default:
        return {
          label: undefined,
          value: undefined,
        };
    }
  };


  useEffect(() => {
    if (props.utenzeOptions === undefined) {
      setUtenzeOptions([]);
    } else {
      const tmp = [];
      _.forEach(props.utenzeOptions, (u) => {
        tmp.push({ value: u.id, label: "(CF): " + u.intestatario.userName + " (Cognome Nome): " + u.intestatario.cognome + " " + u.intestatario.nome + " " + "(Tipo Immobile): " + u.tipoImmobile.descrizione });
      });
      setUtenzeOptions(tmp);
    }
  }, [props.utenzeOptions]);

  useEffect(() => {
    if (props.tipologieSpazzaturaOptions === undefined) {
      setTipologieSpazzaturaOptions([]);
    } else {
      const tmp = [];
      _.forEach(props.tipologieSpazzaturaOptions, (ts) => {
        tmp.push({ value: ts.id, label: ts.descrizione });
      });
      setTipologieSpazzaturaOptions(tmp);
    }
  }, [props.tipologieSpazzaturaOptions]);

  useEffect(() => {
    if (props.qrCodeOptions === undefined) {
      setValoriQrOptions([]);
      setStatiQrCodeOptions([])
    } else {
      const tmp = [];
      _.forEach(props.qrCodeOptions, (qrc) => {
        if(qrc.stato === false) // se il qrCode è libero (non assegnato ad altro mastello)
          tmp.push({ value: qrc.valore, label: qrc.valore });
      });
      setValoriQrOptions(tmp);
      setStatiQrCodeOptions([
        {value: true, label: "assegnato"},
        {value: false, label: "libero"}
      ])
    }
  }, [props.qrCodeOptions]);

  useEffect(() => {
    if (type !== "edit" && mastelloToEdit === undefined) {
      setUtenza(undefined);
      setTipologiaSpazzatura(undefined);
      setValoreQrCode(undefined);
      setStatoQrCode(undefined)
    } else {
      setUtenza({
        label: mastelloToEdit.proprietario + ": " + mastelloToEdit.utenza.tipoImmobile.descrizione,
        value: mastelloToEdit.utenza.id
      });
      setTipologiaSpazzatura({
        label: mastelloToEdit.tipologiaSpazzatura.descrizione,
        value: mastelloToEdit.tipologiaSpazzatura.id
      });
      setValoreQrCode({
        label: mastelloToEdit.qrCode.valore,
        value: mastelloToEdit.qrCode.id
      });
      setStatoQrCode({
        label: mastelloToEdit.qrCode.stato,
        value: mastelloToEdit.qrCode.id
      });
    }
  }, [type, mastelloToEdit]);

  return (
    <div>
      <Modal isOpen={show} labelledBy="utenzaModal" size="lg">
        <ModalHeader id="mastelloModal">{header}</ModalHeader>
        <ModalBody>
          <FormGroup>
            <div className="bootstrap-select-wrapper">
              <label htmlFor="selectUtenza">Utenza</label>
              <Select
                id="utenza"
                onChange={(selectedOption) => {
                  setUtenza(selectedOption);
                }}
                options={utenzeOptions}
                defaultValue={defaultUtenzaValue()}
                placeholder="Seleziona utenza"
                aria-label="Seleziona utenza"
              />
            </div>
          </FormGroup>
          <FormGroup>
            <div className="bootstrap-select-wrapper">
              <label htmlFor="selectTipologiaSpazzatura">Tipologia spazzatura</label>
              <Select
                id="tipologiaSpazzatura"
                onChange={(selectedOption) => {
                  setTipologiaSpazzatura(selectedOption);
                }}
                options={tipologieSpazzaturaOptions}
                defaultValue={defaultTipologiaSpazzaturaValue()}
                placeholder="Seleziona tipologia spazzatura"
                aria-label="Seleziona tipologia spazzatura"
              />
            </div>
          </FormGroup>
          <FormGroup>
            <div className="bootstrap-select-wrapper">
              <label htmlFor="selectValoreQr">Valore QrCode</label>
              <Select
                id="valoreQr"
                onChange={(selectedOption) => {
                  setValoreQrCode(selectedOption);
                }}
                options={valoriQrOptions}
                defaultValue={defaultValoreQrValue()}
                placeholder="Seleziona valore qrCode"
                aria-label="Seleziona valore qrCode"
              />
            </div>
          </FormGroup>
          {listaErrori.length>0 &&
            <Row>
                <Col>
                  {erroriForm(listaErrori)}
                </Col>
            </Row>
          }
        </ModalBody>
        <ModalFooter>
          <span>
            <Button outline color='primary' onClick={() => close()}>ANNULLA</Button>
          </span>
          <span>
            <Button outline color='primary' onClick={() => confirm()}>CONFERMA</Button>
          </span>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default MastelloModal;
