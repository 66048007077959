import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  ModalFooter,
  Select,
  Input,
  Row,
  Col
} from "design-react-kit";
import { hideCalendarioModal } from "../actions/calendarioModal_actions"
import _ from "lodash";

const CalendarioModal = (props) => {

  const dispatch = useDispatch();

  const [collaborazione, setCollaborazione] = useState();
  const [zona, setZona] = useState();
  const [lunedi, setLunedi] = useState();
  const [martedi, setMartedi] = useState();
  const [mercoledi, setMercoledi] = useState();
  const [giovedi, setGiovedi] = useState();
  const [venerdi, setVenerdi] = useState();
  const [sabato, setSabato] = useState();
  const [domenica, setDomenica] = useState();

  const [collaborazioniOptions, setCollaborazioniOptions] = useState();
  const [tipologieSpazzaturaOptions, setTipologieSpazzaturaOptions] = useState();

  const calendarioToEdit = useSelector((state) => state.calendarioModal.obj);

  const type = useSelector((state) => state.calendarioModal.type);
  const header = useSelector((state) => state.calendarioModal.header);
  const show = useSelector((state) => state.calendarioModal.show);
  const f = useSelector((state) => state.calendarioModal.function);

  const [listaErrori, setListaErrori] = useState([]);
  let listaErroriTmp = [];

  function erroriForm(listaErroriInfo){
    return listaErroriInfo.map(function (errore) {
      return(
        <p className="text-mess-alert">{errore}</p>
      )})  
  }

  const confirm = () => {
    listaErroriTmp = [];
    if(collaborazione == null || collaborazione.value === '') listaErroriTmp.push("- Ente è un campo obbligatorio")
    if(zona == null || zona === '') listaErroriTmp.push("- Zona è un campo obbligatorio")
    if(lunedi == null || lunedi.value === '') listaErroriTmp.push("- Lunedì è un campo obbligatorio")
    if(martedi == null || martedi.value === '') listaErroriTmp.push("- Martedì è un campo obbligatorio")
    if(mercoledi == null || mercoledi.value === '') listaErroriTmp.push("- Mercoledì è un campo obbligatorio")
    if(giovedi == null || giovedi.value === '') listaErroriTmp.push("- Giovedì è un campo obbligatorio")
    if(venerdi == null || venerdi.value === '') listaErroriTmp.push("- Venerdì è un campo obbligatorio")
    if(sabato == null || sabato.value === '') listaErroriTmp.push("- Sabato è un campo obbligatorio")
    if(domenica == null || domenica.value === '') listaErroriTmp.push("- Domenica è un campo obbligatorio")
    
    
    if(listaErroriTmp.length == 0){
      switch (type) {
        case "insert":
          listaErroriTmp = [];
          dispatch(f(
            collaborazione.value, zona.toUpperCase(), lunedi.label, martedi.label, mercoledi.label, giovedi.label, venerdi.label, sabato.label, domenica.label)
          );
          break;
        case "edit":
          listaErroriTmp = [];
          dispatch(f(
            calendarioToEdit.id, collaborazione.value, zona.toUpperCase(), lunedi.label, martedi.label, mercoledi.label, giovedi.label, venerdi.label, sabato.label, domenica.label)
          );
          close();
          break;
        default:
          listaErroriTmp = [];
          close();
      }
      setListaErrori([])
      close();
    }else{
      setListaErrori(listaErroriTmp)
    }
    
  };

  const close = () => dispatch(hideCalendarioModal());

  
  const defaultCollaborazioneValue = () => {
    switch (type) {
      case "insert":
        break;
      case "edit":
        return {
          label: calendarioToEdit.collaborazione.ente.denominazione,
          value: calendarioToEdit.collaborazione.id,
        };
      default:
        return {
          label: undefined,
          value: undefined,
        };
    }
  };

  const defaultLunediValue = () => {
    switch (type) {
      case "insert":
        break;
      case "edit":
        return {
          label: calendarioToEdit.lunedi,
          value: calendarioToEdit.lunedi,
        };
      default:
        return {
          label: undefined,
          value: undefined,
        };
    }
  };

  const defaultMartediValue = () => {
    switch (type) {
      case "insert":
        break;
      case "edit":
        return {
          label: calendarioToEdit.martedi,
          value: calendarioToEdit.martedi,
        };
      default:
        return {
          label: undefined,
          value: undefined,
        };
    }
  };

  const defaultMercolediValue = () => {
    switch (type) {
      case "insert":
        break;
      case "edit":
        return {
          label: calendarioToEdit.mercoledi,
          value: calendarioToEdit.mercoledi,
        };
      default:
        return {
          label: undefined,
          value: undefined,
        };
    }
  };

  const defaultGiovediValue = () => {
    switch (type) {
      case "insert":
        break;
      case "edit":
        return {
          label: calendarioToEdit.giovedi,
          value: calendarioToEdit.giovedi,
        };
      default:
        return {
          label: undefined,
          value: undefined,
        };
    }
  };

  const defaultVenerdiValue = () => {
    switch (type) {
      case "insert":
        break;
      case "edit":
        return {
          label: calendarioToEdit.venerdi,
          value: calendarioToEdit.venerdi,
        };
      default:
        return {
          label: undefined,
          value: undefined,
        };
    }
  };

  const defaultSabatoValue = () => {
    switch (type) {
      case "insert":
        break;
      case "edit":
        return {
          label: calendarioToEdit.sabato,
          value: calendarioToEdit.sabato,
        };
      default:
        return {
          label: undefined,
          value: undefined,
        };
    }
  };

  const defaultDomenicaValue = () => {
    switch (type) {
      case "insert":
        break;
      case "edit":
        return {
          label: calendarioToEdit.domenica,
          value: calendarioToEdit.domenica,
        };
      default:
        return {
          label: undefined,
          value: undefined,
        };
    }
  };

  useEffect(() => {
    if (props.collaborazioniOptions === undefined) {
      setCollaborazioniOptions([]);
    } else {
      const tmp = [];
      _.forEach(props.collaborazioniOptions, (c) => {
        tmp.push({ value: c.id, label: c.ente.denominazione });
      });
      setCollaborazioniOptions(tmp);
    }
  }, [props.collaborazioniOptions]);

  useEffect(() => {
    if (props.tipologieSpazzaturaOptions === undefined) {
      setTipologieSpazzaturaOptions([]);
    } else {
      const tmp = [];
      _.forEach(props.tipologieSpazzaturaOptions, (tS) => {
        tmp.push({ value: tS.id, label: tS.descrizione });
      });
      setTipologieSpazzaturaOptions(tmp);
    }
  }, [props.tipologieSpazzaturaOptions]);

  useEffect(() => {
    if (type !== "edit") {
      setCollaborazione(undefined);
      setZona(undefined);
      setLunedi(undefined);
      setMartedi(undefined);
      setMercoledi(undefined);
      setGiovedi(undefined);
      setVenerdi(undefined);
      setSabato(undefined);
      setDomenica(undefined);
    } else {
      setCollaborazione({
        label: calendarioToEdit.collaborazione.ente.denominazione,
        value: calendarioToEdit.collaborazione.id,
      });
      setZona(calendarioToEdit.zona);
      setLunedi({
        label: calendarioToEdit.lunedi,
        value: calendarioToEdit.lunedi,
      });
      setMartedi({
        label: calendarioToEdit.martedi,
        value: calendarioToEdit.martedi,
      });
      setMercoledi({
        label: calendarioToEdit.mercoledi,
        value: calendarioToEdit.mercoledi,
      });
      setGiovedi({
        label: calendarioToEdit.giovedi,
        value: calendarioToEdit.giovedi,
      });
      setVenerdi({
        label: calendarioToEdit.venerdi,
        value: calendarioToEdit.venerdi,
      });
      setSabato({
        label: calendarioToEdit.sabato,
        value: calendarioToEdit.sabato,
      });
      setDomenica({
        label: calendarioToEdit.domenica,
        value: calendarioToEdit.domenica,
      });
    }
  }, [calendarioToEdit, type]);

  return (
    <div>
      <Modal isOpen={show} labelledBy="calendarioModal">
        <ModalHeader id="calendarioModal">{header}</ModalHeader>
        <ModalBody>
          <FormGroup>
            <div className="bootstrap-select-wrapper">
              <label htmlFor="selectCollaborazione">Ente(Collaborazione)</label>
              <Select
                classNamePrefix="react-select"
                id="ente"
                onChange={(selectedOption) => {
                  setCollaborazione(selectedOption);
                }}
                options={collaborazioniOptions}
                defaultValue={defaultCollaborazioneValue()}
                placeholder="Seleziona ente"
                aria-label="Seleziona ente"
              />
            </div>
          </FormGroup>
          <FormGroup>
            <div className="bootstrap-select-wrapper">
              <label htmlFor="inputZona">Zona Raccolta</label>
              <Input
                id="inputZona"
                onChange={(e) => {
                  setZona(e.target.value);
                }}
                value={zona}
                type="text"
                placeholder="Inserisci Zona"
                aria-label="Inserisci Zona"
              />
            </div>
          </FormGroup>
          <FormGroup>
            <div className="bootstrap-select-wrapper">
              <label htmlFor="selectLunedi">Lunedì</label>
              <Select
                classNamePrefix="react-select"
                id="lunedi"
                onChange={(selectedOption) => {
                  setLunedi(selectedOption);
                }}
                options={tipologieSpazzaturaOptions}
                defaultValue={defaultLunediValue()}
                placeholder="Seleziona tipologia spazzatura"
                aria-label="Seleziona tipologia spazzatura"
              />
            </div>
          </FormGroup>
          <FormGroup>
            <div className="bootstrap-select-wrapper">
              <label htmlFor="selectMartedi">Martedì</label>
              <Select
                classNamePrefix="react-select"
                id="martedi"
                onChange={(selectedOption) => {
                  setMartedi(selectedOption);
                }}
                options={tipologieSpazzaturaOptions}
                defaultValue={defaultMartediValue()}
                placeholder="Seleziona tipologia spazzatura"
                aria-label="Seleziona tipologia spazzatura"
              />
            </div>
          </FormGroup>
          <FormGroup>
            <div className="bootstrap-select-wrapper">
              <label htmlFor="selectMercoledi">Mercoledì</label>
              <Select
                classNamePrefix="react-select"
                id="mercoledi"
                onChange={(selectedOption) => {
                  setMercoledi(selectedOption);
                }}
                options={tipologieSpazzaturaOptions}
                defaultValue={defaultMercolediValue()}
                placeholder="Seleziona tipologia spazzatura"
                aria-label="Seleziona tipologia spazzatura"
              />
            </div>
          </FormGroup>
          <FormGroup>
            <div className="bootstrap-select-wrapper">
              <label htmlFor="selectGiovedi">Giovedì</label>
              <Select
                classNamePrefix="react-select"
                id="giovedi"
                onChange={(selectedOption) => {
                  setGiovedi(selectedOption);
                }}
                options={tipologieSpazzaturaOptions}
                defaultValue={defaultGiovediValue()}
                placeholder="Seleziona tipologia spazzatura"
                aria-label="Seleziona tipologia spazzatura"
              />
            </div>
          </FormGroup>
          <FormGroup>
            <div className="bootstrap-select-wrapper">
              <label htmlFor="selectVenerdi">Venerdì</label>
              <Select
                classNamePrefix="react-select"
                id="venerdi"
                onChange={(selectedOption) => {
                  setVenerdi(selectedOption);
                }}
                options={tipologieSpazzaturaOptions}
                defaultValue={defaultVenerdiValue()}
                placeholder="Seleziona tipologia spazzatura"
                aria-label="Seleziona tipologia spazzatura"
              />
            </div>
          </FormGroup>
          <FormGroup>
            <div className="bootstrap-select-wrapper">
              <label htmlFor="selectSabato">Sabato</label>
              <Select
                classNamePrefix="react-select"
                id="sabato"
                onChange={(selectedOption) => {
                  setSabato(selectedOption);
                }}
                options={tipologieSpazzaturaOptions}
                defaultValue={defaultSabatoValue()}
                placeholder="Seleziona tipologia spazzatura"
                aria-label="Seleziona tipologia spazzatura"
              />
            </div>
          </FormGroup>
          <FormGroup>
            <div className="bootstrap-select-wrapper">
              <label htmlFor="selectDomenica">Domenica</label>
              <Select
                classNamePrefix="react-select"
                id="domenica"
                onChange={(selectedOption) => {
                  setDomenica(selectedOption);
                }}
                options={tipologieSpazzaturaOptions}
                defaultValue={defaultDomenicaValue()}
                placeholder="Seleziona tipologia spazzatura"
                aria-label="Seleziona tipologia spazzatura"
              />
            </div>
          </FormGroup>
          {listaErrori.length>0 &&
            <Row>
                <Col>
                  {erroriForm(listaErrori)}
                </Col>
            </Row>
          }
        </ModalBody>
        <ModalFooter>
          <span>
            <Button outline color="primary" onClick={() => close()}>
              ANNULLA
            </Button>
          </span>
          <span>
            <Button outline color="primary" onClick={() => confirm()}>
              CONFERMA
            </Button>
          </span>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default CalendarioModal;
