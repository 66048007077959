import React, { useEffect, useState } from "react";

const CalendarioUtente = (props) => {
    if(props != null){
        props.dati.map((calendario, i) =>{
            console.log(calendario);
        return (
           
              <>
              <div>aaaaaa</div>
              </>
        )
        })
          
    }
        
}

export default CalendarioUtente;