export const SHOW_POPUP = 'showPopup'
export const HIDE_POPUP = 'hidePopup'

export function showPopup(type, header, message, f, arg){ 
    return {
        type: SHOW_POPUP,
        payload:{
            type: type,
            header: header,
            message: message,
            function: f,
            arg: arg
        }
    }
}

export function hidePopup(){
    return {
        type: HIDE_POPUP 
    }
}