import {
  GET_RACCOLTA, GET_RACCOLTA_SUCCESS, GET_RACCOLTA_FAIL,
  INSERT_RACCOLTA, INSERT_RACCOLTA_SUCCESS, INSERT_RACCOLTA_FAILED,
  UPDATE_RACCOLTA, UPDATE_RACCOLTA_SUCCESS, UPDATE_RACCOLTA_FAILED,
  DELETE_RACCOLTA, DELETE_RACCOLTA_SUCCESS, DELETE_RACCOLTA_FAILED,
  STAMPA_RACCOLTA, STAMPA_RACCOLTA_SUCCESS, STAMPA_RACCOLTA_FAILED
} from "../actions/raccolta_actions";
import _ from "lodash";

export function raccoltaReducer(state = { loading: false, error: undefined, insertSuccessData: undefined, mastelli: undefined }, action) {

  if (_.isEmpty(action) || _.isEmpty(action.type)) return state

  const newState = state

  switch (action.type) {

    case GET_RACCOLTA:
      newState.loading = true
      newState.error = undefined
      newState.raccolta = undefined
      break;
    case GET_RACCOLTA_SUCCESS:
      newState.loading = false
      newState.error = undefined
      newState.raccolta = action.payload
      break;
    case GET_RACCOLTA_FAIL:
      newState.loading = false
      newState.error = action.payload
      newState.raccolta = undefined
      break;
    case INSERT_RACCOLTA:
      break;
    case INSERT_RACCOLTA_SUCCESS:
      break;
    case INSERT_RACCOLTA_FAILED:
      break;
    case UPDATE_RACCOLTA:
      break;
    case UPDATE_RACCOLTA_SUCCESS:
      break;
    case UPDATE_RACCOLTA_FAILED:
      break;
    case DELETE_RACCOLTA:
      break;
    case DELETE_RACCOLTA_SUCCESS:
      break;
    case DELETE_RACCOLTA_FAILED:
      break;
    case STAMPA_RACCOLTA:
      break;
    case STAMPA_RACCOLTA_SUCCESS:
      break;
    case STAMPA_RACCOLTA_FAILED:
      break;
    default:
      break;
  }

  return Object.assign({}, state, newState)
}