import { GET_TIPI_IMMOBILI, GET_TIPI_IMMOBILI_SUCCESS, GET_TIPI_IMMOBILI_FAIL } from "../actions/tipoImmobile_actions";
import _ from "lodash";

export function tipoImmobileReducer(state = { tipiImmobili: undefined }, action) {
  if (_.isEmpty(action) || _.isEmpty(action.type)) return state
  const newState = state
  switch (action.type) {
    case GET_TIPI_IMMOBILI:
      newState.tipiImmobili = undefined
      break;
    case GET_TIPI_IMMOBILI_SUCCESS:
      newState.tipiImmobili = action.payload
      break;
    case GET_TIPI_IMMOBILI_FAIL:
      newState.tipiImmobili = undefined
      break;
    default:
      break;
  }
  return Object.assign({}, state, newState)
}