import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  ModalFooter,
  Input,
  Select
} from "design-react-kit";
import { hideUserModal, showUserModal } from "../dashboard/dashboardModal_action"
import _ from "lodash";

const ProfiloModal = (props) => {

  const dispatch = useDispatch();

  const [telefono, setTelefono] = useState();
  const [email, setMail] = useState();
  const [emailPec, setMailPec] = useState();

  const userInfoToEdit = useSelector((state) => state.userInfoModal);

  console.log(localStorage)

  const type = useSelector((state) => state.userInfoModal.type);
  const header = useSelector((state) => state.userInfoModal.header);
  const show = useSelector((state) => state.userInfoModal.show);
  const f = useSelector((state) => state.userInfoModal.function);


  const confirm = () => {

    switch (type) {
      case "insert":
        dispatch(f(telefono, email, emailPec));
        break;
      case "edit":
        // let enteId = ente.value
        // if(ente.value === undefined || ente.value === null){
        //   enteId = ente.id
        // }
        if(userInfoToEdit.obj.tipologiaUtente === "ENTE" || userInfoToEdit.obj.tipologiaUtente === "UTENTE" || userInfoToEdit.obj.tipologiaUtente === "OPERATORE")
        {
          dispatch(f(userInfoToEdit.obj.id,userInfoToEdit.obj.idSecondary, telefono, email, "default@webedifferentpec.it", userInfoToEdit.obj.tipologiaUtente));
        }else{
          dispatch(f(userInfoToEdit.obj.id,userInfoToEdit.obj.idSecondary, telefono, email, emailPec, userInfoToEdit.obj.tipologiaUtente));
        }
        
        close();
        break;
      default:
        close();
    }
    close();
  };

  const close = () => dispatch(hideUserModal());

  console.log(userInfoToEdit.obj)

  useEffect(() => {
    if (type !== "edit" && userInfoToEdit.obj === undefined) {
      setTelefono(undefined);
      setMail(undefined);
      setMailPec(undefined);

    } else {
    
      setTelefono(userInfoToEdit.obj.telefono);
      setMail(userInfoToEdit.obj.email);
      setMailPec(userInfoToEdit.obj.emailPec);

    }
  }, [type, userInfoToEdit]);

  
  return (
    <div>
      <Modal isOpen={show} labelledBy="userModal">
        <ModalHeader id="userModal">{header}</ModalHeader>
        <ModalBody>
          <FormGroup>
            <div className="bootstrap-select-wrapper">
              <Input
                id="telefono"
                onChange={(e) => {
                  setTelefono(e.target.value);
                }}
                value={telefono}
                type="text"
                placeholder="Inserisci Telefono"
                aria-label="Inserisci Telefono"
              />
              <Input
                id="email"
                onChange={(e) => {
                  setMail(e.target.value);
                }}
                value={email}
                type="text"
                placeholder="Inserisci Email"
                aria-label="Inserisci Email"
              />
              {userInfoToEdit != null && userInfoToEdit.tipologiaUtente === "COMUNE" &&
                <Input
                id="emailpec"
                onChange={(e) => {
                  setMailPec(e.target.value);
                }}
                value={emailPec}
                type="text"
                placeholder="Inserisci Email Pec"
                aria-label="Inserisci Email Pec"
              />
              }
              
            </div>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <span>
            <Button outline color='primary' onClick={() => close()}>ANNULLA</Button>
          </span>
          <span>
            <Button outline color='primary' onClick={() => confirm()}>CONFERMA</Button>
          </span>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ProfiloModal;
